import { connect } from 'react-redux';

import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapDispatchToProps = (dispatch) => ({
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

const container = connect(null, mapDispatchToProps);

export default container;
