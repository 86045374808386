import React from 'react';

import PlaylistSortableView from '../PlaylistSortableView';

const Playlist = (props) => {
  const {
    pid,
    currentYtid,
    customOrderedYtids,
    videoState,
    playlistState,
    userState,
    userhistoryState,
    sortBy,
    uid,
    ownUid,
  } = props;

  if (!pid) {
    return null;
  }
  return (
    <PlaylistSortableView
      pid={pid}
      currentYtid={currentYtid}
      customOrderedYtids={customOrderedYtids}
      videoState={videoState}
      playlistState={playlistState}
      userState={userState}
      userhistoryState={userhistoryState}
      sortBy={sortBy}
      editable={uid === ownUid}
    />
  );
};

export default Playlist;
