import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';

import { Ad } from 'modules/ads';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    minHeight: 500,
    background: theme.palette.paperColor
  },
  slot728: {
    minHeight: 90,
    background: theme.palette.paperColor
  },
  slot300: {
    minWidth: 300,
    minHeight: 250,
    background: theme.palette.paperColor,
    textAlign: 'center'
  }
}));

function MainColumn() {
  const classes = useStyles();

  return (
    <>
      <Hidden xsDown>
        <Ad
          className={classes.slot728}
          adContainer="top_ad_fixed"
          adName="desktop_top_ad_fixed"
          slotName="top_ad"
          divId={'div-gpt-ad-1468352687772-0'}
          noReport={true}
        />
      </Hidden>
      <Card className={classes.card} style={{ marginBottom: '10px' }}>
        <div></div>
      </Card>
      <Hidden xsDown>
        <Ad className={classes.slot728} />
      </Hidden>
      <Hidden smUp>
        <Ad className={classes.slot300} />
      </Hidden>
      <Card className={classes.card}>
        <div></div>
      </Card>
    </>
  );
}

MainColumn.propTypes = {
  loading: PropTypes.bool
};

export default MainColumn;
