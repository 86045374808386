import { connect } from 'react-redux';

import { setSliderStart } from 'store/userhistory/userhistory.actions';

const mapStateToProps = (state) => ({
  articleState: state.article,
  playerState: state.player,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLyricsStart: (ytid, slider) => dispatch(setSliderStart(ytid, slider)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
