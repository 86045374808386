import { takeLatest, call, put, all, fork, select } from "redux-saga/effects";
import _ from "lodash";

import axios from "instances/server";

import * as ArticleType from "../article/article.types";
import * as PlaylistType from "./playlist.types";
import * as UserhistoryType from "../userhistory/userhistory.types";
import * as YourLorType from "../yourlor/yourlor.types";
import * as SearchType from "../search/search.types";

import * as playlistAction from "./playlist.actions";
import {
  playFeature,
  setRadioMode,
  viewPlayerVideoStart,
} from "../player/player.actions";
import {
  getVideoMetadataStart,
  getVideoStatsStart,
} from "../video/video.actions";
import { setCurrentTab } from "../siteCoordinator/siteCoordinator.actions";

import Feature from "constants/Feature";
import SectionTab from "constants/SectionTab";
import { isSystemPlaylist } from "./playlist.utils";
import {
  getArticlesPageStart,
  getStaffpicksPageStart,
} from "store/article/article.actions";
import { getNextResultsPageStart } from "store/search/search.actions";
import {
  getPlaysPageStart,
  getHeartsPageStart,
} from "store/userhistory/userhistory.actions";

const getPlaylist = (state) => state.playlist;
const getStreams = (state) => state.streams;
const getSearchState = (state) => state.search;
const getUserhistoryState = (state) => state.userhistory;
const getArticleState = (state) => state.article;

export function* initializeStore() {
  yield all([
    fork(getAllUserPlaylists),
    yield put(playlistAction.getFollowedPlaylistStart()),
  ]);
}

export function* setCurrentPlaylistAsync({ payload: { pid } }) {
  const playlistState = yield select(getPlaylist);
  const streamState = yield select(getStreams);

  // Track non lor made user-defined playlist actions
  const changed = playlistState.currentPlaylist !== pid;
  // if (
  //   changed &&
  //   !_.includes(pid, 'lor:') &&
  //   nullish(streamState.streamPlaying)
  // ) {
  //   analytics.actions.trackAction('Played Playlist', pid)
  // }

  let feature = Feature.Playlist;
  if (
    pid === UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS ||
    pid === UserhistoryType.PLAYLIST_NAME_YOUR_REPEAT_ORDERED
  ) {
    feature = Feature.History;
  } else if (pid === "lor:history:favorites") {
    feature = Feature.Favorites;
  } else if (pid === "lor:search") {
    feature = Feature.Search;
  } else if (!_.isNil(streamState.streamPlaying)) {
    feature = Feature.Stream;
  } else if (_.includes(pid, "lor:articles")) {
    feature = Feature.Reviews;
  } else if (pid === YourLorType.DISCOVERY_PLAYLIST) {
    feature = Feature.YourLOR;
  }

  yield put(playFeature(feature));

  yield put(playlistAction.setCurrentPlaylistSuccess({ pid, changed }));
}

export function* createPlaylistAsync({ payload: { name } }) {
  // console.log('createPlaylistAsync...', name);

  // analytics.actions.trackAction('New Playlist', name)

  try {
    const payload = {
      name,
    };
    const { data } = yield axios.post("/playlist/create", payload);
    yield put(playlistAction.createPlaylistSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.createPlaylistFail(err));
  }
}

export function* createPlaylistAddSongAsync({ payload: { name, ytids } }) {
  // console.log('createPlaylistAddSongAsync...', name);

  // analytics.actions.trackAction('New Playlist', name)

  try {
    const { data } = yield axios.post("/playlist/create", {
      name,
    });
    let allYtids = _.uniq(ytids);
    let pid = data.pid;

    yield all([
      put(getVideoMetadataStart(allYtids)),
      put(getVideoStatsStart(allYtids)),
    ]);

    const body = { ytids };
    // console.log({ body });

    const { data: data1 } = yield axios.post(
      `/playlist/add-ytids/${pid}`,
      body
    );

    yield put(playlistAction.createPlaylistAddSongSuccess(data1));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.createPlaylistAddSongFail(err));
  }
}

export function* getAllUserPlaylists(cursor = "") {
  const payload = {
    payload: { uid: null, cursor },
  };
  const data = yield call(getUserPlaylistPageAsync, payload);
  console.log(data, "we are hereeeee");
  if (data && data?.next && data?.next != "") {
    yield* getAllUserPlaylists(data.next);
  }
}

export function* getUserPlaylistPageAsync({ payload: { uid, cursor } }) {
  // console.log('getUserPlaylistPageAsync...');

  // analytics.actions.trackAction('New Playlist', name)
  let query = { cursor, uid };
  let result;
  console.log("playlist query");
  console.log(query);
  try {
    const { data } = yield axios.get("/playlist/page", {
      params: query,
    });
    // console.log({ data });
    // let allYtids = [];
    // for (let p of data.res) {
    //   allYtids = allYtids.concat(p.ytids);
    // }
    // allYtids = _.uniq(allYtids);
    result = data;
    yield put(playlistAction.getUserPlaylistPageSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.getUserPlaylistPageFail(err));
  }

  return result;
}

export function* getUserPlaylistAsync({ payload: { pids } }) {
  console.log("getUserPlaylistAsync...");
  pids = pids.map((pid) => `pid:${pid}`);

  try {
    // console.log({ pids });
    const { data } = yield axios.get("/playlist", {
      params: { key: pids },
    });
    // console.log({ data });
    const playlistsFiltered = _.compact(data);

    yield put(playlistAction.getUserPlaylistSuccess(playlistsFiltered));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.getUserPlaylistFail(err));
  }
}

export function* openPlaylistAsync({ payload: { pid } }) {
  // console.log('openPlaylistAsync...', pid);

  const playlistState = yield select(getPlaylist);

  const allYtids = playlistState.playlists[pid].ytids;

  yield all([
    put(getVideoMetadataStart(allYtids)),
    put(getVideoStatsStart(allYtids)),
  ]);

  yield put(playlistAction.setCurrentViewdPlaylist(pid));
  yield put(setCurrentTab(SectionTab.Playlists));

  // uiUtils.scrollToMainContentArea()
}

export function* playPlaylistAsync({ payload: { pid, ytid } }) {
  // console.log('playPlaylistAsync...', pid);

  const playlistState = yield select(getPlaylist);

  const thisPlaylist = playlistState.playlists[pid];
  if (!thisPlaylist.ytids || thisPlaylist?.ytids?.length === 0) {
    return;
  }

  const playYtid = ytid ? ytid : thisPlaylist.ytids[0];
  if (!playYtid) {
    return;
  }

  // when starting playlist from beginning set repeat mode
  if (!ytid) {
    yield put(setRadioMode(false));
  }

  yield put(playlistAction.setCurrentPlaylistStart(pid));
  yield put(viewPlayerVideoStart(playYtid));
}

export function* addYtidsPlaylistAsync({ payload: { pid, ytids } }) {
  // @@TODO: remove this and allow multiple copies of the same ytid in a playlist
  let ytidsToAdd = [];

  const playlistState = yield select(getPlaylist);

  for (let ytid of _.uniq(ytids)) {
    if ((playlistState.playlists[pid].ytids || []).indexOf(ytid) === -1) {
      ytidsToAdd.push(ytid);
    }
  }

  if (ytidsToAdd.length === 0) {
    return;
  }

  // analytics.actions.trackUIClick('Playlist Song Added', pid);

  ytidsToAdd = _.uniq(ytidsToAdd);

  yield all([
    put(getVideoMetadataStart(ytidsToAdd)),
    put(getVideoStatsStart(ytidsToAdd)),
  ]);

  try {
    const body = { ytids: ytidsToAdd };
    // console.log({ body });
    const { data } = yield axios.post(`/playlist/add-ytids/${pid}`, body);
    // console.log({ data });
    yield put(playlistAction.addYtidPlaylistSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.addYtidPlaylistFail(err));
  }
}

export function* setPlaylistYtidsAsync({ payload: { pid, ytids } }) {
  // console.log('setPlaylistYtidsAsync...');
  try {
    const body = { ytids };
    // console.log({ body });
    const { data } = yield axios.post(`/playlist/set-ytids/${pid}`, body);
    // console.log({ data });
    yield put(playlistAction.setPlaylistYtidsSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.setPlaylistYtidsFail(err));
  }
}

export function* renamePlaylistAsync({ payload: { pid, name } }) {
  // console.log('deletePlaylistAsync...');
  try {
    const { data } = yield axios.post(`/playlist/rename/${pid}`, {
      name,
    });
    yield put(playlistAction.renamePlaylistSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.renamePlaylistFail(err));
  }
}

export function* deletePlaylistAsync({ payload: { pid } }) {
  // console.log('deletePlaylistAsync...');
  try {
    const { data } = yield axios.post(`/playlist/delete/${pid}`);
    // console.log({ data });
    yield put(playlistAction.deletePlaylistSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.deletePlaylistFail(err));
  }
}

export function* getFollowedPlaylistPageAsync({ payload: { uid, cursor } }) {
  // analytics.actions.trackAction('Bookmarked Playlist', pid)
  let query = { cursor, uid };
  try {
    const { data } = yield axios.get("/playlist/follow/page", {
      params: query,
    });
    // console.log({ data });
    const pids = [];
    if (data.res) {
      for (let fp of data.res) {
        pids.push(fp.pid);
      }
    }
    // console.log({ data });
    yield put(playlistAction.getFollowedPlaylistSuccess(data));
    if (pids.length > 0) {
      yield put(playlistAction.getUserPlaylistStart(pids));
    }
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.getFollowedPlaylistFail(err));
  }
}

export function* addFollowedPlaylistAsync({ payload: { pid } }) {
  // analytics.actions.trackAction('Bookmarked Playlist', pid)
  try {
    const { data } = yield axios.post(`/playlist/follow/add/${pid}`);
    // console.log({ data });
    yield put(playlistAction.addFollowedPlaylistSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.addFollowedPlaylistFail(err));
  }
}

export function* deleteFollowedPlaylistAsync({ payload: { pid } }) {
  // analytics.actions.trackAction('Bookmarked Playlist', pid)
  try {
    const { data } = yield axios.post(`/playlist/follow/delete/${pid}`);
    // console.log({ data });
    yield put(playlistAction.deleteFollowedPlaylistSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(playlistAction.deleteFollowedPlaylistFail(err));
  }
}

export function* loadMoreMusic(pid) {
  const searchState = yield select(getSearchState);
  const userhistoryState = yield select(getUserhistoryState);
  const articleState = yield select(getArticleState);

  // console.log('loadMoreMusic...', pid);

  if (!isSystemPlaylist(pid)) {
    console.log("Non-system playlist");
    return;
  }

  // Load more search music
  if (pid.indexOf(SearchType.PLAYLIST_SEARCH) === 0) {
    // console.log('search...');
    yield put(getNextResultsPageStart(searchState.query, searchState.next));
  }

  // Load more userhistory music
  if (
    pid === UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS ||
    pid === UserhistoryType.PLAYLIST_NAME_YOUR_FAVORITES ||
    pid === UserhistoryType.PLAYLIST_NAME_YOUR_REPEAT_ORDERED
  ) {
    // console.log('userhistory...');
    if (pid === UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS) {
      if (userhistoryState.historyNext !== "") {
        yield put(getPlaysPageStart(userhistoryState.historyNext, true));
      }
    } else if (pid === UserhistoryType.PLAYLIST_NAME_YOUR_FAVORITES) {
      if (userhistoryState.heartsNext !== "") {
        yield put(getHeartsPageStart(userhistoryState.heartsNext, true));
      }
    } else {
      if (
        !userhistoryState.repeatOrderedHistoryLoaded ||
        userhistoryState.repeatOrderedHistoryNext !== ""
      ) {
        this.actions.getPlaysByRepeatsPage(
          userhistoryState.repeatOrderedHistoryNext
        );
      }
    }
  }

  // Load more articles music
  if (
    pid === ArticleType.PLAYLIST_ARTICLES ||
    pid === ArticleType.PLAYLIST_STAFFPICKS
  ) {
    // console.log('articles...');
    if (pid === ArticleType.PLAYLIST_ARTICLES) {
      yield put(getArticlesPageStart(articleState.articlesCursor, 3));
      return;
    }

    yield put(getStaffpicksPageStart(articleState.staffPicksCursor, 3));
  }
}

export function* addQueueAsync({ payload: ytid }) {
  yield put(setRadioMode(false));
  yield all([
    put(getVideoMetadataStart([ytid])),
    put(getVideoStatsStart([ytid])),
  ]);
  yield put(playlistAction.addToQueueSuccess(ytid));
}

export function* watchSetCurrentPlaylist() {
  yield takeLatest(
    PlaylistType.SET_CURRENT_PLAYLIST_START,
    setCurrentPlaylistAsync
  );
}

export function* watchGetUserPlaylistPage() {
  yield takeLatest(
    PlaylistType.GET_USER_PLAYLIST_PAGE_START,
    getUserPlaylistPageAsync
  );
}

export function* watchGetUserPlaylist() {
  yield takeLatest(PlaylistType.GET_USER_PLAYLIST_START, getUserPlaylistAsync);
}

export function* watchCreatePlaylist() {
  yield takeLatest(PlaylistType.CREATE_PLAYLIST_START, createPlaylistAsync);
}

export function* watchCreatePlaylistAddSong() {
  yield takeLatest(
    PlaylistType.CREATE_PLAYLIST_ADD_SONG_START,
    createPlaylistAddSongAsync
  );
}

export function* watchOpenPlaylist() {
  yield takeLatest(PlaylistType.OPEN_PLAYLIST_START, openPlaylistAsync);
}

export function* watchPlayPlaylist() {
  yield takeLatest(PlaylistType.PLAY_PLAYLIST_START, playPlaylistAsync);
}

export function* watchAddYtidsPlaylist() {
  yield takeLatest(
    PlaylistType.ADD_YTIDS_TO_PLAYLIST_START,
    addYtidsPlaylistAsync
  );
}

export function* watchSetPlaylistYtids() {
  yield takeLatest(
    PlaylistType.SET_PLAYLIST_YTIDS_START,
    setPlaylistYtidsAsync
  );
}

export function* watchRenamePlaylist() {
  yield takeLatest(PlaylistType.RENAME_PLAYLIST_START, renamePlaylistAsync);
}

export function* watchDeletePlaylist() {
  yield takeLatest(PlaylistType.DELETE_PLAYLIST_START, deletePlaylistAsync);
}

export function* watchGetFollowedPlaylistPage() {
  yield takeLatest(
    PlaylistType.GET_FOLLOWED_PLAYLIST_START,
    getFollowedPlaylistPageAsync
  );
}

export function* watchAddFollowedPlaylist() {
  yield takeLatest(
    PlaylistType.ADD_FOLLOWED_PLAYLIST_START,
    addFollowedPlaylistAsync
  );
}

export function* watchDeleteFollowedPlaylist() {
  yield takeLatest(
    PlaylistType.DELETE_FOLLOWED_PLAYLIST_START,
    deleteFollowedPlaylistAsync
  );
}

export function* watchAddQueue() {
  yield takeLatest(PlaylistType.ADD_QUEUE_START, addQueueAsync);
}

export function* watchInitializeStore() {
  yield takeLatest(PlaylistType.INIT_PLAYLIST_START, initializeStore);
}

export function* playlistSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchSetCurrentPlaylist),
    call(watchGetUserPlaylistPage),
    call(watchGetUserPlaylist),
    call(watchCreatePlaylist),
    call(watchOpenPlaylist),
    call(watchPlayPlaylist),
    call(watchAddYtidsPlaylist),
    call(watchSetPlaylistYtids),
    call(watchRenamePlaylist),
    call(watchDeletePlaylist),
    call(watchGetFollowedPlaylistPage),
    call(watchAddFollowedPlaylist),
    call(watchDeleteFollowedPlaylist),
    call(watchCreatePlaylistAddSong),
    call(watchAddQueue),
  ]);
}
