import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import DoneIcon from '@material-ui/icons/Done';
import SortIcon from '@material-ui/icons/Sort';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.iconColor,
    height: 20,
    width: 20,
    cursor: 'pointer',
  },
  iconButton: {
    width: 30,
    height: 30,
    fill: theme.palette.iconColor,
  },
  popperStyle: {
    zIndex: 999,
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
  },
  paper: {
    color: theme.palette.textColor,
    background: theme.palette.bodyColor,
  },
  termsDetails: {
    width: 224,
  },
  menuItems: {
    paddingTop: 13,
    paddingBottom: 13,
    fontFamily: 'Arial',
  },
  itemIcon: {
    right: 0,
    position: 'absolute',
    color: theme.palette.textColor,
  },
}));

const SortMenu = (props) => {
  const classes = useStyles();
  const { title, items, initialItem, onSortByChange } = props;

  const [open, setOpen] = useState(false);
  const [sortBy, setSortBy] = React.useState(initialItem);
  const anchorRef = React.useRef(null);

  const selectedIcon = (itemID) => {
    if (itemID === sortBy) {
      return <DoneIcon />;
    } else {
      return <div />;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const onSortTap = (event, typeID) => {
    setSortBy(typeID);
    onSortByChange(typeID);
    handleClose(event);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.container}>
      <Tooltip title={title} TransitionProps={{ timeout: 300 }}>
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.iconButton}
        >
          <SortIcon className={classes.icon} />
        </IconButton>
      </Tooltip>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popperStyle}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  className={classes.termsDetails}
                >
                  {items.map((item, i) => {
                    return (
                      <MenuItem
                        key={i}
                        onClick={(e) => onSortTap(e, item.id)}
                        className={classes.menuItems}
                      >
                        {item.text}
                        <ListItemIcon className={classes.itemIcon}>
                          {selectedIcon(item.id)}
                        </ListItemIcon>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SortMenu;
