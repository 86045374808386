import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

const PlayPauseControl = props => {
  const { isPlaying, showTooltip, onClick } = props;

  const tooltip = isPlaying ? 'Pause Video' : 'Play Video';

  return (
    <Tooltip title={showTooltip ? tooltip : undefined}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        {isPlaying ? (
          <PauseIcon style={{ color: '#ccc' }} />
        ) : (
          <PlayArrowIcon style={{ color: '#ccc' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default PlayPauseControl;
