export const HIDE_ADD_PLAYLIST_TOOLTIP = 'HIDE_ADD_PLAYLIST_TOOLTIP';
export const HIDE_DELETE_PLAYLIST_TOOLTIP = 'HIDE_DELETE_PLAYLIST_TOOLTIP';

export const ENQUEUE_TOOLTIP = 'ENQUEUE_TOOLTIP';
export const ENQUEUE_RADIO_TOOLTIP = 'ENQUEUE_RADIO_TOOLTIP';
export const ENQUE_POD_CAST_TOOLTIP = 'ENQUE_POD_CAST_TOOLTIP';

export const ENQUEUE_TOOLTIP_START = 'ENQUEUE_TOOLTIP_START';
export const ENQUEUE_TOOLTIP_SUCCESS = 'ENQUEUE_TOOLTIP_SUCCESS';

export const CLOSE_TOOLTIP = 'CLOSE_TOOLTIP';
export const CHANGE_TOOLTIP = 'CHANGE_TOOLTIP';

export const AddToPlaylistToolTip = 'AddToPlaylistToolTip';
export const DeletePlaylistToolTip = 'DeletePlaylistToolTip';

export const DeleteToolTip = 'DeleteToolTip';
