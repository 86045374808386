import { connect } from 'react-redux';

import {
  getArticlesStart,
  viewDetails,
  getArticlesPageStart,
  getStaffpicksPageStart,
} from 'store/article/article.actions';
import {
  setAutoplay,
  setPlayerYtidStart,
  viewPlayerVideoStart,
  setLastPlayed,
} from 'store/player/player.actions';
import { setCurrentPlaylistStart } from 'store/playlist/playlist.actions';
import { initBaseUrl } from 'store/url/url.actions';

const mapStateToProps = (state) => ({
  article: state.article,
  playerState: state.player,
  isLoading: state.article.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onGetArticlesStart: (cursor, limit) =>
    dispatch(getArticlesStart(cursor, limit)),
  onGetArticlesPageStart: (cursor, limit) =>
    dispatch(getArticlesPageStart(cursor, limit)),
  onGetStaffpicksPageStart: (cursor, limit) =>
    dispatch(getStaffpicksPageStart(cursor, limit)),
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onSetLastPlayed: (value) => dispatch(setLastPlayed(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onSetPlayerYtidStart: (ytid) => dispatch(setPlayerYtidStart(ytid)),
  onSetCurrentPlaylistStart: (details) =>
    dispatch(setCurrentPlaylistStart(details)),
  onViewDetails: (details) => dispatch(viewDetails(details)),
  onInitBaseUrl: (ytid) => dispatch(initBaseUrl(ytid)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
