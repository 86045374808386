import React from 'react';

import { ContentPageBlank } from 'common/ui/content-page';
import ArticleListCard from 'modules/article/Article.component';
import { Discovery, YourHistory } from './components';

const YourLorPage = (props) => {
  return (
    <ContentPageBlank
      body={
        <div>
          <Discovery />

          <div style={{ marginBottom: 20 }}>
            <ArticleListCard />
          </div>

          <YourHistory />
        </div>
      }
    />
  );
};

export default YourLorPage;
