const styles = {
  item: {
    border: 10,
    boxSizing: 'border-box',
    display: 'block',
    fontFamily: 'Arial',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    cursor: 'pointer',
    textDecoration: 'none',
    margin: 0,
    padding: 0,
    outline: 'none',
    fontSize: 16,
    fontWeight: 'inherit',
    position: 'relative',
    color: 'rgb(37, 37, 37)',
    lineHeight: '16px',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    background: 'none',
  },
};

export default styles;
