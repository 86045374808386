import * as RadioType from './radio.types';

export const fetchRadioListStart = () => ({
  type: RadioType.FETCH_RADIO_LIST_START,
});

export const fetchRadioListSuccess = (data) => ({
  type: RadioType.FETCH_RADIO_LIST_SUCCESS,
  payload: data,
});

export const fetchRadioListFail = (err) => ({
  type: RadioType.FETCH_RADIO_LIST_FAIL,
  payload: { err },
});

export const setCurrentState = (value) => ({
  type: RadioType.SET_CURRENT_STATES,
  payload: value,
});

export const setCurrentGenres = (value) => ({
  type: RadioType.SET_CURRENT_GENRES,
  payload: value,
});

export const setCountryCode = (value) => ({
  type: RadioType.SET_COUNTRY_CODE,
  payload: value,
});

export const fetchGenresRadioListStart = (countrycode, tag) => ({
  type: RadioType.FETCH_GENRES_RADIO_LIST_START,
  payload: { countrycode, tag },
});

export const fetchGenresRadioListSuccess = (data) => ({
  type: RadioType.FETCH_GENRES_RADIO_LIST_SUCCESS,
  payload: data,
});

export const fetchGenresRadioListFail = (err) => ({
  type: RadioType.FETCH_GENRES_RADIO_LIST_FAIL,
  payload: err,
});

export const ratingHandleStart = (stationid) => ({
  type: RadioType.RATING_START,
  payload: stationid,
});