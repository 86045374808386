import _ from 'lodash';

import * as PlaylistType from './podcastPlaylist.types';

const INITIAL_STATE = {
  currentPodcastId: null,
  playlists: {},
  episodes: {},
  currentlyViewedPlaylist: null,
  queue: [],
  queueEntry: null,
  loading: false
};

const playlistReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let updatedState;
  switch (type) {
    case PlaylistType.SET_POD_QUEUE:
      updatedState = {
        ...state
      };
      updatedState.queue = state.playlists[payload.podcastId];
      updatedState.queueEntry = payload.episodeId;
      return updatedState;

    case PlaylistType.SET_CURRENT_POD_ID:
      return {
        ...state,
        currentPodcastId: payload
      };

    case PlaylistType.QUEUE_POD_EPISODE_ID:
      return {
        ...state,
        queueEntry: payload
      };

    case PlaylistType.SET_CURRENT_VIEWED_POD_PLAYLIST:
      updatedState = {
        ...state,
        currentlyViewedPlaylist: payload.pid
      };

      updatedState.playlists[payload.pid] = state.playlists[payload.pid] ?? [];

      return updatedState;

    case PlaylistType.CLEAR_CURRENT_VIEWED_POD_PLAYLIST:
      return {
        ...state,
        currentlyViewedPlaylist: null
      };

    case PlaylistType.FETCH_PODCAST_EPISODE_START:
      return {
        ...state,
        loading: true
      };

    case PlaylistType.FETCH_PODCAST_EPISODE_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
        episodes: {
          ...state.episodes
        }
      };

      updatedState.playlists[payload.podcastId] = payload.data
        .filter((m) => !_.isNil(m))
        .sort(
          (a, b) =>
            new Date(b.datePublished * 1000) - new Date(a.datePublished * 1000)
        )
        .map((meta) => {
          const id = meta.id ?? `${meta.podcastId}_${meta.guid.split(' ')[0]}`;
          updatedState.episodes[id] = meta;
          return id;
        });

      return updatedState;

    case PlaylistType.FETCH_PODCAST_EPISODE_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export default playlistReducer;
