import { connect } from 'react-redux';
import { toggleSearch } from 'store/searchPodcast/searchPodcast.actions';
import { setSwitchTab } from 'store/siteCoordinator/siteCoordinator.actions';

const mapStateToProps = (state) => ({
    searchPodcastState: state.searchPodcast,
    siteCoordinatorState: state.siteCoordinator,
});

const mapDispatchToProps = (dispatch) => ({
    onToggleSearch: (value) => dispatch(toggleSearch(value)),
    onSetSwitchTab: (value) => dispatch(setSwitchTab(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
