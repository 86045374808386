/** Returns `true` if `val` is `null` or `undefined`. */
export function nullish(...vals) {
  for (const val of vals) {
    if (val === null || val === undefined) {
      return true;
    }
  }
  return false;
}

export function nonemptyString(str) {
  return !nullish(str) && typeof str === 'string' && str.trim().length > 0;
}

export function flatten(oldArr) {
  var newArr = [];
  for (var i = 0; i < oldArr.length; i++) {
    if (Array.isArray(oldArr[i])) {
      newArr = newArr.concat(flatten(oldArr[i]));
    } else {
      newArr.push(oldArr[i]);
    }
  }
  return newArr;
}
