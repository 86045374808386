import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import headerBg from 'assets/images/header-background.jpg';

import { Topbar, Sidebar, MainColumn, RightColumn } from './components';
import styles from 'pages/LandingPage/MainPage.styles';
import Spinner from 'common/Spinner/Spinner';

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    height: '100%'
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  background: {
    position: 'absolute',
    background: `url("${headerBg}") 0% 0% / cover`,
    height: '370px',
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      left: 0
    },
    [theme.breakpoints.up('md')]: {
      left: 0
    },
    [theme.breakpoints.up('lg')]: {
      left: 200
    },
    [theme.breakpoints.up('xl')]: {
      left: 200
    }
  },
  lgBackground: {
    position: 'absolute',
    background: `url("${headerBg}") 0% 0% / cover`,
    height: '370px',
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      left: 0
    },
    [theme.breakpoints.up('md')]: {
      left: 0
    },
    [theme.breakpoints.up('lg')]: {
      left: 0
    },
    [theme.breakpoints.up('xl')]: {
      left: 0
    }
  },
  mainAreaOffset: {
    ...styles.mainArea,
    // background: theme.palette.eclipseGray,
    background: theme.palette.bodyColor,
    color: theme.palette.textColor,
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  mainColumn: {
    ...styles.mainColumn,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  rightColumn: {
    ...styles.rightColumn
  }
}));

const Minimal = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesMD = useMediaQuery(theme.breakpoints.up('lg'));
  const mdMatches = useMediaQuery('(min-width:1076px)');

  return (
    <div className={classes.root}>
      <Topbar sidebarOpen={matchesMD} />
      <Sidebar />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: matchesMD
        })}
      >
        <Grid container className={classes.mainAreaOffset}>
          <Grid item className={classes.mainColumn}>
            <MainColumn />
          </Grid>

          <Hidden mdDown={!mdMatches}>
            <Grid item className={classes.rightColumn}>
              <RightColumn />
            </Grid>
          </Hidden>
        </Grid>
      </main>
    </div>
  );
};

export default Minimal;
