const styles = {
  discovery: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0px 8%',
    paddingBottom: 20,

    title: {
      padding: 10,
      paddingLeft: 20,
      pointerEvents: 'none',
      fontSize: 16,
    },

    loadingHello: {
      marginBottom: 5,
    },

    description: {
      position: 'relative',
      marginBottom: 20,
    },

    loadingPhraseContainer: {
      position: 'relative',
      marginBottom: 40,
      width: '100%',
      opacity: 0.5,
    },

    loadingPhrase: {
      position: 'absolute',
      width: '100%',
    },

    cta: {
      color: 'rgb(255, 0, 102)',
      cursor: 'pointer',
    },
  },
};

export default styles;
