import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import styles from './ArtistRow.styles';

import container from './ArtistRow.container';

const useStyles = makeStyles({
  row: {
    ...styles.artistRow,
  },
  artistImage: {
    ...styles.artistImage,
  },
  image: (props) => ({
    backgroundImage: `url(${props.image})`,
  }),
  artistNameWrapper: {
    ...styles.artistNameWrapper,
  },
  artistName: {
    ...styles.artistName,
  },
});

const ArtistRow = (props) => {
  const classes = useStyles(props);

  const { onPerformSearchStart } = props;

  function openArtistSearch() {
    if (props.onClick) {
      props.onClick();
    }
    // analytics.actions.trackUIClick('Recommended Artists', props.name)
    onPerformSearchStart(props.name);
  }

  return (
    <div className={classes.row} onClick={openArtistSearch}>
      <i className={clsx(classes.artistImage, classes.image)} />

      <div className={classes.artistNameWrapper}>
        <h5 className={classes.artistName}>{props.name}</h5>
      </div>
    </div>
  );
};

ArtistRow.propTypes = {};

export default container(ArtistRow);
