import React from 'react';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import { ContentPage } from 'common/ui/content-page';
import { PlaylistCardDetailHeader, PlaylistCard } from './components';
import { parseDuration, durationToHMSString } from 'common/yt_utils';
import { pluralizeUnits } from 'common/utils';
import { scrollToVideo } from 'common/ui/utils';
import HistorySort from 'constants/HistorySort';
import * as UserhistoryType from 'store/userhistory/userhistory.types';

import container from './PlaylistPage.container';

const PlaylistPage = (props) => {
  // console.log("main props", props);
  const theme = useTheme();

  const {
    userhistoryState,
    playlistState,
    ownUid,
    videoState,
    playerState: { ytid: currentPlayerYtid },
    userState,
    onViewPlayerVideoStart,
    onSetCurrentPlaylistStart,
    onQueueYtid,
  } = props;

  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const description = (ytids) => {
    const totalSongLength = ytids
      .filter((ytid) => videoState.videos[ytid])
      .map((ytid) => parseDuration(videoState.videos[ytid].duration))
      .reduce((a, b) => a + b, 0);

    let subtitle = pluralizeUnits(ytids.length, 'video', 'videos');

    if (!matchesMD) {
      subtitle += ` | ${durationToHMSString(totalSongLength)} total length`;
    }
    return subtitle;
  };

  const totalDescription = () => {
    const myPlaylistsList = _.compact(
      playlistState.playlistsByUser[ownUid] || []
    );
    let myPlaylists = _.map(
      myPlaylistsList,
      (pid) => playlistState.playlists[pid]
    );
    myPlaylists = _.compact(myPlaylists);

    if (!myPlaylists) {
      return '';
    }

    if (myPlaylists.length === 0) {
      return 'Create some playlists so you can play the music you love easily!';
    }

    const songCount = _.sumBy(myPlaylists, (playlist) => {
      if (playlist.ytids && playlist.ytids.length > 0) {
        return playlist.ytids.length;
      }

      return 0;
    });

    return `You’ve made ${myPlaylists.length} playlists with ${songCount} songs`;
  };

  const currentlyViewedPid = playlistState.currentlyViewedPlaylist;
  const currentlyViewedPlaylist =
    playlistState.playlists[currentlyViewedPid] || {};
  const ytids = currentlyViewedPlaylist.ytids || [];

  const isOwnPlaylist = ownUid && currentlyViewedPlaylist.uid === ownUid;

  const isFollowed = _.find(
    playlistState.followedPlaylistsByUser[ownUid],
    (item) => {
      return item.pid === currentlyViewedPid;
    }
  );

  const onClickRow = (ytid) => {
    // console.log('onClickRow...');
    onQueueYtid(ytid);
    scrollToVideo();
    onViewPlayerVideoStart(ytid);
    if (userhistoryState.sortBy === HistorySort.RepeatCount) {
      onSetCurrentPlaylistStart(
        UserhistoryType.PLAYLIST_NAME_YOUR_REPEAT_ORDERED
      );
      // analytics.actions.trackUIClick('Repeat Count Feed', ytid);
    } else {
      onSetCurrentPlaylistStart(UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS);
      // analytics.actions.trackUIClick('Repeat Feed', ytid);
    }
  };

  return (
    <ContentPage
      header={{
        title: currentlyViewedPid ? currentlyViewedPlaylist.name : 'Your Music',
        description: currentlyViewedPid
          ? description(ytids)
          : totalDescription(),
        controls:
          ytids.length > 0 ? (
            <PlaylistCardDetailHeader
              selectedPid={currentlyViewedPid}
              selectedPlaylistName={currentlyViewedPlaylist.name}
              viewingOwnPlaylist={isOwnPlaylist}
              isFollowed={isFollowed}
            />
          ) : null,
      }}
      body={
        <PlaylistCard
          {...props}
          userState={userState}
          currentYtid={currentPlayerYtid}
          onVideoClick={onClickRow}
        />
      }
    />
  );
};

export default container(PlaylistPage);
