import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { List, Hidden, Collapse } from '@material-ui/core';
import {
  TrendingUp as TrendingUpIcon,
  // Stars as StarsIcon,
  Album as AlbumIcon,
  Search as SearchIcon,
  // ViewList as ViewListIcon,
  Assignment as AssignmentIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';
import SectionTab from 'constants/SectionTab';
// import SidebarMoreItem from 'layouts/Main/components/Sidebar/components/SidebarMoreItem';
// import { CollapseListItem } from 'layouts/RadioMain/components/Sidebar/components';
import NavigationDrawer from 'layouts/Main/components/Sidebar/navigation';
import { SidebarItem } from 'layouts/Main/components/Sidebar/components';

import container from './Sidebar.container';
import { scrollToMainContent, scrollToTopContent } from 'common/ui/utils';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    '&.MuiList-padding': {
      padding: 0,
      paddingBottom: 35,
    },
  },
  toolbar: {
    minHeight: 50,
  },
  discordIcon: {
    color: 'white',
    height: 20,
    width: 20,
    marginTop: -4,
  },
  playerControls: {
    display: 'flex',
    paddingTop: 0,
    justifyContent: 'center',
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const {
    siteCoordinator,
    playerState,
    open,
    onSidebarOpen,
    onSidebarClose,
    onSetCurrentTab,
  } = props;

  /*const [openList, setOpenList] = React.useState(true);
  const handleClickListenNote = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenList(!openList);
  };*/

  const onListItemClick = (tab) => {
    if (matchesMD) {
      onSidebarClose();
    }
    onSetCurrentTab(tab);
    if (tab === SectionTab.PodCastFavourite) {
      scrollToTopContent();
    } else {
      scrollToMainContent();
    }
  };

  const onBlogTap = () => {
    window.open('https://blog.listenonrepeat.com/', '_blank');
  };

  return (
    <NavigationDrawer
      open={open}
      onSidebarOpen={onSidebarOpen}
      onSidebarClose={onSidebarClose}
    >
      <List className={classes.listContainer}>
        <Hidden smUp>
          <div className={classes.toolbar} />
        </Hidden>
        <SidebarItem
          name={'Most Popular'}
          section={SectionTab.PopularPodCast}
          icon={<TrendingUpIcon />}
          clicked={() => onListItemClick(SectionTab.PopularPodCast)}
          /*selected={
            SectionTab.Station ===
            (siteCoordinator.currentTab === SectionTab.None
              ? SectionTab.PopularPodCast
              : siteCoordinator.currentTab)
          }*/
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        {/*<SidebarItem
            name={'Five Star'}
            section={SectionTab.FiveStar}
            icon={<StarsIcon />}
            clicked={() => onListItemClick(SectionTab.FiveStar)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
        />*/}
        <SidebarItem
          name={'Category'}
          section={SectionTab.Genres}
          icon={<AlbumIcon />}
          clicked={() => onListItemClick(SectionTab.Genres)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        {/*<SidebarItem
            name="Listen Notes"
            nestedList={
              <SidebarMoreItem
                  openList={openList}
                  handleClick={handleClickListenNote}
              />
            }
            section={SectionTab.GenresListenNote}
            icon={<AlbumIcon />}
            clicked={() => onListItemClick(SectionTab.GenresListenNote)}
            selected={
              SectionTab.GenresListenNote ===
              (siteCoordinator.currentTab === SectionTab.None
                  ? SectionTab.GenresListenNote
                  : siteCoordinator.currentTab) ||
              SectionTab.SearchListenNote ===
              (siteCoordinator.currentTab === SectionTab.None
                  ? SectionTab.SearchListenNote
                  : siteCoordinator.currentTab)
            }
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
        />
        <Collapse in={openList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <CollapseListItem
                name={'Search'}
                section={SectionTab.SearchListenNote}
                clicked={() => onListItemClick(SectionTab.SearchListenNote)}
                siteCoordinator={siteCoordinator}
            />
            <CollapseListItem
                name={'Genres'}
                section={SectionTab.GenresListenNote}
                clicked={() => onListItemClick(SectionTab.GenresListenNote)}
                siteCoordinator={siteCoordinator}
            />
          </List>
        </Collapse>*/}
        <SidebarItem
            name="Favorite/History"
            section={SectionTab.PodCastFavourite}
            icon={<FavoriteBorderIcon />}
            clicked={() => onListItemClick(SectionTab.PodCastFavourite)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
        />
        <SidebarItem
            name="Blog"
            section={SectionTab.None}
            icon={<AssignmentIcon className={classes.discordIcon} />}
            clicked={() => onBlogTap()}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
        />
        {props.showSearchTab && (
          <SidebarItem
            name="Search Results"
            section={SectionTab.SearchPodCast}
            icon={<SearchIcon />}
            clicked={() => onListItemClick(SectionTab.SearchPodCast)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
          />
        )}
      </List>
    </NavigationDrawer>
  );
};

export default container(Sidebar);
