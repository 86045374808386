import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  playlistState: state.playlist,
  uid: state.user.profile.uid,
});

const container = connect(mapStateToProps);

export default container;
