import _ from 'lodash';

export function parseDurationHMS(d) {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  var hours = 0,
    minutes = 0,
    seconds = 0;

  if (reptms.test(d)) {
    var matches = reptms.exec(d);
    if (matches[1]) {
      hours = Number(matches[1]);
    }
    if (matches[2]) {
      minutes = Number(matches[2]);
    }
    if (matches[3]) {
      seconds = Number(matches[3]);
    }
  }
  return [hours, minutes, seconds];
}

export function durationToHMSString(num) {
  var d = Number(num);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);
  return (
    (h > 0 ? h + 'h ' + (m < 10 ? '0' : '') : '') +
    m +
    'm ' +
    (s < 10 ? '0' : '') +
    s +
    's'
  );
}

export function parseDurationHMSString(d) {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  var hours = 0,
    minutes = 0,
    seconds = 0;

  if (reptms.test(d)) {
    var matches = reptms.exec(d);
    if (matches[1]) {
      hours = Number(matches[1]);
    }
    if (matches[2]) {
      minutes = Number(matches[2]);
    }
    if (matches[3]) {
      seconds = Number(matches[3]);
    }
  }

  var second_str, minute_str, hour_str;
  second_str = seconds.toString();
  minute_str = minutes.toString();
  hour_str = hours.toString();

  if (seconds < 10) {
    second_str = '0' + second_str;
  }

  if (hours === 0) {
    return minute_str + ':' + second_str;
  }

  if (minutes < 10) {
    minute_str = '0' + minute_str;
  }

  if (hours < 10) {
    hour_str = '0' + hour_str;
  }

  return hour_str + ':' + minute_str + ':' + second_str;
}

export function parseDuration(d) {
  if (_.isEmpty(d)) {
    return 0;
  }

  const [h, m, s] = parseDurationHMS(d);
  return h * 3600 + m * 60 + s;
}
