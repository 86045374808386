import React from 'react';

import { ContentPage } from 'common/ui/content-page';
import { Top10Card } from './components';

const StreamsPage = () => {
  return (
    <ContentPage
      header={{
        title: 'Top 10',
        description: 'Find out the videos trending on LOR today',
      }}
      body={<Top10Card />}
    />
  );
};

export default StreamsPage;
