import React from 'react';

import { ContentPageBlank } from 'common/ui/content-page';
import ArticleCard from './Article.component';

const ArticlePage = () => {
  return <ContentPageBlank body={<ArticleCard />} />;
};

export default ArticlePage;
