import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Ad } from 'modules/ads';

const useStyles = makeStyles((theme) => ({
  slot300: {
    minWidth: 300,
    minHeight: 250,
    background: theme.palette.paperColor
  }
}));

const RightColumn = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item>
        <Ad className={classes.slot300} />
      </Grid>
      <Grid item>
        <Ad className={classes.slot300} />
      </Grid>
    </Grid>
  );
};

export default RightColumn;
