import React from 'react';

import PlaylistCardDetail from '../PlaylistCardDetail';
import PlaylistCardList from '../PlaylistCardList';

const PlaylistCard = (props) => {
  const {
    ownUid,
    currentYtid,
    videoState,
    playlistState,
    userState,
    userhistoryState,
    onVideoClick,
  } = props;

  // console.log(
  //   'currentlyViewedPlaylist::',
  //   playlistState.currentlyViewedPlaylist
  // );

  if (playlistState.currentlyViewedPlaylist) {
    return (
      <PlaylistCardDetail
        selectedPid={playlistState.currentlyViewedPlaylist}
        ownUid={ownUid}
        currentYtid={currentYtid}
        playlistState={playlistState}
        userState={userState}
        videoState={videoState}
        userhistoryState={userhistoryState}
        onVideoClick={onVideoClick}
      />
    );
  }

  return (
    <PlaylistCardList
      uid={ownUid}
      playlistState={playlistState}
      currentPlaylist={playlistState.currentPlaylist}
    />
  );
};

export default PlaylistCard;
