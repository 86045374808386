import React, { useState } from 'react';
import Card from '@material-ui/core/Card';

const Ad = (props) => {
  const {
    noReport,
    adName,
    adContainer,
    className,
    parentClassName,
    divId
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div id={adName} className={parentClassName}>
      <div style={{ marginBottom: 10 }}>
        <Card className={className}>
          <div id={adContainer}>
            <div id={divId}></div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Ad;
