import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import * as PlayerType from './playerPodCast.types';
import * as playerPodCastAction from './playerPodCast.actions';
import {
  getPodcastMetadataAsync,
  getPodcastEpisodeMetadataAsync
} from '../podcast/podcast.sagas';

export function* viewEpisodePlayerStart({ payload: { podcastid, episodeid } }) {
  yield put(playerPodCastAction.setPodCastUrl());
}

export function* fetchURLPodcastAsync({ payload: { podcastURL, episodeURL } }) {
  yield call(getPodcastMetadataAsync, podcastURL);
  yield call(getPodcastEpisodeMetadataAsync, podcastURL);
  yield put(playerPodCastAction.fetchURLPodcastSuccess(podcastURL, episodeURL));
}

/**
 * watch generator functions
 */
export function* watchViewPodCastPlayer() {
  yield takeLatest(
    PlayerType.VIEW_EPISODE_PLAYER_START,
    viewEpisodePlayerStart
  );
}

export function* watchFetchURLPodcastStart() {
  yield takeLatest(PlayerType.FETCH_URL_PODCAST_START, fetchURLPodcastAsync);
}

export function* playerPodCastSagas() {
  yield all([call(watchViewPodCastPlayer), call(watchFetchURLPodcastStart)]);
}
