export const INIT_PLAYLIST_START = 'INIT_PLAYLIST_START';
export const INIT_PLAYLIST_SUCCESS = 'INIT_PLAYLIST_SUCCESS';

export const SET_CURRENT_PLAYLIST_START = 'SET_CURRENT_PLAYLIST_START';
export const SET_CURRENT_PLAYLIST_SUCCESS = 'SET_CURRENT_PLAYLIST_SUCCESS';
export const SET_CURRENT_PLAYLIST_FAIL = 'SET_CURRENT_PLAYLIST_FAIL';

export const GET_USER_PLAYLIST_PAGE_START = 'GET_USER_PLAYLIST_PAGE_START';
export const GET_USER_PLAYLIST_PAGE_SUCCESS = 'GET_USER_PLAYLIST_PAGE_SUCCESS';
export const GET_USER_PLAYLIST_PAGE_FAIL = 'GET_USER_PLAYLIST_PAGE_FAIL';

export const GET_USER_PLAYLIST_START = 'GET_USER_PLAYLIST_START';
export const GET_USER_PLAYLIST_SUCCESS = 'GET_USER_PLAYLIST_SUCCESS';
export const GET_USER_PLAYLIST_FAIL = 'GET_USER_PLAYLIST_FAIL';

export const CREATE_PLAYLIST_START = 'CREATE_PLAYLIST_START';
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_FAIL = 'CREATE_PLAYLIST_FAIL';

export const CREATE_PLAYLIST_ADD_SONG_START = 'CREATE_PLAYLIST_ADD_SONG_START';
export const CREATE_PLAYLIST_ADD_SONG_SUCCESS =
  'CREATE_PLAYLIST_ADD_SONG_SUCCESS';
export const CREATE_PLAYLIST_ADD_SONG_FAIL = 'CREATE_PLAYLIST_ADD_SONG_FAIL';

export const OPEN_PLAYLIST_START = 'OPEN_PLAYLIST_START';
export const OPEN_PLAYLIST_SUCCESS = 'OPEN_PLAYLIST_SUCCESS';
export const OPEN_PLAYLIST_FAIL = 'OPEN_PLAYLIST_FAIL';

export const PLAY_PLAYLIST_START = 'PLAY_PLAYLIST_START';
export const PLAY_PLAYLIST_SUCCESS = 'PLAY_PLAYLIST_SUCCESS';
export const PLAY_PLAYLIST_FAIL = 'PLAY_PLAYLIST_FAIL';

export const SET_PLAYLIST_YTIDS_START = 'SET_PLAYLIST_YTIDS_START';
export const SET_PLAYLIST_YTIDS_SUCCESS = 'SET_PLAYLIST_YTIDS_SUCCESS';
export const SET_PLAYLIST_YTIDS_FAIL = 'SET_PLAYLIST_YTIDS_FAIL';

export const RENAME_PLAYLIST_START = 'RENAME_PLAYLIST_START';
export const RENAME_PLAYLIST_SUCCESS = 'RENAME_PLAYLIST_SUCCESS';
export const RENAME_PLAYLIST_FAIL = 'RENAME_PLAYLIST_FAIL';

export const DELETE_PLAYLIST_START = 'DELETE_PLAYLIST_START';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAIL = 'DELETE_PLAYLIST_FAIL';

export const GET_FOLLOWED_PLAYLIST_START = 'GET_FOLLOWED_PLAYLIST_START';
export const GET_FOLLOWED_PLAYLIST_SUCCESS = 'GET_FOLLOWED_PLAYLIST_SUCCESS';
export const GET_FOLLOWED_PLAYLIST_FAIL = 'GET_FOLLOWED_PLAYLIST_FAIL';

export const ADD_FOLLOWED_PLAYLIST_START = 'ADD_FOLLOWED_PLAYLIST_START';
export const ADD_FOLLOWED_PLAYLIST_SUCCESS = 'ADD_FOLLOWED_PLAYLIST_SUCCESS';
export const ADD_FOLLOWED_PLAYLIST_FAIL = 'ADD_FOLLOWED_PLAYLIST_FAIL';

export const DELETE_FOLLOWED_PLAYLIST_START = 'DELETE_FOLLOWED_PLAYLIST_START';
export const DELETE_FOLLOWED_PLAYLIST_SUCCESS =
  'DELETE_FOLLOWED_PLAYLIST_SUCCESS';
export const DELETE_FOLLOWED_PLAYLIST_FAIL = 'DELETE_FOLLOWED_PLAYLIST_FAIL';

export const ADD_YTIDS_TO_PLAYLIST_START = 'ADD_YTIDS_TO_PLAYLIST_START';
export const ADD_YTIDS_TO_PLAYLIST_SUCCESS = 'ADD_YTIDS_TO_PLAYLIST_SUCCESS';
export const ADD_YTIDS_TO_PLAYLIST_FAIL = 'ADD_YTIDS_TO_PLAYLIST_FAIL';

export const SET_CURRENT_YTID = 'SET_CURRENT_YTID';
export const SET_SYSTEM_PLAYLIST_YTIDS = 'SET_SYSTEM_PLAYLIST_YTIDS';
export const SET_SYSTEM_PLAYLIST_NAME = 'SET_SYSTEM_PLAYLIST_NAME';
export const SET_CURRENT_VIEWED_PLAYLIST = 'SET_CURRENT_VIEWED_PLAYLIST';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const SHOW_ADD_PLAYLIST_MODAL = 'SHOW_ADD_PLAYLIST_MODAL';
export const HIDE_ADD_PLAYLIST_MODAL = 'HIDE_ADD_PLAYLIST_MODAL';
export const SHOW_EDIT_PLAYLIST_MODAL = 'SHOW_EDIT_PLAYLIST_MODAL';
export const HIDE_EDIT_PLAYLIST_MODAL = 'HIDE_EDIT_PLAYLIST_MODAL';
export const SHOW_DELETE_PLAYLIST_MODAL = 'SHOW_DELETE_PLAYLIST_MODAL';
export const HIDE_DELETE_PLAYLIST_MODAL = 'HIDE_DELETE_PLAYLIST_MODAL';

export const CLEAR_CURRENTLY_VIEWED_PLAYLIST =
  'CLEAR_CURRENTLY_VIEWED_PLAYLIST';

export const EVISCERATE_QUEUE = 'EVISCERATE_QUEUE';
export const REMOVE_QUEUE = 'REMOVE_QUEUE';
export const POP_QUEUE = 'POP_QUEUE';
export const ADD_QUEUE_START = 'ADD_QUEUE_START';
export const ADD_QUEUE_SUCCESS = 'ADD_QUEUE_SUCCESS';
export const SET_QUEUE = 'SET_QUEUE';
export const QUEUE_YTID = 'QUEUE_YTID';

export const CREATE_INTERNAL_PLAYLIST = 'CREATE_INTERNAL_PLAYLIST';
