import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Feature from 'constants/Feature';
import ToolTipKeys from 'constants/ToolTipKeys';
import * as yourlor from 'store/yourlor/yourlor.types';
import { scrollToVideo } from 'common/ui/utils';
import Discover from 'modules/playlist/components/PlaylistView';
import VideoListItem from 'modules/video/components/VideoListItem';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';
import { HistoryDeleteToolTip } from 'modules/userhistory/tooltip';

import container from './DiscoveryList.container';

const DiscoveryList = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    playerState,
    playlistState,
    userState,
    tooltipState,
    onQueueYtid,
    onSetAutoplay,
    onSetCurrentPlaylistStart,
    onViewPlayerVideoStart,
    onShowSnackbarNotification,
    onHideDeletePlaylistToolTip,
    onEnqueueToolTip,
    onCloseToolTip,
    onRemoveDiscovery,
    onShowAddPlaylistModal,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
      setOpen1(false);
      onCloseToolTip();
    };
  }, []);

  const onDiscoverySelect = (ytid) => {
    onQueueYtid(ytid);
    scrollToVideo();
    onSetAutoplay(true);
    if (playerState.playingFeature !== Feature.YourLOR) {
      //   analytics.actions.trackAction('Discovery Start')
      window._gaq.push(['_trackEvent', 'Action', 'Discovery Start', ytid]);

    }
    onSetCurrentPlaylistStart(yourlor.DISCOVERY_PLAYLIST);
    onViewPlayerVideoStart(ytid);
    // yourlor.actions.trackDiscoveryPlay()
    if (props.onClick) {
      props.onClick(ytid);
    }
  };

  const onAddToPlaylistClick = (evt, ytid) => {
    var element = evt.currentTarget;
    // console.log('onAddToPlaylistClick...', { ytid });

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }

    toggleAddToPlaylistToolTip(ytid, element);

    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const onDeleteFromHistoryClick = (evt, ytid) => {
    let element = evt.currentTarget;
    toggleDeleteToolTip(ytid, element);
  };

  function toggleDeleteToolTip(ytid, element) {
    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.DeleteToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen1(false);
      return;
    }
    window.anchorEl = element;
    setOpen1(true);
    onEnqueueToolTip(ToolTipKeys.DeleteToolTip, null, ytid);
  }

  const confirmDelete = (ytid) => {
    onRemoveDiscovery(ytid);
    onShowSnackbarNotification('Deleted video 😢');
    onCloseToolTip();
    window.anchorEl = null;
    setOpen1(false);
  };

  const cancelDelete = (ytid) => {
    onCloseToolTip();
    window.anchorEl = null;
    setOpen1(false);
  };

  const discoveryItems = props.ytids.map((ytid) => {
    return { ytid };
  });

  return (
    <>
      <Discover
        items={discoveryItems}
        next={''}
        elementFactory={(item, i) => {
          return (
            <div key={item.ytid + '-' + i}>
              <VideoListItem
                // isEditable={props.showControls}
                showControls={props.showControls}
                ytid={item.ytid}
                isPlaying={item.ytid === props.currentYtid}
                history={props.userhistoryState.history[item.ytid]}
                metadata={props.videoState.videos[item.ytid]}
                globalStats={props.videoState.videoStats[item.ytid]}
                onClick={onDiscoverySelect}
                onAddToPlaylistClick={onAddToPlaylistClick}
                onDeleteClick={onDeleteFromHistoryClick}
                showMobileAuthor={false}
                showMobileGlobalRepeats={true}
                showMobileGlobalHearts={true}
                showMobileUserRepeats={false}
                showMobileDuration={true}
                showMobileLastViewed={false}
              />
            </div>
          );
        }}
      />
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
      {open1 && tooltipState.currentEntry.key === ToolTipKeys.DeleteToolTip && (
        <HistoryDeleteToolTip
          toolTipKey={ToolTipKeys.DeleteToolTip}
          title="Remove Video from Discovery?"
          description="Are you sure you want to do this?"
          element={window.anchorEl}
          ytid={tooltipState.currentEntry.ytid}
          confirm={confirmDelete}
          cancel={cancelDelete}
        />
      )}
    </>
  );
};

DiscoveryList.propTypes = {
  ytids: PropTypes.array.isRequired,
  showControls: PropTypes.bool.isRequired,
  currentYtid: PropTypes.string,
  userhistoryState: PropTypes.object.isRequired,
  videoState: PropTypes.object.isRequired,
};

export default container(DiscoveryList);
