import * as fb from 'lib/facebook';
import * as google from 'lib/google';
import * as twitter from 'lib/twitter';

import * as UserTypes from 'store/user/user.types';

export const initActions = () => {
  if (typeof window !== 'undefined') {
    fb.init();
    google.init();
    twitter.init();
  }
};

export const userData = (userState) => {
  const userType = userState.type;

  switch (userType) {
    case UserTypes.ANONYMOUS:
      return {
        firstName: null,
        avatarUrl: null,
      };
    case UserTypes.FACEBOOK:
      return {
        firstName: userState.fb.firstName,
        avatarUrl: userState.fb.pictureUrl,
      };
    case UserTypes.GOOGLE:
      return {
        firstName: userState.google.firstName,
        avatarUrl: userState.fb.imageURL,
      };
    default:
      return {
        firstName: null,
        avatarUrl: null,
      };
  }
};
