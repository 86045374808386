import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { RepeatIcon2, RepeatNumberIcon } from 'common/ui/icons';

const RepeatControl = (props) => {
  const { repeatMode, showTooltip, onClick } = props;
  const tooltip = repeatMode ? 'Disable Repeat Video' : 'Enable Repeat Video';

  return (
    <Tooltip title={showTooltip ? tooltip : undefined}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        {repeatMode ? (
          <RepeatNumberIcon
            iconStyle={{ width: 22, height: 22 }}
            textStyle={{ fontSize: 9 }}
            color="#CCC"
          />
        ) : (
          <RepeatIcon2 style={{ color: 'rgb(95, 95, 95)' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default RepeatControl;
