import React from 'react';
import clsx from 'clsx';

const Spinner = (props) => {
  const visibilityStyle = { display: props.hidden ? 'none' : 'block' };
  const styles = {
    ...props.style,
    ...visibilityStyle,
  };
  return <div className={clsx('spinner', props.className)} style={styles} />;
};

export default Spinner;
