import _ from 'lodash';

import * as StreamsType from './streams.types';

const INITIAL_STATE = {
  streamPlaying: null,
  streams: {},
  currentUTCDay: null,
  userOrderedStreams: [],
  top10: [],
};

const streamsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let pid, updatedState;
  switch (type) {
    case StreamsType.GET_TOP10_SUCCESS:
      return {
        ...state,
        top10: _.isEmpty(payload) ? [] : payload,
      };

    case StreamsType.GET_STREAM_LIST_START:
      return {
        ...state,
        loading: true,
      };

    case StreamsType.GET_STREAM_LIST_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
      };
      for (let stream of payload) {
        updatedState.streams[stream.pid] = stream;
      }
      return updatedState;

    case StreamsType.RANDOMIZE_VIEWER_COUNT_SUCCESS:
      return {
        ...state,
        streams: {
          ...state.streams,
          [state.streamPlaying]: {
            viewers: payload,
          },
        },
      };

    case StreamsType.GET_TOP10_FAIL:
    case StreamsType.GET_STREAM_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errors: [payload],
      };

    case StreamsType.SET_CURRENT_STREAM_START:
      pid = payload.pid;

      updatedState = {
        ...state,
      };
      updatedState.streamPlaying = pid;

      updatedState.currentUTCDay = Math.floor(
        new Date().getTime() / (1000 * 60 * 60 * 24)
      );

      return updatedState;

    case StreamsType.CLEAR_CURRENT_VIEW_STREAM:
      return {
        ...state,
        streamPlaying: null,
      };

    default:
      return state;
  }
};

export default streamsReducer;
