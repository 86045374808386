import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Favorite as FavoriteIcon,
  PlaylistAdd as PlaylistAddIcon,
  Delete as DeleteIcon,
  Queue as QueueIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  actionDialog: {
    color: theme.palette.textColor3,
    backgroundColor: theme.palette.modalColor,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    fontFamily: 'Arial',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    borderRadius: 2,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    zIndex: 2,
    maxHeight: '80%',
    overflow: 'scroll',
  },
  title: {
    padding: '16px 16px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.borderColor,
  },
  item: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.borderColor,
  },
  icon: {
    fill: theme.palette.textColor3,
  },
}));

const MobileMoreActions = (props) => {
  const classes = useStyles();
  const {
    onClose,
    open,
    noHearts,
    isHearted,
    playHandler,
    heartHandler,
    playlistHandler,
    deleteHandler,
    queueHandler,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="action-dialog-title"
      classes={{
        paper: classes.actionDialog,
      }}
    >
      <DialogTitle id="action-dialog-title" className={classes.title}>
        Actions
      </DialogTitle>
      <List>
        <ListItem button className={classes.item} onClick={playHandler}>
          <ListItemText primary={'Play'} />
          <ListItemIcon>
            <PlayCircleOutlineIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
        {!noHearts && isHearted && (
          <ListItem button className={classes.item} onClick={heartHandler}>
            <ListItemText primary={'Unheart'} />
            <ListItemIcon>
              <FavoriteIcon className={classes.icon} />
            </ListItemIcon>
          </ListItem>
        )}
        {!noHearts && !isHearted && (
          <ListItem button className={classes.item} onClick={heartHandler}>
            <ListItemText primary={'Heart'} />
            <ListItemIcon>
              <FavoriteBorderIcon className={classes.icon} />
            </ListItemIcon>
          </ListItem>
        )}
        <ListItem button className={classes.item} onClick={playlistHandler}>
          <ListItemText primary={'Add to playlist'} />
          <ListItemIcon>
            <PlaylistAddIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
        <ListItem button className={classes.item} onClick={deleteHandler}>
          <ListItemText primary={'Delete'} />
          <ListItemIcon>
            <DeleteIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
        <ListItem button className={classes.item} onClick={queueHandler}>
          <ListItemText primary={'Add to Queue'} />
          <ListItemIcon>
            <QueueIcon className={classes.icon} />
          </ListItemIcon>
        </ListItem>
      </List>
    </Dialog>
  );
};

export default MobileMoreActions;
