import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 200,
    height: '100%',
    // overflow: 'auto',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: theme.palette.shadow,
    borderRight: theme.palette.borderRight,
    [theme.breakpoints.up('sm')]: {
      marginTop: 50,
    },
    // [theme.breakpoints.up("md")]: {
    //   overflow: 'auto',
    //   position: 'relative',
    // }
  },
}));

const NavigationDrawer = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const { open, onSidebarOpen, onSidebarClose } = props;
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const id = open ? 'scroll-playground' : null;

  return (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          open={open}
          onOpen={onSidebarOpen}
          onClose={onSidebarClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {props.children}
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant={!matchesSM ? 'persistent' : null}
          anchor="left"
          open={open}
          onClose={onSidebarClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {props.children}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavigationDrawer;
