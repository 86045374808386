import React from 'react';

import Tooltip from 'common/ui/tooltip';
import { AddPlaylistToolTip, AddPlaylistOnboardToolTip } from './components';

const PlaylistTooltip = (props) => {
  // console.log('[PlaylistTooltip]');

  const hasPlaylists = props.playlistList.length > 0;

  return (
    <Tooltip
      id={props.id}
      open={props.open}
      title="Add to playlist"
      anchorRef={props.element}
    >
      {hasPlaylists ? (
        <AddPlaylistToolTip
          ytid={props.ytid}
          playlistList={props.playlistList}
        />
      ) : (
        <AddPlaylistOnboardToolTip ytid={props.ytid} />
      )}
    </Tooltip>
  );
};

export default PlaylistTooltip;
