const styles = {
  subItem: {
    padding: '0px 5px 0px 20px',
    fontSize: 13,
    cursor: 'pointer',
    MozUserSelect: 'none',
    userSelect: 'none',
  },
  regularStyle: {
    color: '#a9a9a9',
    fontSize: 12,
    textAlign: 'left',
    lineHeight: 'normal',
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 5,
    textOverflow: 'ellipsis',
    display: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'top',
    float: 'left',
    width: 130,
    position: 'relative',
    borderLeft: '1px solid #404040',
  },

  regularStyleShadow: {
    display: 'none',
    position: 'absolute',
    left: 0,
  },

  rightIcon: {
    position: 'absolute',
    right: 10,
    top: 6,
    margin: 0,
    width: 16,
    height: 16,
  },

  marqueeStyle: {
    textOverflow: 'initial',
    verticalAlign: 'top',
    display: 'inline-block',
    overflow: 'visible',
  },
};

export default styles;
