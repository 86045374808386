import { createSelector } from 'reselect';

const selectHistoryPodcastStore = (state) => state.userhistoryPodcast;

export const selectPodcasts = createSelector(
    [selectHistoryPodcastStore],
    (podcastHistory) => podcastHistory.history
);

export const selectPodcastHistoryForPreview = (podcastid) =>
    createSelector([selectPodcasts], (podcasts) => (podcasts ? podcasts[podcastid] : {}));
