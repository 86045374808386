import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import _ from 'lodash';

import * as UpsellType from './upsell.types';
import { initializeUpsellSuccess, showFeatureUpsell } from './upsell.actions';

const DAY_SECONDS = 3600 * 24;
const UPSELL_STORE = 'lor-upsell';

const getUpsellState = (state) => state.upsell;

export const featureDemos = [
  {
    feature: UpsellType.DISCOVERY,
    eligible: () => {
      return showTimeBased(UpsellType.DISCOVERY, DAY_SECONDS * 7);
    },
    show: false,
  },
];

// actions for any upsells
export function* showFeatureUpsellIfEligible(name) {
  const upsellState = yield select(getUpsellState);
  const featureUpsell = yield getUpsell(name);

  if (
    !featureUpsell ||
    (featureUpsell.onActionEligible && !featureUpsell.onActionEligible())
  ) {
    return;
  }

  if (upsellState.featureDemo.feature === name) {
    yield put(showFeatureUpsell(name));
    yield setFeatureUpsellSeen(name);
  }
}

export function getUpsell(name) {
  return _.find(featureDemos, (demo) => demo.feature === name);
}

export function setFeatureUpsellSeen(name) {
  const shownAt = Math.floor(new Date().getTime() / 1000);
  const store = localStorage.getItem(UPSELL_STORE);
  const data = store ? JSON.parse(store) : {};
  data[`${UpsellType.DEMO}-${name}`] = shownAt;

  localStorage.setItem(UPSELL_STORE, JSON.stringify(data));
}

function showTimeBased(name, showAfter) {
  const lastSeen = getLastSeen(name);

  if (!lastSeen) {
    return true;
  }

  const now = Math.floor(new Date().getTime() / 1000);

  return now - lastSeen > showAfter;
}

function getLastSeen(name) {
  let data = {};
  const store = localStorage.getItem(UPSELL_STORE);
  if (store) {
    try {
      data = JSON.parse(store);
    } catch {}
  }

  const save = data[`${UpsellType.DEMO}-${name}`];
  return save ? save : null;
}

export function* initializeStore() {
  // console.log('initializeStore UPSELL...');
  const eligibleFeatureDemos = yield featureDemos.filter((upsell) =>
    upsell.eligible()
  );

  let show = false;
  let featureDemo = null;
  if (eligibleFeatureDemos.length > 0) {
    featureDemo = eligibleFeatureDemos.shift();

    yield setFeatureUpsellSeen(featureDemo.feature);
  }

  const data = {
    featureDemo: {
      show,
      feature: featureDemo ? featureDemo.feature : null,
    },
    featureDemos: eligibleFeatureDemos.map((upsell) => upsell.feature),
  };

  yield put(initializeUpsellSuccess(data));
}

export function* watchInitializeStore() {
  yield takeLatest(UpsellType.INITIALIZE_UPSHELL, initializeStore);
}

export function* upshellSagas() {
  yield all([call(watchInitializeStore)]);
}
