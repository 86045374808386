const styles = {
  addButton: {
    textAlign: 'left',
    width: '100%',

    label: {
      fontSize: 12,
      color: '#ff0066',
      textTransform: 'none',
      justifyContent: 'left',
      marginLeft: 30,
    },
  },

  textfield: {
    fontSize: 12,
    width: '100%',
    borderLeft: '1px solid #404040',
    paddingLeft: 10,
    height: 30,

    input: {
      border: 'none',
      color: '#f06',
    },
  },
};

export default styles;
