import { connect } from 'react-redux';

import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';
import { showLoginModal } from 'store/login/login.actions';
import { logoutStart } from 'store/login/login.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';
import { clearCurrentlyViewedPlaylist } from 'store/playlist/playlist.actions';

const mapStateToProps = (state) => ({
  userState: state.user,
  loginState: state.login,
  userhistoryState: state.userhistory,
  siteCoordinator: state.siteCoordinator,
  playerState: state.player,
  search: state.search,
  showRadioTab: state.artist.nextVideos.length > 0,
  showSearchTab: state.search.query,
});
const mapDispatchToProps = (dispatch) => ({
  onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
  onShowLoginModal: () => dispatch(showLoginModal()),
  onLogoutStart: () => dispatch(logoutStart()),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onClearCurrentlyViewedPlaylist: () =>
    dispatch(clearCurrentlyViewedPlaylist()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
