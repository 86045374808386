const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    // padding: '8px 8px 8px 12px',
    flexWrap: 'wrap',
    maxHeight: 100,
    marginBottom: 5,
    marginTop: 5,
    fontFamily: 'Arial',

    more: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      maxHeight: 100,
      overflow: 'hidden',
    },

    image: {
      width: 100,
      height: 100,
      borderRadius: 2,
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    },

    info: {
      position: 'absolute',
      left: 10,
      top: '50%',
      zIndex: 1,
      right: 10,
      transform: 'translateY(-50%)',

      title: {
        fontSize: 16,
        fontWeight: 400,
        display: 'inline',
        color: '#FFF',

        highlight: {
          color: '#ff0066',
          display: 'block',
          margin: '0 5px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },

    actions: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,

      button: {
        height: 30,
        padding: 0,
        marginRight: 0,
        display: 'block',
        width: '100%',

        icon: {
          width: 30,
          height: 30,
          margin: '0 auto',
        },
      },
    },

    suggestion: {
      width: 252,
      height: 84,
      padding: 0,
      borderRadius: 2,
      marginRight: 1,
      position: 'relative',
      cursor: 'pointer',

      cover: {
        width: '100%',
        position: 'absolute',
        zIndex: 1,
        background: 'rgba(0,0,0,0.6)',
        height: '100%',
        top: 0,
        left: 0,
      },

      image: {
        width: 168,
        height: 84,
        padding: 0,
        float: 'right',

        item: {
          width: 42,
          height: 42,
          float: 'left',
          background: '#eee',
          backgroundSize: 'cover',
        },
      },
    },
  },
};

export default styles;
