export const INIT_ARTICLE = 'INIT_ARTICLE';

export const GET_ARTICLES_START = 'GET_ARTICLES_START';
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_FAIL = 'GET_ARTICLES_FAIL';

export const GET_ARTICLES_PAGE_START = 'GET_ARTICLES_PAGE_START';
export const GET_ARTICLES_PAGE_SUCCESS = 'GET_ARTICLES_PAGE_SUCCESS';
export const GET_ARTICLES_PAGE_FAIL = 'GET_ARTICLES_PAGE_FAIL';

export const GET_STAFF_PAGE_START = 'GET_STAFF_PAGE_START';
export const GET_STAFF_PAGE_SUCCESS = 'GET_STAFF_PAGE_SUCCESS';
export const GET_STAFF_PAGE_FAIL = 'GET_STAFF_PAGE_FAIL';

export const VIEW_DETAILS = 'VIEW_DETAILS';
export const VIEW_DETAILS_SUCCESS = 'VIEW_DETAILS_SUCCESS';

export const NONE = 'None';
export const STAFF = 'Staff';

export const PLAYLIST_ARTICLES = 'lor:articles:all';
export const PLAYLIST_STAFFPICKS = 'lor:articles:staffpicks';
