import _ from 'lodash';

import * as ArticleType from './article.types';

const INITIAL_STATE = {
  hasInitialized: false,
  articles: {},
  articleYtidsOrdered: [],
  staffPickYtidsOrdered: [],
  next: '',
  articlesCursor: '',
  staffPicksCursor: '',
  playerContributor: {},
  details: {},
};

const articleReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let updatedState;

  switch (type) {
    case ArticleType.GET_ARTICLES_START:
      return {
        ...state,
        loading: true,
      };

    case ArticleType.GET_ARTICLES_PAGE_START:
    case ArticleType.GET_STAFF_PAGE_START:
      return {
        ...state,
        loading: true,
      };

    case ArticleType.GET_ARTICLES_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
        articles: {
          ...state.articles,
        },
      };

      _.toPairs(payload).forEach((pair) => {
        const [ytid, article] = pair;
        updatedState.articles[ytid] = article;
      });

      return updatedState;

    case ArticleType.VIEW_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case ArticleType.VIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload,
      };

    case ArticleType.GET_ARTICLES_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        articleYtidsOrdered: [...state.articleYtidsOrdered, ...payload.current],
        articlesCursor: payload.next,
      };

    case ArticleType.GET_STAFF_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        staffPickYtidsOrdered: [
          ...state.staffPickYtidsOrdered,
          ...payload.current,
        ],
        staffPicksCursor: payload.next,
      };

    case ArticleType.GET_ARTICLES_FAIL:
    case ArticleType.GET_ARTICLES_PAGE_FAIL:
    case ArticleType.GET_STAFF_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        errors: [payload],
      };

    default:
      return state;
  }
};

export default articleReducer;
