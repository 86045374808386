import { connect } from 'react-redux';

import { createPlaylistAddSongStart } from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';
import { closeToolTip } from 'store/tooltip/tooltip.actions';

const mapStateToProps = (state) => ({
  article: state.article,
  playerState: state.player,
  isLoading: state.article.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onCreatePlaylistAddSongStart: (name, ytids) =>
    dispatch(createPlaylistAddSongStart(name, ytids)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onCloseToolTip: () => dispatch(closeToolTip()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
