import React from 'react';

import PlayerState from 'constants/PlayerState';
import SectionTab from 'constants/SectionTab';

import {
  HeartControl,
  PrevControl,
  PlayPauseControl,
  NextControl,
  RepeatControl,
  ShuffleControl,
  QueueControl,
} from 'modules/player/controls';

import container from './PlayerControls.container';
import { scrollToMainContentArea } from 'common/ui/utils';

const PlayerControls = (props) => {
  const {
    userhistoryState,
    sidebarElement,
    onTogglePlayPause,
    playerStore,
    onAddHeartStart,
    onDeleteHeartStart,
    onToggleRepeatMode,
    onToggleShuffleMode,
    onPlayPrevStart,
    onPlayNextStart,
    onPlayRandomStart,
    onShowSnackbarNotification,
    onClearCurrentlyViewedPlaylist,
    onSetCurrentTab,
    onCloseSidebar,
  } = props;

  const ytid = playerStore.ytid;
  const userPlay = ytid ? userhistoryState.history[ytid] || {} : {};
  const hearted = userPlay.star;
  const isPlaying = playerStore.player.state === PlayerState.Playing;

  const controlClass = sidebarElement
    ? 'player-controls sidebar'
    : 'player-controls';

  const onShowQueue = () => {
    let tab = SectionTab.PlayQueue;
    onClearCurrentlyViewedPlaylist();

    // analytics.actions.trackUIClick('Smart Menu', SectionTab.tabToString(tab));
    window._gaq.push(['_trackEvent', 'UI Click', 'Smart Menu', tab]);


    onSetCurrentTab(tab);
    scrollToMainContentArea();

    if (sidebarElement) {
      onCloseSidebar();
    }
  };

  const onPrevPlay = () => onPlayPrevStart();

  const onNextPlay = () => onPlayNextStart();

  const onPlayPause = () =>
    onTogglePlayPause(isPlaying ? PlayerState.Paused : PlayerState.Playing);

  const onToggleHeart = (ytid) => {
    const userPlay = ytid ? userhistoryState.history[ytid] || {} : {};
    const hearted = userPlay.star;
    if (hearted) {
      onDeleteHeartStart(ytid);
      onShowSnackbarNotification(`Video Un-hearted ❤️`);
      window._gaq.push(['_trackEvent', 'Action', 'Unheart Video', ytid]);

    } else {
      onAddHeartStart(ytid);
      window._gaq.push(['_trackEvent', 'Action', 'Heart Video', ytid]);

      onShowSnackbarNotification(`Video Hearted ❤️`);
    }
  };

  return (
    <div
      className={controlClass}
      style={{ marginTop: 8, marginRight: 8, marginLeft: 10 }}
    >
      {sidebarElement ? (
        <div className="controls" style={{ textAlign: 'center' }}>
          <div>
            <HeartControl
              onClick={() => onToggleHeart(ytid)}
              hearted={hearted}
            />
            <PrevControl onClick={onPrevPlay} />
            <PlayPauseControl onClick={onPlayPause} isPlaying={isPlaying} />
            <NextControl onClick={onNextPlay} />
          </div>
          <div>
            <RepeatControl
              repeatMode={playerStore.player.repeatMode}
              onClick={onToggleRepeatMode}
            />
            <ShuffleControl
              shuffleMode={playerStore.player.shuffleMode}
              onClick={onToggleShuffleMode}
            />
            <QueueControl
              queueShow={playerStore.playingFeature}
              onClick={onShowQueue}
            />
          </div>
        </div>
      ) : (
        <div className="controls">
          <HeartControl
            onClick={() => onToggleHeart(ytid)}
            showTooltip
            hearted={hearted}
          />
          <PrevControl onClick={onPrevPlay} showTooltip />
          <PlayPauseControl
            onClick={onPlayPause}
            isPlaying={isPlaying}
            showTooltip
          />
          <NextControl onClick={onNextPlay} showTooltip />
          <RepeatControl
            showTooltip
            repeatMode={playerStore.player.repeatMode}
            onClick={onToggleRepeatMode}
          />
          <ShuffleControl
            showTooltip
            shuffleMode={playerStore.player.shuffleMode}
            onClick={onToggleShuffleMode}
          />
          <QueueControl
            showTooltip
            queueShow={playerStore.playingFeature}
            onClick={onShowQueue}
          />
        </div>
      )}
    </div>
  );
};

export default container(PlayerControls);
