import { connect } from 'react-redux';

import { clearCurrentlyViewedStream } from 'store/streams/streams.actions';

const mapDispatchToProps = (dispatch) => ({
  onClearCurrentlyViewedStream: () => dispatch(clearCurrentlyViewedStream()),
});

const container = connect(null, mapDispatchToProps);

export default container;
