import React, { useEffect } from 'react';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import headerBg from 'assets/images/header-background.jpg';
import { Sidebar, Topbar } from './components';
import container from './PodcastMain.container';

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
 root: {
   paddingTop: 50,
   height: '100%',
 },
 content: {
   flexGrow: 1,
   transition: theme.transitions.create('margin', {
     easing: theme.transitions.easing.sharp,
     duration: theme.transitions.duration.leavingScreen,
   }),
   marginLeft: 0,
 },
 contentShift: {
   transition: theme.transitions.create('margin', {
     easing: theme.transitions.easing.easeOut,
     duration: theme.transitions.duration.enteringScreen,
   }),
   marginLeft: drawerWidth,
 },
 background: {
   position: 'absolute',
   background: `url("${headerBg}") 0% 0% / cover`,
   height: '370px',
   left: 0,
   right: 0,
   [theme.breakpoints.up('sm')]: {
     left: 0,
   },
   [theme.breakpoints.up('md')]: {
     left: 0,
   },
   [theme.breakpoints.up('lg')]: {
     left: 200,
   },
   [theme.breakpoints.up('xl')]: {
     left: 200,
   },
 },
 lgBackground: {
   position: 'absolute',
   background: `url("${headerBg}") 0% 0% / cover`,
   height: '370px',
   left: 0,
   right: 0,
   [theme.breakpoints.up('sm')]: {
     left: 0,
   },
   [theme.breakpoints.up('md')]: {
     left: 0,
   },
   [theme.breakpoints.up('lg')]: {
     left: 0,
   },
   [theme.breakpoints.up('xl')]: {
     left: 0,
   },
 },
}));

const PodcastMain = (props) => {
 const classes = useStyles();
 const theme = useTheme();
 const {
     children,
     siteCoordinator: { sidebarOpen },
     onToggleSidebar,
 } = props;
 const matchesMD = useMediaQuery(theme.breakpoints.up('lg'));
 const matchesSM = useMediaQuery(theme.breakpoints.down('md'));

 useEffect(() => {
     onToggleSidebar(!matchesSM);
 }, [onToggleSidebar, matchesSM]);

 const handleSidebarOpen = () => {
   onToggleSidebar(true);
 };

 const handleSidebarClose = () => {
   onToggleSidebar(false);
 };
 return (
   <div className={classes.root}>
     <Topbar
       sidebarOpen={sidebarOpen}
       onSidebarOpen={handleSidebarOpen}
       onSidebarClose={handleSidebarClose}
     />
     <Sidebar
       open={sidebarOpen}
       onSidebarOpen={handleSidebarOpen}
       onSidebarClose={handleSidebarClose}
     />
     <Hidden xsDown>
       {(() => {
         if (sidebarOpen && matchesMD) {
           return <div className={classes.background} />;
         } else {
           return <div className={classes.lgBackground} />;
         }
       })()}
     </Hidden>
     <main
       className={clsx(classes.content, {
         [classes.contentShift]: sidebarOpen && matchesMD,
       })}
     >
       {children}
     </main>
   </div>
 );
};

export default container(PodcastMain);
