import { connect } from 'react-redux';

import { viewPlayerVideoStart, setAutoplay } from 'store/player/player.actions';
import {
  queueYtid,
  setCurrentPlaylistStart,
  showAddPlaylistModal,
} from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';
import {
  enqueueToolTip,
  closeToolTip,
  hideDeletePlaylistToolTip,
} from 'store/tooltip/tooltip.actions';
import { removeDiscoveryStart } from 'store/yourlor/yourlor.actions';

const mapStateToProps = (state) => ({
  article: state.article,
  isLoading: state.article.loading,
  playerState: state.player,
  playlistState: state.playlist,
  userState: state.user,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onSetCurrentPlaylistStart: (details) =>
    dispatch(setCurrentPlaylistStart(details)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onHideDeletePlaylistToolTip: (key) =>
    dispatch(hideDeletePlaylistToolTip(key)),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
  onRemoveDiscovery: (ytid) => dispatch(removeDiscoveryStart(ytid)),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
