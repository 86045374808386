import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RcSlider from 'rc-slider';

import { usePrevious } from 'hooks';
import { parseDuration } from 'common/yt_utils';
import { formatSeconds } from 'common/utils';

import container from './Slider.container';

const Range = RcSlider.Range;

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    opacity: 1,
    overflow: 'hidden',
    height: 65,
    transition: 'all 0.2s ease',
  },
  sliderContainer: {
    borderRadius: 2,
    padding: '36px 34px 10px',
    borderTop: 'solid 1px',
    marginTop: 3,
    '& .rc-slider-rail': {
      backgroundColor: '#e5e5e5',
    },
    '& .rc-slider-track': {
      background: '#aaa',
      boxShadow: 'inset 0 0 3px rgba(51, 51, 51, 0.3)',
      transition: 'background 450ms',
      height: 6,
      borderRadius: 0,
    },
    '& .rc-slider-handle': {
      backgroundColor: '#ff0066',
      boxShadow:
        '0 2px 5px 0 rgba(0, 0, 0, 0.16),0 2px 10px 0 rgba(0, 0, 0, 0.12)',
      width: 24,
      height: 24,
      border: 0,
      marginTop: -10,
    },
    '& .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging': {
      borderColor: 'none',
      boxShadow: 'none',
    },
    '& .rc-slider-handle-click-focused': {
      borderColor: 'none',
      boxShadow: 'none',
    },
  },
  sliderSubTitle: {
    textAlign: 'center',
    fontSize: 13,
    color: '#a9a9a9',
    letterSpacing: 0,
    lineHeight: 0,
    fontWeight: 400,
    fontFamily: 'Arial',
    marginTop: -2,
    paddingBottom: 15,
  },
  tooltip: {
    width: 60,
    position: 'relative',
    top: -30,
    float: 'left',
    textAlign: 'center',
    fontWeight: 300,
    fontFamily: 'Arial',
  },
  tooltipLeft: {
    color: theme.palette.globalColor,
  },
  tooltipRight: {
    color: theme.palette.globalColor,
  },
}));

const Slider = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { ytid, slider, durationStr, onSetSliderStart } = props;
  // Get the previous value (was passed into hook on last render)
  const prevSlider = usePrevious(slider);
  const prevDurationStr = usePrevious(durationStr);

  // console.log({ slider, prevSlider });
  // console.log({ durationStr, prevDurationStr });

  const [duration, setDuration] = useState(0);
  const [endTime, setEndTime] = useState(slider?.endTime || duration);
  const [startTime, setStartTime] = useState(slider?.startTime || 0);
  const [value, setValue] = useState([0, 0]);

  useEffect(() => {
    if (prevDurationStr !== durationStr) {
      setDuration(parseDuration(durationStr));
    }
  }, [prevDurationStr, durationStr]);

  useEffect(() => {
    if (_.isEmpty(prevSlider) && _.isEmpty(slider)) {
      const val1 = 0;
      const val2 = duration;
      setStartTime(val1);
      setEndTime(val2);
      setValue([val1, val2]);
      return;
    }
    if (!_.isEmpty(prevSlider) && prevSlider !== slider) {
      const val1 = slider?.startTime || 0;
      const val2 = slider?.endTime || duration;
      setStartTime(val1);
      setEndTime(val2);
      setValue([val1, val2]);
      return;
    }
    if (!_.isEmpty(slider)) {
      const val1 = slider?.startTime || 0;
      const val2 = slider?.endTime || duration;
      setStartTime(val1);
      setEndTime(val2);
      setValue([val1, val2]);
    }
  }, [prevSlider, slider, duration]);

  const onSliderChange = (value) => {
    setStartTime(value[0]);
    setEndTime(value[1]);
    setValue(value);
  };

  const onSliderChangeComplete = (value) => {
    // analytics.actions.trackActionSession('Slider Change', this.props.ytid);
    window._gaq.push(['_trackEvent', 'Action', 'Slider Change', ytid]);

    onSetSliderStart(ytid, {
      startTime: value[0],
      endTime: value[1] || duration,
      show: true,
    });
  };

  const leftTooltipStyle = {
    left: 'calc(' + (startTime / duration) * 100 + '% - 30px)',
  };
  const rightTooltipStyle = {
    left: 'calc(' + (endTime / duration) * 100 + '% - 90px)',
  };

  // console.log({ startTime, endTime });

  return (
    <Grid container direction="column">
      <Grid item className={classes.sliderWrapper}>
        <div
          className={classes.sliderContainer}
          style={{ borderColor: theme.palette.sliderBorder }}
        >
          <div
            className={clsx(classes.tooltip, {
              [classes.tooltipLeft]: true,
            })}
            style={leftTooltipStyle}
          >
            {formatSeconds(startTime)}
          </div>
          {endTime > 0 && (
            <div
              className={clsx(classes.tooltip, {
                [classes.tooltipRight]: true,
              })}
              style={rightTooltipStyle}
            >
              {formatSeconds(endTime)}
            </div>
          )}

          <Range
            allowCross={false}
            value={value}
            min={0}
            max={duration}
            tipFormatter={null} // hide the default tooltips provided by the RcSlider component
            onChange={onSliderChange}
            onAfterChange={onSliderChangeComplete}
            style={{ padding: 0 }}
          />
        </div>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" className={classes.sliderSubTitle}>
          Loop any section of the video using the slider!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default container(Slider);
