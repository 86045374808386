import React from 'react';
import PropTypes from 'prop-types';

import Feature from 'constants/Feature';
import SortType from 'constants/SortType';

import { PlaylistSortableView } from 'modules/playlist/components';

const QueueCard = (props) => {
  const {
    videoState,
    userhistoryState,
    playlistState,
    onSetAutoplay,
    onViewPlayerVideoStart,
    onPlayFeature,
    onEviscerateQueue,
    onRemoveQueue,
    onSetQueue,
  } = props;

  const skipQueue = (ytid) => {
    console.log({ ytid });
    const queueItems = getQueueItems();
    const index = queueItems.indexOf(ytid);

    if (index <= 0) {
      return;
    }

    onPlayFeature(Feature.PlayQueue);
    onSetAutoplay(true);
    onViewPlayerVideoStart(ytid);
    onEviscerateQueue(index - 1);
  };

  const onSortEnd = (oldIndex, newIndex, newYtids) => {
    const nowPlaying = newYtids.shift();
    onSetQueue(newYtids);
    if (oldIndex === 0 || newIndex === 0) {
      onPlayFeature(Feature.PlayQueue);
      onSetAutoplay(true);
      onViewPlayerVideoStart(nowPlaying);
    }
  };

  const removeFromQueue = (evt, ytid, index) => {
    if (index === 0) {
      return;
    }

    onRemoveQueue(index - 1);

    evt.stopPropagation();
    evt.preventDefault();
  };

  const getQueueItems = () => {
    const queueItems = playlistState.queue.slice();
    // console.log({ queueItems });
    queueItems.unshift(playlistState.currentYtid);
    return queueItems;
  };

  const queueItems = getQueueItems();

  let queueDisplay = (
    <div
      style={{
        textAlign: 'center',
        padding: 10,
        paddingBottom: 40,
        color: '#a9a9a9',
      }}
    >
      You have no items queued!
    </div>
  );

  if (queueItems.length !== 0) {
    queueDisplay = (
      <PlaylistSortableView
        currentYtid={playlistState.currentYtid}
        customOrderedYtids={queueItems}
        videoState={videoState}
        userhistoryState={userhistoryState}
        sortBy={SortType.Custom}
        editable={true}
        onVideoClick={skipQueue}
        onSortEnd={onSortEnd}
        onDeleteFromPlaylistClick={removeFromQueue}
        onlyPlayFirst={true}
      />
    );
  }

  return <div>{queueDisplay}</div>;
};

QueueCard.propTypes = {};

export default QueueCard;
