import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import Feature from 'constants/Feature';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '@keyframes pulse_animation': {
    '0%': {
      transform: 'scale(1)',
    },
    '30%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '80%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  queueActive: {
    animationName: '$pulse_animation',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    marginTop: 5,
  },
}));

const QueueControl = (props) => {
  const classes = useStyles();
  const { queueShow, showTooltip, onClick } = props;
  return (
    <Tooltip title={showTooltip ? 'Show Play Queue' : null}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        {queueShow === Feature.PlayQueue ? (
          <div className={classes.queueActive}>
            <QueueMusicIcon style={{ color: '#ff0066' }} />
          </div>
        ) : (
          <QueueMusicIcon style={{ color: 'rgb(95, 95, 95)' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default QueueControl;
