import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

import { scrollToVideo } from 'common/ui/utils';
import { DISCOVERY_LENGTH } from 'store/yourlor/yourlor.types';

import DiscoveryList from '../DiscoveryList';

import container from './Discovery.container';

import styles from './Discovery.styles';
import yourLORStyles from 'modules/yourlor/YourLorPage.styles';

const useStyles = makeStyles((theme) => ({
  featureCard: {
    ...yourLORStyles.featureCard,
    background: theme.palette.paperColor,
  },
  title: {
    ...styles.discovery.title,
    color: theme.palette.pink,
  },
  highlight: {
    color: '#ff0066',
  },
  discovery: {
    ...styles.discovery,
    color: theme.palette.textColor,
    backgroundColor: theme.palette.paperColor,
    fontFamily: 'Arial',
  },
  description: {
    ...styles.discovery.description,
  },
  loadingHello: {
    ...styles.discovery.loadingHello,
  },
  loadingPhrase: {
    ...styles.discovery.loadingPhrase,
  },
  loadingPhraseContainer: {
    ...styles.discovery.loadingPhraseContainer,
  },
  cta: {
    ...styles.discovery.cta,
  },
  noPadding: {
    padding: 0,
  },
}));

const Discovery = (props) => {
  const classes = useStyles();

  const {
    currentYtid,
    userState,
    userhistoryState,
    videoState,
    yourLORState,
  } = props;

  const [fakeCounter, setFakeCounter] = useState(0);
  const [loadingPhrase, setLoadingPhrase] = useState('');

  useEffect(() => {
    setLoadingPhrase(_.sample(loadingPhrases));
    const phraseTimer = setInterval(onPhraseTick, 10000);
    const counterTimer = setInterval(() => {
      setFakeCounter((count) => count + 1);
    }, 10000);
    if (fakeCounter === 15) {
      clearInterval(counterTimer);
    }
    return () => {
      clearInterval(phraseTimer);
      clearInterval(counterTimer);
    };
  }, [fakeCounter]);

  const onPhraseTick = () => {
    setLoadingPhrase((prev) => {
      let newPhrase = prev.loadingPhrase;
      while (newPhrase === prev.loadingPhrase) {
        newPhrase = _.sample(loadingPhrases);
      }

      return newPhrase;
    });
  };

  const onItemClick = (ytid) => {
    scrollToVideo();
  };

  const ctaClick = () => {
    // onSetCurrentTab(SectionTab.Streams)
  };

  const getTitle = () => {
    let title = 'Weekly Discovery';
    if (yourLORState.discovery.created && userState.firstName) {
      title = `${userState.firstName}'s ` + title;
    }
    return title;
  };

  const getLoadingMessage = () => {
    const firstName = userState.firstName;
    if (
      yourLORState.discovery.eligible ||
      yourLORState.discovery.createFailure
    ) {
      return (
        <span>
          Hey {firstName ? firstName : 'there'}, we will make a fresh playlist
          of music just for you every Monday. Start by listening to&nbsp;
          <span className={classes.cta} onClick={ctaClick}>
            some music
          </span>
          .
        </span>
      );
    }
    return (
      <span>
        Hey {firstName ? firstName : 'there'}, we're building an awesome
        playlist just for you
      </span>
    );
  };

  const discoveredCounter = Math.max(
    fakeCounter,
    yourLORState.discovery.ytids.length
  );
  const loadingMessage = getLoadingMessage();
  const title = getTitle();

  return (
    <Card className={classes.featureCard}>
      <CardHeader
        classes={{
          root: classes.noPadding,
          title: classes.title,
        }}
        title={title}
      />
      <CardContent classes={{ root: classes.noPadding }}>
        {!yourLORState.discovery.created && (
          <div>
            {yourLORState.discovery.eligible &&
              !yourLORState.discovery.createFailure && (
                <div className={classes.discovery}>
                  <div
                    className={clsx(classes.description, classes.loadingHello)}
                  >
                    {loadingMessage}
                  </div>
                  <div className={classes.description}>
                    {discoveredCounter}/{DISCOVERY_LENGTH} songs added
                  </div>
                  <div className={classes.description}>
                    <CircularProgress color="primary" />
                  </div>
                  <Fade
                    in
                    timeout={{
                      enter: 500,
                      exit: 500,
                    }}
                    className={classes.loadingPhraseContainer}
                  >
                    <div key={loadingPhrase} className={classes.loadingPhrase}>
                      {loadingPhrase}
                    </div>
                  </Fade>
                </div>
              )}
            {(!yourLORState.discovery.eligible ||
              yourLORState.discovery.createFailure) && (
              <div className={classes.discovery}>
                <div className={classes.description}>{loadingMessage}</div>
              </div>
            )}
          </div>
        )}

          <DiscoveryList
            ytids={yourLORState.discovery.ytids}
            showControls={true}
            onClick={onItemClick}
            currentYtid={currentYtid}
            userhistoryState={userhistoryState}
            videoState={videoState}
          />
      </CardContent>
    </Card>
  );
};

const loadingPhrases = [
  'Searching through every song ever made to find your jam...',
  'Adding Justin Bieber’s entire discography...',
  'Having a quick break for coffee...',
  'Stealing all the music from Ed Sheeran’s iPod...',
  'Making a sacrifice to the gods of music discovery...',
  'Performing the secret “good music” dance in the LOR office...',
  'Announcing to the world that you are “Repeater of the Year”...',
  'Humming that song that you keep getting stuck in your head...',
  'Randomly picking a CD from the LOR cupboard...',
  'Lighting some candles to create good ambiance...',
  'Sneaking backstage at a nearby concert to get music tips...',
  'Never giving you up or letting you down, running around or deserting you...',
  'Flying to LA and kidnapping some musicians...',
  'Changing the lyrics of every song you like to “Despacito”...',
  'Checking behind the sofa for that MP3 player we lost in 2004...',
  'Carefully selecting the best Russian Electro-Folk we can find...',
  'Flicking the lights on and off so it feels like we’re at a rave...',
  'Asking your friends what the most embarrassing music is you like...',
  'Writing and recording a new song just for you...',
  'Crying a little because this playlist is so damn beautiful...',
];

export default container(Discovery);
