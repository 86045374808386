import _ from 'lodash';

import * as VideoType from './video.types';

const INITIAL_STATE = {
  loading: false,
  videos: {},
  videoStats: {},
  errors: [],
};

const videoReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let updatedState;
  switch (type) {
    case VideoType.GET_VIDEO_METADATA_START:
    case VideoType.GET_VIDEO_STATS_START:
      return {
        ...state,
        loading: true,
      };

    case VideoType.GET_VIDEO_METADATA_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
        videos: {
          ...state.videos,
        },
      };

      _.values(payload)
        .filter((m) => !_.isNil(m))
        .forEach((meta) => {
          updatedState.videos[meta.ytid] = meta;
        });

      return updatedState;

    case VideoType.GET_VIDEO_STATS_SUCCESS:
      updatedState = {
        ...state,
        loading: false,
        videoStats: {
          ...state.videoStats,
        },
      };

      payload
        .filter((s) => !_.isNil(s) && !_.isEmpty(s.ytid))
        .forEach((s) => (updatedState.videoStats[s.ytid] = s));

      return updatedState;

    case VideoType.GET_VIDEO_METADATA_FAILURE:
    case VideoType.GET_VIDEO_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case VideoType.UPDATE_GLOBAL_COUNT:
      updatedState = {
        ...state,
      };
      const ytid = payload.ytid;
      console.log('[UPDATE_GLOBAL_COUNT]', ytid);
      updatedState.videoStats[ytid] = updatedState.videoStats[ytid] || {};
      updatedState.videoStats[ytid].totalRepeats =
        (updatedState.videoStats[ytid].totalRepeats || 0) + 1;
      updatedState.videoStats[ytid].todayRepeats =
        (updatedState.videoStats[ytid].todayRepeats || 0) + 1;
      return updatedState;

    default:
      return state;
  }
};

export default videoReducer;
