import { takeLatest, call, put, all, select } from "redux-saga/effects";
import _ from "lodash";
import * as cookie from "component-cookie";

import axios from "instances/server";
import * as YourLorType from "./yourlor.types";
import * as UpsellType from "../upsell/upsell.types";

import {
  setArtistsToDiscover,
  ineligbleForDiscovery,
  setDiscovery,
  setDiscoveryExpires,
  setOldDiscoveries,
  setNextArtistSuccess,
  setIneligbleArtist,
  removeDiscoveryArtist,
  addNewDiscoverySuccess,
  validateYtidsStart,
  validateYtidsSuccess,
  discoveryFailure,
  removeDiscoverySuccess,
} from "./yourlor.actions";
import {
  getArtistRecsDataAsync,
  findRandomVideo,
} from "../artist/artist.sagas";
import { getPlaysStart } from "../userhistory/userhistory.actions";
import {
  getPlaysPageAsync,
  getPlaysAsync,
} from "../userhistory/userhistory.sagas";
import {
  getVideoMetadataStart,
  getVideoStatsStart,
} from "../video/video.actions";
import {
  getVideoMetadataAsync,
  getVideoStatsAsync,
} from "../video/video.sagas";
import { playNextStart } from "../player/player.actions";
import { showFeatureUpsellIfEligible } from "../upsell/upsell.sagas";
import { normalizeArtistName } from "store/artist/artist.utils";
import { parseDuration } from "common/yt_utils";
import { onlySearch } from "store/search/search.sagas";
import {
  setSystemPlaylistHumanName,
  setSystemPlaylistYtids,
} from "store/playlist/playlist.actions";

const getUser = (state) => state.user;
const getUserhistory = (state) => state.userhistory;
const getPlayer = (state) => state.player;
const getVideo = (state) => state.video;
const getYourLor = (state) => state.yourlor;

function* getDiscovery() {
  try {
    const { data: discoverySave } = yield axios.get("/discovery");
    console.log(discoverySave?.ytids?.length, "discosave");

    if (discoverySave?.ytids?.length > 0) {
      console.log("discosave2");
      return discoverySave;
    }

    const localDiscoverySave = localStorage.getItem(YourLorType.DISCOVERY_SAVE);
    if (localDiscoverySave) {
      const discoveryLoaded = JSON.parse(localDiscoverySave);
      const expires = new Date(discoveryLoaded.expires);
      if (expires > new Date()) {
        const formattedSave = {
          expires: expires.getTime(),
          ytids: discoveryLoaded.ytids,
        };
        call(remoteSaveDiscovery, formattedSave);
        return formattedSave;
      }
    }
  } catch (err) {
    console.error("Discovery Error::", err);
  }

  return null;
}

function* remoteSaveDiscovery(save) {
  // console.log('discovery:: saveDiremoteSaveDiscoveryscovery :: 1', { save });
  yield localStorage.setItem(YourLorType.DISCOVERY_SAVE, JSON.stringify(save));
  yield axios.post("/discovery", save);
}

export function* initializeStore() {
  const userhistoryState = yield select(getUserhistory);

  // console.log('discovery:: initDiscovery :: 1');
  if (
    userhistoryState.historyYtids.length < YourLorType.REQUIRED_VALID_ARTISTS
  ) {
    // console.log('discovery:: initDiscovery :: 2');
    yield call(validateDiscoveryEligible);
    return;
  }

  // console.log('discovery:: initDiscovery :: 3');
  const discoverySave = yield call(getDiscovery);

  // console.log('discovery:: initDiscovery :: 4');
  if (discoverySave?.ytids?.length > 0) {
    // console.log('discovery:: initDiscovery :: 5', { discoverySave });
    const expires = discoverySave.expires;
    if (expires > new Date().getTime()) {
      // console.log('discovery:: initDiscovery :: 6');
      const videoData = {
        payload: {
          ytids: discoverySave.ytids,
        },
      };
      yield all([
        call(getVideoMetadataAsync, videoData),
        call(getVideoStatsAsync, videoData),
      ]);

      yield put(setDiscovery(discoverySave.ytids));
      yield put(setDiscoveryExpires(expires));
      const saveData = yield call(getOldDiscoveries);
      yield put(setOldDiscoveries(saveData));
      return null;
    } else {
      // console.log('discovery:: initDiscovery :: 7');
      yield call(saveOldDiscovery, discoverySave.ytids);
    }
  }

  // console.log('discovery:: initDiscovery :: 8');
  const saveData = yield call(getOldDiscoveries);
  yield call(setOldDiscoveries, saveData);
  yield call(validateDiscoveryEligible);
  return null;
}

function* getOldDiscoveries() {
  const existingSave = yield localStorage.getItem(
    YourLorType.OLD_DISCOVERY_SAVE
  );
  return existingSave ? JSON.parse(existingSave) : [];
}

function* saveOldDiscovery(oldDiscovery) {
  let saveData = yield call(getOldDiscoveries);
  // dont save if already saved
  if (saveData && saveData[0] === oldDiscovery[0]) {
    return;
  }
  saveData = _.take(
    saveData,
    YourLorType.DISCOVERY_LENGTH * (YourLorType.WEEKS_TO_SAVE - 1)
  );
  saveData = _.concat(oldDiscovery, saveData);
  localStorage.setItem(
    YourLorType.OLD_DISCOVERY_SAVE,
    JSON.stringify(saveData)
  );
}

function* validateDiscoveryEligible() {
  const userhistoryState = yield select(getUserhistory);

  const artistNames = yield call(getArtistNamesFromHistory);
  // console.log('discovery:: validateDiscoveryEligible :: 1', { artistNames });

  try {
    const responses = yield all(
      artistNames.map((name) => call(getArtistRecsDataAsync, name, false))
    );
    // console.log('discovery:: validateDiscoveryEligible :: 2', { responses });

    const validArtistNames = [];
    responses.forEach((response) => {
      const recommendedArtists = response.recs;
      if (recommendedArtists && recommendedArtists.length > 1) {
        validArtistNames.push(response.name);
      }
    });

    // console.log('discovery:: validateDiscoveryEligible :: 3', {
    //   validArtistNames,
    // });

    if (validArtistNames.length >= Number(YourLorType.DESIRED_VALID_ARTISTS)) {
      // console.log('discovery:: validateDiscoveryEligible :: 4');
      yield put(setArtistsToDiscover(validArtistNames));
      return true;
    }
    // console.log('discovery:: validateDiscoveryEligible :: 5');

    if (
      userhistoryState.historyNext === "" ||
      userhistoryState.historyYtids.length >= YourLorType.MAX_HISTORY
    ) {
      // console.log('discovery:: validateDiscoveryEligible :: 6');
      if (validArtistNames.length >= YourLorType.REQUIRED_VALID_ARTISTS) {
        // console.log('discovery:: validateDiscoveryEligible :: 7');
        yield put(setArtistsToDiscover(validArtistNames));
        return true;
      }
      // console.log('discovery:: validateDiscoveryEligible :: 8');
      yield put(ineligbleForDiscovery(validArtistNames.length));
      return false;
    }

    try {
      // console.log('discovery:: validateDiscoveryEligible :: 9');
      const historyPayload = {
        payload: {
          next: userhistoryState.historyNext,
          merge: true,
        },
      };
      yield call(getPlaysPageAsync, historyPayload);
      // console.log('discovery:: validateDiscoveryEligible :: 10');
      yield* validateDiscoveryEligible();
    } catch (err) {
      // console.log('discovery:: validateDiscoveryEligible :: 11');
      yield put(ineligbleForDiscovery(validArtistNames.length));
      return false;
    }
  } catch (err) {
    // console.log('discovery:: validateDiscoveryEligible :: 12');
    yield put(ineligbleForDiscovery(0));
    return false;
  }
}

function* ineligbleForDiscoveryAsync({ payload: { eligibleArtists } }) {
  if (eligibleArtists > 0) {
    // analytics.actions.trackAction(
    //   'Discovery Ineligible',
    //   String(eligibleArtists)
    // );
    window._gaq.push([
      "_trackEvent",
      "Action",
      "Discovery Ineligible",
      String(eligibleArtists),
    ]);
  }
}

function* setArtistsToDiscoverAsync() {
  yield call(addNewDiscoveryAsync);
}

// When no next artist return null as discovery will fail
export function* setNextArtist() {
  const yourlorState = yield select(getYourLor);
  const artists = yourlorState.discovery.artists;
  let data;
  if (artists.length === 0) {
    data = { artist: null };
    yield put(setNextArtistSuccess(data));
    return data;
  }
  const oldArtist = yourlorState.discovery.nextArtist;
  const nextIndex = (artists.indexOf(oldArtist) + 1) % artists.length;
  data = { artist: artists[nextIndex] };
  yield put(setNextArtistSuccess(data));

  return data;
}

export function* addNewDiscoveryAsync() {
  const yourlorState = yield select(getYourLor);
  let nextArtist = yourlorState.discovery.nextArtist;
  yield call(setNextArtist);
  // console.log('discovery:: addNewDiscovery:: 1');

  try {
    const data = yield call(findDiscoveryForArtist, nextArtist);
    console.log("discovery:: addNewDiscovery:: 2", { data });
    if (!data) {
      return;
    }

    let validate = false;
    // console.log('discovery:: addNewDiscovery:: 3 :: BEFORE', {
    //   value:
    //     yourlorState.discovery.ytids.length +
    //     yourlorState.discovery.possibleYtids.length,
    // });
    yield put(addNewDiscoverySuccess(data));
    // console.log('discovery:: addNewDiscovery:: 3 :: AFTER', {
    //   value:
    //     yourlorState.discovery.ytids.length +
    //     yourlorState.discovery.possibleYtids.length,
    // });

    const totalYTIDS =
      yourlorState.discovery.ytids.length +
      yourlorState.discovery.possibleYtids.length;
    if (
      totalYTIDS < YourLorType.DISCOVERY_LENGTH ||
      yourlorState.discovery.possibleYtids.length < 10
    ) {
      // console.log('discovery:: addNewDiscovery:: 4');
      yield* addNewDiscoveryAsync();
    } else {
      // console.log('discovery:: addNewDiscovery:: 5');
      validate = true;
    }

    if (validate) {
      // console.log('discovery:: addNewDiscovery:: 6');
      yield call(validateYtidsAsync);
    }
  } catch (err) {
    console.error("discovery:: ERROR::", err);
  }
}

export function* trackIfDiscovery(ytid) {
  const yourlorState = yield select(getYourLor);
  const inDiscovery = yourlorState.discovery.ytids.indexOf(ytid) > -1;
  // if (inDiscovery && player.store.state.playingFeature === Feature.YourLOR) {
  //   analytics.actions.trackAction('Discovery Play')
  window._gaq.push(["_trackEvent", "Action", "Discovery Play,Track", ytid]);

  // } else if (
  //   inDiscovery ||
  //   yourlorState.discovery.oldYtids.indexOf(ytid) > -1
  // ) {
  //   analytics.actions.trackAction('Discovery Track')
  // }

  return true;
}

export function* validateYtidsAsync() {
  const yourlorState = yield select(getYourLor);
  const userhistoryState = yield select(getUserhistory);
  const videoState = yield select(getVideo);
  const possibleYtids = yourlorState.discovery.possibleYtids;
  // console.log('discovery:: validateYtidsAsync:: 1', { possibleYtids });

  // const videoData = {
  //   payload: {
  //     ytids: possibleYtids,
  //   },
  // };

  // yield all([
  //   call(getVideoMetadataAsync, videoData),
  //   call(getVideoStatsAsync, videoData),
  //   call(getPlaysAsync, videoData),
  // ]);

  yield all([
    put(getVideoMetadataStart(possibleYtids)),
    put(getVideoStatsStart(possibleYtids)),
    // put(getPlaysStart(possibleYtids)),
  ]);
  // console.log('discovery:: validateYtidsAsync:: 2');

  const ytids = possibleYtids.filter((ytid) => {
    // console.log('discovery:: validateYtidsAsync:: 3', { ytid });
    return (
      !(ytid in userhistoryState.history) &&
      videoState.videoStats[ytid] &&
      videoState.videoStats[ytid].totalRepeats > 0 &&
      parseDuration(videoState.videos[ytid].duration) < 600
    );
  });

  // console.log('discovery:: validateYtidsAsync:: 4 ', { ytids });

  let created = false;
  yield put(validateYtidsStart(ytids));
  // console.log('discovery:: validateYtidsAsync:: 5 ', { ytids });

  if (yourlorState.discovery.ytids.length < YourLorType.DISCOVERY_LENGTH) {
    // console.log('discovery:: validateYtidsAsync:: 6 ');
    yield call(addNewDiscoveryAsync);
  } else {
    // console.log('discovery:: validateYtidsAsync:: 7 ');
    yield put(validateYtidsSuccess());
    created = true;
  }

  if (created) {
    // console.log('discovery:: validateYtidsAsync:: 7 ');
    yield call(discoveryCreated);
  }
}

export function* removeDiscoveryAsync({ payload: ytid }) {
  // console.log('removeDiscoveryAsync...', ytid);
  const yourlorState = yield select(getYourLor);
  const playerState = yield select(getPlayer);

  if (playerState.ytid === ytid) {
    yield put(playNextStart());
  }

  const saveData = {
    expires: yourlorState.discovery.expires,
    ytids: yourlorState.discovery.ytids.filter(
      (filterYtid) => filterYtid !== ytid
    ),
  };
  yield call(remoteSaveDiscovery, saveData);

  yield put(removeDiscoverySuccess({ ytid }));
  return { ytid };
}

export function* discoveryFailureAsync() {
  const yourlorState = yield select(getYourLor);
  const userState = yield select(getUser);

  // console.log('discovery:: discoveryFailure:: 1 ');

  if (!yourlorState.discovery.createFailure) {
    const uid = userState.profile.uid ? userState.profile.uid : "";
    const failureType =
      uid + "-" + String(yourlorState.discovery.createGeneration);
    console.error("discovery:: discoveryFailure:: 2 ", failureType);
    // analytics.actions.trackAction('Discovery Failure', failureType);
    window._gaq.push([
      "_trackEvent",
      "Action",
      "Discovery Failure",
      failureType,
    ]);

    yield put(discoveryFailure());
    throw new Error("No discovery artists");
  }
  // console.log('discovery:: discoveryFailure:: 3 ');
  yield put(discoveryFailure());
  return null;
}

export function* trackDiscoveryPlay() {
  const discoveryEvent = yield Number(
    cookie(YourLorType.DISCOVERY_ANALYTICS_COOKIE) || 0
  ) + 1;

  yield cookie(YourLorType.DISCOVERY_ANALYTICS_COOKIE, String(discoveryEvent), {
    maxage: 1000 * 3600 * 24 * 365,
    path: "/",
  });
  if (discoveryEvent === 1) {
    // analytics.actions.trackAction('First Discovery', String(discoveryEvent));
    window._gaq.push([
      "_trackEvent",
      "Action",
      "First Discovery",
      String(discoveryEvent),
    ]);
  }
}

function* saveDiscovery() {
  const yourlorState = yield select(getYourLor);

  // console.log('discovery:: saveDiscovery :: 1');

  // Set to expire on next monday
  const expires = new Date();
  expires.setDate(expires.getDate() + (1 + 7 - expires.getDay()));
  expires.setHours(0, 0, 0, 0);
  yield put(setDiscoveryExpires(expires));
  // console.log('discovery:: saveDiscovery :: 2');

  const saveData = {
    expires: expires.getTime(),
    ytids: yourlorState.discovery.ytids,
  };
  // console.log('discovery:: saveDiscovery :: 3', { saveData });
  yield call(remoteSaveDiscovery, saveData);

  const generationTime =
    new Date().getTime() - yourlorState.discovery.createStartTime;
  const roundedTime = Math.round(generationTime / 10000) * 10;
  // analytics.actions.trackAction('Discovery Created', String(roundedTime));
  window._gaq.push([
    "_trackEvent",
    "Action",
    "Discovery Created",
    String(roundedTime),
  ]);

  return true;
}

// Gets unique artist names ordered by total plays in current history
function* getArtistNamesFromHistory() {
  const userhistoryState = yield select(getUserhistory);
  const videoState = yield select(getVideo);

  // console.log('discovery:: getArtistNamesFromHistory :: 1');

  let artists = {};
  _.forEach(userhistoryState.history, function (value) {
    // console.log('discovery:: getArtistNamesFromHistory :: 2', { value });
    const videoTitle = videoState.videos[value.ytid].title;
    // console.log('discovery:: getArtistNamesFromHistory :: 3', { videoTitle });
    const artistSplit = videoTitle.split("-");
    // console.log('discovery:: getArtistNamesFromHistory :: 4', { artistSplit });

    if (artistSplit.length < 2) {
      return;
    }
    const artist = artistSplit[0].trim();
    const repeats = value.repeats;
    if (artists[artist]) {
      // console.log('discovery:: getArtistNamesFromHistory :: 5');
      artists[artist] = { artist, repeats: artists[artist].repeats + repeats };
    } else {
      // console.log('discovery:: getArtistNamesFromHistory :: 6');
      artists[artist] = { artist, repeats };
    }
  });

  // console.log('discovery:: getArtistNamesFromHistory :: 6', { artists });
  return _.uniq(
    _.map(_.orderBy(_.values(artists), ["repeats"], ["desc"]), "artist")
  );
}

function* randomDiscoverArtist(artistName, recommendedArtists) {
  const yourlorState = yield select(getYourLor);
  const normArtistName = normalizeArtistName(artistName);
  if (
    Math.floor(Math.random() * (1 / YourLorType.CHANCE_SAME_ARTIST)) === 0 &&
    isArtistEligible(normArtistName) &&
    !alreadyDiscovered(normArtistName)
  ) {
    return artistName;
  }

  const eligibleArtists = _.filter(
    recommendedArtists,
    (rec) =>
      !_.includes(yourlorState.discovery.ineligbleArtists, rec.machine_name)
  ).map((rec) => rec.name);

  const notDiscoveredEligibleArtists = _.filter(
    eligibleArtists,
    (eligibleArtist) => !alreadyDiscovered(normalizeArtistName(eligibleArtist))
  );

  if (notDiscoveredEligibleArtists.length > 0) {
    const randomIndex = Math.floor(
      Math.random() * notDiscoveredEligibleArtists.length
    );
    return notDiscoveredEligibleArtists[randomIndex];
  }

  if (eligibleArtists.length > 0) {
    const randomIndex = Math.floor(Math.random() * eligibleArtists.length);
    return eligibleArtists[randomIndex];
  }

  // no eligible artists for root artist
  if (isArtistEligible(normArtistName)) {
    return artistName;
  } else {
    yield put(removeDiscoveryArtist(normArtistName));
    return null;
  }
}

function* isArtistEligible(normArtistName) {
  const yourlorState = yield select(getYourLor);
  return yourlorState.discovery.ineligbleArtists.indexOf(normArtistName) < 0;
}

// when more than one song selected for artist already
function* alreadyDiscovered(normArtistName) {
  const yourlorState = yield select(getYourLor);
  return (
    yourlorState.discovery.selectedArtists[normArtistName] &&
    yourlorState.discovery.selectedArtists[normArtistName] > 1
  );
}

function* findDiscoveryForArtist(artistName) {
  try {
    // Use api to ensure that next artist is a valid artist name
    const data = yield call(getArtistRecsDataAsync, artistName, false);
    // console.log('discovery:: findDiscoveryForArtist:: 1 ', { data });
    const recommendedArtists = data.recs;
    artistName = yield call(
      randomDiscoverArtist,
      artistName,
      recommendedArtists
    );
    // console.log('discovery:: findDiscoveryForArtist:: 2 ', { artistName });
    if (!artistName) {
      throw new Error("Root Artist Ineligible");
    }
    const resp = yield call(onlySearch, artistName);
    // console.log('discovery:: findDiscoveryForArtist:: 3 ', {
    //   result: resp['res'],
    // });
    const ytid = yield call(
      findRandomVideo,
      resp["res"],
      artistName,
      "discovery"
    );
    // console.log('discovery:: findDiscoveryForArtist:: 4 ', { ytid });

    return { ytid, artistName };
  } catch (err) {
    console.error("discovery:: findDiscoveryForArtist:: 5 ", { err });
    return yield call(handleDiscoverFailure, artistName);
  }
}

function* handleDiscoverFailure(artistName) {
  const yourlorState = yield select(getYourLor);

  yield put(setIneligbleArtist(artistName));

  const nextArtist = yourlorState.discovery.nextArtist;
  const createGeneration = yourlorState.discovery.createGeneration;

  // console.log('discovery:: handleDiscoverFailure:: 1 ', {
  //   nextArtist,
  //   createGeneration,
  // });
  yield call(setNextArtist);

  if (!nextArtist || createGeneration >= YourLorType.MAX_GENERARIONS) {
    // console.log('discovery:: handleDiscoverFailure:: 2 ');
    return yield call(discoveryFailureAsync);
  }

  // console.log('discovery:: handleDiscoverFailure:: 3 ');
  return yield call(findDiscoveryForArtist, nextArtist);
}

export function* videoSearchEligiblity(viableYtid) {
  const yourlorState = yield select(getYourLor);
  return (
    yourlorState.discovery.possibleYtids.indexOf(viableYtid) < 0 &&
    yourlorState.discovery.ytids.indexOf(viableYtid) < 0
  );
}

function* extraVideoEligiblity(viableYtid) {
  const userhistoryState = yield select(getUserhistory);
  const videoState = yield select(getVideo);

  // console.log('discovery:: extraVideoEligiblity:: 1 ', { viableYtid });

  if (!(viableYtid in userhistoryState.history))
    return (
      !(viableYtid in userhistoryState.history) &&
      videoState.videoStats[viableYtid] &&
      videoState.videoStats[viableYtid].totalRepeats > 0 &&
      parseDuration(videoState.videos[viableYtid].duration) < 600
    );
}

export function* discoveryCreated() {
  // console.log('discovery:: discoveryCreated:: 1 ');
  yield call(saveDiscovery);
  yield call(showFeatureUpsellIfEligible, UpsellType.DISCOVERY);
}

export function* createPlaylistListeners() {
  const state = yield select(getYourLor);
  // console.log('createPlaylistListeners...');

  yield put(
    setSystemPlaylistHumanName(YourLorType.DISCOVERY_PLAYLIST, "Discovery")
  );
  const items = state.discovery.ytids;
  yield put(
    setSystemPlaylistYtids({
      [YourLorType.DISCOVERY_PLAYLIST]: items,
    })
  );
}

export function* watchInitializeStore() {
  yield takeLatest(
    [YourLorType.INIT_DISCOVERY, YourLorType.RESET_DISCOVERY],
    initializeStore
  );
}

export function* watchIneligbleForDiscovery() {
  yield takeLatest(YourLorType.INELIGBLE_DISCOVERY, ineligbleForDiscoveryAsync);
}

export function* watchSetArtistsToDiscover() {
  yield takeLatest(YourLorType.SET_ARTISTS_DISCOVER, setArtistsToDiscoverAsync);
}

export function* watchDiscoveryPlaylist() {
  yield takeLatest(
    [
      YourLorType.SET_DISCOVERY,
      YourLorType.VALIDATE_YTIDS,
      YourLorType.VALIDATE_YTIDS_SUCCESS,
      YourLorType.REMOVE_DISCOVERY_SUCCESS,
    ],
    createPlaylistListeners
  );
}

export function* watchRemoveDiscovery() {
  yield takeLatest(YourLorType.REMOVE_DISCOVERY_START, removeDiscoveryAsync);
}

export function* yourlorSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchIneligbleForDiscovery),
    call(watchSetArtistsToDiscover),
    call(watchRemoveDiscovery),
    call(watchDiscoveryPlaylist),
  ]);
}
