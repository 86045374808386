import * as PlayerPodCastType from './playerPodCast.types';

export const episodePlayerStart = (podcastid, episodeid) => ({
    type: PlayerPodCastType.VIEW_EPISODE_PLAYER_START,
    payload: { podcastid, episodeid },
});

export const setCurrentViewPodCastUrl = (podcastId) => ({
    type: PlayerPodCastType.SET_VIEW_PODCAST_URL,
    payload: podcastId,
});

export const setPodCastUrl = () => ({
    type: PlayerPodCastType.SET_EPISODE_PLAY_URL,
    payload: { },
});

export const fetchURLPodcastStart = (podcastURL, episodeURL) => ({
    type: PlayerPodCastType.FETCH_URL_PODCAST_START,
    payload: { podcastURL, episodeURL },
});

export const fetchURLPodcastSuccess = (podcastid, episodeid) => ({
    type: PlayerPodCastType.VIEW_EPISODE_PLAYER_START,
    payload: { podcastid, episodeid },
});

export const setAudioStatus = (status) => ({
    type: PlayerPodCastType.SET_AUDIO_STATUS,
    payload: { status }
});
