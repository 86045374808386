import * as SearchPodcastType from './searchPodcast.types';

export const getPodcastAutocompleteSuggestionStart = (name) => ({
    type: SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_START,
    payload: { name },
});

export const getPodcastAutocompleteSuggestionSuccess = (data) => ({
    type: SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_SUCCESS,
    payload: data,
});

export const getPodcastAutocompleteSuggestionFail = (error) => ({
    type: SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_FAIL,
    payload: error,
});

export const searchPodcastStart = (query) => ({
    type: SearchPodcastType.SEARCH_PODCAST_START,
    payload: { query },
});

export const searchPodcastSuccess = (data) => ({
    type: SearchPodcastType.SEARCH_PODCAST_SUCCESS,
    payload: data,
});

export const searchPodcastFail = (err) => ({
    type: SearchPodcastType.SEARCH_PODCAST_FAIL,
    payload: err,
});

export const toggleSearch = (value) => ({
    type: SearchPodcastType.TOGGLE_SEARCH,
    payload: value
});
