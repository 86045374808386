export const SET_USER_PROFILE_START = 'SET_USER_PROFILE_START';
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS';
export const SET_USER_PROFILE_FAIL = 'SET_USER_PROFILE_FAIL';

export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAIL = 'FETCH_USER_PROFILE_FAIL';

export const SET_FB_PROFILE = 'SET_FB_PROFILE';
export const SET_GOOGLE_PROFILE = 'SET_GOOGLE_PROFILE';
export const SET_PROFILE_DATA_START = 'SET_PROFILE_DATA_START';
export const SET_PROFILE_DATA_SUCCESS = 'SET_PROFILE_DATA_SUCCESS';

export const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';

export const ANONYMOUS = 'anonymous';
export const FACEBOOK = 'facebook';
export const GOOGLE = 'google';
