import { FB_INIT_SUCCESS, FB_FETCH_PROFILE_SUCCESS } from "./facebook.types";

const INITIAL_STATE = {
  sdkIsLoaded: false,
  profile: {
    fbUid: null,
    firstName: null,
    email: null,
    pictureUrl: null,
  },
};

const fbReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FB_INIT_SUCCESS:
      return {
        ...state,
        sdkIsLoaded: true,
      };

    case FB_FETCH_PROFILE_SUCCESS:
      console.log(payload, "fb profile success");
      return {
        ...state,
        profile: {
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default fbReducer;
