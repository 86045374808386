const styles = {
  colors: {
    icon: '#b0b0b0',
  },

  item: {
    display: 'flex',
    margin: 0,
    listStyle: 'none',
    cursor: 'pointer',
    borderBottom: '1px solid',
    padding: '0 16px',

    title: {
      flexGrow: 1,
      width: '100%',
      fontSize: 13,
      color: 'grey',
      lineHeight: '38px',
    },

    count: {
      color: '#d3d3d3',
      fontSize: 11,
      minWidth: 100,
      textAlign: 'right',
      lineHeight: '38px',
      margin: '0 5px',
    },

    iconButton: {
      padding: 0,
      height: 38,
      width: 30,
      margin: '0 5px',
    },
  },
};

export default styles;
