import { connect } from 'react-redux';

import { viewPlayerVideoStart } from 'store/player/player.actions';
import { getVideoStatsStart } from 'store/video/video.actions';
import { showAddPlaylistModal } from 'store/playlist/playlist.actions';
import {
  enqueueToolTip,
  closeToolTip,
  hideDeletePlaylistToolTip,
} from 'store/tooltip/tooltip.actions';

const mapStateToProps = (state) => ({
  playlistState: state.playlist,
  userState: state.user,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onGetVideoStatsStart: (ytids) => dispatch(getVideoStatsStart(ytids)),
  onHideDeletePlaylistToolTip: () => dispatch(hideDeletePlaylistToolTip()),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
