import React from 'react';

import { ContentPage } from 'common/ui/content-page';
import SortMenu from 'common/ui/sort-menu';
import HistorySortType from 'constants/HistorySort';

import { HistoryCard } from './components';
import container from './HistoryPage.container';

const HistoryPage = (props) => {
  const sortItems = [
    { id: HistorySortType.LastPlayed, text: 'Last Played' },
    { id: HistorySortType.RepeatCount, text: 'Repeat Count' },
  ];

  const onSortByChange = (typeID) => {
    console.log('[HistoryPage] onSortByChange', typeID);
    props.onChangeSortStart(typeID);
    // if (typeID === HistorySortType.RepeatCount) {
    //   analytics.actions.trackUIClick('Repeat Count');
    // }
  };

  return (
    <ContentPage
      header={{
        title: 'History',
        description:
          'All the music you’ve ever played. Take a walk back through time!',
        controls: (
          <SortMenu
            title="Sort History"
            items={sortItems}
            initialItem={HistorySortType.LastPlayed}
            onSortByChange={onSortByChange}
          />
        ),
      }}
      body={<HistoryCard />}
    />
  );
};

export default container(HistoryPage);
