import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import { ArticleDetail as ArticleDetailCard } from 'modules/article/components';
import useStyles from './ContentPageStyle';

export const ContentHeader = (props) => {
  const classes = useStyles();
  function noHeader(mobile) {
    return (
      <>
        <div className={classes.cardTitle}>
          <div>
            <div className={classes.cardHeader}>
              {props.titleComponent ? props.titleComponent : props.title}
            </div>
            <div className={classes.cardSubHeader}>{props.description}</div>
          </div>
          <div className={classes.controls}>{props.controls}</div>
        </div>
        {/*<CardHeader
              title={props.titleComponent ? props.titleComponent : props.title}
              subheader={props.description}
              action={props.controls}
          />*/}
        <div
          className={
            mobile ? classes.controlsMobile : classes.controlsLargePlayer
          }
        >
          {/*{props.controls}*/}
        </div>
      </>
    );
  }

  return (
    <div>
      <Hidden mdUp>{noHeader(false)}</Hidden>
      <Hidden smDown>{noHeader(true)}</Hidden>
    </div>
  );
};

export function ContentBody() {
  return <div id="main-content-body">{this.props.children}</div>;
}

const ContentPage = (props) => {
  const classes = useStyles();
  const noHeader = () => {
    return (
      <Card className={classes.rootBody}>
        <ContentHeader {...props.header} />
        <Divider />
        <CardContent id="main-content-body" style={{ padding: 0 }}>
          {props.body}
        </CardContent>
      </Card>
    );
  };
  return (
    <div className="main-content-page">
      {/* <Hidden mdUp>
        <ArtistInsightCard
          insight={props.required.artistState.info.insight}
        />
      </Hidden> */}
      <ArticleDetailCard />
      {noHeader()}
    </div>
  );
};

export default ContentPage;
