import { connect } from 'react-redux';

import {
  addHeartStart,
  deleteHeartStart,
} from 'store/userhistory/userhistory.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapDispatchToProps = (dispatch) => ({
  onAddHeartStart: (ytid) => dispatch(addHeartStart(ytid)),
  onDeleteHeartStart: (ytid) => dispatch(deleteHeartStart(ytid)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

const container = connect(null, mapDispatchToProps);

export default container;
