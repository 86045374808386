export const SET_DATA_START = 'SET_DATA_START';
export const SET_DATA_SUCCESS = 'SET_DATA_SUCCESS';
export const SET_DATA_FAIL = 'SET_DATA_FAIL';

export const GET_VIEW_VIDEO_SUGGESTION_START =
  'GET_VIEW_VIDEO_SUGGESTION_START';
export const GET_VIEW_VIDEO_SUGGESTION_SUCCESS =
  'GET_VIEW_VIDEO_SUGGESTION_SUCCESS';
export const GET_VIEW_VIDEO_SUGGESTION_FAIL = 'GET_VIEW_VIDEO_SUGGESTION_FAIL';

export const GET_SEARCH_PREVIEW_START = 'GET_SEARCH_PREVIEW_START';
export const GET_SEARCH_PREVIEW_SUCCESS = 'GET_SEARCH_PREVIEW_SUCCESS';
export const GET_SEARCH_PREVIEW_FAIL = 'GET_SEARCH_PREVIEW_FAIL';

export const GET_ARTIST_RECS_SUCCESS = 'GET_ARTIST_RECS_SUCCESS';
export const GET_ARTIST_RECS_DATA_SUCCESS = 'GET_ARTIST_RECS_DATA_SUCCESS';

export const GET_ARTIST_INFO_TITLE = 'GET_ARTIST_INFO_TITLE';

export const GET_ARTIST_INFO_START = 'GET_ARTIST_INFO_START';
export const GET_ARTIST_INFO_SUCCESS = 'GET_ARTIST_INFO_SUCCESS';
export const GET_ARTIST_INFO_FAIL = 'GET_ARTIST_INFO_FAIL';

export const SET_RADIO_SEARCH = 'SET_RADIO_SEARCH';
export const SET_RADIO_VIDEO = 'SET_RADIO_VIDEO';
export const SET_RADIO = 'SET_RADIO';
export const SETUP_ARTIST_RADIO = 'SETUP_ARTIST_RADIO';
export const REMOVE_ITEM_CUE = 'REMOVE_ITEM_CUE';
export const CUE_SONG = 'CUE_SONG';
export const REPEAT_CUE = 'REPEAT_CUE';
export const SKIP_ARTIST_RADIO = 'SKIP_ARTIST_RADIO';
export const CUE_SEARCHING_SONG = 'CUE_SEARCHING_SONG';
export const REMOVE_SEARCHING_SONG = 'REMOVE_SEARCHING_SONG';
export const REMOVE_ARTIST_RADIO_REC = 'REMOVE_ARTIST_RADIO_REC';
export const PLAY_ARTIST_RADIO = 'PLAY_ARTIST_RADIO';
