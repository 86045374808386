import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { StreamsGrid } from 'modules/streams/components/StreamsCard/components';

import container from './Streams.container';
import { scrollToVideo } from 'common/ui/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.paperColor,
    position: 'relative',
    marginBottom: 20,
  },
  highlight: {
    color: '#ff0066',
  },
  title: {
    color: '#ff0066',
    padding: 10,
    paddingLeft: 20,
    pointerEvents: 'none',
    fontSize: 16,
  },
  explain: {
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '10pt',
    paddingTop: 10,
    paddingBottom: 10,
  },
  discovery: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    margin: '0px 8%',
    paddingBottom: 20,
  },
  description: {
    position: 'relative',
    marginBottom: 20,
  },
}));

const Streams = (props) => {
  const classes = useStyles();

  const { streamsState } = props;

  const onStreamSelect = () => {
    // analytics.actions.trackUIClick('Your LOR', 'Stream');
  };

  return (
    <Card className={classes.featureCard}>
      <div className={classes.streamsTitle}>Live Streams</div>
      <StreamsGrid
        streamsState={streamsState}
        selectFunction={onStreamSelect}
        cardStyle={{ width: '25%' }}
        isSmall={true}
      />
    </Card>
  );
};

export default container(Streams);
