import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import ToolTipKeys from 'constants/ToolTipKeys';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';

import container from './PlaylistAddButton.container';

import styles from '../../StatusBar.styles';

const useStyles = makeStyles(() => ({
  iconButton: {
    ...styles.iconButton,
  },
  icon: {
    ...styles.iconButton.icon,
  },
}));

const PlaylistAddButton = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    ytid,
    playlistState,
    userState,
    tooltipState,
    onHideDeletePlaylistToolTip,
    onCloseToolTip,
    onEnqueueToolTip,
    onShowAddPlaylistModal,
  } = props;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  function onClick(evt) {
    const element = evt.currentTarget;

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }

    toggleAddToPlaylistToolTip(ytid, element);
    evt.stopPropagation();
    evt.preventDefault();
  }

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.key === ToolTipKeys.PlayerAddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.PlayerAddToPlaylistToolTip, null, ytid);
  }

  function onDblClick(evt) {
    // console.log('Dbl Click..');
    // playlist.actions.addYtidsToCurrentPlaylist([props.ytid])
    // playlist.actions.hideToolTips()
  }

  return (
    <>
      <Tooltip title={matchesSM ? '' : 'Add to playlist'}>
        <IconButton
          className={classes.iconButton}
          onClick={onClick}
          onDoubleClick={onDblClick}
          style={{
            paddingRight: 9,
            marginTop: 0,
          }}
        >
          <PlaylistAddIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      {open &&
        tooltipState.currentEntry.key ===
          ToolTipKeys.PlayerAddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.PlayerAddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
    </>
  );
};

export default container(PlaylistAddButton);
