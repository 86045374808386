import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import AddPlaylist from 'assets/images/add-to-playlist.jpg';
import styles from './PlaylistEmpty.style';

const useStyles = makeStyles((theme) => ({
  container: {
    ...styles.container,
  },
  firstItem: {
    ...styles.container.firstItem,
  },
  secondItem: {
    ...styles.container.secondItem,
  },
  image: {
    ...styles.container.firstItem.image,
  },
  description: {
    ...styles.container.firstItem.description,
  },
  textfield: {
    ...styles.container.secondItem.textfield,
  },
  button: {
    ...styles.container.secondItem.button,
  },
  inputLabel: {
    color: theme.palette.darkGray,
    fontFamily: 'Arial',
    '&$focusedLabel': {
      color: theme.palette.darkGray,
    },
    '&$erroredLabel': {
      color: '#f44336',
    },
  },
  underline: {
    '&$error:after': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottom: `2px solid rgb(255, 0, 102)`,
    },
  },
  inputBase: {
    fontSize: 16,
    color: theme.palette.alternateTextColor,
    fontFamily: 'Arial',
  },
}));

const PlaylistEmpty = (props) => {
  const classes = useStyles();

  const [newPlaylist, setNewPlaylist] = useState({
    value: '',
    isValid: false,
    errorText: '',
  });

  const onPlaylistNameChange = (event) => {
    let isValid = event.target.value.length > 0;
    setNewPlaylist({
      value: event.target.value,
      isValid: isValid,
      errorText: '',
    });
  };

  const onPlaylistNameBlur = (event) => {
    let isValid = event.target.value.length > 0;
    setNewPlaylist({
      errorText: !isValid && 'Invalid playlist name',
      value: event.target.value,
      isValid: isValid,
    });
  };

  const onPlaylistNameKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    let newName = newPlaylist.value;

    if (newName.length === 0) {
      return;
    }

    if (newName.length >= 50) {
      // notification.actions.showSnackbarNotification(`Playlist name too long`);
      return;
    }

    // playlist.actions.createPlaylist(newName);

    // notification.actions.showSnackbarNotification(
    //   `Created playlist ${newName} 🎉`
    // );

    setNewPlaylist({ value: '', isValid: false, errorText: '' });
  };

  return (
    <div className={classes.container}>
      <div className={classes.firstItem}>
        <div>
          <img
            className={classes.image}
            src={AddPlaylist}
            alt="add-to-playlist"
          />
          <span className={classes.description}>
            Press the <strong>Add to Playlist</strong> button on your choosen
            video.
          </span>
        </div>
      </div>

      <div className={classes.secondItem}>
        <div style={{ width: '100%' }}>
          <div style={{ height: 140, marginTop: 10 }}>
            <TextField
              className={classes.textfield}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                },
              }}
              InputProps={{
                classes: {
                  input: classes.inputBase,
                  root: classes.underline,
                },
              }}
              label=""
              placeholder="New Playlist +"
              onChange={onPlaylistNameChange}
              onBlur={onPlaylistNameBlur}
              onKeyDown={onPlaylistNameKeyDown}
            />
            <Button className={classes.button}>
              <AddIcon />
            </Button>
          </div>
          <span className={classes.description}>
            Or create an empty playlist {'🗒'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlaylistEmpty;
