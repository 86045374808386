import { takeLatest, call, put, all, select } from "redux-saga/effects";
import axios from "axios";
import * as RadioType from "./radio.types";
import * as radioActions from "./radio.actions";
const RADIO_URL_SERVER = `https://de1.api.radio-browser.info`;
// const RADIO_URL_SERVER = `https://fr1.api.radio-browser.info`;
// const RADIO_URL_SERVER = `https://nl1.api.radio-browser.info`;

export function* fetchRadioListAsync() {
  try {
    const response = yield axios.post(
      "https://system.listenonrepeat.com/ads/geo"
    );
    window.country = response.data;
    const countrycode = response.data.loc;
    // console.log('countrycode:: ', countrycode);
    const { data } = yield axios.get(
      `${RADIO_URL_SERVER}/json/stations/search`,
      {
        params: {
          countrycode,
        },
      }
    );
    const tagsArray = [];
    if (data) {
      for (const key in data) {
        const obj = data[key];
        if (!tagsArray.includes(obj.tags)) {
          tagsArray.push(obj.tags);
        }
      }
    }
    // console.log('tagsArray:: ', tagsArray);
    yield put(radioActions.setCountryCode(countrycode));
    yield put(radioActions.fetchRadioListSuccess(data));
    window._gaq.push([
      "_trackEvent",
      "RadioAction",
      "fetchingradiolist",
      "true",
    ]);
  } catch (err) {
    console.error("error::", err);
    window._gaq.push([
      "_trackEvent",
      "RadioAction",
      "fetchingradiolist",
      "false",
    ]);

    yield put(radioActions.fetchRadioListFail(err));
  }

  try {
    yield axios
      .get("https://admin.listenonrepeat.com/api/ads")
      .then((response) => {
        window.ads = response.data;
      });
  } catch (err) {
    console.log(err);
  }
  // try {
  //     yield axios.post('/ads/geo').then((response) => {
  //         window.country=response.data;
  //     })
  // } catch (err) {
  //     console.log(err);
  // }
}

export function* fetchGenresRadioListAsync({ payload: { countrycode, tag } }) {
  try {
    const { data } = yield axios.get(
      `${RADIO_URL_SERVER}/json/stations/search`,
      {
        params: {
          countrycode,
          tag,
        },
      }
    );
    // console.log('tag result station data:: ', data);
    yield put(radioActions.fetchGenresRadioListSuccess(data));
    window._gaq.push([
      "_trackEvent",
      "RadioAction",
      "fetchingradiolist",
      "true",
    ]);
  } catch (err) {
    console.log("error:: ", err);
    window._gaq.push([
      "_trackEvent",
      "RadioAction",
      "fetchingradiolist",
      "false",
    ]);

    yield put(radioActions.fetchGenresRadioListFail(err));
  }
}

export function* watchFetchRadioList() {
  yield takeLatest(RadioType.FETCH_RADIO_LIST_START, fetchRadioListAsync);
}

export function* watchFetchGenresRadioList() {
  yield takeLatest(
    RadioType.FETCH_GENRES_RADIO_LIST_START,
    fetchGenresRadioListAsync
  );
}

export function* radioSagas() {
  yield all([call(watchFetchRadioList), call(watchFetchGenresRadioList)]);
}
