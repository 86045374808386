import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import useStyles from '../../../styles';

import container from './ToggleHeartButton.container';

const ToggleHeartButton = (props) => {
  const classes = useStyles();

  const {
    heart,
    ytid,
    onAddHeartStart,
    onDeleteHeartStart,
    onShowSnackbarNotification,
  } = props;

  const handleClick = () => {
    // console.log('ToggleHeartButton...');
    if (heart) {
      onDeleteHeartStart(ytid);
      window._gaq.push(['_trackEvent', 'Action', 'Unheart Video', ytid]);
      onShowSnackbarNotification(`Video Un-hearted ��`);

    } else {
      onAddHeartStart(ytid);
      window._gaq.push(['_trackEvent', 'Action', 'Heart Video', ytid]);
      onShowSnackbarNotification(`Video Hearted ❤️`);

    }
  };

  return (
    <Tooltip title={heart ? 'Un-heart Video' : 'Heart Video'}>
      <IconButton
        className={classes.iconButton}
        onClick={handleClick}
        style={{
          paddingRight: 2,
          marginTop: 0,
        }}
      >
        {heart ? (
          <FavoriteIcon className={classes.actionIcon} />
        ) : (
          <FavoriteBorderIcon className={classes.actionIcon} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default container(ToggleHeartButton);
