import _ from 'lodash';
import cookie from 'component-cookie';

import * as PlayerType from './player.types';

import Feature from 'constants/Feature';

const savedCookieVal = cookie('repeat-mode');

let savedRepeatMode = true;

if (savedCookieVal === 'true') {
  savedRepeatMode = true;
} else if (savedCookieVal === 'false') {
  savedRepeatMode = false;
} else {
  // Default to repeat for new user
  savedRepeatMode = true;
}

const savedShuffleMode = cookie('shuffle-mode') === 'true';

const INITIAL_STATE = {
  ytid: null,
  viewCount: 0,
  player: {
    toggle: false,
    repeatMode: savedRepeatMode,
    shuffleMode: savedShuffleMode,
    radioMode: false,
    isReady: false,
    state: -1,
    playPrevOrRestart: null,
  },
  isLoading: false,
  autoplay: false,
  alreadyShuffled: [],
  playHistory: [],
  skipTo: { duration: 0, ytid: '' },
  playingFeature: Feature.History,
};

const playerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case PlayerType.TOGGLE_PLAY_PAUSE:
      return {
        ...state,
        player: {
          ...state.player,
          toggle: !state.player.toggle,
        },
      };

    case PlayerType.VIEW_PLAYER_VIDEO_SUCCESS:
      return {
        ...state,
        ytid: payload.player.ytid,
        viewCount: state.viewCount + 1,
        alreadyShuffled: _.uniq([
          ...state.alreadyShuffled,
          payload.player.ytid,
        ]),
        playHistory: [...state.playHistory, payload.player.ytid],
      };

    case PlayerType.SET_PLAYER_YTID_SUCCESS:
      return {
        ...state,
        ytid: payload,
      };

    case PlayerType.SET_CURRENT_PLAYLIST:
      return {
        ...state,
        alreadyShuffled: [],
        playHistory: [],
      };

    case PlayerType.PLAYER_DID_CHANGE_STATE:
      return {
        ...state,
        player: {
          ...state.player,
          state: payload,
        },
      };

    case PlayerType.PLAYER_IS_READY:
      return {
        ...state,
        player: {
          ...state.player,
          isReady: true,
        },
      };

    case PlayerType.SET_SHUFFLE_MODE:
      if (state.player.shuffleMode !== payload) {
        state.alreadyShuffled = [];
      }

      return {
        ...state,
        player: {
          ...state.player,
          shuffleMode: payload,
        },
      };

    case PlayerType.SET_REPEAT_MODE:
      return {
        ...state,
        player: {
          ...state.player,
          repeatMode: payload,
        },
      };

    case PlayerType.SET_RADIO_MODE:
      return {
        ...state,
        player: {
          ...state.player,
          radioMode: payload,
        },
      };

    case PlayerType.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case PlayerType.SET_AUTOPLAY:
      return {
        ...state,
        autoplay: payload,
      };

    case PlayerType.GET_STREAM_INIT:
      return {
        ...state,
        skipTo: {
          duration: payload['seconds_in'],
          ytid: payload['playing_ytid'],
        },
      };

    case PlayerType.CLEAR_SEEK:
      return {
        ...state,
        skipTo: { duration: 0, ytid: '' },
      };

    case PlayerType.PLAY_FEATURE:
      cookie('last-used-feature-v2', payload, {
        maxage: 1000 * 3600 * 24 * 365,
        path: '/',
      });
      return {
        ...state,
        playingFeature: payload,
      };

    case PlayerType.SET_LAST_PLAYED:
      localStorage.setItem('lastPlayed', payload);
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default playerReducer;
