import { all, call, put, select, takeLatest } from "redux-saga/effects";
import * as PodcastType from "./podcast.types";
import * as podcastActions from "./podcast.actions";
import { scrollToMainContent } from "common/ui/utils";
import axios from "instances/radioServer";
import apple_genres from "modules3/data/apple_genres.json";
import * as playerPodCastAction from "../playerPodCast/playerPodCast.actions";

const getPodcastState = (state) => state.podcast;

export function* fetchInitialStartAsync() {
  try {
    //https://3rugykz490.execute-api.us-west-1.amazonaws.com/dev/listen/podcast/top
    // index
    const { data } = yield axios.get(`/apple/podcast/top`);
    yield put(podcastActions.fetchInitialSuccess(data.feeds));
  } catch (err) {
    yield put(podcastActions.fetchInitialFail(err));
  }
  try {
    yield axios
      .get("https://admin.listenonrepeat.com/api/ads")
      .then((response) => {
        window.ads = response.data;
      });
  } catch (err) {
    console.log(err);
  }
  try {
    yield axios
      .post("https://system.listenonrepeat.com/ads/geo")
      .then((response) => {
        window.country = response.data;
      });
  } catch (err) {
    console.log(err);
  }
}

export function* getGenresAsync() {
  try {
    // const { data } = yield axios.get(`apple/podcast/genre`);
    // console.log('genres list result: ', data);
    yield put(podcastActions.getGenresSuccess(apple_genres));
  } catch (e) {
    yield put(podcastActions.getGenresFail(e));
  }
}

export function* requestGenresPodcastAsync({
  payload: { genresId, currentPage },
}) {
  try {
    const { data } = yield axios.get(
      // `/podcastIndex/podcast/genre/${genresId}`,
      `/podcast/genre/${genresId}`,
      {
        params: {
          next: currentPage === 1 ? null : currentPage,
        },
      }
    );
    // console.log('data: ', data);
    // yield put(podcastActions.requestGenresPodcastSuccess(data.feeds));
    yield put(podcastActions.requestGenresPodcastSuccess(data.Items));
    yield put(podcastActions.setPodcastCurrentPage(data.hasMore));
  } catch (err) {
    yield put(podcastActions.requestGenresPodcastFail(err));
  }
}

export function* requestApplePodcastEpisodeAsync({ payload: { podcastId } }) {
  try {
    //https://3rugykz490.execute-api.us-west-1.amazonaws.com/dev/apple/podcast/201671138/feed
    const { data } = yield axios.get(`apple/podcast/${podcastId}/feed`);
    // console.log('apple podcast episode data: ', data.episodes);
    yield put(
      podcastActions.fetchPodCastEpisodeSuccess(podcastId, data.episodes)
    );
  } catch (e) {
    yield put(podcastActions.fetchApplePodcastEpisodeFail(e));
  }
}

export function* requestPodcastEpisodeAsync({ payload: { podcastId } }) {
  try {
    const { data } = yield axios.get(`listen/podcast/${podcastId}`);
    yield put(
      podcastActions.fetchPodCastEpisodeSuccess(podcastId, data.episodes)
    );
    let podcastData = [];
    delete data.episodes;
    podcastData.push(data);
    // console.log('here is podcastData: ', podcastData);
    yield put(podcastActions.requestGenresPodcastSuccess(podcastData));
  } catch (e) {
    yield put(podcastActions.fetchListenNotesPodcastEpisodeFail(e));
  }
}

export function* setCurrentViewPodcastAsync({ payload: { podcastId } }) {
  // console.log('current podcastId: ', podcastId);
  // const podcastState = yield select(getPodcastState);
  // const podcast = podcastState.genresPodcasts[podcastId];
  // console.log('here is podcast data: ', podcast);
  // yield put(podcastActions.setCurrentViewPodcastSuccess(podcastId));
  yield put(playerPodCastAction.setCurrentViewPodCastUrl(podcastId));
}

export function* requestNextPodcastEpisodeAsync({
  payload: { podcastId, nextEpisodeValue },
}) {
  try {
    // const { data } = yield axios.get(`listen/podcast/${podcastId}/?nextEpisode={next_episode_pub_date}`);
    // console.log('next episode: ', nextEpisodeValue);
    const { data } = yield axios.get(`listen/podcast/${podcastId}/`, {
      params: {
        nextEpisode: nextEpisodeValue,
      },
    });
    // console.log('next episode data: ', data);
    // yield put(podcastActions.setCurrentViewPodcastSuccess(podcastId));
    yield put(
      podcastActions.fetchPodCastEpisodeSuccess(podcastId, data.episodes)
    );
  } catch (e) {
    yield put(podcastActions.requestNextPodcastEpisodeFail(e));
  }
}

export function* getPodcastMetadataAsync(itunesId) {
  const podcastState = yield select(getPodcastState);
  const podcasts = podcastState.genresPodcasts;
  const check = Object.values(podcasts).some(
    (value) => value.id === itunesId && value.fetched === true
  );
  if (!check) {
    const { data } = yield axios.get(`/podcastIndex/podcast/${itunesId}`);
    data.feed.fetched = true;
    let podcastData = [];
    podcastData.push(data.feed);
    // console.log('here is podcastData: ', podcastData);
    yield put(podcastActions.requestGenresPodcastSuccess(podcastData));
  } else {
    yield put(podcastActions.podcastEpisodeDataExists(itunesId));
  }
}

export function* getPodcastEpisodeMetadataAsync(itunesId) {
  const podcastState = yield select(getPodcastState);
  const podcastEpisodes = podcastState.podcastEpisodes;
  const check = Object.values(podcastEpisodes).some(
    (value) => value.podcast_id === itunesId
  );
  // console.log('podcastEpisodes: ', check);
  if (!check) {
    const { data } = yield axios.get(
      `/podcastIndex/podcast/episodes/${itunesId}`
    );
    yield put(podcastActions.fetchPodCastEpisodeSuccess(itunesId, data.items));
  } else {
    yield put(podcastActions.podcastEpisodeDataExists(itunesId));
  }
}

export function* fetchPodCastEpisode({ payload: { podcastId } }) {
  try {
    // console.log('podcastId: ', podcastId);
    // const podcastState = yield select(getPodcastState);
    // const itunesId = podcastState.genresPodcasts[podcastId].itunesId;
    yield call(getPodcastEpisodeMetadataAsync, podcastId);
    yield call(getPodcastMetadataAsync, podcastId);
    scrollToMainContent();
  } catch (err) {
    yield put(podcastActions.fetchPodCastEpisodeFail(err));
  }
}

// --------------------------------------------

export function* requestGenresPodcast({ payload: { genresId } }) {
  try {
    // console.log('genres id: ', genresId);
    const { data } = yield axios.get(`/podcast/genre/${genresId}`);
    // console.log('genres podcast data: ', data);
    yield put(podcastActions.requestGenresPodcastSuccess(data.Items));
  } catch (err) {
    yield put(podcastActions.requestGenresPodcastFail(err));
  }
}

export function* addHistoryAsync() {
  console.log("podcast playing..");
}

/**
 * watch generator functions
 */
export function* watchFetchInitialStart() {
  yield takeLatest(PodcastType.FETCH_INITIAL_START, fetchInitialStartAsync);
}

export function* watchGetGenres() {
  yield takeLatest(PodcastType.GET_GENRES_START, getGenresAsync);
}

export function* watchRequestGenresPodcast() {
  yield takeLatest(
    PodcastType.REQUEST_GENRES_PODCAST_START,
    requestGenresPodcastAsync
  );
}

export function* watchRequestApplePodcastEpisode() {
  yield takeLatest(
    PodcastType.REQUEST_APPLE_PODCAST_EPISODE_START,
    requestApplePodcastEpisodeAsync
  );
}

export function* watchRequestPodcastEpisode() {
  yield takeLatest(
    PodcastType.REQUEST_PODCAST_EPISODE_START,
    requestPodcastEpisodeAsync
  );
}

export function* watchCurrentViewPodcast() {
  yield takeLatest(
    PodcastType.SET_CURRENT_VIEW_PODCAST,
    setCurrentViewPodcastAsync
  );
}

export function* watchRequestNextPodcastEpisode() {
  yield takeLatest(
    PodcastType.REQUEST_NEXT_PODCAST_EPISODE,
    requestNextPodcastEpisodeAsync
  );
}

export function* watchFetchPodCastEpisode() {
  yield takeLatest(
    PodcastType.FETCH_POD_CAST_EPISODE_START,
    fetchPodCastEpisode
  );
}

// ------------------------------------------

export function* watchAddPlay() {
  yield takeLatest(PodcastType.ADD_PLAY_POD_CAST_START, addHistoryAsync);
}

export function* podCastSagas() {
  yield all([
    call(watchFetchInitialStart),
    call(watchGetGenres),
    call(watchRequestGenresPodcast),
    call(watchRequestApplePodcastEpisode),
    call(watchRequestPodcastEpisode),
    call(watchCurrentViewPodcast),
    call(watchRequestNextPodcastEpisode),
    call(watchFetchPodCastEpisode),
    call(watchAddPlay),
  ]);
}
