import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';

import SectionTab from 'constants/SectionTab';

import { generateHeader } from 'store/artist/artist.utils';
import ArtistSuggestionImage from '../SuggestionImage';

import styles from './RadioSearchSuggestion.styles';
import clsx from 'clsx';
import { scrollToMainContentArea } from 'common/ui/utils';

import container from './RadioSearchSuggestion.container';

const useStyles = makeStyles((theme) => ({
  root: {
    ...styles.root,
    borderColor: theme.palette.accent3Color,
    '&:hover': {
      backgroundColor: theme.palette.hoverColorItem,
    },
  },
  image: {
    ...styles.root.image,
  },
  infoWrapper: {
    ...styles.root.info,
  },
  title: {
    ...styles.root.info.title,
  },
  message: {
    ...styles.root.info.message,
  },
  infoMsg: {
    ...styles.root.info.info,
  },
  actions: {
    ...styles.root.actions,
  },
  button: {
    ...styles.root.actions.button,
    color: theme.palette.accent2Color,
  },
  icon: {
    ...styles.root.actions.button.icon,
  },
}));

const RadioSearchSuggestion = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    artistState,
    results,
    onClearCurrentlyViewedStream,
    onSetAutoplay,
    onSetRepeatMode,
    onSetCurrentTab,
    onSetupArtistRadio,
  } = props;
  if (
    !artistState.searchPreview.radioName ||
    !artistState.searchPreview.artistRadioRecs
  ) {
    return null;
  }
  if (_.isNull(results)) {
    return null;
  }

  const onClickRow = (e) => {
    scrollToMainContentArea();
    onClearCurrentlyViewedStream();
    onSetRepeatMode(false);
    onSetAutoplay(true);
    onSetupArtistRadio(
      artistState.searchPreview.radioName,
      artistState.searchPreview.startVideo
    );
    onSetCurrentTab(SectionTab.Radio);

    e.stopPropagation();
    e.preventDefault();
  };

  const { description, images } = generateHeader(
    artistState.searchPreview.artistRadioRecs
  );
  const name = artistState.searchPreview.radioName;

  return (
    <div
      className={clsx('radio-suggestion', classes.root)}
      onClick={onClickRow}
    >
      <ArtistSuggestionImage images={images} className={classes.image} />
      <div className={classes.infoWrapper}>
        <h5 className={classes.title}>
          Play <span style={{ color: '#ff0066' }}>{name}</span> Artist Radio
        </h5>
        <Hidden smDown>
          <div className={classes.message}>
            Let our AI DJ for you - we'll create a never-ending mix of music
            you'll love!
          </div>
        </Hidden>

        <span className={classes.infoMsg}>
          <strong>Featuring:</strong> {description}
        </span>
      </div>
      <div className={classes.actions}>
        <IconButton className={classes.button}>
          <PlayIcon width={40} height={40} classes={{ root: classes.icon }} />
        </IconButton>
      </div>
    </div>
  );
};

RadioSearchSuggestion.propTypes = {};

export default container(RadioSearchSuggestion);
