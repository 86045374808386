import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import LoveIcon from '@material-ui/icons/Favorite';
import LoveBorderIcon from '@material-ui/icons/FavoriteBorder';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import QueueIcon from '@material-ui/icons/Queue';

import { TooltipMobile } from 'common/ui/tooltip/components';

import styles from './VideoActionTooltip.style';

const useStyles = makeStyles((theme) => ({
  list: {
    ...styles.list,
  },
  item: {
    ...styles.list.item,
  },
  icon: {
    ...styles.list.icon,
    fill: theme.palette.textColor3,
  },
}));

const VideoActionTooltip = (props) => {
  const classes = useStyles();

  const closeToolTip = () => {
    props.onHandleClose();
  };

  const onPlayClick = (event) => {
    closeToolTip();
    props.onPlayClick(event);
  };

  const onHeartClick = (event) => {
    closeToolTip();
    props.onHeartClick(event);
  };

  const onDeleteClick = (event) => {
    closeToolTip();
    props.onDeleteClick(event);
  };

  const onAddToPlaylistClick = (event) => {
    closeToolTip();
    props.onAddToPlaylistClick(event);
  };

  const onAddToQueue = (event) => {
    closeToolTip();
    props.onAddToQueueClick(event);
  };

  // console.log('VideoActionTooltip...');

  return (
    <TooltipMobile
      title="Actions"
      toolTipKey={props.toolTipKey}
      onHandleClose={props.onHandleClose}
    >
      <List className={classes.list}>
        <ListItem
          className={clsx('tooltip-list-item', classes.item)}
          onClick={onPlayClick}
        >
          <ListItemText primary="Play" />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="play">
              <PlayCircleOutline className={classes.icon} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {!props.noHearts && onHeartClick && (
          <ListItem
            className={clsx('tooltip-list-item', classes.item)}
            onClick={onHeartClick}
          >
            {props.heart ? (
              <>
                <ListItemText primary="Unheart" />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="Favorite">
                    <LoveIcon className={classes.icon} />
                  </IconButton>
                </ListItemSecondaryAction>
              </>
            ) : (
              <>
                <ListItemText primary="Heart" />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="UnFavorite">
                    <LoveBorderIcon className={classes.icon} />
                  </IconButton>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        )}
        <ListItem
          className={clsx('tooltip-list-item', classes.item)}
          onClick={onAddToPlaylistClick}
        >
          <ListItemText primary="Add to playlist" />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="play">
              <PlaylistAddIcon className={classes.icon} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {props.onDeleteClick && (
          <ListItem
            className={clsx('tooltip-list-item', classes.item)}
            onClick={onDeleteClick}
          >
            <ListItemText primary="Delete" />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete">
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
        <ListItem
          className={clsx('tooltip-list-item', classes.item)}
          onClick={onAddToQueue}
        >
          <ListItemText primary="Add To Queue" />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="queue">
              <QueueIcon className={classes.icon} />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </TooltipMobile>
  );
};

VideoActionTooltip.propTypes = {
  toolTipKey: PropTypes.string.isRequired,
  heart: PropTypes.bool.isRequired,
  onPlayClick: PropTypes.func,
  onHeartClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAddToPlaylistClick: PropTypes.func,
  onAddToQueueClick: PropTypes.func,
};

export default VideoActionTooltip;
