import * as ArticleType from './article.types';

export const initArticle = () => ({
  type: ArticleType.INIT_ARTICLE,
});

export const getArticlesStart = (allYtids) => ({
  type: ArticleType.GET_ARTICLES_START,
  payload: { allYtids },
});

export const getArticlesSuccess = (data) => ({
  type: ArticleType.GET_ARTICLES_SUCCESS,
  payload: data,
});

export const getArticlesFail = (error) => ({
  type: ArticleType.GET_ARTICLES_FAIL,
  payload: error,
});

export const viewDetails = (details) => ({
  type: ArticleType.VIEW_DETAILS,
  payload: { details },
});

export const viewDetailSuccess = (details) => ({
  type: ArticleType.VIEW_DETAILS_SUCCESS,
  payload: details,
});

export const getArticlesPageStart = (cursor = '', limit = 5) => ({
  type: ArticleType.GET_ARTICLES_PAGE_START,
  payload: { cursor, limit },
});

export const getArticlesPageSuccess = (data) => ({
  type: ArticleType.GET_ARTICLES_PAGE_SUCCESS,
  payload: data,
});

export const getArticlesPageFail = (error) => ({
  type: ArticleType.GET_ARTICLES_PAGE_FAIL,
  payload: error,
});

export const getStaffpicksPageStart = (cursor = '', limit = 5) => ({
  type: ArticleType.GET_STAFF_PAGE_START,
  payload: { cursor, limit },
});

export const getStaffpicksPageSuccess = (data) => ({
  type: ArticleType.GET_STAFF_PAGE_SUCCESS,
  payload: data,
});

export const getStaffpicksPageFail = (error) => ({
  type: ArticleType.GET_STAFF_PAGE_FAIL,
  payload: error,
});
