import { connect } from 'react-redux';

import { showLogoutConfirmationModal } from 'store/login/login.actions';

const mapStateToProps = (state) => ({
  loginState: state.login,
  userState: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onShowLogoutConfirmationModal: () => dispatch(showLogoutConfirmationModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
