import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Slide,
    TextField,
    DialogActions,
    Button,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PublishIcon from '@material-ui/icons/Publish';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    modalWidth: {
        width: '768px',
        // marginTop: -100,
        maxWidth: '75%'
    },
    dialogBody: {
        fontFamily: 'Arial',
        backgroundColor: theme.palette.modalColor,
        color: theme.palette.textColor
    },
    textColor: {
        fontFamily: 'Arial',
        color: theme.palette.textColor2,
    },
    textfield: {
        '&::placeholder': {
            opacity: 1,
            color: theme.palette.textColor,
        },
    },
    label: {
        color: theme.palette.darkGray,
        fontFamily: 'Arial',
        "&$Mui-focused": {
            color: theme.palette.pink,
        },
    },
    underline: {
        "&$error:after": {
            borderBottomColor: theme.palette.pink,
        },
        "&:after": {
            borderBottom: `2px solid rgb(255, 0, 102)`
        }
    },
    inputBase: {
        color: theme.palette.alternateTextColor,
        fontFamily: 'Arial',
    },
    checkbox: {
      color: theme.palette.textColor2,
      '&:$Mui-checked': {
          color: theme.palette.pink,
      },
    },
    checked: {
        color: theme.palette.pink,
        '&:$Mui-checked': {
            color: theme.palette.pink,
        },
    },
    btnScreenshot: {
        marginTop: 10,
        minWidth: '100%',
        borderRadius: 2,
        color: theme.palette.pink,
        fontWeight: 400,
        backgroundColor: theme.palette.modalColor,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }
    },
    buttonStyle: {
        fontWeight: 300,
        fontFamily: 'Arial',
        color: theme.palette.pink,
    },
}));

const ReportAdForm = props => {
    const classes = useStyles();
    const {open, onRequestClose} = props;
    const [state, setState] = React.useState({
        sound: true,
        video: false,
        crashes: false,
        redirects: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return(
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                // onClose={onRequestClose}
                // keepMounted
                // fullWidth
                // maxWidth={matchesMD ? 'sm' : 'md'}
                classes={{
                    paper: classes.modalWidth,
                }}
            >
                <div className={classes.dialogBody}>
                <DialogTitle id="alert-dialog-title">{"Annoying Advert? Report it 🚫️"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" classes={{root: classes.textColor}}>
                        We need help identifying ads that play sound, video or redirect you to other sites. Answer a couple of questions to help us remove these.
                    </DialogContentText>
                    <TextField
                        className={classes.textfield}
                        InputLabelProps={{
                            classes: {
                                root: classes.label,
                            },
                        }}
                        InputProps={{
                            classes: {
                                input: classes.inputBase,
                                root: classes.underline,
                            }
                        }}
                        label="Who is the advertiser?"
                        placeholder={'Advertiser Name'}
                        fullWidth
                        style={{marginTop: 10, paddingBottom: 10}}
                        multiLine={true}
                        rows={1}
                        rowsMax={4}
                    />
                    <DialogContentText id="alert-dialog-options" classes={{root: classes.textColor}}>
                    What does it do?
                    </DialogContentText>
                    <FormControlLabel
                        control={<Checkbox
                            checked={state.sound}
                            onChange={handleChange}
                            name="sound"
                            classes={{
                                root: classes.checkbox, checked: classes.checked,
                            }}
                        />}
                        label="Plays sound"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={state.video}
                            onChange={handleChange}
                            name="video"
                            classes={{
                                root: classes.checkbox, checked: classes.checked,
                            }}
                        />}
                        label="Plays video"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={state.crashes}
                            onChange={handleChange}
                            name="crashes"
                            classes={{
                                root: classes.checkbox, checked: classes.checked,
                            }}
                        />}
                        label="Crashes the site"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={state.redirects}
                            onChange={handleChange}
                            name="redirects"
                            classes={{
                                root: classes.checkbox, checked: classes.checked,
                            }}
                        />}
                        label="Redirects me"
                    />
                    <Button
                        variant="contained"
                        color="default"
                        endIcon={<PublishIcon />}
                        fullWidth
                        disableElevation
                        className={classes.btnScreenshot}
                        labelPosition="before"
                        secondary={true}
                    >
                        Attach a screenshot
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onRequestClose} className={classes.buttonStyle}>
                        Cancel
                    </Button>
                    <Button onClick={onRequestClose} className={classes.buttonStyle}>
                        Submit
                    </Button>
                </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default ReportAdForm;