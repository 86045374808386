import { connect } from 'react-redux';

import {
  playerDidChangeState,
  setIsLoading,
  clearSeek,
  playerIsReady,
  playNextStart,
} from 'store/player/player.actions';
import { addPlayStart } from 'store/userhistory/userhistory.actions';
import { setCurrentStreamStart } from 'store/streams/streams.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';
import { skipArtistRadio } from 'store/artist/artist.actions';

const mapStateToProps = (state) => ({
  playerStore: state.player,
  userhistoryState: state.userhistory,
  playlistState: state.playlist,
  streamState: state.streams,
});

const mapDispatchToProps = (dispatch) => ({
  onPlayerDidChangeState: (value) => dispatch(playerDidChangeState(value)),
  onSetIsLoading: (value) => dispatch(setIsLoading(value)),
  onPlayerIsReady: () => dispatch(playerIsReady()),
  onClearSeek: () => dispatch(clearSeek()),
  onAddPlayStart: (ytid) => dispatch(addPlayStart(ytid)),
  onPlayNextStart: () => dispatch(playNextStart()),
  onSetCurrentStreamStart: (pid) => dispatch(setCurrentStreamStart(pid)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onSkipArtistRadio: () => dispatch(skipArtistRadio()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
