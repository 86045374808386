import React, { useEffect, useRef } from "react";
import { Router, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as qs from "querystring";

import { initActions } from "lib/auth";
import setAuthToken from "common/setAuthToken";
import browserHistory from "common/history";

import Notification from "modules/notification";
import SwitchTab from "constants/SwitchTab";

import Theme from "./theme";
import Routes from "./routes";

import { loadUserStart } from "store/login/login.actions";
import { setSwitchTab } from "store/siteCoordinator/siteCoordinator.actions";
import { SuperTokensWrapper } from "supertokens-auth-react";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

window.isMobileDevice = function () {
  // Includes tablets
  const mq = window.matchMedia("only screen and (max-width: 942px)");
  return mq.matches;
};

/*function usePrevious(value) {
  const ref = useRef();
 useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}*/

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log({ location });

  const parsed = qs.parse(location.search);
  const ytid = parsed?.v;

  const currentSwitchTab = useSelector(
    (state) => state.siteCoordinator.switchTab
  );
  // const prevSwitchTab = usePrevious(currentSwitchTab);

  useEffect(() => {
    initActions();
    dispatch(loadUserStart());
    const pathname =
      "/" + window.location.pathname.split("/").slice(1, 2).toString();
    // console.log('pathname: ', pathname);
    switch (pathname) {
      case `/`:
        if (currentSwitchTab !== SwitchTab.LOR) {
          dispatch(setSwitchTab(SwitchTab.LOR));
        }
        break;
      case `/radio`:
        if (currentSwitchTab !== SwitchTab.Radio) {
          dispatch(setSwitchTab(SwitchTab.Radio));
        }
        break;
      case `/podcast`:
        if (currentSwitchTab !== SwitchTab.Podcast) {
          dispatch(setSwitchTab(SwitchTab.Podcast));
        }
        break;
      default:
        return false;
    }
  }, []);

  const darkMode = useSelector((state) => state.siteCoordinator.darkMode);

  return (
    <Theme darkMode={darkMode}>
      <SuperTokensWrapper>
        <Router history={browserHistory}>
          <Routes />
        </Router>
        <Notification />
      </SuperTokensWrapper>
    </Theme>
  );
}

export default App;
