import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import * as TooltipType from './tooltip.types';

import { closeToolTip } from './tooltip.actions';

const getTooltipState = (state) => state.tooltip;

function* toolTipIsCurrentlyDisplayed(key) {
  const state = yield select(getTooltipState);
  return state.currentEntry.key === key;
}

function* hidePlaylistToolTipSaga({ payload: key }) {
  if (!(yield call(toolTipIsCurrentlyDisplayed, key))) {
    return;
  }

  yield put(closeToolTip());
}

export function* watchHidePlaylistToolTipSaga() {
  yield takeLatest(
    [
      TooltipType.HIDE_DELETE_PLAYLIST_TOOLTIP,
      TooltipType.HIDE_DELETE_PLAYLIST_TOOLTIP,
    ],
    hidePlaylistToolTipSaga
  );
}

export function* tooltipSagas() {
  yield all([call(watchHidePlaylistToolTipSaga)]);
}
