import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import * as cookie from 'component-cookie';

import { usePrevious } from 'hooks';
import lightTheme from './lightTheme';
import darkTheme from './darkTheme';

const Theme = (props) => {
  const [muiTheme, setMuiTheme] = useState(lightTheme);
  const [hoverClass, setHoverClass] = useState('light-theme');
  const [rootStyle, setRootStyle] = useState({
    background: '#eee',
    color: '#333333',
  });
  let darkModeRaw = cookie('dark-mode');

  const prevMode = usePrevious(props.darkMode);

  useEffect(() => {
    if (darkModeRaw) {
      const mode = darkModeRaw === 'true';
      updateTheme(mode);
    }
  }, [darkModeRaw]);

  function updateTheme(darkMode) {
    darkMode ? setMuiTheme(darkTheme) : setMuiTheme(lightTheme);
    setRootStyle({
      background: darkMode ? '#343434' : '#eee',
      color: darkMode ? '#b5b5b5' : '#333333',
    });
    darkMode ? setHoverClass('dark-theme') : setHoverClass('light-theme');
    document.body.style.background = rootStyle.background;
  }

  if (props.justHover) {
    return <div className={hoverClass}>{props.children}</div>;
  }

  if (props.justHover) {
    return <div className={hoverClass}>{props.children}</div>;
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <div style={rootStyle} className={hoverClass}>
        {props.children}
      </div>
    </ThemeProvider>
  );
};

export default Theme;
