import { connect } from 'react-redux';

import {
  setCurrentTab,
  closeSidebar,
} from 'store/siteCoordinator/siteCoordinator.actions';
import {
  togglePlayPause,
  toggleRepeatMode,
  toggleShuffleMode,
  playNextStart,
  playPrevStart,
} from 'store/player/player.actions';
import {
  addHeartStart,
  deleteHeartStart,
} from 'store/userhistory/userhistory.actions';
import { clearCurrentlyViewedPlaylist } from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapStateToProps = (state) => ({
  playerStore: state.player,
  userhistoryState: state.userhistory,
});

const mapDispatchToProps = (dispatch) => ({
  onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
  onCloseSidebar: () => dispatch(closeSidebar()),
  onTogglePlayPause: (value) => dispatch(togglePlayPause(value)),
  onAddHeartStart: (ytid) => dispatch(addHeartStart(ytid)),
  onDeleteHeartStart: (ytid) => dispatch(deleteHeartStart(ytid)),
  onToggleRepeatMode: () => dispatch(toggleRepeatMode()),
  onToggleShuffleMode: () => dispatch(toggleShuffleMode()),
  onPlayPrevStart: () => dispatch(playPrevStart()),
  onPlayNextStart: () => dispatch(playNextStart()),
  onClearCurrentlyViewedPlaylist: () =>
    dispatch(clearCurrentlyViewedPlaylist()),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
