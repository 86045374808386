import { connect } from 'react-redux';

import { setAutoplay, setRepeatMode } from 'store/player/player.actions';
import { clearCurrentlyViewedStream } from 'store/streams/streams.actions';
import { setupArtistRadio } from 'store/artist/artist.actions';

const mapDispatchToProps = (dispatch) => ({
  onClearCurrentlyViewedStream: () => dispatch(clearCurrentlyViewedStream()),
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onSetRepeatMode: (value) => dispatch(setRepeatMode(value)),
  onSetupArtistRadio: (artistname, ytid) =>
    dispatch(setupArtistRadio(artistname, ytid)),
});

const container = connect(null, mapDispatchToProps);

export default container;
