import { connect } from 'react-redux';
import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';
import {
    getPodcastAutocompleteSuggestionStart,
    searchPodcastStart
} from 'store/searchPodcast/searchPodcast.actions';

const mapStateToProps = (state) => ({
    searchPodcastState: state.searchPodcast,
});

const mapDispatchToProps = (dispatch) => ({
    onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
    onGetPodcastAutocompleteSuggestionStart: (value) =>
        dispatch(getPodcastAutocompleteSuggestionStart(value)),
    onPerformSearchPodcastStart: (value) => dispatch(searchPodcastStart(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
