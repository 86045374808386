import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Tooltip from 'common/ui/tooltip';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    background: theme.palette.pink,
    '&:hover': {
      background: 'rgb(183, 17, 84)',
      // background: 'rgba(48, 48, 48, 0.4)',
    },
  },
  buttonContainer: {
    padding: 10,
  },
  actionDialog: {
    color: theme.palette.textColor3,
    backgroundColor: theme.palette.modalColor,
  },
  item: {
    borderTop: '1px solid',
    borderTopColor: theme.palette.borderColor,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.borderColor,
  },
  icon: {
    fill: theme.palette.textColor3,
  },
}));

const PlaylistDeleteTooltip = (props) => {
  const classes = useStyles();

  const { ytid } = props;

  const onYesTap = () => {
    props.confirmDelete(ytid);
  };

  const onNoTap = () => {
    props.cancelDelete();
  };

  return (
    <Tooltip
      id={props.id}
      title={props.title}
      toolTipKey={props.toolTipKey}
      anchorRef={props.element}
    >
      <Hidden smDown>
        <div style={{ padding: 10 }}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onYesTap}
            className={classes.button}
            style={{ marginBottom: 10 }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onNoTap}
            className={classes.button}
          >
            No
          </Button>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.actionDialog}>
          <List>
            <ListItem button className={classes.item} onClick={onYesTap}>
              <ListItemIcon>
                <DoneIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={'Yes'} />
            </ListItem>
            <ListItem button className={classes.item} onClick={onNoTap}>
              <ListItemIcon>
                <CloseIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary={'No'} />
            </ListItem>
          </List>
        </div>
      </Hidden>
    </Tooltip>
  );
};

PlaylistDeleteTooltip.propTypes = {};

export default PlaylistDeleteTooltip;
