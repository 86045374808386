import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const UserPicture = (props) => {
  const styles = {
    backgroundImage: props.pictureUrl ? `url(${props.pictureUrl})` : null,
  };
  return (
    <div className="user-picture-wrapper">
      <Avatar
        style={{
          height: 36,
          width: 36,
          marginTop: 7,
          marginBottom: 10,
        }}
        src={props.pictureUrl}
        alt={props.firstName}
      />
    </div>
  );
};

export default UserPicture;
