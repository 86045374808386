import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SectionTab from 'constants/SectionTab';
import PlayerState from 'constants/PlayerState';
import Feature from 'constants/Feature';

import { NowPlayingIcon } from 'common/ui/icons';

import * as playerUtils from 'store/player/player.utils';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 0,
    lineHeight: '16px',
    '&.Mui-selected': {
      backgroundColor: '#2B2B2B',
    },
    '&:hover': {
      backgroundColor: '#2B2B2B',
    },
  },
  drawerItemText: {
    ...theme.typography.tab,
    fontFamily: 'Arial',
    color: 'white',
  },
  drawerItemIcon: {
    color: 'rgb(117, 117, 117)',
    minWidth: 38,
    paddingRight: 1,
  },
  drawerItemSelected: {
    '& .MuiListItemText-root': {
      opacity: 1,
      color: '#FF0066',
    },
  },
  drawerItemDiscord: {
    backgroundColor: theme.palette.common.blue,
  },
  drawerItemChatIcon: {
    color: 'white',
    minWidth: 38,
    paddingRight: 1,
  },
}));

const styles = {
  playerIndicator: {
    position: 'absolute',
    right: 8,
    top: 15,
    margin: 0,
    width: 18,
    height: 18,
    fill: 'rgb(97, 97, 97)',
  },
};

const SidebarItem = (props) => {
  const classes = useStyles();

  const {
    icon,
    name,
    discord,
    section,
    clicked,
    rightIcon,
    nestedList,
    siteCoordinator,
    playerState,
  } = props;

  function isPlaying(tab) {
    if (!playerUtils.isPlaying(playerState.player.state)) {
      return false;
    }

    switch (tab) {
      case SectionTab.History:
        return playerState.playingFeature === Feature.History;
      case SectionTab.Favorites:
        return playerState.playingFeature === Feature.Favorites;
      case SectionTab.Playlists:
        return playerState.playingFeature === Feature.Playlist;
      case SectionTab.Search:
        return playerState.playingFeature === Feature.Search;
      case SectionTab.Streams:
        return playerState.playingFeature === Feature.Stream;
      case SectionTab.Radio:
        return playerState.playingFeature === Feature.Radio;
      case SectionTab.Reviews:
        return playerState.playingFeature === Feature.Reviews;
      case SectionTab.YourLOR:
        return playerState.playingFeature === Feature.YourLOR;
      case SectionTab.PlayQueue:
        return playerState.playingFeature === Feature.PlayQueue;
      case SectionTab.Top10:
        return playerState.playingFeature === Feature.Top10;
      default:
        return false;
    }
  }

  const playerIndicator = (tab) => {
    if (isPlaying(tab)) {
      return (
        <NowPlayingIcon
          playerIsPlaying={playerState.player.state === PlayerState.Playing}
          style={styles.playerIndicator}
        />
      );
    }
  };

  return (
    <ListItem
      button
      className={classes.listContainer}
      selected={
        props.selected
          ? props.selected
          : (
            section ===
            (siteCoordinator.currentTab === SectionTab.None
              ? SectionTab.YourLOR
              : siteCoordinator.currentTab)
          )
      }
      classes={{
        root: discord ? classes.drawerItemDiscord : undefined,
        selected: classes.drawerItemSelected,
      }}
      onClick={clicked}
    >
      <ListItemIcon
        classes={{
          root: discord ? classes.drawerItemChatIcon : classes.drawerItemIcon,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={name}
        className={classes.drawerItemText}
        disableTypography
      />

      {nestedList}
      {rightIcon && (() => playerIndicator(section))()}
    </ListItem>
  );
};

export default SidebarItem;
