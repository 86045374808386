import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import {
  RateReview as RateReviewIcon,
  FavoriteBorder as FavoriteBorderIcon,
  History as HistoryIcon,
  Chat as ChatIcon,
  Assignment as AssignmentIcon,
  Whatshot as WhatshotIcon,
  PlaylistPlay as PlaylistPlayIcon,
  Search as SearchIcon,
  Extension as ExtensionIcon,
  Radio as RadioIcon,
} from '@material-ui/icons';

import SectionTab from 'constants/SectionTab';
import { LOR as LORIcon, Stream as StreamIcon } from 'common/ui/icons';
import { scrollToMainContentArea } from 'common/ui/utils';

import { PlayerControls } from 'components/MainColumn/components';
import { UserWidget } from 'modules/user/components';
import {
  SidebarItem,
  SidebarPlaylistList,
  SidebarMoreItem,
} from './components';
import NavigationDrawer from './navigation';

import container from './Sidebar.container';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    '&.MuiList-padding': {
      padding: 0,
      paddingBottom: 35,
    },
  },
  toolbar: {
    minHeight: 50,
  },
  discordIcon: {
    color: 'white',
    height: 20,
    width: 20,
    marginTop: -4,
  },
  playerControls: {
    display: 'flex',
    paddingTop: 0,
    justifyContent: 'center',
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

  const {
    userState,
    loginState,
    playerState,
    userhistoryState,
    siteCoordinator,
    open,
    onSidebarOpen,
    onSidebarClose,
    onSetCurrentTab,
    onShowLoginModal,
    onClearCurrentlyViewedPlaylist,
  } = props;

  const [openList, setOpenList] = React.useState(true);
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOpenList(!openList);
  };

  const onListItemClick = (tab) => {
    onClearCurrentlyViewedPlaylist();
    // analytics.actions.trackUIClick('Smart Menu', SectionTab.tabToString(tab));
    window._gaq.push(['_trackEvent', 'UI Click', 'Smart Menu', tab]);

    if (matchesMD) {
      onSidebarClose();
    }
    onSetCurrentTab(tab);
    scrollToMainContentArea();
  };

  const onDiscordTap = () => {
    window.open('https://discord.gg/AqEarNk', '_blank');
  };

  const onBlogTap = () => {
    window.open('https://blog.listenonrepeat.com/', '_blank');
  };

  const onChromeExtensionTap = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/listen-on-repeat-youtube/pgjcgpbffennccofdpganblbjiglnbip?hl=en',
      '_blank'
    );
  };

  const onNewChromeExtensionTap = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/listenonrepeat-youtube-to/nkadianhnfpopgoheppecbcbmpbocihe?hl=en',
      '_blank'
    );
  };

  return (
    <NavigationDrawer
      open={open}
      onSidebarOpen={onSidebarOpen}
      onSidebarClose={onSidebarClose}
    >
      <List className={classes.listContainer}>
        <Hidden smUp>
          <div className={classes.toolbar} />
          <UserWidget
            lor
            usesPopover={false}
            hearts={userhistoryState.profile?.totalStars}
            repeats={userhistoryState.profile?.totalRepeats}
            loginStatus={loginState.loginStatus}
            assimilating={loginState.assimilating}
            firstName={userState.firstName}
            pictureUrl={userState.avatarUrl}
            onShowLoginModal={onShowLoginModal}
          />

          <ListItem className={classes.playerControls}>
            <PlayerControls sidebarElement />
          </ListItem>
        </Hidden>

        <SidebarItem
          name="Our Reviews"
          section={SectionTab.Reviews}
          selected={
            SectionTab.Reviews ===
            (siteCoordinator.currentTab === SectionTab.None
              ? SectionTab.YourLOR
              : siteCoordinator.currentTab)
          }
          icon={<RateReviewIcon />}
          clicked={() => onListItemClick(SectionTab.Reviews)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="Your LOR"
          section={SectionTab.YourLOR}
          icon={<LORIcon />}
          clicked={() => onListItemClick(SectionTab.YourLOR)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="Streams"
          section={SectionTab.Streams}
          icon={<StreamIcon />}
          clicked={() => onListItemClick(SectionTab.Streams)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="Hearted"
          section={SectionTab.Favorites}
          icon={<FavoriteBorderIcon />}
          clicked={() => onListItemClick(SectionTab.Favorites)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="History"
          section={SectionTab.History}
          icon={<HistoryIcon />}
          clicked={() => onListItemClick(SectionTab.History)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="Top 10"
          section={SectionTab.Top10}
          icon={<WhatshotIcon />}
          clicked={() => onListItemClick(SectionTab.Top10)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="Discord Chat"
          discord
          section={SectionTab.None}
          icon={<ChatIcon className={classes.discordIcon} />}
          clicked={() => onDiscordTap()}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
        />
        <SidebarItem
          name="Blog"
          section={SectionTab.None}
          icon={<AssignmentIcon className={classes.discordIcon} />}
          clicked={() => onBlogTap()}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
        />
        <Hidden smDown>
          <SidebarItem
            name="Chrome Extension"
            section={SectionTab.None}
            icon={<ExtensionIcon className={classes.discordIcon} />}
            clicked={() => onChromeExtensionTap()}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
          />
        </Hidden>
        <Hidden smDown>
        <SidebarItem
            name=" Youtube to LOR"
            section={SectionTab.None}
            icon={<ExtensionIcon className={classes.discordIcon} />}
            clicked={() => onNewChromeExtensionTap()}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
          />
        </Hidden>
        {props.showSearchTab && (
          <SidebarItem
            name="Search Results"
            section={SectionTab.Search}
            icon={<SearchIcon />}
            clicked={() => onListItemClick(SectionTab.Search)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
          />
        )}

        {props.showRadioTab && (
          <SidebarItem
            name="Artist Radio"
            section={SectionTab.Radio}
            icon={<RadioIcon style={{ width: 24, height: 24 }} />}
            clicked={() => onListItemClick(SectionTab.Radio)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
          />
        )}

        <SidebarItem
          name="Your Music"
          nestedList={
            <SidebarMoreItem openList={openList} handleClick={handleClick} />
          }
          section={SectionTab.Playlists}
          icon={<PlaylistPlayIcon />}
          clicked={() => onListItemClick(SectionTab.Playlists)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
        />
        <Collapse in={openList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <SidebarPlaylistList
              isViewingPlaylists
              onListItemClick={onListItemClick}
            />
          </List>
        </Collapse>
      </List>
    </NavigationDrawer>
  );
};

export default container(Sidebar);
