const styles = {
  colors: {
    icon: '#b0b0b0',
  },

  item: {
    position: 'relative',
    borderBottom: '1px solid',
    borderTop: '1px solid',
    marginTop: '-1px',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Arial',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 12px',
    borderRightColor: 'rgb(238, 238, 238)',
    borderLeftColor: 'rgb(238, 238, 238)',

    textWrapper: {
      flex: '1 1 0%',
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    buttonWrapper: {
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
    },

    title: {
      fontFamily: 'Arial',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 13,
      textAlign: 'left',
      marginRight: 15,
      fontWeight: 500,

      active: {
        color: '#ff0066',
      },
    },

    sortHandle: {
      width: 16,
      height: 16,
      padding: 4,
      borderRadius: 14,
      position: 'relative',
      marginTop: 23,
      cursor: 'move',
      opacity: 0.4,

      icon: {
        width: 16,
        height: 16,
        margin: 4,
        position: 'absolute',
        left: 0,
        top: 0,
      },
    },

    image: {
      marginLeft: 5,
      marginRight: 10,
      minWidth: 75,
      minHeight: 50,
      width: 120,
      height: 70,
      borderRadius: 2,
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      flexShrink: 0,
      position: 'relative',
    },

    innerDivNormal: {
      paddingRight: 0,
    },

    innerDivEditing: {
      paddingLeft: 92,
      paddingRight: 0,
    },

    subInfo: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 5,
      fontSize: 12,

      container: {
        display: 'flex',
        marginRight: 10,
        fontSize: 12,
        lineHeight: '16px',

        iconStyle: {
          width: 14,
          height: 14,
          marginRight: 5,
          marginTop: 1,
        },
      },
    },

    actionIcon: {
      width: 30,
      height: 30,
      padding: 0,
      marginRight: 10,
      display: 'inline-block',

      icon: {
        width: 20,
        height: 20,
      },
    },
  },
};

export default styles;
