import { takeLatest, call, put, all } from 'redux-saga/effects';

import axios from 'instances/server';

import * as LoginType from './login.types';

import {
  loginSuccess,
  loginFail,
  logoutSuccess,
  loadUserSuccess,
  showMergeModal,
  hideMergeModal,
  mergeAccountSuccess,
  mergeAccountFail
} from './login.actions';
import { fbAPILogin, fbFetchProfileStart } from '../facebook/facebook.actions';
import {
  googleAPILogin,
  googleFetchProfileStart
} from '../google/google.actions';
import {
  setProfileData,
  fetchUserProfileStart,
  clearProfileData,
  setProfileDataStart
} from '../user/user.actions';
import {
  showGeneratePasswordModal
} from '../login/login.actions';
import {
  initUserhistory,
  clearHistoryData
} from '../userhistory/userhistory.actions';
import { initPlaylist } from '../playlist/playlist.actions';

import setAuthToken from 'common/setAuthToken';
import { showSnackbarNotification } from 'store/notification/notification.actions';

export function* unauthorizedFetch() {
  yield all([
    put(fetchUserProfileStart()),
    put(initUserhistory()),
    put(initPlaylist())
  ]);
}

export function* authorizedFetch() {
  yield all([
    put(fbFetchProfileStart()),
    put(googleFetchProfileStart()),
    put(initUserhistory()),
    put(initPlaylist())
  ]);
}

export function* loadUserAsync() {
  // console.log('here loadUser Async...');

  setAuthToken(localStorage?.token);

  if (!localStorage.token) {
    yield call(unauthorizedFetch);
    return;
  }

  try {
    const { data } = yield axios.get('/auth/validate');
    // console.log('login data');
    // console.log(data);

    const payload = {
      email: data.email,
      uid: data.uid,
      loginProviders: data.loginProviders,
    };

    yield put(setProfileData(payload));
    yield put(loadUserSuccess(payload));
    if ((data.alreadyGeneratedPassword && payload.loginProviders[0] === 'google' || payload.loginProviders[0] === 'facebook')) {
      yield put(showGeneratePasswordModal({
          provider: payload.loginProviders[0],
          authedUid: payload.uid,
      }))
    }
    yield call(authorizedFetch);
  } catch (error) {
    yield call(unauthorizedFetch);
  }
}

export function* getSnapshotFromUserAuth(provider, params) {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({
      type: provider,
      params
    });
    const { data } = yield axios.post('/auth/login', body, config);

    console.log('ann-lo');
    console.log(data);
    if (data.userData) {
      yield put(loginSuccess(data));
      yield put(showSnackbarNotification('Login Success 🎉'));
      window._gaq.push(['_trackEvent', 'Action', 'Signed In', data.provider]);
    }
    yield call(loadUserAsync);
    // yield call(authorizedFetch);

    if (data.userData.numAnonHearts > 0 || data.userData.numAnonRepeats > 0) {
      console.log('numAnonHearts  numAnonRepeats');
      yield put(showMergeModal(data));
    }
  } catch (err) {
    console.error('Error::', err);
    yield put(showSnackbarNotification(`Failed to login to ${provider} 😢`));
    yield put(loginFail(err));
    throw new Error(err);
  }
}

export function* fbLoginAsync() {
  // console.log('fbLoginAsync');
  yield put(setProfileDataStart());
  try {
    const { authResponse } = yield fbAPILogin();
    const params = {
      accessToken: authResponse.accessToken,
      signedRequest: authResponse.signedRequest
    };
    // console.log({ params });
    yield getSnapshotFromUserAuth('facebook', params);
  } catch (err) {
    console.warn('error>>>', err);
    yield put(loginFail(err));
    yield put(showSnackbarNotification(`Failed to login to Facebook 😢`));
  }
}

export function* googleLoginAsync() {
  // console.log('googleLoginAsync');
  yield put(setProfileDataStart());
  try {
    const params = yield googleAPILogin();
    // console.log('here');
    // console.log({ params });
    yield getSnapshotFromUserAuth('google', params);
  } catch (err) {
    yield put(loginFail(err));
    yield put(showSnackbarNotification(`Failed to login to Google 😢`));
  }
}

// TODO: Add email login
export function* emailLoginAsync({ payload: { userId } }) {
  console.log('login: emailLoginAsync');
  yield put(setProfileDataStart());
  const email = localStorage.getItem("superTokenUserEmail");
  try {
    const params = {
      userId,
      email: email,
      provider_name: "email",
      provider_id: userId,
      name: email,
      type: "email",
    };
    yield getSnapshotFromUserAuth('email', params);
  } catch (err) {
    yield put(loginFail(err));
    yield put(showSnackbarNotification(`Failed to login with Email 😢`));
  }
}

export function* logoutAsync() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  yield put(logoutSuccess());
  yield put(clearProfileData());
  yield put(clearHistoryData());
  yield call(loadUserAsync);
}

export function* mergeAccountAsync() {
  console.log('merging okay yaya');
  yield put(hideMergeModal());

  try {
    const { data } = yield axios.post('/user/merge');
    console.log('merge data');
    console.log({ data });
    yield put(mergeAccountSuccess());
  } catch (error) {
    yield put(mergeAccountFail(error));
  }
  //
  // console.log("merging hidden")
}

export function* watchFbLogin() {
  yield takeLatest(LoginType.FB_LOGIN_START, fbLoginAsync);
}

export function* watchGoogleLogin() {
  yield takeLatest(LoginType.GOOGLE_LOGIN_START, googleLoginAsync);
}

export function* watchEmailLogin() {
  yield takeLatest(LoginType.EMAIL_LOGIN_START, emailLoginAsync);
}

export function* watchLogout() {
  yield takeLatest(LoginType.LOGOUT_START, logoutAsync);
}

export function* watchLoadUser() {
  yield takeLatest(LoginType.LOAD_USER_START, loadUserAsync);
}

export function* watchMerge() {
  yield takeLatest(LoginType.MERGE_ACCOUNT_START, mergeAccountAsync);
}

export function* loginSagas() {
  yield all([
    call(watchLoadUser),
    call(watchFbLogin),
    call(watchGoogleLogin),
    call(watchLogout),
    call(watchMerge),
    call(watchEmailLogin)
  ]);
}
