import React from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';

const StreamSkeleton = () => {
  return (
    <div>
      <Hidden xsDown>
        <Box width={210}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 50, width: 75 }}
            />
            <Skeleton variant="text" width="60%" style={{ height: 20 }} />
          </div>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" />
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box width={'100%'} style={{ padding: '0px 15px 0px 15px' }}>
          <div style={{ display: 'flex' }}>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: 50, width: 75, marginRight: 15 }}
            />
            <Skeleton variant="text" width="40%" style={{ height: 20 }} />
          </div>
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="90%" />
        </Box>
      </Hidden>
    </div>
  );
};

export default StreamSkeleton;
