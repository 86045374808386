import _ from "lodash";

import * as PlaylistType from "./playlist.types";

import * as UserhistoryType from "../userhistory/userhistory.types";
import SortType from "constants/SortType";

const INITIAL_STATE = {
  currentYtid: null,
  playlists: {},
  playlistsByUser: {
    "#system": [],
  },
  followedPlaylistsByUser: {},
  currentPlaylist: UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS,
  currentlyViewedPlaylist: null,
  hasInitializedUserPlaylists: false,
  isAddPlaylistModalVisible: false,
  sortBy: SortType.Custom,
  editPlaylistModal: {
    visible: false,
    name: null,
    pid: null,
    publicPlaylist: false,
    publicLink: null,
  },
  deletePlaylistModal: { visible: false, name: null, pid: null },
  queue: [],
  queueEntry: null,
  queueFeatureEntry: null,
};

const playlistReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let pid, uid, ytids, updatedState;
  switch (type) {
    case PlaylistType.EVISCERATE_QUEUE:
      return {
        ...state,
        queue: state.queue.slice(payload + 1, state.queue.length),
      };

    case PlaylistType.REMOVE_QUEUE:
      updatedState = {
        ...state,
      };
      updatedState.queue.splice(payload, 1);
      return updatedState;

    case PlaylistType.QUEUE_YTID:
      return {
        ...state,
        queueEntry: payload,
      };

    case PlaylistType.SET_QUEUE:
      return {
        ...state,
        queue: payload,
      };

    case PlaylistType.POP_QUEUE:
      updatedState = {
        ...state,
      };
      updatedState.queue.shift();
      return updatedState;

    case PlaylistType.ADD_QUEUE_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.queue.push(payload);

      if (!updatedState.queueEntry) {
        updatedState.queueEntry = updatedState.currentYtid;
      }
      return updatedState;

    case PlaylistType.SET_CURRENT_VIEWED_PLAYLIST:
      return {
        ...state,
        currentlyViewedPlaylist: payload.pid,
      };

    case PlaylistType.SET_CURRENT_PLAYLIST_SUCCESS:
      return {
        ...state,
        currentPlaylist: payload.pid,
      };

    case PlaylistType.GET_USER_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };
      console.log({ payload });

      payload
        .filter((p) => !_.isEmpty(p))
        .forEach((p) => {
          updatedState.playlists[p.pid] = p;
          updatedState.playlistsByUser[p.uid] = _.uniq(
            (updatedState.playlistsByUser[p.uid] || []).concat(p.pid)
          );
        });
      return updatedState;

    case PlaylistType.CREATE_PLAYLIST_SUCCESS:
      pid = payload.pid;
      uid = payload.uid;

      updatedState = {
        ...state,
      };

      updatedState.playlists[pid] = payload;
      updatedState.playlistsByUser[uid] = _.uniq(
        (updatedState.playlistsByUser[uid] || []).concat(pid)
      );

      return updatedState;

    case PlaylistType.CREATE_PLAYLIST_ADD_SONG_SUCCESS:
      pid = payload.pid;
      uid = payload.uid;

      updatedState = {
        ...state,
      };

      updatedState.playlists[pid] = payload;
      updatedState.playlistsByUser[uid] = _.uniq(
        (updatedState.playlistsByUser[uid] || []).concat(pid)
      );
      return updatedState;

    case PlaylistType.GET_USER_PLAYLIST_PAGE_SUCCESS:
      updatedState = {
        ...state,
      };

      updatedState.playlists[payload.uid] = state.playlists[payload.uid] || {};
      for (let p of payload.res) {
        updatedState.playlists[p.pid] = p;
        updatedState.playlistsByUser[p.uid] = _.uniq(
          (updatedState.playlistsByUser[p.uid] || []).concat(p.pid)
        );
      }

      return updatedState;

    case PlaylistType.CREATE_INTERNAL_PLAYLIST:
      pid = payload.pid;
      ytids = payload.ytids;

      updatedState = {
        ...state,
      };

      let newPlaylist = {
        pid,
        ytids,
        uid: "#system",
        created: 0,
        name: "@@TODO",
      };

      updatedState.playlists[newPlaylist.pid] = newPlaylist;

      return updatedState;

    case PlaylistType.SET_CURRENT_YTID:
      return {
        ...state,
        currentYtid: payload,
      };

    case PlaylistType.SET_SORT_TYPE:
      return {
        ...state,
        sortBy: payload.sortBy,
      };

    case PlaylistType.SHOW_ADD_PLAYLIST_MODAL:
      return {
        ...state,
        isAddPlaylistModalVisible: true,
      };

    case PlaylistType.HIDE_ADD_PLAYLIST_MODAL:
      return {
        ...state,
        isAddPlaylistModalVisible: false,
      };

    case PlaylistType.SHOW_EDIT_PLAYLIST_MODAL:
      return {
        ...state,
        editPlaylistModal: {
          visible: true,
          name: payload.name,
          pid: payload.pid,
          publicPlaylist: payload.publicPlaylist,
          publicLink: payload.publicLink,
        },
      };

    case PlaylistType.HIDE_EDIT_PLAYLIST_MODAL:
      return {
        ...state,
        editPlaylistModal: {
          visible: false,
          name: null,
          pid: null,
          publicPlaylist: false,
          publicLink: null,
        },
      };

    case PlaylistType.SHOW_DELETE_PLAYLIST_MODAL:
      return {
        ...state,
        deletePlaylistModal: {
          visible: true,
          name: payload.name,
          pid: payload.pid,
        },
      };

    case PlaylistType.HIDE_DELETE_PLAYLIST_MODAL:
      return {
        ...state,
        deletePlaylistModal: { visible: false, name: null, pid: null },
      };
    case PlaylistType.ADD_YTIDS_TO_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };

      updatedState.playlists[payload.pid] = payload;

      return updatedState;

    case PlaylistType.SET_PLAYLIST_YTIDS_SUCCESS:
      updatedState = {
        ...state,
      };

      updatedState.playlists[payload.pid] = payload;
      updatedState.playlistsByUser[payload.uid] = _.uniq(
        (updatedState.playlistsByUser[payload.uid] || []).concat(payload.pid)
      );
      return updatedState;

    case PlaylistType.RENAME_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };

      updatedState.playlists[payload.pid] = payload;
      updatedState.playlistsByUser[payload.uid] = _.uniq(
        (updatedState.playlistsByUser[payload.uid] || []).concat(payload.pid)
      );

      return updatedState;

    case PlaylistType.DELETE_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };

      delete updatedState.playlists[payload.pid];
      updatedState.playlistsByUser[payload.uid] = (
        updatedState.playlistsByUser[payload.uid] || []
      ).filter((pid) => pid !== payload.pid);

      return updatedState;

    case PlaylistType.SET_SYSTEM_PLAYLIST_NAME:
      pid = payload.pid;
      const { name } = payload;

      updatedState = {
        ...state,
      };

      updatedState.playlists[pid] = updatedState.playlists[pid] || {};
      updatedState.playlists[pid].name = name;

      return updatedState;

    case PlaylistType.SET_SYSTEM_PLAYLIST_YTIDS:
      updatedState = {
        ...state,
      };

      for (let pid of _.keys(payload)) {
        updatedState.playlists[pid] = updatedState.playlists[pid] || {};
        updatedState.playlists[pid].ytids = payload[pid];
      }

      return updatedState;

    case PlaylistType.CLEAR_CURRENTLY_VIEWED_PLAYLIST:
      return {
        ...state,
        currentlyViewedPlaylist: null,
      };

    case PlaylistType.GET_FOLLOWED_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };
      if (payload.res) {
        for (let fp of payload.res) {
          updatedState.followedPlaylistsByUser[payload.uid] =
            updatedState.followedPlaylistsByUser[payload.uid] || [];
          updatedState.followedPlaylistsByUser[payload.uid].push(fp);
        }
      }

      // make sure we don't have duplicates
      updatedState.followedPlaylistsByUser[payload.uid] = _.uniqBy(
        updatedState.followedPlaylistsByUser[payload.uid],
        "pid"
      );

      return updatedState;

    case PlaylistType.ADD_FOLLOWED_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.followedPlaylistsByUser[payload.uid] =
        updatedState.followedPlaylistsByUser[payload.uid] || [];
      updatedState.followedPlaylistsByUser[payload.uid].push(payload);
      updatedState.followedPlaylistsByUser[payload.uid] = _.uniqBy(
        updatedState.followedPlaylistsByUser[payload.uid],
        "pid"
      );
      return updatedState;

    case PlaylistType.DELETE_FOLLOWED_PLAYLIST_SUCCESS:
      updatedState = {
        ...state,
      };

      updatedState.followedPlaylistsByUser[payload.uid] =
        updatedState.followedPlaylistsByUser[payload.uid] || [];
      updatedState.followedPlaylistsByUser[payload.uid] =
        updatedState.followedPlaylistsByUser[payload.uid].filter(
          (x) => x.pid !== payload.pid
        );

      return updatedState;

    default:
      return state;
  }
};

export default playlistReducer;
