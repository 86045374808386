import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import {
  LOR as LORIcon,
  TrophyIcon,
  TimerIcon2,
  PersonIcon,
} from 'common/ui/icons';

import container from './ArticleListItem.container';

const useStyles = makeStyles((theme) => ({
  articleListItem: {
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
    maxWidth: 216,
    maxHeight: 333,
    marginBottom: 20,
    borderRadius: 2,
    boxShadow: 'none',
    '&:hover': {
      boxShadow:
        '0 2px 3px 0 rgba(0, 0, 0, 0.15), 0 2px 7px 0 rgba(0, 0, 0, 0.15)',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  cardWrapper: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 6,
    width: 203,
    height: 114,
    display: 'inline-block',
    position: 'relative',
    backgroundPosition: '0px -19px',
    backgroundSize: 203,
    backgroundPositionY: -19,
  },
  media: {
    width: 203,
    height: 'auto',
  },
  layer: {
    position: 'absolute',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1))',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'block',
  },
  darkenLayer: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'block',
  },
  articleListItemPinned: {
    background: '#f6f6f6',
  },
  videoImageOverlay: {
    width: 203,
    left: 6,
    height: 114,
    marginBottom: -114,
    position: 'relative',
    bottom: 114,
    borderRadius: 2,
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.8) , rgba(0,0,0,0.1))',
  },
  videoImageOverlayStaffPick: {
    height: 93,
    marginBottom: -93,
  },
  datelineByline: {
    color: '#9e9e9e',
    fontWeight: 400,
    position: 'relative',
    right: 4,
    fontSize: 14,
    paddingBottom: 3,
  },
  lineDetail: {
    marginTop: 2,
    marginLeft: 2,
  },
  articleTitle: {
    color: 'white',
    margin: 0,
    fontSize: 15,
    paddingLeft: 10,
    paddingTop: 4,
    paddingRight: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 60,
  },
  reviewContainer: {
    paddingTop: 8,
    paddingRight: 6,
    paddingLeft: 6,
  },
  contributorNamePinned: {
    color: '#ff0066',
  },
  overlayFlair: {
    width: 32,
    height: 32,
    position: 'absolute',
    right: -1,
    display: 'inline-block',
    paddingLeft: 4,
    paddingTop: 6,
    borderRadius: 33,
    top: 82,
  },
  overlayFlairStaffPick: {
    top: 86,
    right: -4,
  },
  contentShort: {
    '& .line-clamp': {
      marginTop: 8,
      transition: 'all 0.3s ease',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      height: 140,
      position: 'relative',
      fontSize: 14,
      fontFamily: 'Roboto',
      lineHeight: '24px',
    },
  },
  pinnedIcon: {
    height: 24,
    width: 24,
    color: '#ff0066',
    fontSize: 18,
    position: 'absolute',
    right: 1,
    bottom: 99,
    display: 'block',
    zIndex: 1,
  },
  articleListIcon: {
    fill: '#b0b0b0',
    height: 17,
    marginRight: 2,
    verticalAlign: 'bottom',
  },
}));

function ArticleListItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { article, isPinned, isPlayed, onClearCurrentlyViewedStream } = props;

  const title = article.title;
  const isStaffPick = article.flagStaffPick;
  const isLeaderboard = article.flagLeaderboard;
  const hasCreatedTime = article.created;
  const authorImage = article.authorPhoto;
  const [viewDetails, setViewDetails] = useState([
    {
      title: title,
      date: moment('' + article.created, 'X').format('MMMM Do, YYYY'),
      authorImage: article.authorPhoto,
      authorName: article.authorName,
      content: article.content,
      ytid: article.ytid,
    },
  ]);

  const onClickHandler = () => {
    props.onClick(article.ytid);
    onClearCurrentlyViewedStream();
  };

  return (
    <Card
      className={classes.articleListItem}
      style={{ background: isPinned ? theme.palette.pinnedBackground : '' }}
      raised={false}
    >
      <CardActionArea onClick={onClickHandler}>
        <div className={classes.cardWrapper}>
          <CardMedia
            component="img"
            width={203}
            height={114}
            image={`//i.ytimg.com/vi/${article.ytid}/hqdefault.jpg`}
            alt={title}
          />

          <div className={clsx(classes.layer)}>
            <div
              className={clsx(classes.overlayFlair, {
                [classes.overlayFlairStaffPick]: isStaffPick,
              })}
            >
              {isStaffPick && <LORIcon />}
              {isLeaderboard && <TrophyIcon />}
            </div>

            <div className={classes.articleTitle}>{title}</div>

            {isPinned && <BookmarkIcon className={classes.pinnedIcon} />}

            {isPlayed && (
              <div className={classes.darkenLayer}>
                <Grid container justify="center">
                  <PlayArrowIcon
                    style={{
                      width: 44,
                      height: 44,
                      fill: '#FFFFFF',
                      marginTop: 30,
                    }}
                  />
                </Grid>
              </div>
            )}
          </div>
        </div>

        <CardContent
          className={clsx(classes.reviewContainer, {
            'review-meta-sp': isStaffPick,
            'review-meta': isStaffPick,
          })}
        >
          {hasCreatedTime && (
            <div className={classes.datelineByline}>
              <TimerIcon2 className={classes.articleListIcon} />
              <span className={classes.lineDetail}>
                {moment('' + article.created, 'X').format('MMMM Do, YYYY')}
              </span>
            </div>
          )}

          <div className={classes.datelineByline}>
            <PersonIcon className={classes.articleListIcon} />
            <span className={classes.lineDetail}>
              by{' '}
              <span
                className={clsx({
                  [classes.contributorNamePinned]: isPinned,
                })}
              >
                {article.authorName}
              </span>
            </span>
          </div>

          <div className={classes.contentShort}>
            <span
              className="line-clamp"
              style={{ fontFamily: 'Arial' }}
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default container(ArticleListItem);
