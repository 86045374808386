import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import _ from 'lodash';
import cookie from 'component-cookie';

import axios from 'instances/server';

import Feature from 'constants/Feature';
import * as PlayerType from './player.types';

import {
  setAutoplay,
  playFeature,
  setRepeatMode,
  setShuffleMode,
  setPlayerYtidSuccess,
  viewPlayerVideoStart,
  viewPlayerVideoSuccess,
  viewPlayerVideoFail,
  setRadioMode,
  setIsLoading,
  twitterMetatag,
  setPlayerYtidStart,
} from './player.actions';
import { getArticlesStart } from '../article/article.actions';
import {
  getViewVideoSuggestionStart,
  getArtistInfoFromTitle,
  setDataStart,
  skipArtistRadio,
} from '../artist/artist.actions';
import {
  popQueue,
  setCurrentYtid,
  queueYtid,
} from '../playlist/playlist.actions';
import { getPlaysStart } from '../userhistory/userhistory.actions';
import { getVideoStatsStart } from '../video/video.actions';

import { getVideoMetadataAsync } from '../video/video.sagas';
import { loadMoreMusic } from 'store/playlist/playlist.sagas';

const getPlayer = (state) => state.player;
const getPlaylist = (state) => state.playlist;
const getVideoState = (state) => state.video;
const getUserHistory = (state) => state.userhistory;
const getStreamState = (state) => state.streams;
const getArtistState = (state) => state.artist;

export function* initializeStore({ payload: { video } }) {
  // set autoplay on or off depending on whether the user navigated directly to a video or not
  const mobile = window.isMobileDevice();
  if (!mobile) {
    if (!_.isNil(video)) {
      yield put(setAutoplay(true));
    } else {
      yield put(setAutoplay(false));
    }
  }
}

export function* setPlayerYTIDAsync({ payload: { ytid } }) {
  let defaultYTID = ytid;
  if (!ytid) {
    defaultYTID = 'Y5WQ6gF-8vA';
  }

  yield put(setPlayerYtidSuccess(defaultYTID));
}

/**
    Makes a request to /v/, the response from which signals the player to start playing.
 */
export function* viewPlayerVideoAsync({ payload: { ytid, radioMode } }) {
  // console.log('viewPlayerVideoAsync...', ytid);
  console.log("before _trackPageview" );
  // window._gaq.push(["_set", "title", window.document.title]);
  // window._gaq.push("_trackPageview")
  window._gaq.push(['_trackEvent', 'Action', 'Refactor', 'true'])
  // window._gaq.push(['_trackEvent', 'Resolution', "ps", window.screen.width + "x"+ window.screen.height]);


  console.log("after _trackPageview main"+window.document.title);
  const playerState = yield select(getPlayer);

  // console.log({ radioMode });

  if (playerState.count >= 1) {
    yield put(setAutoplay(true));
  }

  yield put(setRadioMode(radioMode));

  yield put(setPlayerYtidStart(ytid));

  yield put(setCurrentYtid(ytid));

  const videoData = {
    payload: {
      ytids: [ytid],
    },
  };

  // Player is now loading the video
  yield put(setIsLoading(true));

  // if (!π.nullish(streams.store.state.streamPlaying)) {
  //   analytics.actions.trackAction('Streaming Play', ytid)
  // }

  // yourlor.actions.trackIfDiscovery(ytid)

  // Search for recommendations
  yield put(getPlaysStart([ytid]));
  const metas = yield call(getVideoMetadataAsync, videoData);
  // console.log({ metas });
  if (metas[ytid]) {
    yield put(getViewVideoSuggestionStart(metas[ytid].title, ytid));
    yield put(getArtistInfoFromTitle(metas[ytid].title));
    // ads.actions.tonefuse(metas[ytid].title)
  }
  // console.log("before _trackPageview" );
  // window._gaq.push(["_set", "title", window.document.title]);
  // window._gaq.push("_trackPageview")
  // console.log("after _trackPageview");

  try {
    const { data } = yield axios.get('/video', {
      params: {
        ytid,
      },
    });
    // console.log('[ViewPlayerVideo]', { data });

    if (_.isNil(data) || data.metadata_request === true) {
      return;
    }

    yield put(viewPlayerVideoSuccess(data));
  } catch (err) {
    console.error('error::', err);
    yield put(viewPlayerVideoFail(err));
  }
}

export function* viewPlayerVideoCompleteAsync({ payload }) {
  // console.log('completed>>', { payload });
  const artistTitle = "Check out more music by artists you'll love";

  // console.log(artistTitle);
  // console.log({ payload });

  yield put(twitterMetatag());

  if (
    _.isNull(payload) ||
    _.isNull(payload.video) ||
    _.isNull(payload.video.metadata) ||
    _.isNull(payload.video.metadata.ytid)
  ) {
    return;
  }

  const ytid = payload.video.metadata.ytid;

  // Try to fetch the current video's article
  yield all([put(getArticlesStart([ytid])), put(getVideoStatsStart([ytid]))]);
  // console.log(artistTitle);

  if (
    _.isNull(payload.artist) ||
    payload.artist.hasOwnProperty('get') ||
    payload.artist.leaderboard
  ) {
    try {
      const { data } = yield axios.get('/video', {
        params: {
          ytid: '2Vv-BfVoq4g',
        },
      });

      // console.log('completed>>', { data });

      const artistData = data.artist;
      const rawData = artistData.similar;
      artistData.similar = _.filter(rawData, (item) => {
        return item['image'] !== '';
      });
      yield put(setDataStart(artistData, artistTitle));
      //   artist.actions.setData(artistData, artistTitle);
    } catch (err) {
      console.warn('error::', err);
      yield put(setDataStart([], artistTitle));
    }
  } else {
    const artistData = payload.artist;
    const rawData = artistData.similar;
    // console.log({ rawData });
    artistData.similar = _.filter(rawData, (item) => {
      return item['image'] !== '';
    });
    yield put(setDataStart(artistData, artistTitle));
  }
}

export function* toggleRepeatModeAsync() {
  const playerState = yield select(getPlayer);
  const repeatOnce = playerState.player.repeatMode;
  if (repeatOnce) {
    // Set to repeat all
    yield put(setRepeatMode(false));
    cookie('repeat-mode', 'false', {
      maxage: 730 * 24 * 3600 * 1000,
      path: '/',
    });
    // analytics.actions.trackAction('Repeat Mode', 'Repeat All');
    window._gaq.push(['_trackEvent', 'Action', 'Repeat Mode', 'Repeat All']);

  } else {
    // Set to repeat once
    yield put(setRepeatMode(true));
    cookie('repeat-mode', 'true', {
      maxage: 730 * 24 * 3600 * 1000,
      path: '/',
    });
    // analytics.actions.trackAction('Repeat Mode', 'Repeat Once');
    window._gaq.push(['_trackEvent', 'Action', 'Repeat Mode', 'Repeat Once']);

  }
}

export function* toggleShuffleModeAsync() {
  // console.log('toggleShuffleModeAsync...');
  const playerState = yield select(getPlayer);

  const shuffleOn = playerState.player.shuffleMode;
  // console.log('shuffleOn>>', shuffleOn);

  if (shuffleOn) {
    // Set shuffle off
    yield put(setShuffleMode(false));
    cookie('shuffle-mode', 'false', {
      maxage: 730 * 24 * 3600 * 1000,
      path: '/',
    });
    // analytics.actions.trackAction('Shuffle Mode', 'Shuffle Off')
    window._gaq.push(['_trackEvent', 'Action', 'Shuffle Mode', 'Shuffle Off']);

  } else {
    // Set shuffle on
    yield put(setShuffleMode(true));
    cookie('shuffle-mode', 'true', {
      maxage: 730 * 24 * 3600 * 1000,
      path: '/',
    });
    // analytics.actions.trackAction('Shuffle Mode', 'Shuffle On')
    window._gaq.push(['_trackEvent', 'Action', 'Shuffle Mode', 'Shuffle On']);

  }
}

export function* playNextAsync() {
  const playlistState = yield select(getPlaylist);
  const playerState = yield select(getPlayer);

  console.log('playNextAsync...');

  if (playlistState.queue.length !== 0) {
    // Song is queued
    // console.log('Song is queued');
    yield all([
      put(setAutoplay(true)),
      put(viewPlayerVideoStart(playlistState.queue[0])),
      put(popQueue()),
      put(playFeature(Feature.PlayQueue)),
    ]);

    return;
  }

  let currentYtid = playlistState.currentYtid;
  // console.log('currentYtid>>>', currentYtid);

  if (playlistState.queueEntry) {
    currentYtid = playlistState.queueEntry;

    yield put(playFeature(playlistState.queueEntryFeature));

    if (playlistState.queueEntryFeature === Feature.Radio) {
      yield put(setRadioMode(true));
    }
    yield put(queueYtid(null));
  }

  // console.log({ radioMode: playerState.player.radioMode });

  if (playerState.player.radioMode) {
    // console.log('skipArtistRadio...');
    yield put(skipArtistRadio());
    // artist.actions.skipArtistRadio()
    return;
  } else if (playerState.player.shuffleMode) {
    yield call(playRandomAsync);
    // player.actions.playRandom()
    return;
  }

  const currentPlaylist =
    playlistState.playlists[playlistState.currentPlaylist] ?? {};
  // console.log(currentPlaylist);

  const ytids = currentPlaylist.ytids ?? [];
  // console.log(ytids);

  // console.log({ isLoading: playerState.isLoading });
  if (ytids.length <= 0 || playerState.isLoading) {
    return;
  }
  // console.log('here...');
  const idx = ytids.indexOf(currentYtid);
  const newIdx = idx + 1;
  // console.log(idx, newIdx);

  let ytid;
  if (idx === -1 || ytids.length <= newIdx) {
    // if we can't find the current song, go back to the beginning
    // console.log('here... :: 1');
    ytid = ytids[0];
  } else {
    // console.log('here... :: 2');
    ytid = ytids[newIdx];
  }

  if (newIdx + 2 >= ytids.length) {
    // One song from the last so load up new data (do this to avoid non-functionality if last song is short and connection is slow)
    yield call(loadMoreMusic, playlistState.currentPlaylist);
    // playlist.loadMoreMusic(playlistState.currentPlaylist);
  }

  // console.log('next song...');
  // console.log(ytid);
  yield put(setAutoplay(true));
  yield put(viewPlayerVideoStart(ytid));
}

function* playPrevOrRestart(startTime = 0) {
  // console.log('playPrevOrRestart..');

  const sliderStart = startTime;
  // console.log({ sliderStart });
  const startTime1 = yield !_.isEmpty(sliderStart) && sliderStart >= 0
    ? sliderStart
    : 0;
  // console.log({ startTime1 });
  const player = window.ytPlayer;
  // console.log({ player });
  if (player !== null) {
    let currentTime = yield player.getCurrentTime();
    // console.log({ currentTime });

    if (currentTime >= startTime1 + 5) {
      yield player.seekTo(startTime1, true);
      return true;
    }
  }
  return false;
}

export function* playPrevAsync() {
  // console.log('playPrevAsync...');
  const playlistState = yield select(getPlaylist);
  const playerState = yield select(getPlayer);
  const userhistoryStore = yield select(getUserHistory);

  const userPlay = userhistoryStore.history[playerState.ytid] ?? {};
  const slider = userPlay?.slider ?? {};

  // console.log({ slider });

  let nearStart = yield call(playPrevOrRestart, slider?.startTime);
  if (nearStart) {
    return;
  }

  // Remove most recent (currently playing)
  playerState.playHistory.pop();

  // Grab the next Ytid to play from play history
  let nextYtid = playerState.playHistory.pop();
  if (nextYtid) {
    yield put(setAutoplay(true));
    yield put(viewPlayerVideoStart(nextYtid));
    return;
  }

  // If no play history, play the previous song in the order of the play source
  const currentPlaylist =
    playlistState.playlists[playlistState.currentPlaylist] ?? {};
  const ytids = currentPlaylist.ytids ?? [];

  if (ytids.length <= 0 || playerState.isLoading) {
    return;
  }

  const idx = ytids.indexOf(playlistState.currentYtid);
  const newIdx = idx - 1;

  let ytid;
  if (idx === -1 || ytids.length <= newIdx || newIdx < 0) {
    // if we can't find the current song, go back to the beginning
    ytid = ytids[0];
  } else {
    ytid = ytids[newIdx];
  }

  yield put(setAutoplay(true));
  yield put(viewPlayerVideoStart(ytid));
}

export function* playRandomAsync() {
  const playlistState = yield select(getPlaylist);
  const playerState = yield select(getPlayer);

  const oldYtid = playerState.ytid;
  const currentPlaylist =
    playlistState.playlists[playlistState.currentPlaylist] ?? {};
  const ytids = currentPlaylist.ytids ?? [];

  if (ytids.length <= 0 || playerState.isLoading) {
    return;
  }

  const alreadyShuffled = playerState.alreadyShuffled.concat([oldYtid]);
  let possibleYtids = _.difference(ytids, alreadyShuffled);

  if (possibleYtids.length <= 2) {
    // Two songs from the last so load up new data (do this to avoid non-functionality if last song is short and connection is slow)
    // yield put(loadMoreMusic(playlistState.currentPlaylist));
    // playlist.loadMoreMusic(playlistState.currentPlaylist);
  }

  if (possibleYtids.length === 0) {
    possibleYtids = _.filter(ytids, (item) => item !== oldYtid);
  }

  const idx = Math.floor(Math.random() * possibleYtids.length);
  const ytid = possibleYtids[idx];

  yield put(setAutoplay(true));
  yield put(viewPlayerVideoStart(ytid));
}

export function* twitterTagSaga() {
  const streamState = yield select(getStreamState);
  const artistState = yield select(getArtistState);
  const playerState = yield select(getPlayer);
  const userhistoryStore = yield select(getUserHistory);
  const videoStore = yield select(getVideoState);

  const userPlay = userhistoryStore[playerState.ytid] || {};
  const numRepeats = userPlay.repeats || 0;
  const playingStream = streamState.streamPlaying;
  const playingArtist = playerState.radioMode;

  // send repeat count to twitter meta tag
  const metaTag = document.getElementsByTagName('meta');
  for (let i = 0; i < metaTag.length; i++) {
    const meta = metaTag[i];
    // console.log('check meta tag...');
    // console.log(meta);
    if (meta.getAttribute('name') === 'twitter:title') {
      // console.log('twitter:title');
      if (playingStream) {
        meta.content = `Check out the stream ${streamState.streams[playingStream].name}`;
      } else if (playingArtist) {
        meta.content = `Check out the artist radio for ${artistState.videoPreview.radioName}`;
      } else if (playerState.ytid) {
        meta.content = `Check out ${videoStore.videos[playerState.ytid].title}`;
      }
    } else if (meta.getAttribute('name') === 'twitter:description') {
      // console.log('twitter:description');
      meta.content = '. ';
    }
  }
}

export function* watchSetPlayerYTID() {
  yield takeLatest(PlayerType.SET_PLAYER_YTID_START, setPlayerYTIDAsync);
}

export function* watchViewPlayerVideo() {
  yield takeLatest(PlayerType.VIEW_PLAYER_VIDEO_START, viewPlayerVideoAsync);
}

export function* watchViewPlayerVideoCompleted() {
  yield takeLatest(
    PlayerType.VIEW_PLAYER_VIDEO_SUCCESS,
    viewPlayerVideoCompleteAsync
  );
}

export function* watchToggleRepeatMode() {
  yield takeLatest(PlayerType.TOGGLE_REPEAT_MODE, toggleRepeatModeAsync);
}

export function* watchToggleShuffleMode() {
  yield takeLatest(PlayerType.TOGGLE_SHUFFLE_MODE, toggleShuffleModeAsync);
}

export function* watchPlayNext() {
  yield takeLatest(PlayerType.PLAY_NEXT_START, playNextAsync);
}

export function* watchPlayPrev() {
  yield takeLatest(PlayerType.PLAY_PREV_START, playPrevAsync);
}

export function* watchPlayRandom() {
  yield takeLatest(PlayerType.PLAY_RANDOM_START, playRandomAsync);
}

export function* watchInitializeStore() {
  yield takeLatest(PlayerType.INIT_PLAYER, initializeStore);
}

export function* watchTwitterTag() {
  yield takeLatest(PlayerType.TWITTER_METATAG, twitterTagSaga);
}

export function* playerSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchSetPlayerYTID),
    call(watchViewPlayerVideo),
    call(watchViewPlayerVideoCompleted),
    call(watchToggleRepeatMode),
    call(watchToggleShuffleMode),
    call(watchPlayNext),
    call(watchPlayPrev),
    // call(watchTwitterTag),
  ]);
}
