module.exports = Object.freeze({
  History: 'history',
  Favorites: 'favorites',
  Playlist: 'playlist',
  Search: 'search',
  Stream: 'stream',
  Radio: 'radio',
  Hub: 'hub',
  Reviews: 'reviews',
  Profile: 'profile',
  YourLOR: 'yourlor',
  PlayQueue: 'playqueue',
  Top10: 'top10'
});
