import {
  GET_VIDEO_METADATA_START,
  GET_VIDEO_METADATA_SUCCESS,
  GET_VIDEO_METADATA_FAILURE,
  GET_VIDEO_STATS_START,
  GET_VIDEO_STATS_SUCCESS,
  GET_VIDEO_STATS_FAILURE,
  UPDATE_GLOBAL_COUNT,
} from './video.types';

export const getVideoMetadataStart = (ytids) => ({
  type: GET_VIDEO_METADATA_START,
  payload: { ytids },
});

export const getVideoMetadataSuccess = (data) => ({
  type: GET_VIDEO_METADATA_SUCCESS,
  payload: data,
});

export const getVideoMetadataFail = (error) => ({
  type: GET_VIDEO_METADATA_FAILURE,
  payload: error,
});

export const getVideoStatsStart = (ytids) => ({
  type: GET_VIDEO_STATS_START,
  payload: { ytids },
});

export const getVideoStatsSuccess = (data) => ({
  type: GET_VIDEO_STATS_SUCCESS,
  payload: data,
});

export const getVideoStatsFail = (error) => ({
  type: GET_VIDEO_STATS_FAILURE,
  payload: error,
});

export const updateGlobalCount = (ytid) => ({
  type: UPDATE_GLOBAL_COUNT,
  payload: { ytid },
});
