import React, { useEffect } from 'react';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardContent from '@material-ui/core/CardContent';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

import * as SearchType from 'store/search/search.types';

import SectionTab from 'constants/SectionTab';
import { scrollToVideo } from 'common/ui/utils';

import ToolTipKeys from 'constants/ToolTipKeys';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';

import VideoListItem from 'modules/video/components/VideoListItem';
import SearchResultsFeed from 'modules/playlist/components/PlaylistView';
import { ArtistRadioSearchSuggestion } from 'modules/artist/components';

import container from './SearchResultsCard.container';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontFamily: theme.typography.fontFamily,
  },
}));

const SearchResultsCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    userhistoryState,
    videoState,
    searchState: { loading },
    playlistState,
    userState,
    tooltipState,
    onSetAutoplay,
    onViewPlayerVideoStart,
    onSetCurrentPlaylistStart,
    onSetCurrentTab,
    onHideDeletePlaylistToolTip,
    onCloseToolTip,
    onEnqueueToolTip,
    onQueueYtid,
    onGetNextResultsPageStart,
    onShowAddPlaylistModal,
  } = props;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
      onCloseToolTip();
    };
  }, []);

  const resultsToMetadata = (result) => {
    return {
      ytid: result.ytid,
      title: result.title,
      author: result.author,
      duration: result.duration,
      views: result.views,
    };
  };

  const onAddToPlaylistClick = (evt, ytid) => {
    var element = evt.currentTarget;

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }
    toggleAddToPlaylistToolTip(ytid, element);
    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const onClickShowMore = () => {
    onGetNextResultsPageStart(props.query, props.next);
  };

  const onClickRow = (ytid) => {
    // console.log('onClickRow...');
    onQueueYtid(ytid);
    scrollToVideo();
    onSetAutoplay(true);
    onSetCurrentPlaylistStart(SearchType.PLAYLIST_SEARCH);
    onViewPlayerVideoStart(ytid);
    onSetCurrentTab(SectionTab.Reviews);
  };

  let emptyContents;
  if (loading && props.results.length === 0) {
    emptyContents = (
      <div className={classes.spinner}>
        <CircularProgress style={{ color: '#ff0066' }} />
      </div>
    );
  } else if (!loading && props.results.length === 0) {
    emptyContents = <div className={classes.empty}>Nothing found</div>;
  }

  return (
    <>
      <CardContent
        classes={{
          root: classes.root,
        }}
        style={{ padding: 0 }}
      >
        {/* <Fade
        in={true}
        timeout={{
          appear: 500,
          enter: 500,
          exit: 500,
        }}
      > */}
        <ArtistRadioSearchSuggestion
          artistState={props.artistState}
          results={props.results}
        />
        {/* </Fade> */}
        <SearchResultsFeed
          items={props.results ?? []}
          next={props.next}
          onClickShowMore={onClickShowMore}
          emptyContents={emptyContents}
          elementFactory={(searchResult, i) => {
            const metadata = resultsToMetadata(searchResult);
            const userPlay = userhistoryState.history[searchResult.ytid] || {};
            // if we don't filter the play down to just these two properties, the search
            // results show a bunch of extra info that jumbles up the UI
            const filteredPlay = _.pick(userPlay, ['ytid', 'star', 'repeats']);

            return (
              <div key={searchResult.ytid}>
                <VideoListItem
                  ytid={searchResult.ytid}
                  isPlaying={searchResult.ytid === props.currentYtid}
                  metadata={metadata}
                  globalStats={videoState.videoStats[searchResult.ytid]}
                  history={filteredPlay}
                  onClick={onClickRow}
                  onAddToPlaylistClick={onAddToPlaylistClick}
                  // showControls={props.showItemControls}
                  showControls
                  showMobileAuthor={false}
                  showMobileGlobalRepeats={true}
                  showMobileGlobalHearts={true}
                  showMobileUserRepeats={false}
                  showMobileDuration={false}
                  showMobileLastViewed={false}
                />
              </div>
            );
          }}
        />
      </CardContent>
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
    </>
  );
};
export default container(SearchResultsCard);
