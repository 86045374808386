import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';

import { scrollToVideo, scrollToMainContentArea } from 'common/ui/utils';
import SectionTab from 'constants/SectionTab';
import StreamsGridItem from '../StreamsGridItem';

import container from './StreamsGrid.container';

import { useLocation } from "react-router-dom";
import queryString from 'query-string';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useStyles = makeStyles((theme) => ({
  containerInner: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  item: {
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    width: '30.44%',
    padding: 10,
    cursor: 'pointer',
    float: 'left',
    // display: 'table-row',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.hoverColorItem,
    },
  },
  itemMobile: {
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    width: '100%',
    height: 100,
    padding: 10,
    cursor: 'pointer',
    float: 'left',
    display: 'table-row',
  },
}));

const StreamsGrid = (props) => {
  const classes = useStyles();
  const [playingStream, setPlayingStream] = useState({
    pid:null,
    ytid: null,
    ytname: null
  })
  const {playerStore ,streamState, videoStore} = props;

  const { onSetCurrentTab, onSetCurrentStreamStart } = props;

  // let search = useQuery();
  
  const { search } = useLocation();

  useEffect(()=>{
    // const data = queryString.parse(search)
    // console.log(queryString.parse(search),"current playing stream")
    const name = videoStore?.videos[playerStore?.ytid]?.title
    console.log(name, "check name -----------------")
    setPlayingStream({pid: streamState?.streamPlaying, ytid: playerStore?.ytid, ytname: name})
  },[streamState.streamPlaying, playerStore?.ytid, videoStore.videos])

  const onShowStreams = () => {
    onSetCurrentTab(SectionTab.Streams);
    // analytics.actions.trackUIClick('Stream Display', 'Show More')
    // if (this.props.selectFunction) {
    //   this.props.selectFunction()
    // }
    scrollToMainContentArea();
  };

  const onStreamSelect = (e, pid) => {
    // console.log('onStreamSelect...');
    onSetCurrentTab(SectionTab.Streams);
    onSetCurrentStreamStart(pid);

    // analytics.actions.trackUIClick('Stream Opened', pid)
    // if (this.props.selectFunction) {
    //   this.props.selectFunction()
    // }
    scrollToVideo();
  };

  let streamItems = [];
  if (props.isSmall) {
    streamItems = props.streamsState.userOrderedStreams
      .map((pid) => props.streamsState.streams[pid])
      .filter((stream) => !_.isEmpty(stream))
      .slice(0, 4);
  } else {
    streamItems = _.orderBy(
      _.values(props.streamsState.streams),
      ['viewers'],
      ['desc']
    );
  }

 
  // console.log(playingStream , 'hereeeeeeeeeeeeeeeeeeeeeeeeeee')
  // console.log('streamItems>>>', streamItems);

  return (
    <div>
      <Hidden xsDown>
        <div className={classes.containerInner}>
          {streamItems.length > 0 &&
            streamItems.map((streamItem, i) => (
              <StreamsGridItem
                viewers={streamItem.viewers}
                playingTitle={streamItem.playing_title}
                className={clsx(classes.item, props.classesCard)}
                onStreamSelect={onStreamSelect}
                playingStream={playingStream}
                key={streamItem.pid}
                pid={streamItem.pid}
                playingYtid={streamItem.playing_ytid}
                name={streamItem.name}
              />
            ))}
            {/* {
              streamLists.map((streamItem, i) => (
                <StreamsGridItem
                  key={streamItem.pid}
                  pid={streamItem.pid}
                  playingYtid={streamItem.playing_ytid}
                  name={streamItem.name}
                  viewers={streamItem.viewers}
                  playingTitle={streamItem.playing_title}
                  className={clsx(classes.item, props.classesCard)}
                  onStreamSelect={onStreamSelect}
                />
              ))
            } */}
        </div>

        {props.isSmall && (
          <Card
            style={{
              width: '100%',
              boxShadow: 'none',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                paddingTop: 10,
                paddingBottom: 10,
              }}
              className="hover"
              onClick={() => onShowStreams()}
            >
              <span style={{ fontSize: 15 }}>
                Whatever music you like, we've got a stream for you.
                <span style={{ color: '#ff0066' }}>Click here</span> to see them
                all.
              </span>
            </div>
          </Card>
        )}
      </Hidden>

      <Hidden smUp>
        <div style={{ display: 'table' }}>
          {
          streamItems.length > 0 &&
            streamItems.map((streamItem, i) => (
              <StreamsGridItem
                playingStream={playingStream}
                name={streamItem.name}
                viewers={streamItem.viewers}
                playingTitle={streamItem.playing_title}
                className={clsx(classes.itemMobile, props.classesCardMobile)}
                onStreamSelect={onStreamSelect}
                key={streamItem.pid}
                pid={streamItem.pid}
                playingYtid={streamItem.playing_ytid}
              />
            ))}
        </div>
      </Hidden>
    </div>
  );
};

export default container(StreamsGrid);
