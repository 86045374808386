export const INITIALIZE_UPSHELL = 'INITIALIZE_UPSHELL';
export const INITIALIZE_UPSHELL_SUCCESS = 'INITIALIZE_UPSHELL_SUCCESS';

export const SET_FEATURE_DEMO = 'SET_FEATURE_DEMO';
export const SHOW_FEATURE_UPSELL = 'SHOW_FEATURE_UPSELL';
export const HIDE_FEATURE_UPSELL = 'HIDE_FEATURE_UPSELL';

export const DISCOVERY = 'DISCOVERY';
export const PLAYLIST = 'PLAYLIST';
export const DEMO = 'DEMO';
