import * as UserhistoryType from './userhistory.types';

export const initUserhistory = () => ({
  type: UserhistoryType.INIT_USER_HISTORY_START,
});

export const fetchHistoryProfileStart = () => ({
  type: UserhistoryType.FETCH_HISTORY_PROFILE_START,
});

export const fetchHistoryProfileSuccess = (data) => ({
  type: UserhistoryType.FETCH_HISTORY_PROFILE_SUCCESS,
  payload: data,
});

export const fetchHistoryProfileFail = (err) => ({
  type: UserhistoryType.FETCH_HISTORY_PROFILE_FAIL,
  payload: err,
});

export const getPlaysPageStart = (next, merge) => ({
  type: UserhistoryType.GET_PLAYS_PAGE_START,
  payload: { next, merge },
});

export const getPlaysPageSuccess = (data) => ({
  type: UserhistoryType.GET_PLAYS_PAGE_SUCCESS,
  payload: data,
});

export const getPlaysPageFail = (err) => ({
  type: UserhistoryType.GET_PLAYS_PAGE_FAIL,
  payload: err,
});

export const getPlaysStart = (ytids) => ({
  type: UserhistoryType.GET_PLAYS_START,
  payload: { ytids },
});

export const getPlaysSuccess = (data) => ({
  type: UserhistoryType.GET_PLAYS_SUCCESS,
  payload: data,
});

export const getPlaysFail = (err) => ({
  type: UserhistoryType.GET_PLAYS_FAIL,
  payload: err,
});

export const addPlayStart = (ytid) => ({
  type: UserhistoryType.ADD_PLAY_START,
  payload: { ytid },
});

export const addPlaySuccess = (data) => ({
  type: UserhistoryType.ADD_PLAY_SUCCESS,
  payload: data,
});

export const addPlayFail = (err) => ({
  type: UserhistoryType.ADD_PLAY_FAIL,
  payload: err,
});

export const deletePlayStart = (ytid) => ({
  type: UserhistoryType.DELETE_PLAY_START,
  payload: { ytid },
});

export const deletePlaySuccess = (data) => ({
  type: UserhistoryType.DELETE_PLAY_SUCCESS,
  payload: data,
});

export const deletePlayFail = (err) => ({
  type: UserhistoryType.DELETE_PLAY_FAIL,
  payload: err,
});

export const urlSlider = (skip) => ({
  type: UserhistoryType.URL_SLIDER,
  payload: { skip },
});

export const setSliderStart = (ytid, slider) => ({
  type: UserhistoryType.SET_SLIDER_START,
  payload: { ytid, slider },
});

export const setSliderSuccess = (data) => ({
  type: UserhistoryType.SET_SLIDER_SUCCESS,
  payload: data,
});

export const setSliderFail = (err) => ({
  type: UserhistoryType.SET_SLIDER_FAIL,
  payload: err,
});

export const getHeartsPageStart = (next, merge) => ({
  type: UserhistoryType.GET_HEARTS_PAGE_START,
  payload: { next, merge },
});

export const getHeartsPageSuccess = (data) => ({
  type: UserhistoryType.GET_HEARTS_PAGE_SUCCESS,
  payload: data,
});

export const getHeartsPageFail = (err) => ({
  type: UserhistoryType.GET_HEARTS_PAGE_FAIL,
  payload: err,
});

export const addHeartStart = (ytid) => ({
  type: UserhistoryType.ADD_HEART_START,
  payload: { ytid },
});

export const addHeartSuccess = (data) => ({
  type: UserhistoryType.ADD_HEART_SUCCESS,
  payload: data,
});

export const addHeartFail = (err) => ({
  type: UserhistoryType.ADD_HEART_FAIL,
  payload: err,
});

export const deleteHeartStart = (ytid) => ({
  type: UserhistoryType.DELETE_HEART_START,
  payload: { ytid },
});

export const deleteHeartSuccess = (data) => ({
  type: UserhistoryType.DELETE_HEART_SUCCESS,
  payload: data,
});

export const deleteHeartFail = (err) => ({
  type: UserhistoryType.DELETE_HEART_FAIL,
  payload: err,
});

export const clearHistoryData = () => ({
  type: UserhistoryType.CLEAR_USER_HISTORY_DATA,
});

export const getPlaysByRepeatsPageStart = (next) => ({
  type: UserhistoryType.GET_PLAYS_PAGE_REPEAT_START,
  payload: { next },
});

export const getPlaysByRepeatsPageSuccess = (data) => ({
  type: UserhistoryType.GET_PLAYS_PAGE_REPEAT_SUCCESS,
  payload: data,
});

export const getPlaysByRepeatsPageFail = (err) => ({
  type: UserhistoryType.GET_PLAYS_PAGE_REPEAT_FAIL,
  payload: err,
});

export const changeSortStart = (typeID) => ({
  type: UserhistoryType.CHANGE_SORT_START,
  payload: { typeID },
});

export const changeSortSuccess = (data) => ({
  type: UserhistoryType.CHANGE_SORT_SUCCESS,
  payload: data,
});
