import {
  SET_HOME_TAB,
  SET_CURRENT_TAB,
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_DARKMODE,
  ACTIVATE_LOADING,
  DEACTIVATE_LOADING,
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL,
  SCROLL_TO_MAIN_CONTENT_ONLOAD,
  SET_SWITCH_TAB
} from './siteCoordinator.types';

export const setHomeTab = () => ({
  type: SET_HOME_TAB
});

export const setCurrentTab = tab => ({
  type: SET_CURRENT_TAB,
  payload: tab
});

export const toggleSidebar = value => ({
  type: TOGGLE_SIDEBAR,
  payload: value
});

export const closeSidebar = () => ({
  type: CLOSE_SIDEBAR
});

export const toggleDarkMode = () => ({
    type: TOGGLE_DARKMODE
});

export const activateLoading = () => ({
  type: ACTIVATE_LOADING
});

export const deactivateLoading = () => ({
  type: DEACTIVATE_LOADING
});

export const showErrorModal = (title, text) => ({
  type: SHOW_ERROR_MODAL,
  payload: { title, text }
});

export const hideErrorModal = () => ({
  type: HIDE_ERROR_MODAL
});

export const scrollToMainContentOnLoad = () => ({
  type: SCROLL_TO_MAIN_CONTENT_ONLOAD
});

export const setSwitchTab = (tab) => ({
  type: SET_SWITCH_TAB,
  payload: tab
});
