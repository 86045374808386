import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { RepeatGlobalIcon2 } from 'common/ui/icons';
import { parseDuration, durationToHMSString } from 'common/yt_utils';
import { longNumberString, shortNumberString } from 'common/utils';

import useStyles from '../../../styles';

const GlobalRepeatStat = (props) => {
  const classes = useStyles();

  const repeats = props.repeats || 0;
  const durationString = props.duration || '';
  const duration = parseDuration(durationString);
  const totalDuration = repeats * duration;
  const totalDurationString = durationToHMSString(totalDuration);

  const repeatsTooltip = `Global Repeats: ${longNumberString(
    repeats
  )} (${totalDurationString})`;

  return (
    <Tooltip title={repeatsTooltip}>
      <IconButton
        className={classes.iconButton}
        style={{
          margin: '6px 10px 0px 0px',
        }}
      >
        <RepeatGlobalIcon2 className={classes.infoIcon} />
        <span className={classes.spanText}>{shortNumberString(repeats)}</span>
      </IconButton>
    </Tooltip>
  );
};

export default GlobalRepeatStat;
