import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Button,
  CardContent,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { ReportAdForm } from './components';
import axios from 'instances/server';

const reasons = [
  {
    value: 'Bug Report',
    label: 'Bug Report',
  },
  {
    value: 'Feature Request',
    label: 'Feature Request',
  },
  {
    value: 'Advertising Opportunity',
    label: 'Advertising Opportunity',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const useStyles = makeStyles((theme) => ({
  lor_links: {
    textDecoration: 'none',
    color: 'rgb(255, 0, 102)',
    cursor: 'pointer',
  },
  formRoot: {
    '& > *': {
      margin: theme.spacing(1),
      width: '48ch',
    },
  },
  yourMessage: {
    '& > *': {
      margin: theme.spacing(1),
      width: '98ch',
    },
  },
  multilineColor: {
    color: theme.palette.lightGray,
  },
  label: {
    color: theme.palette.darkGray,
    fontFamily: 'Arial',
    '&$focusedLabel': {
      color: theme.palette.darkGray,
    },
    '&$erroredLabel': {
      color: '#f44336',
    },
  },
  underline: {
    '&$error:after': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottom: `2px solid rgb(255, 0, 102)`,
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  error: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  selectEmpty: {
    // color: 'rgb(37, 37, 37)',
    color: theme.palette.textColor,
    // margin: theme.spacing(1),
    minWidth: '100%',
    marginTop: theme.spacing(2),
    paddingBottom: 10,
  },
  inputBase: {
    color: theme.palette.alternateTextColor,
    fontFamily: 'Arial',
  },
}));

const ContactForm = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: {
      message: '',
      isValid: false,
      errorText: '',
    },
    email: {
      message: '',
      isValid: false,
      errorText: '',
    },
    message: {
      message: '',
      isValid: false,
      errorText: '',
    },
  });

  const [reason, setReason] = useState('Bug Report');
  const [submitting, setSubmitting] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [reportAdDialogOpen, setReportAdDialogOpen] = useState(false);

  const { name, email, message } = formData;

  const handleOpen = () => setReportAdDialogOpen(true);

  const handleClose = () => setReportAdDialogOpen(false);

  /** Name Event Handlers **/
  const onNameTextfieldChange = (event) => {
    let message = event.target.value;
    let isValid = message.length > 2;

    setFormData({
      ...formData,
      name: {
        message: message,
        isValid: isValid,
        errorText: '',
      },
    });
  };

  const onNameTextfieldBlur = () => {
    let errorText = !name.isValid && 'Name is required';
    setFormData({
      ...formData,
      name: {
        errorText: errorText,
        message: name.message,
        isValid: name.isValid,
      },
    });
  };

  /** Email Event Handlers **/
  const onEmailTextfieldChange = (event) => {
    let message = event.target.value;
    let isValid = emailRegex.test(message);

    setFormData({
      ...formData,
      email: {
        message: message,
        isValid: isValid,
        errorText: '',
      },
    });
  };

  const onEmailTextfieldBlur = () => {
    let errorText = !email.isValid && 'Valid email is required';
    setFormData({
      ...formData,
      email: {
        errorText: errorText,
        message: email.message,
        isValid: email.isValid,
      },
    });
  };

  /** Message Event Handlers **/
  const onMessageTextfieldChange = (event) => {
    let message = event.target.value;
    let isValid = message.length > 10;

    setFormData({
      ...formData,
      message: {
        message: message,
        isValid: isValid,
        errorText: '',
      },
    });
  };

  const onMessageTextfieldBlur = () => {
    let errorText = !message.isValid && 'Message too short';
    setFormData({
      ...formData,
      message: {
        errorText: errorText,
        message: message.message,
        isValid: message.isValid,
      },
    });
  };

  /** Dropdown Event Handlers **/
  const onDropdownChange = (event) => {
    setReason(event.target.value);
  };

  /** Other Event Handlers **/

  const onSubmit = () => {
    if (!(name.isValid && email.isValid && message.isValid)) {
      return;
    }

    const formData = {
      name: name.message,
      email: email.message,
      message: message.message,
      reason: reason,
      ua: navigator.userAgent,
      path: window.location.href,
    };

    // console.log('form data');
    // console.log(formData);
    setSubmitting(true);

    try {
      axios
        .post('/contactform/contactform', formData)
        .then(function (response) {
          // console.log(response);
          if (response.status == 200) {
            setHasSent(true);
          }
        });
      // console.log(res)
    } catch (e) {
      console.error(e);
    }
    setFormData({
      ...formData,
      name: {
        message: '',
        isValid: false,
        errorText: '',
      },
      email: {
        message: '',
        isValid: false,
        errorText: '',
      },
      message: {
        message: '',
        isValid: false,
        errorText: '',
      },
    });

    // contactform.actions.submitFormData(formData).then(() => {
    //   setState({
    //     name: {
    //       message: '',
    //       isValid: false,
    //       errorText: '',
    //     },
    //     email: {
    //       message: '',
    //       isValid: false,
    //       errorText: '',
    //     },
    //     message: {
    //       message: '',
    //       isValid: false,
    //       errorText: '',
    //     },
    //     reason: 'Bug Report',
    //     submitting: false,
    //     hasSent: true,
    //   });
    // });
  };

  return (
    <CardContent style={{ paddingTop: 0 }}>
      <div style={{ padding: 10 }}>
        <div>
          <p style={{ fontSize: 14, fontWeight: 400, fontFamily: 'Arial' }}>
            We appreciate your feedback! Please use the form below to contact us
            and we will get back to you as soon as possible.
            <br />
            If you are having a problem with our adverts,&nbsp;
            <span
              onClick={handleOpen}
              style={{ color: '#ff0066', cursor: 'pointer' }}
            >
              report them here
            </span>
            .
          </p>
        </div>

        <ReportAdForm open={reportAdDialogOpen} onRequestClose={handleClose} />

        <div style={{ display: 'flex', paddingBottom: 10 }}>
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focusedLabel,
                error: classes.erroredLabel,
              },
            }}
            InputProps={{
              classes: {
                input: classes.inputBase,
                root: classes.underline,
                error: classes.error,
              },
            }}
            label="Name"
            errorText={name.errorText}
            onChange={onNameTextfieldChange}
            onBlur={onNameTextfieldBlur}
            style={{ width: '50%', marginRight: '18px' }}
            errorStyle={{ bottom: 18 }}
          />
          <TextField
            InputLabelProps={{
              classes: {
                root: classes.label,
                focused: classes.focusedLabel,
                error: classes.erroredLabel,
              },
            }}
            InputProps={{
              classes: {
                input: classes.inputBase,
                root: classes.underline,
                error: classes.error,
              },
            }}
            label="Email"
            errorText={email.errorText}
            style={{ width: '50%' }}
            errorStyle={{ bottom: 18 }}
            onChange={onEmailTextfieldChange}
            onBlur={onEmailTextfieldBlur}
          />
        </div>

        <Select
          value={reason}
          onChange={onDropdownChange}
          className={classes.selectEmpty}
        >
          {reasons.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        <TextField
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            classes: {
              input: classes.inputBase,
              root: classes.underline,
              error: classes.error,
            },
          }}
          label="Your Message"
          fullWidth
          errorText={message.errorText}
          style={{ marginTop: 10, paddingBottom: 10 }}
          multiLine={true}
          rows={1}
          rowsMax={4}
          onChange={onMessageTextfieldChange}
          onBlur={onMessageTextfieldBlur}
        />

        <div show={submitting}>
          <Button
            variant="contained"
            color="default"
            endIcon={<SendIcon>send</SendIcon>}
            fullWidth
            disabled={!(email.isValid && message.isValid && message.isValid)}
            style={{
              // marginTop: theme.spacing(1),
              marginTop: 10,
              minWidth: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              color: 'rgb(229, 229, 229)',
              fontWeight: 15,
            }}
            labelPosition="before"
            secondary={true}
            onSubmit={onSubmit}
            onClick={onSubmit}
          >
            Send Message
          </Button>
        </div>
        {/*<div show={submitting}>
                    <CircularProgress
                        style={{display: 'block', margin: '0 auto'}}
                        color="#ff0066"
                    />
                </div>*/}

        {hasSent ? (
          <div show={hasSent} style={{ textAlign: 'center' }}>
            Message sent. Thanks for contacting us! 📬
          </div>
        ) : (
          ''
        )}
      </div>
    </CardContent>
  );
};

export default ContactForm;
