import * as YourLorTypes from './yourlor.types';

export const initYourLOR = () => ({
  type: YourLorTypes.INIT_YOUR_LOR,
});

export const initDiscovery = () => ({
  type: YourLorTypes.INIT_DISCOVERY,
});

export const ineligbleForDiscovery = (eligibleArtists) => ({
  type: YourLorTypes.INELIGBLE_DISCOVERY,
  payload: { eligibleArtists },
});

export const resetDiscovery = () => ({
  type: YourLorTypes.RESET_DISCOVERY,
});

export const setDiscovery = (ytids) => ({
  type: YourLorTypes.SET_DISCOVERY,
  payload: { ytids },
});

export const setDiscoveryExpires = (expires) => ({
  type: YourLorTypes.SET_DISCOVERY_EXPIRES,
  payload: { expires },
});

export const setOldDiscoveries = (ytids) => ({
  type: YourLorTypes.SET_OLD_DISCOVERY,
  payload: { ytids },
});

export const addNewDiscoverySuccess = (data) => ({
  type: YourLorTypes.ADD_NEW_DISCOVERY,
  payload: data,
});

export const validateYtidsStart = (ytids) => ({
  type: YourLorTypes.VALIDATE_YTIDS,
  payload: { ytids },
});

export const validateYtidsSuccess = (ytids) => ({
  type: YourLorTypes.VALIDATE_YTIDS_SUCCESS,
  payload: { ytids },
});

export const setArtistsToDiscover = (artists) => ({
  type: YourLorTypes.SET_ARTISTS_DISCOVER,
  payload: { artists },
});

export const setNextArtistSuccess = (data) => ({
  type: YourLorTypes.SET_NEXT_ARTIST_SUCCESS,
  payload: data,
});

export const removeDiscoveryStart = (ytid) => ({
  type: YourLorTypes.REMOVE_DISCOVERY_START,
  payload: ytid,
});

export const removeDiscoverySuccess = (data) => ({
  type: YourLorTypes.REMOVE_DISCOVERY_SUCCESS,
  payload: data,
});

export const removeDiscoveryArtist = (artist) => ({
  type: YourLorTypes.REMOVE_DISCOVERY_ARTIST,
  payload: { artist },
});

export const setIneligbleArtist = (artistName) => ({
  type: YourLorTypes.SET_INELIGBLE_ARTIST,
  payload: { artist: artistName },
});

export const discoveryFailure = () => ({
  type: YourLorTypes.DISCOVERY_FAILURE,
});
