import _ from 'lodash';

import * as RadioType from './radio.types';

const INITIAL_STATE = {
  currentCountryCode: null,
  currentStates: null,
  currentGenres: null,
  loading: false,
  genresLoading: false,
  radios: {},
  stationList: [],
  mostPopular: [],
  fiveStar: [],
  genreStations: [],
  errors: [],
};

const radioReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let updatedState;
  switch (type) {
    case RadioType.FETCH_RADIO_LIST_START:
      return {
        ...state,
        loading: true,
      };

    case RadioType.FETCH_RADIO_LIST_SUCCESS:
      // console.log({ payload });
      updatedState = {
        ...state,
        loading: false,
        radios: {
          ...state.radios,
        },
      };

      const randomIDS = _.sampleSize(payload, 6).map(
        (meta) => meta.stationuuid
      );
      updatedState.mostPopular = randomIDS;
      const randomStar = _.sampleSize(payload, 6).map(
          (meta) => meta.stationuuid
      );
      updatedState.fiveStar = randomStar;

      _.values(payload)
        .filter((m) => !_.isNil(m))
        .forEach((meta) => {
          updatedState.radios[meta.stationuuid] = meta;
        });

      return updatedState;

    case RadioType.FETCH_RADIO_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case RadioType.SET_COUNTRY_CODE:
      return {
        ...state,
        currentCountryCode: payload,
      };

    case RadioType.SET_CURRENT_STATES:
      return {
        ...state,
        currentStates: payload,
      };

    case RadioType.SET_CURRENT_GENRES:
      return {
        ...state,
        currentGenres: payload,
      };

    case RadioType.FETCH_GENRES_RADIO_LIST_START:
      return {
        ...state,
        genresLoading: true,
      };

    case RadioType.FETCH_GENRES_RADIO_LIST_SUCCESS:
      return {
        ...state,
        genreStations: payload,
        genresLoading: false,
      };

    case RadioType.FETCH_GENRES_RADIO_LIST_FAIL:
      return {
        ...state,
        errors: payload,
        genresLoading: false,
      };

    case RadioType.RATING_START:
      updatedState = {
        ...state,
      };
      /*if (!updatedState.fiveStar.some(value => value === payload)) {
        updatedState.fiveStar.push(payload);
      }*/
      return updatedState;

    default:
      return state;
  }
};

export default radioReducer;
