import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '@keyframes hideshow': {
    '0%': { opacity: 0 },
    '10%': { opacity: 1 },
    '30.2%': { opacity: 1 },
    '63.3%': { opacity: 0 },
    '100%': { opacity: 0 },
  },
  polygon: {
    fill: theme.palette.lightSlateGray,
  },
  speakerInner: {
    fill: theme.palette.lightSlateGray,
    animation: '$hideshow 600ms ease infinite',
  },
  speakerMid: {
    fill: theme.palette.lightSlateGray,
    animation: '$hideshow 600ms ease infinite',
    animationDelay: '200ms',
  },
  speakerOuter: {
    fill: theme.palette.lightSlateGray,
    animation: '$hideshow 600ms ease infinite',
    animationDelay: '400ms',
  },
}));

const NowPlayingIcon = (props) => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 64 64" fontSize={'small'} style={props.style}>
      <g id="Group">
        <rect id="Rectangle" x="0" y="0" width="64" height="64" fill="none" />
        <polygon
          className={classes.polygon}
          id="Path-4"
          points="6.20974807 24.4055443 16.7326446 24.4055443 27.0993784 14.0388105 27.0993784 49.9999327 16.6931341 39.5936884 6.20974807 39.5936884 6.20974807 32.0194935"
        />
        <path
          style={{
            animationPlayState: props.playerIsPlaying ? 'running' : 'paused',
          }}
          className={classes.speakerInner}
          d="M34.0452502,24.8838793 L37.4334502,21.2718748 C37.4334502,21.2718748 42.0017037,26.7586958 42.0393825,32 C42.0770613,37.2413042 37.5855006,42.4228305 37.5855006,42.4228305 L33.9218635,39.1973112 C33.9218635,39.1973112 36.9729848,35.495263 37.0031152,32 C37.0332456,28.504737 34.0452502,24.8838793 34.0452502,24.8838793 Z"
          id="inner"
        />
        <path
          style={{
            animationPlayState: props.playerIsPlaying ? 'running' : 'paused',
          }}
          className={classes.speakerMid}
          d="M40.6574509,17.4928235 L44.1513839,13.7553441 C44.1513839,13.7553441 47.2900639,16.9269997 49.3960798,21.2244183 C51.1979583,24.901231 52.0220093,29.5833039 52.0393825,32 C52.0558286,34.2877329 51.2710607,38.2820671 49.6202641,41.6929505 C47.4890077,46.0965618 44.3645582,49.7432711 44.3645582,49.7432711 L40.6574509,46.4826341 C40.6574509,46.4826341 42.9906817,43.8807637 44.7075734,40.3590448 C46.1295967,37.4421652 46.838385,33.5834597 46.852035,32 C46.866188,30.3581897 46.390108,26.758609 44.9021448,23.7117053 C43.2223713,20.2720315 40.6574509,17.4928235 40.6574509,17.4928235 Z"
          id="mid"
        />
        <path
          style={{
            animationPlayState: props.playerIsPlaying ? 'running' : 'paused',
          }}
          className={classes.speakerOuter}
          d="M47.9763778,10.3103573 L51.4262017,6.67615405 C51.4262017,6.67615405 58.0862391,13.9824669 59.9628131,19.4134545 C61.3432731,23.4086399 62.3645583,29.8279598 62.3819315,32.2446559 C62.3983776,34.5323888 61.4109799,39.7353912 60.3272075,43.0303633 C58.4611636,48.7036598 51.4262017,56.7221556 51.4262017,56.7221556 L47.9763778,53.2568335 C47.9763778,53.2568335 53.7316533,46.7583073 55.1793843,41.9956744 C56.140476,38.8339496 57.1945841,36.8619005 57.1945841,32.2446559 C57.1945841,27.6274112 56.1565678,24.8289646 55.1792812,21.5157967 C53.7837814,16.7848144 47.9763778,10.3103573 47.9763778,10.3103573 Z"
          id="outer"
        />
      </g>
    </SvgIcon>
  );
};

export default NowPlayingIcon;
