import _ from "lodash";

import * as UserType from "./user.types";
import LoginStatus from "constants/LoginStatus";

const INITIAL_STATE = {
  profile: {
    uid: null,
    email: null,
    loginProviders: [],
  },
  loginStatus: LoginStatus.Anonymous,
  assimilating: false,
  firstName: null,
  avatarUrl: null,
  type: UserType.ANONYMOUS,
  fb: {},
  google: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case UserType.SET_PROFILE_DATA_START:
      return {
        ...state,
        loginStatus: LoginStatus.LoggingIn,
      };

    case UserType.SET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        loginStatus: LoginStatus.Authenticated,
        profile: {
          ...state.profile,
          ...payload,
        },
      };

    case UserType.SET_USER_PROFILE_SUCCESS:
      const userType = calculateUserType(state);
      const pictureUrl = calculatePictureUrl(state, userType, payload);
      const firstName = calculateFirstName(state, userType, payload);
      console.log(pictureUrl, "pictureeeeeeeeeeee");
      const mergedState = {
        ...state,
        ...payload,
        firstName,
        avatarUrl: pictureUrl,
        type: userType,
      };

      return mergedState;

    case UserType.SET_FB_PROFILE:
      return {
        ...state,
        loginStatus: LoginStatus.Authenticated,
        type: UserType.FACEBOOK,
        firstName: payload.firstName,
        avatarUrl: payload.pictureUrl,
        fb: {
          ...payload,
        },
      };

    case UserType.SET_GOOGLE_PROFILE:
      return {
        ...state,
        loginStatus: LoginStatus.Authenticated,
        type: UserType.GOOGLE,
        firstName: payload.firstName,
        avatarUrl: payload.imageURL,
        google: {
          ...payload,
        },
      };

    case UserType.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...payload.profile,
        },
      };

    case UserType.CLEAR_PROFILE_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

const calculateUserType = (state) => {
  const existingProfile = state.profile || {};

  const providers = existingProfile.loginProviders;

  return providers.length > 0 ? providers[0] : UserType.ANONYMOUS;
};

const calculatePictureUrl = (state, userType, newUserState) => {
  if (!userType) {
    return null;
  }
  console.log(newUserState, userType, "new goooogle state");
  let image;
  switch (userType) {
    case UserType.ANONYMOUS:
      return null;
    case UserType.GOOGLE:
      image = newUserState?.google?.imageURL || null;
      return image;

    case UserType.FACEBOOK:
      image = newUserState?.fb?.pictureUrl || null;
      return image;
    default:
      throw new Error(
        `User.calculatePictureUrl: Unknown User.Type (${userType})`
      );
  }
};

const calculateFirstName = (state, userType, newUserState) => {
  if (!userType) {
    return null;
  }

  switch (userType) {
    case UserType.ANONYMOUS:
      return null;
    case UserType.FACEBOOK:
      return (
        (_.isEmpty(newUserState.fb) ? null : newUserState.fb.firstName) ||
        state.fb.firstName
      );
    case UserType.GOOGLE:
      return (
        (_.isEmpty(newUserState.google)
          ? null
          : newUserState.google.firstName) || state.google.firstName
      );
    default:
      throw new Error(
        `User.calculateFirstName: Unknown User.Type (${userType})`
      );
  }
};

export default userReducer;
