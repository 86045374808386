import * as UserhistoryRadioType from './userhistoryRadio.types';

export const getHistoryRadioStationStart = (next, merge = false) => ({
  type: UserhistoryRadioType.GET_HISTORY_RADIO_STATION_START,
  payload: { next, merge },
});

export const getHistoryRadioStationSuccess = (response, merge) => ({
  type: UserhistoryRadioType.GET_HISTORY_RADIO_STATION_SUCCESS,
  payload: { response, merge },
});

export const getHistoryRadioStationFail = (err) => ({
  type: UserhistoryRadioType.GET_HISTORY_RADIO_STATION_FAIL,
  payload: err,
});

export const addPlayRadioStationStart = (stationid) => ({
  type: UserhistoryRadioType.ADD_PLAY_RADIO_STATION_START,
  payload: { stationid },
});

export const addPlayRadioStationSuccess = (data) => ({
  type: UserhistoryRadioType.ADD_PLAY_RADIO_STATION_SUCCESS,
  payload: data,
});

export const addPlayRadioStationFail = (err) => ({
  type: UserhistoryRadioType.ADD_PLAY_RADIO_STATION_FAIL,
  payload: err,
});

export const deletePlayRadioStationStart = (stationid) => ({
  type: UserhistoryRadioType.DELETE_PLAY_RADIO_STATION_START,
  payload: { stationid },
});

export const deletePlayRadioStationSuccess = (data) => ({
  type: UserhistoryRadioType.DELETE_PLAY_RADIO_STATION_SUCCESS,
  payload: data,
});

export const deletePlayRadioStationFail = (err) => ({
  type: UserhistoryRadioType.DELETE_PLAY_RADIO_STATION_FAIL,
  payload: err,
});

export const getHeartsRadioStationStart = (next, merge = false) => ({
  type: UserhistoryRadioType.GET_HEARTS_RADIO_STATION_START,
  payload: { next, merge },
});

export const getHeartsRadioStationSuccess = (data) => ({
  type: UserhistoryRadioType.GET_HEARTS_RADIO_STATION_SUCCESS,
  payload: data,
});

export const getHeartsRadioStationFail = (err) => ({
  type: UserhistoryRadioType.GET_HEARTS_RADIO_STATION_FAIL,
  payload: err,
});

export const radioStationHeartStart = (stationid) => ({
  type: UserhistoryRadioType.RADIO_STATION_HEART_START,
  payload: { stationid },
});

export const radioStationHeartSuccess = (data) => ({
  type: UserhistoryRadioType.RADIO_STATION_HEART_SUCCESS,
  payload: data,
});

export const radioStationHeartFail = (err) => ({
  type: UserhistoryRadioType.RADIO_STATION_HEART_FAIL,
  payload: err,
});

export const deleteHeartRadioStationStart = (stationid) => ({
  type: UserhistoryRadioType.DELETE_HEART_RADIO_STATION_START,
  payload: { stationid },
});

export const deleteHeartRadioStationSuccess = (data) => ({
  type: UserhistoryRadioType.DELETE_HEART_RADIO_STATION_SUCCESS,
  payload: data,
});

export const deleteHeartRadioStationFail = (err) => ({
  type: UserhistoryRadioType.DELETE_HEART_RADIO_STATION_FAIL,
  payload: err,
});

export const getNextFavoriteStart = (cursor, limit) => ({
  type: UserhistoryRadioType.GET_NEXT_HEART_START,
  payload: { cursor, limit },
});

export const getNextHistoryStart = (cursor, limit) => ({
  type: UserhistoryRadioType.GET_NEXT_HISTORY_START,
  payload: { cursor, limit },
});
