import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';

import AddPlaylist from 'assets/images/playlists-add-to-video.png';

import Playlist from '../Playlist';
import PlaylistCardSharingInfo from '../PlaylistCardSharingInfo'

const useStyles = makeStyles(() => ({
  empty: {
    fontSize: 13,
    color: 'lightgrey',
    textAlign: 'center',
    padding: 20,
  },
}));

const PlaylistCardDetail = (props) => {
  const classes = useStyles();

  const {
    selectedPid,
    ownUid,
    currentYtid,
    playlistState,
    userState,
    videoState,
    userhistoryState,
    onVideoClick,
  } = props;

  const currentlyViewedPid = selectedPid;
  const currentlyViewedPlaylist =
    playlistState.playlists[currentlyViewedPid] || {};

  const isOwnPlaylist = ownUid && currentlyViewedPlaylist.uid === ownUid;
  const ytids = currentlyViewedPlaylist.ytids || [];

  return (
    <CardContent style={{ padding: 0 }}>
      {ytids.length === 0 ? (
        <div className={classes.empty}>
          <p style={{ marginBottom: 20 }}>No videos yet!</p>
          <img
            style={{ borderRadius: 20 }}
            src={AddPlaylist}
            alt="playlists add to video"
          />
        </div>
      ) : (
        <div>
          <PlaylistCardSharingInfo
            pid={currentlyViewedPid}
          />
          <Playlist
            key={currentlyViewedPlaylist.pid}
            pid={currentlyViewedPlaylist.pid}
            uid={currentlyViewedPlaylist.uid}
            ownUid={ownUid}
            currentYtid={currentYtid}
            customOrderedYtids={ytids}
            videoState={videoState}
            playlistState={playlistState}
            userState={userState}
            userhistoryState={userhistoryState}
            sortBy={playlistState.sortBy}
            currentPlaylist={playlistState.currentPlaylist}
            onVideoClick={onVideoClick}
          />
        </div>
      )}
    </CardContent>
  );
};

export default PlaylistCardDetail;
