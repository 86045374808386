const styles = {
  wrapper: {
    padding: 0,
    fontFamily: 'Roboto, sans-serif',
  },

  hr: {
    margin: 0,
    borderTop: '1px solid #ff0066',
  },

  list: {
    height: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'scroll',
    whiteSpace: 'nowrap',
    padding: 0,
    borderTop: '1px #eee solid',
    paddingBottom: 20,

    item: {
      textDecoration: 'none',
      color: '#494949',
      textAlign: 'left',
      fontSize: 14,
      cursor: 'pointer',
      borderBottom: '1px #eee solid',
    },
    icon: {
      margin: 0
    },
  },

  stickyTextfield: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: 35,
    right: 0,
    backgroundColor: 'white',
    paddingLeft: 10,
    paddingRight: 10,
  },

  textfield: {
    height: 35,
    fontSize: 12,
    width: '100%',
  },
};

export default styles;
