import React, { useState } from 'react';
import * as cookie from 'component-cookie';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import SortMenu from 'common/ui/sort-menu/SortMenu';
import SortType from 'constants/SortType';

import { ShareButtons } from 'modules/player/components';

import container from './PlaylistCardDetailHeader.container';

const useStyles = makeStyles((theme) => ({
  actions: {},
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  iconButton: {
    width: 30,
    height: 30,
    fill: theme.palette.iconColor,
  },
  icon: {
    fill: theme.palette.iconColor,
    height: 20,
    width: 20,
    cursor: 'pointer',
  },
  seperator: {
    height: 20,
    borderRight: '1px solid rgb(234, 234, 234)',
    width: 1,
    margin: '4px 5px',
    display: 'inline-block',
  },
  socialWrapper: {
    padding: 5,
  },
}));

const PlaylistCardDetailHeader = (props) => {
  const classes = useStyles();

  const {
    selectedPid,
    selectedPlaylistName,
    viewingOwnPlaylist,
    onSetSortType,
    onShowEditPlaylistModal,
    onShowDeletePlaylistModal,
    onAddFollowedPlaylistStart,
    onDeleteFollowedPlaylistStart,
    onShowSnackbarNotification,
  } = props;

  const [socialShareMenuOpen, setSocialShareMenuOpen] = useState(false);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const playlistLink = `${window.location.origin}/?p=playlist/${selectedPid}`;

  const onBookmarkTap = () => {
    if (props.isFollowed) {
      onDeleteFollowedPlaylistStart(props.selectedPid);
      onShowSnackbarNotification(`Removed bookmark`);
    } else {
      onAddFollowedPlaylistStart(props.selectedPid);
      onShowSnackbarNotification(`Bookmarked`);
    }

    // Hide tooltip cookie for ever:
    cookie('bookmark-recommend', 999 + '', {
      maxage: 1000 * 3600 * 24,
      path: '/',
    });
  };

  const onToggleSocialShareMenu = (event) => {
    setSocialShareMenuOpen((prevState) => !prevState);
    setShareAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const onSortByChange = (type) => {
    onSetSortType(type);
  };

  const sortItems = [
    { id: SortType.Alphabetical, text: 'Alphabetical' },
    { id: SortType.RepeatCount, text: 'Repeat Count' },
    { id: SortType.LastPlayed, text: 'Last Played' },
    { id: SortType.Custom, text: 'Custom' },
  ];

  const id2 = socialShareMenuOpen ? 'social-share-popover' : undefined;

  return (
    <div className={classes.actions}>
      <div style={{ display: 'flex' }}>
        {!viewingOwnPlaylist && (
          <Tooltip
            title={props.isFollowed ? 'Remove Bookmark' : 'Bookmark playlist'}
          >
            <IconButton className={classes.iconButton} onClick={onBookmarkTap}>
              {props.isFollowed ? (
                <BookmarkIcon className={classes.icon} />
              ) : (
                <BookmarkBorderIcon className={classes.icon} />
              )}
            </IconButton>
          </Tooltip>
        )}
        {viewingOwnPlaylist && (
          <IconButton
            className={classes.iconButton}
            // disableFocusRipple
            disableRipple={true}
            onClick={onToggleSocialShareMenu}
            aria-describedby={id2}
          >
            <ShareIcon className={classes.icon} />
          </IconButton>
        )}
        <Popover
          id={id2}
          open={socialShareMenuOpen}
          anchorEl={shareAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => {
            setSocialShareMenuOpen(false);
            setShareAnchorEl(null);
          }}
        >
          <ShareButtons
            className={classes.socialWrapper}
            url={playlistLink}
            title="Check out playlist - "
            noPinterest
          />
        </Popover>
        <div className={classes.seperator} />
        <SortMenu
          title={'Sort Playlist'}
          onSortByChange={onSortByChange}
          items={sortItems}
          initialItem={SortType.Custom}
        />
        {viewingOwnPlaylist && (
          <div style={{ display: 'flex' }}>
            <div className={classes.seperator} />
            <Tooltip title="Edit Playlist">
              <IconButton
                className={classes.iconButton}
                // disableFocusRipple
                disableRipple={true}
                onClick={(e) =>
                  onShowEditPlaylistModal(
                    selectedPid,
                    selectedPlaylistName,
                    false,
                    playlistLink
                  )
                }
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <div className={classes.seperator} />
            <Tooltip title="Delete Playlist">
              <IconButton
                className={classes.iconButton}
                // disableFocusRipple
                disableRipple={true}
                onClick={(e) =>
                  onShowDeletePlaylistModal(selectedPid, selectedPlaylistName)
                }
              >
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default container(PlaylistCardDetailHeader);
