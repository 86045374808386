export const SET_HOME_TAB = 'SET_HOME_TAB';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE';

export const ACTIVATE_LOADING = 'ACTIVATE_LOADING';
export const DEACTIVATE_LOADING = 'DEACTIVATE_LOADING';

export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';
export const SCROLL_TO_MAIN_CONTENT_ONLOAD = 'SCROLL_TO_MAIN_CONTENT_ONLOAD';

export const SET_SWITCH_TAB = 'SET_SWITCH_TAB';