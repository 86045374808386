import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    border: 10,
    borderRadius: "50%",
    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Roboto",
    "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
    cursor: "pointer",
    textDecoration: "none",
    padding: "0px",
    outline: "none",
    fontSize: "0px",
    fontWeight: "inherit",
    position: "relative",
    overflow: "visible",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    width: "auto",
    marginTop: 6,
    fill: "rgb(176, 176, 176)",
    background: "none",
  },
  infoIcon: {
    color: "#B0B0B0",
    width: 18,
    height: 18,
    fontSize: 12,
    verticalAlign: "bottom",
    display: "inline",
    marginRight: 3,
    borderRadius: "50%",
  },
  actionIcon: {
    color: "#B0B0B0",
    width: 20,
    height: 20,
    fontSize: 12,
    verticalAlign: "bottom",
    display: "inline",
    marginRight: 3,
  },
  spanText: {
    fontFamily: "Arial",
    paddingLeft: 2,
    fontSize: 12,
    verticalAlign: "bottom",
    display: "inline",
    color: "rgb(176, 176, 176)",
  },
}));

export default useStyles;
