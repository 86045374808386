import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { TooltipMobile, TooltipDesktop } from './components';

const Tooltip = (props) => {
  const { open } = props;
  const id = open ? 'scroll-playground' : null;
  return (
    <>
      <Hidden mdUp>
        <TooltipMobile {...props} />
      </Hidden>
      <Hidden smDown>
        <TooltipDesktop id={id} {...props} />
      </Hidden>
    </>
  );
};

export default Tooltip;
