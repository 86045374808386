import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import { usePrevious } from 'hooks';
import Feature from 'constants/Feature';
import ToolTipKeys from 'constants/ToolTipKeys';

import PreviewFeed from 'modules/playlist/components/PlaylistView';
import { VideoListItemLoading, VideoListItem } from 'modules/video/components';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';

import { generateHeader } from 'store/artist/artist.utils';
import { scrollToVideo } from 'common/ui/utils';

import container from './RadioCardSelected.container';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: theme.palette.textColor1,
  },
}));

const RadioCardSelected = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [state, setState] = useState({
    loadingItems: 0,
  });

  const {
    artistState,
    videoState,
    userhistoryState,
    playlistState,
    userState,
    tooltipState,
    onSetAutoplay,
    onSetRepeatMode,
    onRemoveItemFromCue,
    onPlayArtistRadio,
    onPlayFeature,
    onHideDeletePlaylistToolTip,
    onCloseToolTip,
    onEnqueueToolTip,
    onQueueYtid,
    onRepeatCue,
    onShowAddPlaylistModal,
  } = props;

  const prevVideosLen = usePrevious(artistState.nextVideos.length);

  useEffect(() => {
    if (prevVideosLen) {
      // console.log('artist:: Card', {
      //   length1: artistState.nextVideos.length,
      //   length2: prevVideosLen,
      // });
      if (artistState.nextVideos.length > prevVideosLen) {
        const diff = artistState.nextVideos.length - prevVideosLen;
        // console.log('artist:: Card', { diff });
        setState((prevState) => {
          return { loadingItems: prevState.loadingItems - diff };
        });
      }
    }
  }, [artistState, prevVideosLen]);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip);
    return () => {
      setOpen(false);
    };
  }, [tooltipState.currentEntry.key]);

  if (!artistState.radioName || !artistState.artistRadioRecs) {
    return null;
  }

  const onShowMore = () => {
    setState({ loadingItems: 5 });
    onRepeatCue(5);
  };

  const onClickRow = (ytid) => {
    // console.log('onClickRow...');
    scrollToVideo();
    onQueueYtid(ytid);

    onPlayFeature(Feature.Radio);
    onSetRepeatMode(false);
    onSetAutoplay(true);
    onRemoveItemFromCue(ytid);
    onPlayArtistRadio(ytid, props.artistName);
  };

  const onAddToPlaylistClick = (evt, ytid) => {
    var element = evt.currentTarget;
    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }
    toggleAddToPlaylistToolTip(ytid, element);
    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const items = artistState.nextVideos;

  const name = artistState.radioName;
  const { description, images } = generateHeader(artistState.artistRadioRecs);

  const lastItem =
    artistState.radioHistory[artistState.radioHistory.length - 1] ||
    props.currentYtid;
  const currentItem = videoState.videos[lastItem];
  const allItems = props.currentYtid ? [currentItem].concat(items) : items;

  let baseItems = [];
  for (let i = 0; i < 6 - allItems.length; i++) {
    baseItems.push(<VideoListItemLoading key={i + ''} />);
  }

  let loadingItems = [];
  for (let i = 0; i < state.loadingItems; i++) {
    loadingItems.push(<VideoListItemLoading key={i + ''} />);
  }

  return (
    <CardContent style={{ padding: '0px' }}>
      <PreviewFeed
        items={allItems}
        next={null}
        onClickShowMore={() => null}
        elementFactory={(radioItem, i) => {
          const ytid = radioItem.ytid;
          const userPlay = userhistoryState.history[ytid] || {};
          const filteredPlay = _.pick(userPlay, ['ytid', 'star', 'repeats']);
          return (
            <VideoListItem
              key={ytid + i}
              ytid={ytid}
              history={filteredPlay}
              metadata={videoState.videos[ytid]}
              globalStats={videoState.videoStats[ytid]}
              isPlaying={ytid === props.currentYtid}
              showControls
              onClick={onClickRow}
              onAddToPlaylistClick={onAddToPlaylistClick}
              showMobileAuthor={false}
              showMobileGlobalRepeats={true}
              showMobileGlobalHearts={false}
              showMobileUserRepeats={true}
              showMobileDuration={true}
              showMobileLastViewed={true}
            />
          );
        }}
      />
      {baseItems}
      {loadingItems}
      <Button onClick={onShowMore} className={classes.button}>
        Show More
      </Button>
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
    </CardContent>
  );
};

RadioCardSelected.propTypes = {
  userhistoryState: PropTypes.object.isRequired,
  artistState: PropTypes.object.isRequired,
  videoState: PropTypes.object.isRequired,
  currentYtid: PropTypes.string.isRequired,
  artistName: PropTypes.string.isRequired,
};

export default container(RadioCardSelected);
