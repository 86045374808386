import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';

import 'rc-slider/assets/index.css';
//Super Token
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdpartypasswordless";
SuperTokens.init({
  appInfo: {
    appName: "Listen On Repeat",
    apiDomain: "https://supertoken.listenonrepeat.com",
    websiteDomain: "https://milkyway.listenonrepeat.com",
  },
  recipeList: [
    ThirdPartyPasswordless.init({
      onHandleEvent: async (context) => {
         if (context.action === "SUCCESS") {
            localStorage.setItem("isNewUser", context.isNewUser);
            localStorage.setItem("superTokenUserEmail", context.user.email);
        }
    },
      signInUpFeature: {
        disableDefaultUI: true,
        thirdPartyProviderAndEmailOrPhoneFormStyle: `
            [data-supertokens~=button] {
              background-color: rgb(255, 0, 102);
          } [data-supertokens~=superTokensBranding] {
            display: none;
        } `,
        userInputCodeFormStyle: `[data-supertokens~=button] {
          background-color: rgb(255, 0, 102);
      } [data-supertokens~=superTokensBranding] {
        display: none;
    } `
      },
      contactMethod: "EMAIL",
    }),
    Session.init(),
  ],
});



ReactDOM.render(
  <Provider store={store}>
    <SuperTokensWrapper>
      <Router>
        <App />
      </Router>
    </SuperTokensWrapper>
  </Provider>,
  document.getElementById('root')
);
