import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 8,
    cursor: 'pointer',
    fontFamily: 'Arial',
    '&:hover': {
      backgroundColor: theme.palette.hoverColorItem,
    }
  },
}));

const Top10Row = (props) => {
  const classes = useStyles();
  return (
    <div
      key={props.key}
      className={clsx('hover', classes.list)}
      onClick={() => props.onClick(props.ytid)}
    >
      <span style={{ fontWeight: 'bold' }}>({props.index + 1})</span>{' '}
      <span style={{ color: '#ff0066' }}>{props.title}</span>
    </div>
  );
};

export default Top10Row;
