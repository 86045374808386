import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import logo from 'assets/images/site-logo@2x.png';
import SwitchTab from 'constants/SwitchTab';

const Logo = (props) => {
  const classes = props.style;

  const clickHandler = () => {
    props.onSetSwitchTab(SwitchTab.LOR);
  };

  return (
    <Hidden mdDown>
      <Button
        component={Link}
        to="/"
        disableRipple
        className={classes.logoContainer}
        onClick={clickHandler}
      >
        <img src={logo} className={classes.logo} alt="company logo" />
      </Button>
    </Hidden>
  );
};

export default Logo;
