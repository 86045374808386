import * as UpshellType from './upsell.types';

export const initializeUpsellStart = () => ({
  type: UpshellType.INITIALIZE_UPSHELL,
});

export const initializeUpsellSuccess = (data) => ({
  type: UpshellType.INITIALIZE_UPSHELL_SUCCESS,
  payload: data,
});

export const showFeatureUpsell = (name) => ({
  type: UpshellType.SHOW_FEATURE_UPSELL,
  payload: { name },
});

export const hideFeatureUpsell = (name) => ({
  type: UpshellType.HIDE_FEATURE_UPSELL,
  payload: { name },
});

export const setFeatureDemo = (name) => ({
  type: UpshellType.SET_FEATURE_DEMO,
  payload: { name },
});
