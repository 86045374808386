const styles = {
  wrapper: {
    width: 92,
    height: 26,
    display: 'flex',
    float: 'right',
    marginTop: 3,
  },

  iconButton: {
    width: 30,
    height: 30,
    padding: 0,

    icon: {
      width: 20,
      height: 20,
      fill: 'rgb(176, 176, 176)',
    },

    infoIcon: {
      width: 18,
      height: 18,
      fontSize: 12,
      verticalAlign: 'bottom',
      display: 'inline',
      marginRight: 5,
      lineHeight: '18px',
    },
  },

  iconButtonCustom: {
    width: 'auto',
    height: 22,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
    lineHeight: '18px',

    icon: {
      width: 20,
      height: 20,
    },
  },

  controlsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    leftStats: {
      flexGrow: 0,
      flexShrink: 0,
      paddingLeft: 5,
      paddingRight: 5,
    },

    rightActions: {
      flexGrow: 0,
      flexShrink: 0,
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
};

export default styles;
