import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    fontFamily: 'Arial',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
    cardContainer: {
      backgroundColor: theme.palette.paperColor,
      color: theme.palette.textColor,
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderTopColor: theme.palette.borderColor,
      borderBottomColor: theme.palette.borderColor,
    },
    expendMore: {
    color: theme.palette.textColor,
    }
}));

export default function ControlledExpansionPanels({
  panelId,
  heading,
  children,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <ExpansionPanel
        className={classes.cardContainer}
      expanded={expanded === panelId}
      onChange={handleChange(panelId)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon className={classes.expendMore}/>}
        aria-controls="panel1bh-content"
        id={`${panelId}bh-header`}
      >
        <Typography className={classes.heading}>{heading}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography
            style={{
              padding: 16,
              fontSize: 14,
              color: theme.palette.textColor,
              fontFamily: 'Arial',
            }}
        >{children}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
