import { useEffect, useRef } from 'react';

function useInterval(
  callback,
  delay,
  player,
  prevStartTime,
  startTime,
  prevEndTime,
  endTime
) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function playerTimeCheck() {
      savedCallback.current();
    }
    if (!player) {
      return console.log('Player initialize...'); // Don't post anything if no details
    }

    if (prevStartTime !== startTime || prevEndTime !== endTime) {
      // console.log('changed slider value::... 2', delay);
      let interval = setInterval(playerTimeCheck, delay);
      return () => clearInterval(interval);
    }

    if (delay !== null) {
      let interval = setInterval(playerTimeCheck, delay);
      return () => clearInterval(interval);
    }
  }, [player, delay, prevStartTime, startTime, prevEndTime, endTime]);
}

export default useInterval;
