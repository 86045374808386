import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import RepeatIcon2 from '../RepeatIcon2';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 24,
    height: 24,
    display: 'inline-table',
  },
  text: {
    width: '100%',
    position: 'absolute',
    top: 8,
    bottom: 4,
    left: 0,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'sans-serif',
  },
}));

const iconStyle = {
  position: 'absolute',
  top: 1,
  left: 1,
};

const RepeatNumberIcon = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, props.className)}>
      <div
        className={clsx(classes.text, props.classText)}
        style={{ color: props.color, ...props.textStyle }}
      >
        {props.number || 1}
      </div>
      <RepeatIcon2
        style={{ ...iconStyle, ...props.iconStyle, color: props.color }}
        viewBox={props.viewBox}
      />
    </div>
  );
};

export default RepeatNumberIcon;
