import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'instances/radioServer';
import * as SearchPodcastType from './searchPodcast.types';
import * as SearchPodcastAction from './searchPodcast.actions';
import * as podcastActions from "store/podcast/podcast.actions";

export function* getPodcastAutocompleteSuggestions({ payload: { name } }) {
    try {
        const { data } = yield axios.get(
            // `listen/search`,
            `podcastIndex/search`,//apple
            {
                params: {
                    query: name,
                },
            }
        );
        const titleList = [];
        data.feeds.map((value, index) => {
            // titleList.push(value.title_original);
           titleList.push(value.title); //apple
        });
        yield put(SearchPodcastAction.getPodcastAutocompleteSuggestionSuccess(titleList));
    } catch (e) {
        yield put(SearchPodcastAction.getPodcastAutocompleteSuggestionFail(e));
    }
}

export function* performSearchComplete({ payload: { query }}) {
    try {
        const { data } = yield axios.get(
            // `listen/search`,
            `podcastIndex/search`,//apple
            {
                params: {
                    query: query,
                    // type,
                    // offset,
                    // only_in,
                    // language,
                },
            }
        );
        // console.log('search result: ', data);
        yield put(SearchPodcastAction.searchPodcastSuccess(data.feeds));
        yield put(podcastActions.requestGenresPodcastSuccess(data.feeds));//apple
    } catch (e) {
        yield put(SearchPodcastAction.searchPodcastFail(e));
    }
}


/**
 * watch generator functions
 */

export function* watchGetPodcastAutocompleteSuggestions() {
    yield takeLatest(
        SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_START,
        getPodcastAutocompleteSuggestions
    );
}

export function* watchPerformSearchComplete() {
    yield takeLatest(SearchPodcastType.SEARCH_PODCAST_START, performSearchComplete);
}

export function* searchPodcastSagas() {
    yield all([
        call(watchGetPodcastAutocompleteSuggestions),
        call(watchPerformSearchComplete),
    ]);
}