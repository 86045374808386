import { connect } from 'react-redux';

import {
  queueYtid,
  playPlaylistStart,
  setPlaylistYtidsStart,
  showAddPlaylistModal,
} from 'store/playlist/playlist.actions';
import { getPlaysStart } from 'store/userhistory/userhistory.actions';
import {
  enqueueToolTip,
  closeToolTip,
  hideAddToPlaylistToolTip,
  hideDeletePlaylistToolTip,
} from 'store/tooltip/tooltip.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapStateToProps = (state) => ({
  playlistState: state.playlist,
  userState: state.user,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onGetPlaysStart: (ytids) => dispatch(getPlaysStart(ytids)),
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onPlayPlaylistStart: (pid, ytid) => dispatch(playPlaylistStart(pid, ytid)),
  onSetPlaylistYtidsStart: (pid, ytids) =>
    dispatch(setPlaylistYtidsStart(pid, ytids)),
  onHideAddToPlaylistToolTip: () => dispatch(hideAddToPlaylistToolTip()),
  onHideDeletePlaylistToolTip: () => dispatch(hideDeletePlaylistToolTip()),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
