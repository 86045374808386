const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 2,
    maxHeight: '80%',
    overflow: 'scroll',

    content: {
      height: '100%',
      position: 'relative',
      top: 0,
      left: 0,
      fontSize: 14,
      // overflow: 'scroll',
      padding: '8px 16px',
    },
  },

  iconButton: {
    width: 30,
    height: 30,
    padding: 0,
    position: 'absolute',
    right: 0,
    top: 0,

    icon: {
      width: 20,
      height: 20,
      color: '#b0b0b0',
    },
  },

  background: {
    background: 'black',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    position: 'fixed',
    zIndex: 1,
  },
};

export default styles;
