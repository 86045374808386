import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Hidden,
  Slide,
  TextField
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import BackArrowIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import { Facebook, Google as GooglePlus } from "common/ui/icons";
// import ReactFacebookLogin from "react-facebook-login";
import { SignInAndUp } from "supertokens-auth-react/recipe/thirdpartypasswordless";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  loginModal: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
  modalContent: {
    color: 'rgba(37, 37, 37, 0.6)',
    fontSize: 16,
    padding: 22,
    textAlign: 'center',
    background: theme.palette.paperColor,
    zIndex: theme.zIndex.modal + 2,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  facebookBtnColor: {
    flexGrow: 1,
    marginLeft: 0,
    padding: '8px 0px',
    zIndex: 0,
    fontWeight: 400,
    borderRadius: 0,
    color: 'rgb(37, 92, 149)',
    background: theme.palette.facebookColor,
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  googleBtnColor: {
    flexGrow: 1,
    marginLeft: 0,
    padding: '8px 0px',
    zIndex: 0,
    fontWeight: 400,
    borderRadius: 0,
    color: 'rgb(241, 64, 58)',
    background: theme.palette.googleColor,
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  mailBtnColor: {
    flexGrow: 1,
    marginLeft: 0,
    padding: '8px 0px',
    zIndex: 0,
    fontWeight: 400,
    borderRadius: 0,
    color: '#fff',
    background: theme.palette.mailColor,
    '&:hover': {
      backgroundColor: 'rgba(16, 5, 11, 0.69)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  cancelBtnColor: {
    flexGrow: 1,
    marginLeft: 0,
    zIndex: 0,
    fontWeight: 400,
    height: 40,
    borderRadius: 0,
    color: theme.palette.textColor,
    background: theme.palette.cancelColor,
    /*[theme.breakpoints.down('sm')]: {
            backgroundColor: 'rgba(153, 153, 153, 0.2)',
        },*/
    '&:hover': {
      backgroundColor: theme.palette.cancelHoverColor,
    },
  },
  modalWidth: {
    width: '768px',
    marginTop: -100,
    maxWidth: '75%',
  },
  loginText: {
    color: 'rgb(255, 0, 102)',
    fontSize: 22,
    fontWeight: 400,
    fontFamily: 'Arial',
    lineHeight: 1.2,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
    },
  },
  modalMainText: {
    color: theme.palette.textColor1,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1,
    marginBottom: 20,
    fontFamily: 'Arial',
  },
  modalText: {
    color: 'grey',
    fontFamily: 'Arial',
    fontSize: 14,
  },
  label: {
    color: theme.palette.darkGray,
    fontFamily: 'Arial',
    '&$focusedLabel': {
      color: theme.palette.darkGray,
    },
    '&$erroredLabel': {
      color: '#f44336',
    },
  },
  underline: {
    '&$error:after': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottom: `2px solid rgb(255, 0, 102)`,
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  error: {},
  inputBase: {
    color: theme.palette.alternateTextColor,
    fontFamily: 'Arial',
  },
}));

const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

const SCREEN_CUSTOM_FLOW = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  RESET: 'RESET',
  OTP: "OTP",
  HOME: ''
}

const LoginModal = ({
  open,
  handleClose,
  onFbLoginStart,
  onGoogleLoginStart,
}) => {
  const theme = useTheme();
  const [isCustomLogin, setIsCustomLogin] = useState(SCREEN_CUSTOM_FLOW.HOME);
  const classes = useStyles();
  const [codeDetails, setCodeDetails] = useState();
  const [displayFacebookWarning, setDisplayFacebookWarning] = useState(false);

  const [formData, setFormData] = useState({
    email: {
      message: '',
      isValid: false,
      errorText: '',
    },
    password: {
      message: '',
      isValid: false,
      errorText: '',
    },
    otp: {
      message: null,
      isValid: false,
      errorText: '',
    },
  });

  const { email, password, otp } = formData;

  const handleFacebookClick = () => {
    onFbLoginStart();
    handleClose();
  };

  const handleGoogleClick = () => {
    onGoogleLoginStart();
    handleClose();
  };

  const onEmailTextfieldChange = (event) => {
    let message = event.target.value;
    let isValid = emailRegex.test(message);

    setFormData({
      ...formData,
      email: {
        message: message,
        isValid: isValid,
        errorText: '',
      },
    });
  };

  const onEmailTextfieldBlur = () => {
    let errorText = !email.isValid && 'Valid email is required';
    setFormData({
      ...formData,
      email: {
        errorText: errorText,
        message: email.message,
        isValid: email.isValid,
      },
    });
  };

  const onPasswordTextfieldChange = (event) => {
    let message = event.target.value;
    let isValid = message.length !== 0;

    setFormData({
      ...formData,
      password: {
        message: message,
        isValid: isValid,
        errorText: '',
      },
    });
  };

  const onPasswordTextfieldBlur = () => {
    let errorText = !password.isValid && 'Password is required';
    setFormData({
      ...formData,
      password: {
        errorText: errorText,
        message: password.message,
        isValid: password.isValid,
      },
    });
  };

  const onOTPTextfieldChange = (event) => {
    let message = event.target.value;
    let isValid = event.target.value.length !== 0;
    setFormData({
      ...formData,
      otp: {
        message: message,
        isValid: isValid,
        errorText: '',
      },
    });
  };

  const onOTPTextfieldBlur = () => {
    let errorText = !otp.isValid && 'OTP is required';
    setFormData({
      ...formData,
      otp: {
        errorText: errorText,
        message: otp.message,
        isValid: otp.isValid,
      },
    });
  };

  // const handleSignUpSignIn = async () => {
  //   if (!(email.isValid)) {
  //     return;
  //   }

  //   const codeResponse = await createPasswordlessCode({ email: email.message })

  //   if (codeResponse.status === "OK") {
  //     setCodeDetails(codeResponse);
  //     setIsCustomLogin(SCREEN_CUSTOM_FLOW.OTP);
  //   }
  // }

  /*const handleOTPSubmit = async () => {
    if (!otp.isValid) {
      return;
    }

    const otpResponse = await consumePasswordlessCode({ userInputCode: otp.message, userContext: { deviceId: codeDetails.deviceId, preAuthSessionId: codeDetails.preAuthSessionId } });
    if (otpResponse.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
      //Incorrect code
      setFormData(r => ({ ...r, otp: { isValid: false, errorText: "Invalid Code, Please try again!" } }))
    } else if (otpResponse.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
      //Expirre
      setFormData(r => ({ ...r, otp: { isValid: false, errorText: "Code expired" } }))
    } else if (otpResponse.status === "OK") {
      const user = otpResponse.user;
      if (otpResponse.createdNewUser) {
        //New User
        console.log("New User", otpResponse);
      } else {
        //Existing User
        console.log("Existing User", otpResponse);
      }
    }

  }*/

  const resendCode = async () => {

  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // keepMounted
      onClose={handleClose}
      // fullWidth
      // maxWidth={matchesMD ? 'sm' : 'md'}
      classes={{
        root: classes.loginModal,
        paper: classes.modalWidth,
      }}
    >
      {isCustomLogin !== '' && (
        <div style={{ paddingLeft: 8, paddingTop: 8, cursor: 'pointer' }} onClick={() => { setDisplayFacebookWarning(false); setIsCustomLogin(SCREEN_CUSTOM_FLOW.HOME); }}>
          <BackArrowIcon />
        </div>
      )}
      <DialogContent className={classes.modalContent} style={isCustomLogin ? { paddingTop: 0, paddingBottom: 0, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" } : {}}>
        <Typography component="h5" variant="h6" className={classes.loginText}>
          {isCustomLogin === SCREEN_CUSTOM_FLOW.SIGNUP ? 'Sign Up' : 'Login'} to ListenOnRepeat
        </Typography>
        <Typography
          component="h6"
          variant="h6"
          className={classes.modalMainText}
        >
          Never lose your saved music and access it everywhere!
        </Typography>
        {displayFacebookWarning ? <Typography
          component="h6"
          variant="h6"
          className={classes.modalMainText}
          style={{width:"75%"}}
        >
          Due to ongoing Facebook login issues, we have decided to move Facebook users to a passwordless email login system for a streamlined and more efficient user experience. As part of this transition, all your Facebook music and playlists will be replicated to your new email address. Please enter your email address now.
        </Typography> : null}
        <Hidden smUp>
          <p className={classes.modalText}>
            <span role="img" aria-label="rocket">
              🚀
            </span>
            <br />
            Save your repeats, favorites, and playlists forever
          </p>
          <p className={classes.modalText}>
            <span role="img" aria-label="earth">
              🌍
            </span>
            <br />
            Watch &amp; listen to your music from any device
          </p>
        </Hidden>
      </DialogContent>
      {isCustomLogin === SCREEN_CUSTOM_FLOW.LOGIN && <SignInAndUp />}
      {isCustomLogin === '' && (
        <DialogActions style={{ justifyContent: 'space-between', padding: 0 }}>
          {/* <div style={{ flexGrow: 1 }}>
            <ReactFacebookLogin
              appId="1169184690783381"
              autoLoad={true}background: theme.palette.facebookColor,
              }}
            />
          </div> */}
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            startIcon={<Facebook />}
            onClick={() => {
              // setDisplayFacebookWarning(true);
              // setIsCustomLogin(SCREEN_CUSTOM_FLOW.LOGIN);
              handleFacebookClick();
            }}
            className={classes.facebookBtnColor}
            style={{ marginLeft: 0 }}
          >
            <Hidden xsDown>Facebook</Hidden>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            startIcon={<GooglePlus />}
            onClick={handleGoogleClick}
            className={classes.googleBtnColor}
            style={{ marginLeft: 0 }}
          >
            <Hidden xsDown>Google</Hidden>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            startIcon={<EmailIcon />}
            onClick={() => {
              setDisplayFacebookWarning(false);
              setIsCustomLogin(SCREEN_CUSTOM_FLOW.LOGIN);
            }}
            className={classes.mailBtnColor}
            style={{ marginLeft: 0 }}
          >
            <Hidden xsDown>Email</Hidden>
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            startIcon={<CloseIcon />}
            onClick={handleClose}
            className={classes.cancelBtnColor}
            style={{ marginLeft: 0 }}
          >
            <Hidden xsDown>Cancel</Hidden>
          </Button>
        </DialogActions>
      )}

    </Dialog>
  );
};

export default LoginModal;
