import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 200,
    height: '100%',
    // overflow: 'auto',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: theme.palette.shadow,
    borderRight: theme.palette.borderRight,
    [theme.breakpoints.up('sm')]: {
      marginTop: 50
    }
  }
}));

const NavigationDrawer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const { open } = props;

  return (
    <Hidden xsDown>
      <Drawer
        variant={!matchesSM ? 'persistent' : null}
        open={open}
        anchor="left"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {props.children}
      </Drawer>
    </Hidden>
  );
};

export default NavigationDrawer;
