import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import FaqContainer from './FaqListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#eee',
    minWidth: 345,
    marginBottom: theme.spacing(1),
    paddingTop: 40,
    paddingBottom: 40,
  },
  card_root: {
    backgroundColor: theme.palette.paperColor,
    color: theme.palette.textColor,
    minWidth: 345,
    marginBottom: theme.spacing(1),
  },
  cardHeader: {
    fontFamily: 'Arial',
    backgroundColor: 'rgb(255, 0, 102)',
    color: '#ffffff',
    padding: '5px 15px 5px 15px',
    lineHeight: '20px',
  },
  cardSubHeader: {
    fontSize: 14,
  },
}));

const FaqCard = () => {
  const classes = useStyles();
  return (
    <Card className={classes.card_root}>
      {/*<CardHeader
              className={classes.faqheader}
              title="FAQ"
              subheader="Frequently Asked Questions"
          />*/}
      <div className={classes.cardHeader}>
        <div>FAQ</div>
        <div className={classes.cardSubHeader}>Frequently Asked Questions</div>
      </div>
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <FaqContainer
          panelId="panel1"
          heading="1. Why are videos not playing in Listen On Repeat ?"
        >
          <div>
            Please make sure to disable any <b>Pop-up blockers</b> and{' '}
            <b>Adblockers</b> extension in your browsers. Browser extensions
            like <b>Pop-up blockers</b> and <b>Adblockers</b> might be blocking
            you from listening to your favorite songs on LOR.
          </div>
          <div>If your problem persists, do not hesitate to Contact Us.</div>
        </FaqContainer>

        <FaqContainer
          panelId="panel2"
          heading="2. How to loop youtube videos with ListenOnRepeat?"
        >
          <ul>
            <li>
              Go to Youtube.com, play any video and in the address bar: replace
              YouTube with ListenOnRepeat.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/sa.png'
                  }
                  width={'100%'}
                  alt={'youtubeUrl'}
                />
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/Lorurl.png'
                  }
                  width={'100%'}
                  alt={'LorUrl'}
                />
              </p>
            </li>
            <li>
              Copy the YouTube address (Example:{' '}
              <a
                className={classes.lor_links}
                href={'https://listenonrepeat.com/?v=Nj2U6rhnucI'}
              >
                https://www.youtube.com/watch?v=ieJCoRs0Fqs
              </a>
              ) and Paste the YouTube address in the search box of Listen On
              Repeat website. The video will begin playing automatically, but if
              not, scroll down to the Search results section, and then select
              the video from the list.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/YoutubeURlinLOR.png'
                  }
                  width={'100%'}
                  alt={'YoutubeURlinLOR'}
                />
              </p>
            </li>
            <li>
              Search any video in Listen on Repeat by using query in Search
              bar.(Example: Enter query “ListenOnRepeat” and search. You will be
              scrolled down to the Search results section, and then select the
              video to play from the list.)
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/SearchQueryinLOR.png'
                  }
                  width={'100%'}
                  alt={'SearchQueryinLOR'}
                />
              </p>
            </li>
          </ul>
        </FaqContainer>

        <FaqContainer
          panelId="panel3"
          heading="3. How to loop certain part of youtube video ?"
        >
          <p>
            You can trim the part of video you want to loop by using slider and
            loop trimmed videos without any interruptions. For example, you can
            put the first point at 1:45 seconds of the song and the second point
            at 2:20 seconds. Now, the song will start at 1:45 and end at 2:20,
            before it starts again to be played on loop exactly like that.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/Slider.jpg'
              }
              width={'100%'}
              alt={'Slider'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel4"
          heading="4. How can I create my new playlists ?"
        >
          <p>
            Click on Add to Playlist button and enter Playlist name in New
            Playlist field and press enter. Playlists can also be created on the
            left side under “Your Music”. Note: We recommend social media signup
            to save your playlists.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/Playlist1.png'
              }
              width={'100%'}
              alt={'Playlist1'}
            />
          </p>
          <p>
            Click on Your Music in Left Navigation tab . You will be scrolled to
            Your Music where you can create a new playlist by simply typing
            Playlist name in New Playlist field and click (+) button or press
            enter.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/Playlist2.png'
              }
              width={'100%'}
              alt={'Playlist2'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel5"
          heading="5. How can I add videos to my playlists ?"
        >
          <p>
            Click on Add to Playlist button and enter Playlist name in New
            Playlist field to create a playlist or click on any of your
            playlist.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/Playlist2.png'
              }
              width={'100%'}
              alt={'Playlist2'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel6"
          heading="6. How to add videos to my favorite ?"
        >
          <p>
            You can add any of your favorite videos in the favorite list by
            clicking on Heart icon at the bottom of the video or the Heart icon
            at the top on the right side of search bar.
          </p>
          <p>
            <img
              src={'https://assets.listenonrepeat.com/static/img/faq/Heart.png'}
              width={'100%'}
              alt={'Heart'}
            />
          </p>
        </FaqContainer>

        <FaqContainer panelId="panel7" heading="7. How can I login to LOR ?">
          <p>
            You are not required to log in to use the full functionality of
            ListenOnRepeat. However, a user who has been active on
            ListenOnRepeat already will have a richer experience due to the
            personalization of our features - such as their unique weekly
            discovery playlist to discover new videos.
          </p>
          <ul>
            <li>
              Go to ListenOnRepeat website. (
              <a
                className={classes.lor_links}
                href={'https://listenonrepeat.com/?v=Nj2U6rhnucI'}
              >
                https://listenonrepeat.com
              </a>
              )
            </li>
            <li>
              In the top right, click “Login” button.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/Loginbutton.png'
                  }
                  width={'100%'}
                  alt={'Loginbutton'}
                />
              </p>
            </li>
            <li>
              Select “Google” or “Facebook”from the login screen
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/LoginSocial.png'
                  }
                  width={'100%'}
                  alt={'LoginSocial'}
                />
              </p>
            </li>
            <li>Enter valid credentials.</li>
          </ul>
          <b>
            You will now be logged in to ListenOnRepeat on the user account.
          </b>
        </FaqContainer>

        <FaqContainer panelId="panel8" heading="8. How to contact us ?">
          <p>You can contact us via the Contact Us form in LOR website.</p>
          <ul>
            <li>
              Go to url :{' '}
              <a
                className={classes.lor_links}
                href={'https://listenonrepeat.com/?v=Nj2U6rhnucI'}
              >
                www.listenonrepeat.com
              </a>
            </li>
            <li>
              Click on More in Navigation Bar (DownArrow on TopRight corner)
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/MenuDD.png'
                  }
                  width={'100%'}
                  alt={'MenuDD'}
                />
              </p>
            </li>
            <li>
              Click on the Contact Us button in Dropdown Menu.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/ContactUsButton.png'
                  }
                  width={'100%'}
                  alt={'ContactUsButton'}
                />
              </p>
            </li>
            <li>
              Enter your name, email , choose relevant Report Type, write your
              message and click the Send Message button in Contact form.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/ContactUsForm.png'
                  }
                  width={'100%'}
                  alt={'ContactUsForm'}
                />
              </p>
            </li>
          </ul>
          <b>
            Note : In order to authenticate your request, please submit any GDPR
            data requests via the Contact Us form on the ListenOnRepeat website.
            We will not be able to verify any request that are not sent via
            Contact Us form.
          </b>
        </FaqContainer>

        <FaqContainer panelId="panel9" heading="9. What is Weekly discovery ?">
          <p>
            Every Monday, the website creates an exclusive discovery stream
            based on your music taste. But for that, you need to sign up with a
            free account first and listen to songs on the website regularly,
            that’s all. After that, every Monday, you can look forward to having
            a specifically tailored playlist created by the team at
            ListenOnRepeat.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/WeeklyDicovery.png'
              }
              width={'100%'}
              alt={'WeeklyDicovery'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel10"
          heading="10. How can I know about my repeat count ?"
        >
          <p>
            Just below the video or song, you can see two logos, which if you
            hover over will show the global repeat count and your repeat count.
            In addition to the repeat count, the website will also display the
            time length to be more precise. You can also see your total repeat
            count along with total hearted videos on the right side of the
            Profile pic.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/RepeatCount.png'
              }
              width={'100%'}
              alt={'RepeatCount'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel11"
          heading="11. What are control features in ListenOnRepeat ?"
        >
          <p>
            Control features in LOR can be accessed in the top navigation bar on
            the right side of the search bar.
          </p>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/ControlFeatures.png'
              }
              width={'100%'}
              alt={'ControlFeatures'}
            />
          </p>
          <p>Control Features in LOR</p>
          <ul>
            <li>
              Heart Icon :- You can easily add video you’re watching to
              Favorites by clicking on this icon.
            </li>
            <li>
              Previous:- While playing a video from your playlist, you can
              navigate to previous songs by clicking on Previous button( |&lt;
              ).
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/pre.png'
                  }
                  width={'100%'}
                  alt={'preIcon'}
                />
              </p>
            </li>
            <li>
              Next :- You can also skip a video or move on to the next video on
              your playlist by clicking on the Next button( &gt;| ).
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/next.png'
                  }
                  width={'100%'}
                  alt={'nextIcon'}
                />
              </p>
            </li>
            <li>
              Play/Pause :- You can easily pause a video you are playing by
              clicking on the Pause button ( || ) and play a paused video by
              clicking on the Play button ( > ).
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/play.png'
                  }
                  width={'100%'}
                  alt={'playIcon'}
                />
              </p>
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/pause.png'
                  }
                  width={'100%'}
                  alt={'pauseIcon'}
                />
              </p>
            </li>
            <li>
              Enable/Disable Loop :- You can repeat a song from your playlist
              for as long as you want by clicking on Enable Loop button. You can
              also disable loop by clicking on the same button.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/Repeat.png'
                  }
                  width={'100%'}
                  alt={'RepeatIcon'}
                />
              </p>
            </li>
            <li>
              Enable/Disable shuffle :- Shuffle play is a mode of music playback
              in which songs in your playlists are played in a randomized order.
              You can enable shuffle by clicking on Enable Shuffle button and
              can also disable the shuffle by clicking on the same button.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/Shuffle.png'
                  }
                  width={'100%'}
                  alt={'ShuffleIcon'}
                />
              </p>
            </li>
            <li>
              Show Play Queue :- You can see your playlist by clicking on this
              button.
              <p>
                <img
                  src={
                    'https://assets.listenonrepeat.com/static/img/faq/queue.png'
                  }
                  width={'100%'}
                  alt={'queueIcon'}
                />
              </p>
            </li>
          </ul>
        </FaqContainer>

        <FaqContainer
          panelId="panel12"
          heading="12. How can I find the top trending videos in ListenOnRepeat ?"
        >
          <p>
            ListenOnRepeat also offers a list of 10 videos trending on the
            website every day. That means, every day, you will receive fresh,
            new content that is trending on the internet right now. You can
            access the Top 10 trending list by clicking on Top 10 button in the
            Left Sidebar.
          </p>
          <b>
            Note: Click on Hamburger Menu in top-left to open Left Sidebar if it
            is not displayed.
          </b>
          <p>
            <img
              src={'https://assets.listenonrepeat.com/static/img/faq/top10.png'}
              width={'100%'}
              alt={'top10'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel13"
          heading="13. How can I check my play history ?"
        >
          <p>
            Often it happens that you forget the songs that you were listening
            to sometimes ago. For such contingencies, there is a History option
            on the sidebar, from where you can access songs or videos you were
            listening to previously.
          </p>
          <b>
            Note: Click on Hamburger Menu in top-left to open Left Sidebar if it
            is not displayed.
          </b>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/History.png'
              }
              width={'100%'}
              alt={'History'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel14"
          heading="14. Can I watch live videos in ListenOnRepeat ?"
        >
          <p>
            Yes, Live Streams can be accessed through the sidebar on the right
            side of the screen. Once you click on Streams button, various cool
            live streams that are currently being played on YouTube will appear
            on your screen. After you select one of the songs, it will start
            playing right away.
          </p>
          <b>
            Note: Click on Hamburger Menu in top-left to open Left Sidebar if it
            is not displayed.
          </b>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/streams.png'
              }
              width={'100%'}
              alt={'streams'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel15"
          heading="15. How can I read the reviews of the songs ?"
        >
          <p>
            You can read reviews of your favorite songs by clicking on “Our
            Reviews”. You can click on any reviews from the general ‘All’
            section or even the exclusive ‘Staff Picks’ section if you want
            extra curated reviews. Often updated regularly, the Reviews section
            indeed has a ton of reviews if you have extra time in your hand and
            would love to do some exploring.
          </p>
          <b>
            Note: Click on Hamburger Menu in top-left to open Left Sidebar if it
            is not displayed.
          </b>
          <p>
            <img
              src={
                'https://assets.listenonrepeat.com/static/img/faq/Reviews.png'
              }
              width={'100%'}
              alt={'Reviews'}
            />
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel16"
          heading="16. Is there an ad free subscription option ?"
        >
          <p>
            Yes, we have an ad-free subscription option for ListenOnRepeat
            users. The benefits include ad free experience and faster
            performance.
          </p>
          <p>
            To activate an ad free subscription, please sign up to our{' '}
            <a href={'https://subscription.listenonrepeat.com/signup/offer'}>
              Subscription feature
            </a>
            .
          </p>
          <p>
            For further info please visit :{' '}
            <a href={'https://subscription.listenonrepeat.com/faq'}>
              Subscription FAQ
            </a>
          </p>
        </FaqContainer>

        <FaqContainer
          panelId="panel17"
          heading="17. How can I increase performance at the site ?"
        >
          <p>
            At times, ListenOnRepeat performance may be degraded due to your
            browser or computer resources. Please try to clear your browser’s
            cache if you experience lag or performance degradation. We have
            provided instructions for Chrome, for other browsers, please check
            via a google search.
          </p>
          <p>
            <b>Chrome</b>: At the top right (3 bars), click on the dropdown More
            Tools > Clear Browsing Data. At the top, choose a Time range. To
            delete everything, select All time. Please note that this option
            will clear your cookies and other site data which will require you
            to login again. If you do not wish to login again, uncheck Cookies
            and other site data.
          </p>
        </FaqContainer>
      </CardContent>
    </Card>
  );
};

export default FaqCard;
