import { connect } from 'react-redux';

import { setAutoplay, viewPlayerVideoStart } from 'store/player/player.actions';
import {
  setCurrentPlaylistStart,
  queueYtid,
  showAddPlaylistModal,
} from 'store/playlist/playlist.actions';
import {
  deletePlayStart,
  getPlaysPageStart,
  getPlaysByRepeatsPageStart,
} from 'store/userhistory/userhistory.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';
import {
  enqueueToolTip,
  closeToolTip,
  hideDeletePlaylistToolTip,
} from 'store/tooltip/tooltip.actions';

const mapStateToProps = (state) => ({
  userhistoryState: state.userhistory,
  videoState: state.video,
  playerState: state.player,
  playlistState: state.playlist,
  userState: state.user,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onDeletePlayStart: (ytid) => dispatch(deletePlayStart(ytid)),
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onSetCurrentPlaylistStart: (value) =>
    dispatch(setCurrentPlaylistStart(value)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onHideDeletePlaylistToolTip: (key) =>
    dispatch(hideDeletePlaylistToolTip(key)),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
  onGetPlaysByRepeatsPageStart: (next) =>
    dispatch(getPlaysByRepeatsPageStart(next)),
  onGetPlaysPageStart: (next, merge) =>
    dispatch(getPlaysPageStart(next, merge)),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
