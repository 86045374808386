import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import ToolTipKeys from 'constants/ToolTipKeys';
import HistoryFeed from 'modules/playlist/components/PlaylistView';
import VideoListItem from 'modules/video/components/VideoListItem';

import * as UserhistoryType from 'store/userhistory/userhistory.types';
import SectionTab from 'constants/SectionTab';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';
import { HistoryDeleteToolTip } from 'modules/userhistory/tooltip';

import { scrollToVideo, scrollToMainContentArea } from 'common/ui/utils';
import container from './YourHistory.container';

const useStyles = makeStyles((theme) => ({
  featureCard: {
    position: 'relative',
    marginBottom: 20,
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
    fontFamily: theme.typography.fontFamily,
  },
  highlight: {
    color: '#ff0066',
  },
  title: {
    fontFamily: 'Arial',
    color: '#ff0066',
    padding: 10,
    paddingLeft: 20,
    pointerEvents: 'none',
    fontSize: 16,
  },
  explain: {
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '10pt',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const YourHistory = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = React.useState(false);
  const {
    userhistoryState,
    videoState,
    playerState: { ytid: currentPlayerYtid },
    playlistState,
    userState,
    tooltipState,
    onSetCurrentTab,
    onViewPlayerVideoStart,
    onSetCurrentPlaylistStart,
    onShowSnackbarNotification,
    onHideDeletePlaylistToolTip,
    onEnqueueToolTip,
    onCloseToolTip,
    onShowAddPlaylistModal,
  } = props;

  const onHistorySelect = (ytid = null) => {
    onSetCurrentTab(SectionTab.History);
    // analytics.actions.trackUIClick('Your LOR', 'History');
    if (ytid) {
      onSetCurrentPlaylistStart(UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS);
      onViewPlayerVideoStart(ytid);
      scrollToMainContentArea();
      // analytics.actions.trackUIClick('Repeat Feed', ytid);
    }
  };

  useEffect(() => {
    return () => {
      setOpen(false);
      onCloseToolTip();
    };
  }, []);

  const onAddToPlaylistClick = (evt, ytid) => {
    var element = evt.currentTarget;

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }

    toggleAddToPlaylistToolTip(ytid, element);

    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip(ToolTipKeys.DeletePlaylistToolTip);
    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const historyItems = userhistoryState.historyYtids
    .slice(0, 5)
    .map((ytid) => userhistoryState.history[ytid])
    .filter((x) => x);

  return (
    <Card className={classes.featureCard}>
      <div className={classes.title}>History</div>
      <Divider />
      <HistoryFeed
        items={historyItems}
        next={''}
        elementFactory={(play, i) => {
          return (
            <div key={play.ytid + '-' + i}>
              <VideoListItem
                ytid={play.ytid}
                isPlaying={play.ytid === currentPlayerYtid}
                history={play}
                metadata={props.videoState.videos[play.ytid]}
                globalStats={props.videoState.videoStats[play.ytid]}
                onClick={onHistorySelect}
                onAddToPlaylistClick={onAddToPlaylistClick}
                showControls={true}
                showMobileAuthor={false}
                showMobileGlobalRepeats={false}
                showMobileGlobalHearts={false}
                showMobileUserRepeats={false}
                showMobileDuration={false}
                showMobileLastViewed={false}
              />
            </div>
          );
        }}
      />
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
      <div
        className={clsx('hover', classes.explain)}
        onClick={(_) => onHistorySelect()}
      >
        <div style={{ fontSize: 15, fontFamily: 'Arial' }}>
          <span className={classes.highlight}>Click here </span>
          to see every video you've played on LOR.
        </div>
      </div>
    </Card>
  );
};

export default container(YourHistory);
