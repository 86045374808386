import _ from 'lodash';

import * as YourLorType from './yourlor.types';

const INITIAL_STATE = {
  discovery: {
    created: false,
    createGeneration: 0,
    createFailure: false,
    eligible: true,
    nextArtist: '',
    artists: [],
    selectedArtists: {},
    ineligbleArtists: [],
    possibleYtids: [],
    ytids: [],
    oldYtids: [],
  },
};

const yourLorReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let updatedState;

  switch (type) {
    case YourLorType.INIT_DISCOVERY:
      updatedState = {
        ...state,
      };
      updatedState.discovery.createStartTime = new Date().getTime();
      return updatedState;

    case YourLorType.RESET_DISCOVERY:
      return INITIAL_STATE;

    case YourLorType.INELIGBLE_DISCOVERY:
      updatedState = {
        ...state,
      };
      updatedState.discovery.eligible = false;
      return updatedState;

    case YourLorType.SET_DISCOVERY:
      updatedState = {
        ...state,
      };
      updatedState.discovery.created = true;
      updatedState.discovery.ytids = [...payload.ytids];

      return updatedState;

    case YourLorType.SET_DISCOVERY_EXPIRES:
      updatedState = {
        ...state,
      };
      updatedState.discovery.expires = payload.expires;

      return updatedState;

    case YourLorType.SET_OLD_DISCOVERY:
      updatedState = {
        ...state,
      };
      updatedState.discovery.oldYtids = payload.ytids;

      return updatedState;

    case YourLorType.ADD_NEW_DISCOVERY:
      updatedState = {
        ...state,
      };

      updatedState.discovery.possibleYtids.push(payload.ytid);

      if (payload.artistName) {
        if (updatedState.discovery.selectedArtists[payload.artistName]) {
          updatedState.discovery.selectedArtists[payload.artistName] += 1;
        } else {
          updatedState.discovery.selectedArtists[payload.artistName] = 1;
        }
      }

      return updatedState;

    case YourLorType.VALIDATE_YTIDS:
      updatedState = {
        ...state,
      };
      updatedState.discovery.possibleYtids = [];
      updatedState.discovery.ytids = [
        ...updatedState.discovery.ytids,
        ...payload.ytids,
      ];

      return updatedState;

    case YourLorType.VALIDATE_YTIDS_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.discovery.ytids = _.shuffle(
        updatedState.discovery.ytids
      ).slice(0, YourLorType.DISCOVERY_LENGTH);
      updatedState.discovery.created = true;

      return updatedState;

    case YourLorType.SET_ARTISTS_DISCOVER:
      updatedState = {
        ...state,
      };
      updatedState.discovery.artists = payload.artists;
      if (updatedState.discovery.artists.length > 0) {
        updatedState.discovery.nextArtist = updatedState.discovery.artists[0];
      }

      return updatedState;

    case YourLorType.SET_NEXT_ARTIST_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.discovery.nextArtist = payload.artist;
      updatedState.discovery.createGeneration =
        updatedState.discovery.createGeneration + 1;

      return updatedState;

    case YourLorType.REMOVE_DISCOVERY_SUCCESS:
      updatedState = {
        ...state,
      };
      const newRemoveData = updatedState.discovery.ytids.filter(
        (ytid) => ytid !== payload.ytid
      );
      updatedState.discovery.ytids = newRemoveData;
      return updatedState;

    case YourLorType.REMOVE_DISCOVERY_ARTIST:
      updatedState = {
        ...state,
      };
      updatedState.discovery.artists = updatedState.discovery.artists.filter(
        (artist) => artist !== payload.artist
      );
      if (updatedState.discovery.artists.length === 0) {
        updatedState.discovery.nextArtist = null;
      }
      return updatedState;

    case YourLorType.SET_INELIGBLE_ARTIST:
      updatedState = {
        ...state,
      };
      updatedState.discovery.ineligbleArtists.push(payload.artist);
      return updatedState;

    case YourLorType.DISCOVERY_FAILURE:
      updatedState = {
        ...state,
      };

      updatedState.discovery.createFailure = true;

      return updatedState;

    default:
      return state;
  }
};

export default yourLorReducer;
