import { takeLatest, call, put, all, select } from "redux-saga/effects";
import _ from "lodash";
import * as cookie from "component-cookie";

import { setAutoplay, setRepeatMode } from "store/player/player.actions";
import {
  setCurrentTab,
  scrollToMainContentOnLoad,
} from "store/siteCoordinator/siteCoordinator.actions";
import {
  getStreamListStart,
  setCurrentStreamStart,
} from "store/streams/streams.actions";
import { playFeature } from "store/player/player.actions";
import {
  openPlaylistStart,
  playPlaylistStart,
} from "store/playlist/playlist.actions";
import { getUserPlaylistAsync } from "store/playlist/playlist.sagas";

import Feature from "constants/Feature";
import SectionTab from "constants/SectionTab";
import axios from "../../instances/server";

const getUserhistory = (state) => state.userhistory;

export function* route({ payload: { route, video } }) {
  // console.log('route saga....');
  let parts = !_.isNil(route) ? route.split("/") : [];

  const mobile = window.isMobileDevice();

  // console.log('route...', { route, parts });

  let page;
  if (video) {
    // console.log('condn 1...');
    yield call(routeDefault, mobile);
  } else if (!route || !parts.length) {
    // console.log('cond 2...');
    // when no route navigate to last used feature
    page = yield call(getLastUsedFeature, mobile);
    parts = [];
  } else {
    // console.log('else condn...');
    // remove first arg so we can pass the rest to the route functions
    page = parts.shift();
  }

  // console.log('page::', page);

  yield put(playFeature(Feature.History));

  let didNavigate;
  switch (page) {
    case Feature.Playlist:
      didNavigate = yield call(routePlaylist, parts);
      break;
    case Feature.Search:
      didNavigate = yield call(routeSearch, parts);
      break;
    case Feature.History:
      didNavigate = yield call(routeHistory);
      break;
    case Feature.Favorites:
      didNavigate = yield call(routeFavorites);
      break;
    case Feature.Profile:
      didNavigate = yield call(routeProfile);
      break;
    case Feature.Stream:
      didNavigate = yield call(routeStream, parts);
      break;
    case Feature.Radio:
      didNavigate = yield call(routeRadio, parts);
      break;
    case Feature.Reviews:
      didNavigate = yield call(routeReviews);
      break;
    case Feature.YourLOR:
      didNavigate = yield call(routeYourLOR);
      break;
    default:
      break;
  }

  // default to the history tab
  if (!didNavigate) {
    yield call(routeDefault, mobile);
  }
  try {
    yield axios
      .get("https://admin.listenonrepeat.com/api/ads")
      .then((response) => {
        window.ads = response.data;
      });
  } catch (err) {
    console.log(err);
  }
  try {
    yield axios.post("/ads/geo").then((response) => {
      window.country = response.data;
    });
  } catch (err) {
    console.log(err);
  }

  // console.log('donedone country');
}

function* routeDefault(mobile) {
  // console.log('default route...');
  const userhistoryState = yield select(getUserhistory);
  if (
    userhistoryState.historyYtids &&
    userhistoryState.historyYtids.length > 0
  ) {
    yield put(setCurrentTab(SectionTab.YourLOR));
    return;
  }

  // if (mobile) {
  //   yield put(setCurrentTab(SectionTab.Playlists));
  //   return;
  // }

  yield put(setCurrentTab(SectionTab.Reviews));
}

function* getLastUsedFeature(mobile) {
  let setFeature = yield cookie("last-used-feature-v2");
  // console.log('last feature>>>', setFeature);
  if (setFeature) {
    // analytics.actions.trackAction('Returning Feature', setFeature);
    window._gaq.push([
      "_trackEvent",
      "Action",
      "Returning Feature",
      setFeature,
    ]);
  } else {
    if (mobile) {
      return Feature.Playlist;
    }

    return Feature.Reviews;
  }

  // Route search, history, radio to your lor if possible
  if (
    setFeature === Feature.Search ||
    setFeature === Feature.History ||
    setFeature === Feature.Radio ||
    setFeature === Feature.Stream
  ) {
    setFeature = Feature.YourLOR;
  }

  return setFeature;
}

function* routePlaylist(parts) {
  console.log("routePlaylist...");
  yield put(scrollToMainContentOnLoad());

  if (parts.length === 0) {
    yield put(setCurrentTab(SectionTab.Playlists));
    return true;
  }

  const pid = parts[0];
  const payload = {
    payload: {
      pids: [pid],
    },
  };
  yield call(getUserPlaylistAsync, payload);
  yield put(openPlaylistStart(pid));
  yield put(playPlaylistStart(pid));

  // analytics.actions.trackAction('Visiting Playlist', pid);
  window._gaq.push(["_trackEvent", "Action", "Visiting Playlist", pid]);

  return true;
}

function* routeSearch(parts) {
  console.log("routeSearch...");
  if (parts.length === 0) {
    return false;
  }

  yield put(scrollToMainContentOnLoad());

  // yield call(search.actions.performSearch, parts[0]);
  yield put(setCurrentTab(SectionTab.Search));

  return true;
}

function* routeHistory() {
  console.log("routeHistory...");
  yield put(setCurrentTab(SectionTab.History));
  yield put(scrollToMainContentOnLoad());
  return true;
}

function* routeFavorites() {
  console.log("routeFavorites...");
  yield put(setCurrentTab(SectionTab.Favorites));
  yield put(scrollToMainContentOnLoad());
  return true;
}

function* routeProfile() {
  console.log("routeProfile...");
  yield put(setCurrentTab(SectionTab.Profile));
  yield put(scrollToMainContentOnLoad());

  return true;
}

function* routeReviews() {
  console.log("routeReviews...");
  yield put(setCurrentTab(SectionTab.Reviews));
  yield put(scrollToMainContentOnLoad());
  return true;
}

function* routeYourLOR() {
  console.log("routeYourLOR...");
  yield put(setCurrentTab(SectionTab.YourLOR));
  yield put(scrollToMainContentOnLoad());
  return true;
}

function* routeRadio(parts) {
  console.log("routeRadio...");
  if (parts.length === 0) {
    yield put(scrollToMainContentOnLoad());
    return false;
  }

  yield put(setCurrentTab(SectionTab.Radio));
  yield put(setAutoplay(true));
  yield put(setRepeatMode(false));

  const artistName = parts[0];
  // analytics.actions.trackAction('Artist Radio Direct', artistName);
  window._gaq.push([
    "_trackEvent",
    "Action",
    "Artist Radio Direct",
    artistName,
  ]);

  // yield call(artist.actions.setupArtistRadio, artistName);
  // yield put(player.actions.playFeature(Feature.Radio));

  return true;
}

function* routeStream(parts) {
  console.log("routeStream...");
  yield put(setCurrentTab(SectionTab.Streams));
  if (parts.length === 0) {
    return true;
  }

  const stream = parts[0];
  // yield put(getStreamInit(stream));
  yield put(setCurrentStreamStart(stream));
  yield put(getStreamListStart());

  // analytics.actions.trackAction('Stream Direct', stream);
  window._gaq.push(["_trackEvent", "Action", "Stream Direct", stream]);

  return true;
}

export function* watchInitializeStore() {
  yield takeLatest("INIT_ROUTE", route);
}

export function* routeSagas() {
  yield all([call(watchInitializeStore)]);
}
