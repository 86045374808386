import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import container from './HeaderRight.container';
import LoginStatus from 'constants/LoginStatus';
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import {HeaderUserIcon, HeaderMenu} from "layouts/Main/components/Topbar/components/HeaderRight/components";

const useStyles = makeStyles((theme) => ({
    headerRight: {
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 13,
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 21,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 7,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 3,
            marginLeft: 278,
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: 1,
            marginLeft: 278,
        },
    },
    loginButton: {
        color: theme.palette.white,
        fontSize: 14,
        fontWeight: 400,
        fontFamily: 'Arial',
        letterSpacing: 0,
        border: '10px',
        boxSizing: 'border-box',
        display: 'inline-block',
        cursor: 'pointer',
        textDecoration: 'none',
        margin: '0px -50px 0px 0px',
        padding: '0px 50px 0px 0px',
        outline: 'none',
        position: 'relative',
        height: '50px',
        lineHeight: '36px',
        minWidth: '88px',
        borderRadius: '2px',
        userSelect: 'none',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        textAlign: 'center',
        '&:hover': {
            background: 'rgba(153, 153, 153, 0.2)',
        },
    },
}));

const HeaderRight = (props) => {
    const classes = useStyles();
    const {
        userState: { loginStatus, firstName, avatarUrl },
        onShowLoginModal,
    } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const isLoggedIn = loginStatus === LoginStatus.Authenticated;

    const onLoginTap = () => {
        if (isLoggedIn) {
            // TODO: Public profile page
            // router.route('profile')
        } else {
            onShowLoginModal();
        }
    };

    return(
        <div className={classes.headerRight}>
            {!isLoggedIn && (
                <Button classes={{ root: classes.loginButton }} onClick={onLoginTap}>
                    <span style={{ padding: '0px 16px 0px 16px' }}>Login</span>
                </Button>
            )}
            <HeaderUserIcon
                isLoggedIn={isLoggedIn}
                pictureUrl={avatarUrl}
                firstName={firstName}
                onLoginTap={onLoginTap}
            />
            <Hidden xsDown>
                <Tooltip title="More">
                    <IconButton
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        style={{
                            marginRight: 4,
                            marginTop: -2,
                        }}
                    >
                        <KeyboardArrowDownIcon style={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <HeaderMenu
                                open={open}
                                handleClose={handleClose}
                                isLoggedIn={isLoggedIn}
                            />
                        </Grow>
                    )}
                </Popper>
            </Hidden>
        </div>
    );
};

export default container(HeaderRight);