import * as TooltipType from './tooltip.types';
import ToolTipKeys from 'constants/ToolTipKeys';

export const hideAddToPlaylistToolTip = () => ({
  type: TooltipType.HIDE_ADD_PLAYLIST_TOOLTIP,
  payload: ToolTipKeys.AddToPlaylistToolTip,
});

export const hideDeletePlaylistToolTip = () => ({
  type: TooltipType.HIDE_DELETE_PLAYLIST_TOOLTIP,
  payload: ToolTipKeys.DeletePlaylistToolTip,
});

export const changeToolTip = () => ({
  type: TooltipType.CHANGE_TOOLTIP,
});

export const enqueueToolTip = (key, element, ytid) => ({
  type: TooltipType.ENQUEUE_TOOLTIP,
  payload: { key, element, ytid },
});

export const enqueueRadioToolTip = (key, element, stationid) => ({
  type: TooltipType.ENQUEUE_RADIO_TOOLTIP,
  payload: { key, element, stationid },
});

export const enqueuePodcastToolTop = (key, element, podcastid) => ({
  type: TooltipType.ENQUE_POD_CAST_TOOLTIP,
  payload: { key, element, podcastid }
});

export const enqueueToolTipSuccess = (data) => ({
  type: TooltipType.ENQUEUE_TOOLTIP_SUCCESS,
  payload: data,
});

export const closeToolTip = () => ({
  type: TooltipType.CLOSE_TOOLTIP,
});
