import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase/InputBase';
import TextField from '@material-ui/core/TextField';

import PlaylistsBox from 'assets/images/playlists-box.png';

import container from './AddPlaylistOnboard.container';
import styles from './AddPlaylistOnboard.styles';

const useStyles = makeStyles((theme) => ({
  description: {
    ...styles.description,
  },
  image: {
    ...styles.image,
  },
  gettingStarted: {
    ...styles.gettingStarted,
  },
  textfield: {
    ...styles.textfield,
    borderColor: theme.palette.borderColor,
  },
  input: {
    color: theme.palette.textColor,
  },
  label: {
    color: theme.palette.darkGray,
    fontFamily: 'Arial',
  },
  // underline: {
  //   "&$error:after": {
  //     borderBottomColor: "#f44336"
  //   },
  //   "&:after": {
  //     borderBottom: `2px solid rgb(255, 0, 102)`
  //   }
  // },
  inputBase: {
    fontSize: 12,
    color: theme.palette.alternateTextColor,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Arial',
  },
}));

const AddPlaylistOnboard = (props) => {
  const classes = useStyles();

  const {
    onCreatePlaylistAddSongStart,
    onShowSnackbarNotification,
    onCloseToolTip,
  } = props;

  const [newPlaylist, setNewPlaylist] = useState({
    value: '',
    isValid: false,
    errorText: '',
  });

  const onPlaylistNameChange = (event) => {
    let isValid =
      event.target.value.length > 0 && event.target.value.length < 50;
    setNewPlaylist({
      value: event.target.value,
      isValid: isValid,
      errorText: '',
    });
  };

  const onPlaylistNameBlur = (event) => {
    let isValid =
      event.target.value.length > 0 && event.target.value.length < 50;
    setNewPlaylist({
      errorText: !isValid && 'Invalid Name',
      value: event.target.value,
      isValid: isValid,
    });
  };

  const onNewPlaylistKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    let newName = newPlaylist.value;

    let isValid =
      event.target.value.length > 0 && event.target.value.length < 50;
    if (!isValid) {
      onShowSnackbarNotification(`Invalid playlist name`);
      return;
    }

    let pid = onCreatePlaylistAddSongStart(newName, [props.ytid]);

    onShowSnackbarNotification(`Created playlist ${newName} 🎉`);

    setNewPlaylist({
      newPlaylist: {
        value: '',
        isValid: false,
        errorText: '',
      },
    });

    onCloseToolTip();
  };

  return (
    <section className="playlist-onboarding-tooltip-section">
      <p className={classes.description}>
        Now collect your favorite repeats for rainy days, yoga sessions or crazy
        nights out using playlists!
      </p>

      <img
        className={classes.image}
        height="70"
        src={PlaylistsBox}
        alt="playlists-box"
      />

      <p className={classes.gettingStarted}>
        Get started by making your first playlist
      </p>

      <Divider />
      <TextField
        className={classes.textfield}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          classes: {
            input: classes.inputBase,
            // root: classes.underline,
          },
        }}
        // autoFocus
        placeholder="New Playlist +"
        onChange={onPlaylistNameChange}
        onBlur={onPlaylistNameBlur}
        onKeyDown={onNewPlaylistKeyDown}
      />
    </section>
  );
};

AddPlaylistOnboard.propTypes = {
  ytid: PropTypes.string,
};

export default container(AddPlaylistOnboard);
