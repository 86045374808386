import { all, call, delay, put, take, takeLatest } from 'redux-saga/effects';

import { scrollToMainContentArea } from 'common/ui/utils';

import {
  ACTIVATE_LOADING,
  SCROLL_TO_MAIN_CONTENT_ONLOAD,
} from './siteCoordinator.types';
import { deactivateLoading } from './siteCoordinator.actions';

export function* activateLoadingAsync() {
  yield delay(2000);
  yield put(deactivateLoading());
}

export function* scrollToMainContentSaga() {
  console.log('scrollToMainContentSaga...');
  // Once ads init scoll to content (to avoid offset) if desired
  yield delay(1000);
  scrollToMainContentArea();
}

export function* watchActivateLoading() {
  yield takeLatest(ACTIVATE_LOADING, activateLoadingAsync);
}

export function* siteCoordinatorSagas() {
  yield all([call(watchActivateLoading)]);
}
