export const FETCH_INITIAL_START = 'FETCH_INITIAL_START';
export const FETCH_INITIAL_SUCCESS = 'FETCH_INITIAL_SUCCESS';
export const FETCH_INITIAL_FAIL = 'FETCH_INITIAL_FAIL';

export const GET_GENRES_START = 'GET_GENRES_START';
export const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS';
export const GET_GENRES_FAIL = 'GET_GENRES_FAIL';


export const REQUEST_GENRES_PODCAST_START = 'REQUEST_GENRES_PODCAST_START';
export const REQUEST_GENRES_PODCAST_SUCCESS = 'REQUEST_GENRES_PODCAST_SUCCESS';
export const REQUEST_GENRES_PODCAST_FAIL = 'REQUEST_GENRES_PODCAST_FAIL';

export const REQUEST_APPLE_PODCAST_EPISODE_START = 'REQUEST_APPLE_PODCAST_EPISODE_START';
export const REQUEST_APPLE_PODCAST_EPISODE_FAIL = 'REQUEST_APPLE_PODCAST_EPISODE_FAIL';

export const REQUEST_PODCAST_EPISODE_START = 'REQUEST_PODCAST_EPISODE_START';
export const REQUEST_PODCAST_EPISODE_FAIL = 'REQUEST_PODCAST_EPISODE_FAIL';

export const REQUEST_NEXT_PODCAST_EPISODE = 'REQUEST_NEXT_PODCAST_EPISODE';
export const REQUEST_NEXT_PODCAST_EPISODE_FAIL = 'REQUEST_NEXT_PODCAST_EPISODE_FAIL';

export const SET_PLAYER_PLAYLIST = 'SET_PLAYER_PLAYLIST';
export const SET_POD_CAST_EPISODE_CLICK = 'SET_POD_CAST_EPISODE_CLICK';


// --------------------------------------------


export const SET_CURRENT_GENRES = 'SET_CURRENT_GENRES';
export const SET_PODCAST_CURRENT_PAGE = 'SET_PODCAST_CURRENT_PAGE';

export const REQUEST_GENRES_POD_CAST_START = 'REQUEST_GENRES_POD_CAST_START';
export const REQUEST_GENRES_POD_CAST_SUCCESS = 'REQUEST_GENRES_POD_CAST_SUCCESS';
export const REQUEST_GENRES_POD_CAST_FAIL = 'REQUEST_GENRES_POD_CAST_FAIL';

export const FETCH_POD_CAST_EPISODE_START = 'FETCH_POD_CAST_EPISODE_START';
export const FETCH_POD_CAST_EPISODE_SUCCESS = 'FETCH_POD_CAST_EPISODE_SUCCESS';
export const FETCH_POD_CAST_EPISODE_FAIL = 'FETCH_POD_CAST_EPISODE_FAIL';
export const PODCAST_EPISODE_DATA_EXISTS = 'PODCAST_EPISODE_DATA_EXISTS';

export const FETCH_POD_CAST_GENRES_START = 'FETCH_POD_CAST_GENRES_START';
export const FETCH_POD_CAST_GENRES_SUCCESS = 'FETCH_POD_CAST_GENRES_SUCCESS';
export const FETCH_POD_CAST_GENRES_FAIL = 'FETCH_POD_CAST_GENRES_FAIL';

export const FETCH_POD_CAST_LIST_START = 'FETCH_POD_CAST_LIST_START';
export const FETCH_POD_CAST_LIST_SUCCESS = 'FETCH_POD_CAST_LIST_SUCCESS';
export const FETCH_POD_CAST_LIST_FAIL = 'FETCH_POD_CAST_LIST_FAIL';

export const FETCH_EPISODE_LIST_START = 'FETCH_EPISODE_LIST_START';
export const FETCH_EPISODE_LIST_SUCCESS = 'FETCH_EPISODE_LIST_SUCCESS';
export const FETCH_EPISODE_LIST_FAIL = 'FETCH_EPISODE_LIST_FAIL';

export const SET_CURRENT_VIEW_PODCAST = 'SET_CURRENT_VIEW_PODCAST';
export const SET_CURRENT_VIEW_PODCAST_SUCCESS = 'SET_CURRENT_VIEW_PODCAST_SUCCESS';

export const RATING_START = 'RATING_START';

// move to podcast history types
export const ADD_PLAY_POD_CAST_START = 'ADD_PLAY_POD_CAST_START';
