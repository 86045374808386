import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';

import { StreamSkeleton } from 'common/ui/skeleton-ui';
import { StreamsGrid } from './components';
import container from './StreamsCard.container';

const useStyles = makeStyles((theme) => ({
  liveTitle: {
    paddingLeft: 2,
    display: 'flex',
    alignItems: 'center',
  },
  '@keyframes pulse_animation': {
    '0%': {
      transform: 'scale(1)',
    },
    '30%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '80%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  liveIcon: {
    animationName: '$pulse_animation',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    backgroundColor: '#ff0066',
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  text: {
    color: '#fff',
    textShadow: '0 0 2px rgba(0, 0, 0, .5)',
    display: 'inline-block',
    paddingLeft: 10,
  },
  textMobile: {
    fontSize: 16,
    lineHeight: '20px',
    paddingLeft: 10,
    color: theme.palette.textColor,
    fontFamily: 'Arial',
  },
  gridCard: {
    boxShadow: 'none',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderRadius: 0,
    borderRightColor: theme.palette.borderColor,
    borderBottomColor: theme.palette.borderColor,
    [theme.breakpoints.down('xs')]: {
      height: 80,
    }
  },
  rootBody: {
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
    marginBottom: 15,
  },
  cardHeader: {
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.textColor0,
    fontFamily: theme.typography.fontFamily,
    padding: '6px 15px 5px',
    lineHeight: '28px',
    position: 'relative',
    border: '1px solid',
    borderBottomColor: theme.palette.borderColor,
    borderTopColor: theme.palette.borderColor,
    borderRightColor: theme.palette.borderColor,
    borderLeftColor: theme.palette.borderColor,
  },
  articleDetail: {
    color: theme.palette.textColor1,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '17px',
    '& h1': {
      lineHeight: '30px',
    },
  },
}));

const StreamsCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { streamsState } = props;

  const noHeaderTitle = () => {
    return (
      <div
        className={classes.liveTitle}
        style={{
          padding: 10,
          paddingLeft: 10,
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <div
          className={clsx('player-streams-footer-live-icon', classes.liveIcon)}
        />
        <div className={classes.textMobile}>Live Streams</div>
      </div>
    );
  };

  const displayStreamList = (num) => {
    if (streamsState.loading && streamsState.streams) {
      return [...Array(num)].map((_, idx) => {
        return <StreamSkeleton key={idx} />;
      });
    }
    return (
      <CardContent style={{ padding: 0 }}>
        <StreamsGrid
          classesCard={classes.gridCard}
          classesCardMobile={classes.gridCard}
          streamsState={streamsState}
        />
      </CardContent>
    );
  };

  let currentStream = null;
  if (
    streamsState.streamPlaying &&
    streamsState.streams[streamsState.streamPlaying]
  ) {
    currentStream = streamsState.streams[streamsState.streamPlaying];
  }

  return (
    <>
      {currentStream && currentStream.description && (
        <Card className={classes.rootBody}>
          <div className={classes.cardHeader}>{currentStream.name}</div>
          {/*<CardHeader title={currentStream.name} />*/}
          {/*<Divider />*/}
          <CardContent
            classes={{
              root: classes.articleDetail,
            }}
            dangerouslySetInnerHTML={{ __html: currentStream.description }}
          />
        </Card>
      )}
      <Card style={{ background: theme.palette.paperColor }}>
        <div>{noHeaderTitle()}</div>
        <Hidden xsDown>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {displayStreamList(3)}
          </div>
        </Hidden>
        <Hidden smUp>{displayStreamList(1)}</Hidden>
      </Card>
    </>
  );
};

export default container(StreamsCard);
