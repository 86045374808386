import { createMuiTheme } from '@material-ui/core';

import overrides from './overrides';

const white = '#FFFFFF';
const black = '#000000';
const featherGray = '#e5e5e5';
const lightGray = '#616161';
const lightSlateGray = 'rgb(97, 97, 97)';
const darkGray = '#A9A9A9';
const gray = '#2b2b2b';
const eclipse = '#404040';
const eclipseGray = 'rgb(52, 52, 52)';
const pink = '#FF0066'; //rgb(255, 0, 102)
const darkPink = 'rgba(255,0,102,.8)';
const borderGray = 'rgb(238, 238, 238)';
const outerCircle = '#FFFFFF';

const theme = createMuiTheme({
  palette: {
    common: {
      blue: '#3C59C3',
    },
    black,
    white,
    pink,
    lightGray,
    darkGray,
    lightSlateGray,
    gray,
    eclipse,
    eclipseGray,
    darkPink,
    borderGray,
    outerCircle,
    accent1Color: '#b0b0b0',
    accent2Color: '#b0b0b0',
    accent3Color: '#eee',
    bodyColor: 'rgb(238, 238, 238)',
    modalColor: white,
    textColor: '#363537',
    // textColor: 'rgba(37, 37, 37, 0.6)',
    globalColor: black,
    canvasColor: '#FFF',
    textColor0: 'rgba(37, 37, 37, 0.87)',
    textColor1: 'rgb(48, 48, 48)',
    textColor2: 'rgba(37, 37, 37, 0.54)',
    textColor3: 'rgb(152, 152, 152)',
    // hoverColorItem: 'rgba(153, 153, 153, 0.2)',
    hoverColorItem: '#eee',
    sliderBorder: featherGray,
    border: '1px solid rgb(238, 238, 238, 1)',
    borderColor: 'rgb(238, 238, 238, 1)',
    fillColor: 'rgb(170, 170, 170)',
    iconColor: 'rgb(176, 176, 176)',
    playlistHover: 'rgba(37, 37, 37, 0.1)',
    alternateTextColor: black,
    paperColor: white,
    personBackground: 'rgb(188, 188, 188)',
    personIcon: white,
    subHeaderColor: lightGray,
    pinnedBackground: '#EEE',
    buttonBackground: darkGray,
    facebookColor:
      'linear-gradient(to left, rgba(0, 198, 255, 0.3), rgba(0, 114, 255, 0.3)) rgba(0, 0, 0, 0)',
    googleColor:
      'linear-gradient(to left, rgba(253, 116, 108, 0.3), rgba(255, 144, 104, 0.3))',
    cancelColor: 'rgba(128, 128, 128, 0.1)',
    cancelHoverColor: 'rgba(153, 153, 153, 0.2)',
    nextBtnHover: 'rgba(153, 153, 153, 0.65)',
    iconHover: 'rgb(37, 37, 37)',
    primary: {
      main: pink,
    },
    secondary: {
      light: '#343434',
      main: '#252525',
    },
    background: {
      default: '#EEEEEE',
      paper: white,
    },
    shadow:
      'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
    borderRight: 0,
  },
  typography: {
    fontFamily: 'Roboto,sans-serif',
    tab: {
      fontFamily: 'Roboto,sans-serif',
      fontSize: '14px',
    },
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
