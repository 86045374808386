import {
  takeEvery,
  takeLatest,
  call,
  put,
  all,
  select,
} from "redux-saga/effects";
import _ from "lodash";

import originalxios from "axios";
import axios from "instances/server";

import * as ArticleType from "./article.types";
import {
  getArticlesSuccess,
  getArticlesFail,
  getArticlesPageStart,
  getArticlesPageSuccess,
  getArticlesPageFail,
  getStaffpicksPageStart,
  getStaffpicksPageSuccess,
  getStaffpicksPageFail,
  viewDetailSuccess,
} from "./article.actions";

import { selectArticles } from "./article.selectors";

import { nullish } from "lib/misc";
import {
  setSystemPlaylistHumanName,
  setSystemPlaylistYtids,
} from "store/playlist/playlist.actions";

const getArticle = (state) => state.article;
const getVideo = (state) => state.video;

export function* initializeStore() {
  yield all([
    put(getArticlesPageStart("", 5)),
    put(getStaffpicksPageStart("", 6)),
  ]);
  yield call(createPlaylistListeners);
}

export function* getArticlesAsync({ payload: { allYtids } }) {
  // console.log('getArticlesAsync....', { allYtids });

  const selectedArticles = yield select(selectArticles);
  const videoState = yield select(getVideo);

  const missingYtids = allYtids.filter((ytid) =>
    nullish(selectedArticles[ytid])
  );

  if (missingYtids.length === 0) {
    let articles = {};
    return getArticlesSuccess(articles);
  }

  try {
    const { data } = yield axios.get("/article/articles", {
      params: {
        ytids: missingYtids.join(","),
      },
    });

    let articles = {};

    _.zip(missingYtids, data).forEach((pair) => {
      const [ytid, article] = pair;
      if (article) {
        articles[ytid] = article;
      } else {
        const ytidtitle = videoState.videos[ytid].title;
        articles[ytid] = {
          title: ytidtitle,
          content:
            "<h1> " +
            ytidtitle +
            " </h1><b>" +
            ytidtitle +
            "</b> is one the best songs to listen to at ListenOnRepeat right now. The artists in " +
            ytidtitle +
            " have delivered an amazing performance in their official music video as well as with vocals and chords. Kpop, Jpop, Hip Hop, Classic or R&B, ListenOnRepeat has all the music you need either to break the party floor or heal the broken heart and " +
            ytidtitle +
            " is definitely the one you can listen to at all times without getting bored. You can check out the trending music every week at ListenOnRepeat from the “Top 10” section in the sidebar and read all the exclusive reviews curated just for you.\nListen to " +
            ytidtitle +
            ", a song that relates to mixed feelings including love, happiness, joy, sorrow and life. Sometimes, Music can be the only soundtrack of life and the only truth held within. Did you like the music video for " +
            ytidtitle +
            "? You can now create your own playlist from your favorite artists and musicians. Heart your favorite music videos and make sure you never forget them. Watch and loop the official music video for " +
            ytidtitle +
            " at ListenOnRepeat now!\nRead More Music Reviews at <a href='https://listenonrepeat.com/' style='color: #065a8e; text-decoration: none;'><b>ListenOnRepeat</b></a>!",
        };
      }
    });

    // console.log({ articles });

    if (articles.length > 1) {
      // TODO: Get captions for more then 1 videos
    } else {
      const playingVideoId = _.head(allYtids);

      try {
        const geCapRes = yield axios.get(`/video/lyrics?ytids=${playingVideoId}`);

        let lyricsHtml = `<h1>${articles[playingVideoId].title}</h1><br />`;
        geCapRes.data.data.forEach((lyrics) => {
          lyricsHtml += `<p data-start="${lyrics.start}" data-dur="${lyrics.dur}" data-end="${+lyrics.start + +lyrics.dur}">${lyrics.text}</p>`;
        })
        articles[playingVideoId].lyrics = lyricsHtml;
      } catch (err) {
        articles[playingVideoId].lyrics = "<h3 style='text-align:center;'>Lyrics does not exist with this video.</h3>";
      }
    }

    yield put(getArticlesSuccess(articles));
  } catch (err) {
    console.error("error::", err);
    yield put(getArticlesFail(err));
  }
}

export function* getArticlesPageAsync({ payload: { cursor, limit } }) {
  // console.log('getArticlesPageAsync....');

  try {
    const { data } = yield axios.get(
      `https://blog.listenonrepeat.com/wp-json/wp/v2/category/content?category_slug=contributor-reviews&paged=${cursor}&page_post=6`
    );

    yield put(
      getArticlesPageSuccess({
        current: data?.data,
        next: data?.next,
      })
    );
  } catch (err) {
    console.error("error::", err);
    yield put(getArticlesPageFail(err));
  }
}

export function* getStaffpicksPageAsync({ payload: { cursor, limit } }) {
  // console.log('getStaffpicksPageAsync....');

  try {
    const { data } = yield axios.get(
      `https://blog.listenonrepeat.com/wp-json/wp/v2/category/content?category_slug=staff-picks&paged=${cursor}&page_post=6`
    );
    console.log(data, "response");

    yield put(
      getStaffpicksPageSuccess({
        current: data.data,
        next: data.next,
      })
    );
  } catch (err) {
    console.error("error::", err);
    yield put(getStaffpicksPageFail(err));
  }
}

function* createPlaylistListeners() {
  const state = yield select(getArticle);
  // console.log('createPlaylistListeners...');

  yield put(
    setSystemPlaylistHumanName(ArticleType.PLAYLIST_ARTICLES, "Fresh Reviews")
  );
  yield put(
    setSystemPlaylistHumanName(ArticleType.PLAYLIST_STAFFPICKS, "Staff Picks")
  );

  let articlePlaylist = {};
  articlePlaylist[ArticleType.PLAYLIST_ARTICLES] =
    state.articleYtidsOrdered.map((result) => result.ytid);
  articlePlaylist[ArticleType.PLAYLIST_STAFFPICKS] =
    state.staffPickYtidsOrdered.map((result) => result.ytid);

  yield put(setSystemPlaylistYtids(articlePlaylist));
}

export function* viewDetailsAsync({ payload: { details } }) {
  yield put(viewDetailSuccess(details));
}

export function* watchGetArticles() {
  yield takeEvery(ArticleType.GET_ARTICLES_START, getArticlesAsync);
}

export function* watchGetArticlesPage() {
  yield takeLatest(ArticleType.GET_ARTICLES_PAGE_START, getArticlesPageAsync);
}

export function* watchGetStaffpicksPage() {
  yield takeLatest(ArticleType.GET_STAFF_PAGE_START, getStaffpicksPageAsync);
}

export function* watchViewDetails() {
  yield takeLatest(ArticleType.VIEW_DETAILS, viewDetailsAsync);
}

export function* watchArticlePlaylist() {
  yield takeLatest(
    [ArticleType.GET_ARTICLES_PAGE_SUCCESS, ArticleType.GET_STAFF_PAGE_SUCCESS],
    createPlaylistListeners
  );
}

export function* watchInitializeStore() {
  yield takeLatest(ArticleType.INIT_ARTICLE, initializeStore);
}

export function* articleSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchGetArticles),
    call(watchGetArticlesPage),
    call(watchGetStaffpicksPage),
    call(watchViewDetails),
    call(watchArticlePlaylist),
  ]);
}
