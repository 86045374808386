import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { ArticleDetail as ArticleDetailCard } from 'modules/article/components';
import Hidden from '@material-ui/core/Hidden';

const ContentPageBlank = (props) => {
  return (
    <div className="main-content-page">
      {/* <Hidden mdUp>
    <ArtistInsightCard
      insight={props.required.artistState.info.insight}
    />
  </Hidden> */}
      
      <CardContent id="main-content-body" style={{ padding: 0 }}>
        {props.body}
      </CardContent>
    </div>
  );
};

export default ContentPageBlank;
