import * as PodcastTypes from './podcast.types';
import _ from "lodash";

const INITIAL_STATE = {
    loading: false,
    podcastLoading: false,
    genres: [],
    podcasts: {},
    episodes: {},
    mostPopular: [],
    fiveStar: [],
    currentPodcast: null,
    currentGenres: null,
    podcastCurrentPage: null,
    nextEpisode: null,
    isNextEpisode: true,
    genresPodcasts: {},
    podcastEpisodes: {},
    playlistEpisodeIds: [],
    errors: [],
    isPodCastClicked: false,
};

const podcastReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    let updatedState;
    switch (type) {
        case PodcastTypes.FETCH_INITIAL_START:
            updatedState = {
                ...state,
                loading: true,
            };
            // console.log('FETCH_INITIAL_START: ', updatedState);
            return updatedState;

        case PodcastTypes.FETCH_INITIAL_SUCCESS:
            // console.log('FETCH_INITIAL_SUCCESS: ', payload);
            updatedState = {
                ...state,
                loading: false,
                genresPodcasts: {
                    ...state.genresPodcasts,
                },
            };
            const randomIDS = payload.map(
                (meta) => meta.id ?? meta.trackId
            );
            updatedState.mostPopular = randomIDS;
            const randomStar = _.sampleSize(payload, 9).map(
                (meta) => meta.id ?? meta.trackId
            );
            updatedState.fiveStar = randomStar;
            _.values(payload)
                .filter((m) => !_.isNil(m))
                .forEach((meta) => {
                    updatedState.genresPodcasts[meta.id ?? meta.trackId] = meta;
                });
            return updatedState;

        case PodcastTypes.FETCH_INITIAL_FAIL:
            return {
                ...state,
                loading: false,
                errors: payload,
            };

        /*case PodcastTypes.GET_GENRES_START:
            return {
                ...state,
                loading: true,
            };*/

        case PodcastTypes.GET_GENRES_SUCCESS:
            return {
                ...state,
                // loading: false,
                genres: payload,
            };

        case PodcastTypes.GET_GENRES_FAIL:
            return {
                ...state,
                loading: false,
                errors: payload,
            };

        case PodcastTypes.SET_CURRENT_GENRES:
            return {
                ...state,
                currentGenres: payload,
            };

        case PodcastTypes.SET_PODCAST_CURRENT_PAGE:
            return {
                ...state,
                podcastCurrentPage: payload,
            };

        case PodcastTypes.REQUEST_GENRES_PODCAST_START:
            /*return {
                ...state,
                loading: true,
            };*/
            updatedState = {
                ...state,
            };
            if (payload.currentPage === 1) {
                updatedState.podcastLoading = true;
            }
            return updatedState;

        case PodcastTypes.REQUEST_GENRES_PODCAST_SUCCESS:
            updatedState = {
                ...state,
                podcastLoading: false,
                genresPodcasts: {
                    ...state.genresPodcasts
                },
            };
            _.values(payload)
                .filter((m) => !_.isNil(m))
                .forEach((meta) => {
                    if (updatedState.genresPodcasts[meta.id]) {
                        meta.genres = updatedState.genresPodcasts[meta.id].genres ?? [];
                    }
                    updatedState.genresPodcasts[meta.id ?? meta.trackId] = meta;
                });
            return updatedState;

        case PodcastTypes.REQUEST_GENRES_PODCAST_FAIL:
            return {
                ...state,
                podcastLoading: false,
                errors: payload,
            };

        case PodcastTypes.SET_CURRENT_VIEW_PODCAST:
            return {
                ...state,
                currentPodcast: payload.podcastId,
            };

        case PodcastTypes.REQUEST_NEXT_PODCAST_EPISODE:
            return {
                ...state,
                currentPodcast: payload.podcastId,
            };

        case PodcastTypes.FETCH_POD_CAST_EPISODE_START:
            return {
                ...state,
                loading: true,
            };

        case PodcastTypes.FETCH_POD_CAST_EPISODE_SUCCESS:
            updatedState = {
                ...state,
                loading: false,
                podcastEpisodes: {
                    ...state.podcastEpisodes,
                },
            };
            _.values(payload.data)
                .filter((m) => !_.isNil(m))
                .forEach((meta) => {
                    meta.podcast_id = payload.podcastId;
                    updatedState.podcastEpisodes[meta.id ?? `${meta.podcastId}_${meta.guid.split(' ')[0]}`] = meta;
                });
            return updatedState;

        case PodcastTypes.PODCAST_EPISODE_DATA_EXISTS:
            return {
                ...state,
                loading: false,
            };

        case PodcastTypes.FETCH_POD_CAST_EPISODE_FAIL:
            return {
                ...state,
                loading: false,
                errors: payload,
            };

        case PodcastTypes.SET_PLAYER_PLAYLIST:
            return {
                ...state,
                playlistEpisodeIds: payload
            };
            /*updatedState = {
                ...state,
            };
            _.values(payload)
                .filter((m) => !_.isNil(m))
                .forEach((meta) => {
                    updatedState.playlist[meta.id ?? meta.trackId] = meta;
                });
            return updatedState;*/

// --------------------------------------------

        case PodcastTypes.REQUEST_APPLE_PODCAST_EPISODE_START:
            return {
                ...state,
                loading: true,
            };

        case PodcastTypes.REQUEST_PODCAST_EPISODE_START:
            return {
                ...state,
                loading: true,
            };

        case PodcastTypes.RATING_START:
            updatedState = {
                ...state,
            };
            /*if (!updatedState.fiveStar.some(value => value === payload)) {
              updatedState.fiveStar.push(payload);
            }*/
            return updatedState;

        case PodcastTypes.SET_POD_CAST_EPISODE_CLICK:
            return {
                ...state,
                isPodCastClicked: true,
            }

        default:
            return state;
    }
};

export default podcastReducer;