import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import MailIcon from '@material-ui/icons/Mail';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { LOR as LORIcon } from 'common/ui/icons';
import { scrollToMainContentArea } from 'common/ui/utils';

import * as SectionTab from 'constants/SectionTab';
import container from './HeaderMenu.container';

const useStyles = makeStyles((theme) => ({
  termsDetails: {
    width: 224,
  },
  menuItems: {
    paddingTop: 13,
    paddingBottom: 13,
    fontFamily: 'Arial',
  },
  iconRightAlign: {
    position: 'absolute',
    fill: theme.palette.lightGray,
    right: 0,
    paddingRight: 15,
  },
  paperColor: {
    backgroundColor: theme.palette.paperColor,
    color: theme.palette.textColor,
    borderRadius: 2,
    marginTop: -2,
    marginLeft: -3,
    marginRight: 3,
  },
  linkage: {
    textDecoration: 'none',
    color: theme.palette.textColor,
  },
}));

const HeaderMenu = (props) => {
  const classes = useStyles();
  const {
    isLoggedIn,
    open,
    handleClose,
    siteCoordinator: { darkMode },
    onSetCurrentTab,
    onToggleDarkMode,
    onLogoutStart,
    onShowSnackbarNotification,
  } = props;

  const onDarkModeTap = (evt) => {
    // analytics.actions.trackUIClick('Dark Mode');
    onToggleDarkMode();
    handleClose(evt);
  };

  const onContactTap = (evt) => {
    onSetCurrentTab(SectionTab.Contact);
    handleClose(evt);
    scrollToMainContentArea();
  };

  const onAboutTap = (evt) => {
    onSetCurrentTab(SectionTab.About);
    handleClose(evt);
    scrollToMainContentArea();
  };

  const onFaqTap = (evt) => {
    onSetCurrentTab(SectionTab.Faq);
    handleClose(evt);
    scrollToMainContentArea();
  };

  const onPrivacyTap = (evt) => {
    handleClose(evt);
  };

  const onTermsTap = (evt) => {
    handleClose(evt);
  };

  const onSignOutTap = (evt) => {
    signOut();
    handleClose(evt);
    onLogoutStart();
    onShowSnackbarNotification(`Log Out success 😢`);
    // notification.actions.showSnackbarNotification(`Log Out success `);
  };

  return (
    <Paper className={classes.paperColor}>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList
          autoFocusItem={open}
          id="menu-list-grow"
          className={classes.termsDetails}
        >
          {darkMode ? (
            <MenuItem onClick={onDarkModeTap} className={classes.menuItems}>
              Light Theme
              <VisibilityOffIcon className={classes.iconRightAlign} />
            </MenuItem>
          ) : (
            <MenuItem onClick={onDarkModeTap} className={classes.menuItems}>
              Dark Theme
              <VisibilityIcon className={classes.iconRightAlign} />
            </MenuItem>
          )}
          {/*<MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={onSignOutTap}>Logout</MenuItem>
          <Divider />*/}
          <MenuItem onClick={onContactTap} className={classes.menuItems}>
            Contact Us
            <MailIcon className={classes.iconRightAlign} />
          </MenuItem>
          <MenuItem onClick={onAboutTap} className={classes.menuItems}>
            About Us:{process.env?.REACT_APP_VERSION}
            <LORIcon className={classes.iconRightAlign} />
          </MenuItem>
          <MenuItem onClick={onFaqTap} className={classes.menuItems}>
            FAQ
            <LORIcon className={classes.iconRightAlign} />
          </MenuItem>
          <Divider />
          <a
            className={classes.linkage}
            href={'/privacy'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MenuItem onClick={onPrivacyTap} className={classes.menuItems}>
              Privacy Policy
              <ErrorOutlineIcon className={classes.iconRightAlign}/>
            </MenuItem>
          </a>
          <a
            className={classes.linkage}
            href={'/terms'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MenuItem onClick={onTermsTap} className={classes.menuItems}>
              Terms of Service
              <ErrorOutlineIcon className={classes.iconRightAlign}/>
            </MenuItem>
          </a>
          {isLoggedIn && (
            <div>
              <Divider />
              <MenuItem onClick={onSignOutTap} className={classes.menuItems}>
                Sign out
              </MenuItem>
            </div>
          )}
        </MenuList>
      </ClickAwayListener>
    </Paper>
  );
};

export default container(HeaderMenu);
