import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { List, Hidden } from '@material-ui/core';
import {
  TrendingUp as TrendingUpIcon,
  Stars as StarsIcon,
  Explore as ExploreIcon,
  Album as AlbumIcon,
  Search as SearchIcon,
  Assignment as AssignmentIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';
import SectionTab from 'constants/SectionTab';
import NavigationDrawer from 'layouts/Main/components/Sidebar/navigation';
import { SidebarItem } from 'layouts/Main/components/Sidebar/components';
// import SidebarMoreItem from 'layouts/Main/components/Sidebar/components/SidebarMoreItem';
// import { CollapseListItem } from './components';
import { UserWidget } from 'modules/user/components';
import { scrollToMainContent, scrollToTopContent } from 'common/ui/utils';
import container from './Sidebar.container';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    '&.MuiList-padding': {
      padding: 0,
      paddingBottom: 35,
    },
  },
  toolbar: {
    minHeight: 50,
  },
  discordIcon: {
    color: 'white',
    height: 20,
    width: 20,
    marginTop: -4,
  },
  playerControls: {
    display: 'flex',
    paddingTop: 0,
    justifyContent: 'center',
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const {
    loginState,
    userState,
    siteCoordinator,
    playerState,
    open,
    onSidebarOpen,
    onSidebarClose,
    onSetCurrentTab,
    onShowLoginModal,
  } = props;

  /*const [openList, setOpenList] = React.useState(true);
  const handleClickFeatures = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenList(!openList);
  };*/

  const onListItemClick = (tab) => {
    if (matchesMD) {
      onSidebarClose();
    }
    onSetCurrentTab(tab);
    if (tab === SectionTab.RadioFavourite) {
      scrollToTopContent();
    } else {
      scrollToMainContent();
    }
  };

  const onBlogTap = () => {
    window.open('https://blog.listenonrepeat.com/', '_blank');
  };

  return (
    <NavigationDrawer
      open={open}
      onSidebarOpen={onSidebarOpen}
      onSidebarClose={onSidebarClose}
    >
      <List className={classes.listContainer}>
        <Hidden smUp>
          <div className={classes.toolbar} />
          <UserWidget
            usesPopover={false}
            loginStatus={loginState.loginStatus}
            assimilating={loginState.assimilating}
            firstName={userState.firstName}
            pictureUrl={userState.avatarUrl}
            onShowLoginModal={onShowLoginModal}
          />
        </Hidden>
        {/*<SidebarItem
          name="Today Features"
          nestedList={
            <SidebarMoreItem
              openList={openList}
              handleClick={handleClickFeatures}
            />
          }
          section={SectionTab.PopularChannel}
          icon={<SettingsIcon />}
          clicked={() => onListItemClick(SectionTab.PopularChannel)}
          selected={
            SectionTab.PopularChannel ===
              (siteCoordinator.currentTab === SectionTab.None
                ? SectionTab.PopularChannel
                : siteCoordinator.currentTab) ||
            SectionTab.WorkPlaceChannel ===
              (siteCoordinator.currentTab === SectionTab.None
                ? SectionTab.WorkPlaceChannel
                : siteCoordinator.currentTab) ||
            SectionTab.FiveStar ===
              (siteCoordinator.currentTab === SectionTab.None
                ? SectionTab.FiveStar
                : siteCoordinator.currentTab)
          }
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <Collapse in={openList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <CollapseListItem
              name={'Most Popular Channel'}
              section={SectionTab.PopularChannel}
              clicked={() => onListItemClick(SectionTab.PopularChannel)}
              siteCoordinator={siteCoordinator}
            />
            <CollapseListItem
              name={'Work Place Channel'}
              section={SectionTab.WorkPlaceChannel}
              clicked={() => onListItemClick(SectionTab.WorkPlaceChannel)}
              siteCoordinator={siteCoordinator}
            />
            <CollapseListItem
              name={'Five Star'}
              section={SectionTab.FiveStar}
              clicked={() => onListItemClick(SectionTab.FiveStar)}
              siteCoordinator={siteCoordinator}
            />
          </List>
        </Collapse>*/}
        <SidebarItem
          name={'Popular Channel'}
          section={SectionTab.PopularChannel}
          icon={<TrendingUpIcon />}
          clicked={() => onListItemClick(SectionTab.PopularChannel)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
            name={'Favorite/History'}
            section={SectionTab.RadioFavourite}
            icon={<FavoriteBorderIcon />}
            clicked={() => onListItemClick(SectionTab.RadioFavourite)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
        />
        <SidebarItem
          name={'Five Star'}
          section={SectionTab.FiveStar}
          icon={<StarsIcon />}
          clicked={() => onListItemClick(SectionTab.FiveStar)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name={'Location'}
          section={SectionTab.Location}
          icon={<ExploreIcon />}
          clicked={() => onListItemClick(SectionTab.Location)}
          selected={
            SectionTab.Station ===
            (siteCoordinator.currentTab === SectionTab.None
              ? SectionTab.Location
              : siteCoordinator.currentTab)
          }
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name={'Category'}
          section={SectionTab.Genres}
          icon={<AlbumIcon />}
          clicked={() => onListItemClick(SectionTab.Genres)}
          selected={
            SectionTab.GenreStation ===
            (siteCoordinator.currentTab === SectionTab.None
              ? SectionTab.Genres
              : siteCoordinator.currentTab)
          }
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        {/*<SidebarItem
          name="Hearted"
          section={SectionTab.RadioFavourite}
          icon={<FavoriteBorderIcon />}
          clicked={() => onListItemClick(SectionTab.RadioFavourite)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />
        <SidebarItem
          name="History"
          section={SectionTab.RadioHistory}
          icon={<HistoryIcon />}
          clicked={() => onListItemClick(SectionTab.RadioHistory)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />*/}
        {/*<SidebarItem
          name="Top 10"
          section={SectionTab.RadioTop10}
          icon={<WhatshotIcon />}
          clicked={() => onListItemClick(SectionTab.RadioTop10)}
          siteCoordinator={siteCoordinator}
          playerState={playerState}
          rightIcon
        />*/}
        <SidebarItem
            name="Blog"
            section={SectionTab.None}
            icon={<AssignmentIcon className={classes.discordIcon} />}
            clicked={() => onBlogTap()}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
        />
        {props.showSearchTab && (
          <SidebarItem
            name="Search Results"
            section={SectionTab.SearchRadio}
            icon={<SearchIcon />}
            clicked={() => onListItemClick(SectionTab.SearchRadio)}
            siteCoordinator={siteCoordinator}
            playerState={playerState}
            rightIcon
          />
        )}
      </List>
    </NavigationDrawer>
  );
};

export default container(Sidebar);
