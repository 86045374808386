import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { PlaylistAddButton, ToggleHeartButton } from '../StatusBar/components';
import { nullish } from 'lib/misc';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '@keyframes pulse_animation': {
    '0%': {
      transform: 'scale(1)',
    },
    '30%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '80%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  liveIcon: {
    animationName: '$pulse_animation',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    backgroundColor: '#ff0066',
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  viewers: {
    color: 'red',
  },
  count: {
    fontSize: 14,
    display: 'inline-block',
    paddingLeft: 20,
    marginTop: 5,
    paddingBottom: 13,
    fontFamily: 'Arial',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
    borderBottom: '1px solid',
  },
  custom: {
    borderColor: theme.palette.accent3Color,
    marginBottom: 8,
  },
}));

const StreamsFooter = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { streamsState, onRandomizeViewerCountStart } = props;

  const [intervalTimer, setIntervalTime] = useState(null);

  useEffect(() => {
    // randomizeViewerCount();
    setIntervalTime(
      setInterval(() => {
        // console.log('test');
        // randomizeViewerCount();
      }, 15 * 1000)
    );

    return () => {
      clearInterval(intervalTimer);
      setIntervalTime(null);
    };
  }, []);

  const accent2Color = theme.palette.accent2Color;

  return (
    <div className={classes.root}>
      <div className={classes.viewers}>
        <span
          className={clsx('player-streams-footer-live-icon', classes.liveIcon)}
        />
        <Typography className={classes.count} component="span">
          NOW LIVE
        </Typography>
      </div>
      <div className={clsx(classes.actions, classes.custom)}>
        <PlaylistAddButton ytid={props.ytid} color={accent2Color} />
        <ToggleHeartButton
          ytid={props.ytid}
          heart={!nullish(props.userPlay) && props.userPlay.star === true}
          color={accent2Color}
        />
      </div>
    </div>
  );
};

export default StreamsFooter;
