import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as UserhistoryPodcastType from './userHistoryPodcast.types';
import * as userhistoryPodcastAction from './userHistoryPodcast.actions';
import * as podcastAction from '../podcast/podcast.actions';
import axios from 'instances/radioServer';
import { selectPodcastForPreview } from '../podcast/podcast.selectors';
import { selectPodcastHistoryForPreview } from './userHistoryPodcast.selectors';
import _ from 'lodash';
const getUserState = (state) => state.user;

export function* getHistoryPodcastMetadataAsync(list) {
  // console.log('podcast heart/history list: ', list);
  try {
    const {data} = yield axios.post(`/podcast/batch`, {ids: list});
    // console.log('result: ', result);
    yield put(userhistoryPodcastAction.getHistoryPodcastMetadataSuccess(data));
    yield put(podcastAction.requestGenresPodcastSuccess(data.data));
  } catch (err) {
    yield put(userhistoryPodcastAction.getHistoryPodcastMetadataFail(err));
  }
}

export function* getFavoritePodcastAsync({ payload: { next, merge } }) {
  const userState = yield select(getUserState);
  const uid = userState.profile.uid;
  try {
    const { data } = yield axios.get(`/podcast/heart/${uid}`);
    const dataArr = data.map((value, index) => (
      value.id
    )).filter((value) => value);
    // console.log('daraArr: ', dataArr);
    yield put(userhistoryPodcastAction.getHeartsPodcastSuccess(dataArr));
    yield call(getHistoryPodcastMetadataAsync, dataArr);
  } catch (e) {
    yield put(userhistoryPodcastAction.getHeartsPodcastFail(e));
  }
}

export function* getHistoryPodcastAsync({ payload: { next, merge } }) {
  const userState = yield select(getUserState);
  const uid = userState.profile.uid;
  try {
    const { data } = yield axios.get(`/podcast/history/${uid}`);
    const dataArr = data.map((value, index) => (
        value.id
    )).filter((value) => value);
    yield put(userhistoryPodcastAction.getHistoryPodcastSuccess(dataArr));
    yield call(getHistoryPodcastMetadataAsync, dataArr);
  } catch (e) {
    yield put(userhistoryPodcastAction.getHistoryPodcastFail(e));
  }
}

export function* addHeartAsync({ payload: { podcastId } }) {
  try {
    let selectedPodcastStats = yield select(selectPodcastForPreview(podcastId));
    if (_.isEmpty(selectedPodcastStats)) {
      selectedPodcastStats = yield select(selectPodcastHistoryForPreview(podcastId));
    }
    const dataObject = {
      podcast: selectedPodcastStats
    };
    yield put(userhistoryPodcastAction.podcastHeartSuccess(dataObject));
    yield call(addFavorite, podcastId);
  } catch (err) {
    yield put(userhistoryPodcastAction.podcastHeartFail(err));
  }
}

export function* addFavorite(podcastId) {
  const userState = yield select(getUserState);
  try {
    const body = {
      uid: userState.profile.uid,
      podcast_id: podcastId,
    };
    // const { data } = yield axios.post('/podcast/heart/set', body);
    axios.post('/podcast/heart/set', body);
    // console.log('data: ', data);
  } catch (err) {
    console.log('Error on add favorite', err);
  }
}

export function* removeFavoritePodcastAsync({ payload: { podcastId } }) {
  const userState = yield select(getUserState);
  try {
    const body = {
      uid: userState.profile.uid,
      podcast_id: podcastId,
    };
    const { data } = yield axios.post(`/podcast/heart/clear`, body);
    yield put(userhistoryPodcastAction.removeFavoritePodcastSuccess(data));
  } catch (err) {
    yield put(userhistoryPodcastAction.removeFavoritePodcastFail(err));
  }
}

export function* addHistoryAsync({ payload: { podcastId } }) {
  const userState = yield select(getUserState);
  // const selectedPodcastStats = yield select(selectPodcastForPreview(podcastId));

  try {
    const body = {
      uid: userState.profile.uid,
      podcast_id: podcastId,
    };
    const { data } = yield axios.post(`/podcast/history/set`, body);
    // console.log('data: ', data);
    yield put(userhistoryPodcastAction.addPlayPodcastSuccess(data));
  } catch (err) {
    yield put(userhistoryPodcastAction.addPlayPodcastFail(err));
  }
}

export function* deleteHistoryPodcastAsync({ payload: { podcastId } }) {
  const userState = yield select(getUserState);
  try {
    const body = {
      uid: userState.profile.uid,
      podcast_id: podcastId,
    };

    const { data } = yield axios.post(`/podcast/history/clear`, body);
    yield put(userhistoryPodcastAction.removeHistoryPodcastSuccess(data));
  } catch (e) {
    yield put(userhistoryPodcastAction.removeHistoryPodcastFail(e));
  }
}

/**
 * watch generator functions
 */
export function* watchGetFavoritePodcast() {
  yield takeLatest(UserhistoryPodcastType.GET_FAVORITE_PODCAST_START, getFavoritePodcastAsync);
}

export function* watchGetHistoryPodcast() {
  yield takeLatest(UserhistoryPodcastType.GET_HISTORY_PODCAST_START, getHistoryPodcastAsync);
}

export function* watchAddHeartPodcast() {
  yield takeLatest(UserhistoryPodcastType.PODCAST_HEART_START, addHeartAsync);
}

export function* watchRemoveFavoritePodcast() {
  yield takeLatest(UserhistoryPodcastType.DELETE_FAVORITE_PODCAST_START, removeFavoritePodcastAsync);
}

export function* watchDeleteHistoryPodcast() {
  yield takeLatest(UserhistoryPodcastType.DELETE_HISTORY_PODCAST_START, deleteHistoryPodcastAsync)
}

export function* watchAddPlay() {
  yield takeLatest(
    UserhistoryPodcastType.ADD_PLAY_PODCAST_START,
    addHistoryAsync
  );
}

export function* userHistoryPodcastSagas() {
  yield all([
    call(watchGetFavoritePodcast),
    call(watchGetHistoryPodcast),
    call(watchAddPlay),
    call(watchAddHeartPodcast),
    call(watchRemoveFavoritePodcast),
    call(watchDeleteHistoryPodcast),
  ]);
}
