import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Subheader from '@material-ui/core/ListSubheader';
import Slide from '@material-ui/core/Slide';
import ToolTipKeys from 'constants/ToolTipKeys';
import styles from './TooltipMobile.style';

import container from './TooltipMobile.container';

const useStyles = makeStyles((theme) => ({
  background: {
    ...styles.background,
  },
  bgOpen: {},
  bgClose: {},
  container: {},
  open: {},
  close: {},
  subHeader: {
    paddingLeft: 16,
    fontSize: 15,
    fontWeight: 300,
    color: '#858585',
  },
  content: {
    ...styles.container.content,
  },
  paper: {
    width: 270,
    // height: 280,
    overflow: 'auto',
    fontFamily: 'Arial',
    color: theme.palette.textColor,
    backgroundColor: theme.palette.paperColor,
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
      left: 0,
    },
  },
  tooltipsLor: {
    height: 424,
  },
  tooltips: {
    height: 324,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TooltipMobile = (props) => {
  const classes = useStyles();
  const { id, title, onCloseToolTip, children, titleClassName } = props;

  const [open, setOpen] = React.useState(true);

  const onCloseTap = () => {
    setOpen(false);
    if (props.onHandleClose) {
      props.onHandleClose();
    }
    setTimeout(() => {
      onCloseToolTip();
    }, 500);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onCloseTap}
      aria-labelledby="playlist-xs-view"
    >
      <Paper className={clsx('paper', classes.paper, {
        [classes.tooltipsLor]: props.toolTipKey === ToolTipKeys.VideoAction,
          }, {
        [classes.tooltips]: props.toolTipKey !== ToolTipKeys.VideoAction,
          }
          )}
      >
        <Subheader className={clsx(classes.subHeader, titleClassName)}>
          {title}
        </Subheader>
        <div className={classes.content}>{children}</div>
      </Paper>
    </Dialog>
  );
};

TooltipMobile.defaultProps = {
  flipped: false,
};

export default container(TooltipMobile);
