import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
} from 'react-share';

const useStyles = makeStyles(() => ({
  socialIcon: {
    marginRight: 5,
    '&:focus': {
      outline: 'none',
    },
  },
}));

const ShareButtons = (props) => {
  const classes = useStyles();

  const { title, image, className } = props;

  let url = props.url;
  if (!url) {
    url = window.location.href;
  }

  let newURL = new URL(url);

  url = "https://link.listenonrepeat.com" + (newURL.href.replace(newURL.origin, ""));

  return (
    <Grid item className={className}>
      {!props.noTwitter && (
        <TwitterShareButton
          url={url}
          title={title}
          className={classes.socialIcon}
        >
          <TwitterIcon size={26} />
        </TwitterShareButton>
      )}
      {!props.noFacebook && (
        <FacebookShareButton
          url={url}
          quote={title}
          className={classes.socialIcon}
        >
          <FacebookIcon size={26} />
        </FacebookShareButton>
      )}
      {!props.noPinterest && (
        <PinterestShareButton
          url={url}
          media={image}
          description={title}
          className={classes.socialIcon}
        >
          <PinterestIcon size={26} />
        </PinterestShareButton>
      )}
    </Grid>
  );
};

export default ShareButtons;
