export const INIT_STREAMS = 'INIT_STREAMS';

export const GET_TOP10_START = 'GET_TOP10_START';
export const GET_TOP10_SUCCESS = 'GET_TOP10_SUCCESS';
export const GET_TOP10_FAIL = 'GET_TOP10_FAIL';

export const GET_STREAM_INIT_START = 'GET_STREAM_INIT_START';

export const GET_STREAM_LIST_START = 'GET_STREAM_LIST_START';
export const GET_STREAM_LIST_SUCCESS = 'GET_STREAM_LIST_SUCCESS';
export const GET_STREAM_LIST_FAIL = 'GET_STREAM_LIST_FAIL';

export const RANDOMIZE_VIEWER_COUNT_START = 'RANDOMIZE_VIEWER_COUNT_START';
export const RANDOMIZE_VIEWER_COUNT_SUCCESS = 'RANDOMIZE_VIEWER_COUNT_SUCCESS';
export const RANDOMIZE_VIEWER_COUNT_FAIL = 'RANDOMIZE_VIEWER_COUNT_FAIL';

export const SET_CURRENT_STREAM_START = 'SET_CURRENT_STREAM_START';
export const SET_CURRENT_STREAM_SUCCESS = 'SET_CURRENT_STREAM_SUCCESS';
export const SET_CURRENT_STREAM_FAIL = 'SET_CURRENT_STREAM_FAIL';

export const CLEAR_CURRENT_VIEW_STREAM = 'CLEAR_CURRENT_VIEW_STREAM';

export const PLAYLIST_TOP10 = 'lor:top10';
