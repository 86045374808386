import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 50,
    marginLeft: -2,
  },
  smallOuter: {
    // marginLeft: -2,
    [theme.breakpoints.down('xs')]: {
      marginRight: 2,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: -2,
    },
  },
  small: {
    width: 30,
    height: 30,
    background: theme.palette.personBackground,
  },
  iconRoot: {
    width: '60%',
    height: '60%',
    color: theme.palette.personIcon,
  },
  icon: {
    width: 18,
    height: 18,
    color: theme.palette.personIcon,
  },
}));

const HeaderUserIcon = (props) => {
  const classes = useStyles();

  const { isLoggedIn, onLoginTap, firstName, pictureUrl } = props;
  const tooltip = isLoggedIn ? firstName ?? 'Unnamed User' : 'Login';
  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={classes.root}
        onClick={onLoginTap}
        tooltip={tooltip}
      >
        <div className={classes.smallOuter}>
          {isLoggedIn ? (
            <Avatar className={classes.small} src={pictureUrl} alt={tooltip}/>
          ) : (
            <Avatar
              className={classes.small}
              classes={{ fallback: classes.iconRoot }}
              alt={tooltip}
            >
              <PersonIcon className={classes.icon}/>
            </Avatar>
          )}
        </div>
      </IconButton>
    </Tooltip>
  );
};

export default HeaderUserIcon;
