import { connect } from 'react-redux';

import { setSliderStart } from 'store/userhistory/userhistory.actions';

const mapDispatchToProps = (dispatch) => ({
  onSetSliderStart: (ytid, slider) => dispatch(setSliderStart(ytid, slider)),
});

const container = connect(null, mapDispatchToProps);

export default container;
