import { createSelector } from 'reselect';

const selectArticleStore = (state) => state.article;

export const selectArticles = createSelector(
  [selectArticleStore],
  (article) => article.articles
);

export const selectArticleForPreview = (ytid) =>
  createSelector([selectArticles], (articles) =>
    articles ? articles[ytid] : {}
  );
