export const INIT_USER_HISTORY_START = 'INIT_USER_HISTORY_START';
export const INIT_USER_HISTORY_SUCCESS = 'INIT_USER_HISTORY_SUCCESS';

export const FETCH_HISTORY_PROFILE_START = 'FETCH_HISTORY_PROFILE_START';
export const FETCH_HISTORY_PROFILE_SUCCESS = 'FETCH_HISTORY_PROFILE_SUCCESS';
export const FETCH_HISTORY_PROFILE_FAIL = 'FETCH_HISTORY_PROFILE_FAIL';

export const GET_PLAYS_PAGE_START = 'GET_PLAYS_PAGE_START';
export const GET_PLAYS_PAGE_SUCCESS = 'GET_PLAYS_PAGE_SUCCESS';
export const GET_PLAYS_PAGE_FAIL = 'GET_PLAYS_PAGE_FAIL';

export const GET_PLAYS_PAGE_REPEAT_START = 'GET_PLAYS_PAGE_REPEAT_START';
export const GET_PLAYS_PAGE_REPEAT_SUCCESS = 'GET_PLAYS_PAGE_REPEAT_SUCCESS';
export const GET_PLAYS_PAGE_REPEAT_FAIL = 'GET_PLAYS_PAGE_REPEAT_FAIL';

export const GET_PLAYS_START = 'GET_PLAYS_START';
export const GET_PLAYS_SUCCESS = 'GET_PLAYS_SUCCESS';
export const GET_PLAYS_FAIL = 'GET_PLAYS_FAIL';

export const ADD_PLAY_START = 'ADD_PLAY_START';
export const ADD_PLAY_SUCCESS = 'ADD_PLAY_SUCCESS';
export const ADD_PLAY_FAIL = 'ADD_PLAY_FAIL';

export const DELETE_PLAY_START = 'DELETE_PLAY_START';
export const DELETE_PLAY_SUCCESS = 'DELETE_PLAY_SUCCESS';
export const DELETE_PLAY_FAIL = 'DELETE_PLAY_FAIL';

export const SET_SLIDER_START = 'SET_SLIDER_START';
export const SET_SLIDER_SUCCESS = 'SET_SLIDER_SUCCESS';
export const SET_SLIDER_FAIL = 'SET_SLIDER_FAIL';

export const GET_HEARTS_PAGE_START = 'GET_HEARTS_PAGE_START';
export const GET_HEARTS_PAGE_SUCCESS = 'GET_HEARTS_PAGE_SUCCESS';
export const GET_HEARTS_PAGE_FAIL = 'GET_HEARTS_PAGE_FAIL';

export const ADD_HEART_START = 'ADD_HEART_START';
export const ADD_HEART_SUCCESS = 'ADD_HEART_SUCCESS';
export const ADD_HEART_FAIL = 'ADD_HEART_FAIL';

export const DELETE_HEART_START = 'DELETE_HEART_START';
export const DELETE_HEART_SUCCESS = 'DELETE_HEART_SUCCESS';
export const DELETE_HEART_FAIL = 'DELETE_HEART_FAIL';

export const CHANGE_SORT_START = 'CHANGE_SORT_START';
export const CHANGE_SORT_SUCCESS = 'CHANGE_SORT_SUCCESS';

export const URL_SLIDER = 'URL_SLIDER';
export const CLEAR_USER_HISTORY_DATA = 'CLEAR_USER_HISTORY_DATA';

export const PLAYLIST_NAME_YOUR_REPEATS = 'lor:history';
export const PLAYLIST_NAME_YOUR_FAVORITES = 'lor:history:favorites';
export const PLAYLIST_NAME_YOUR_REPEAT_ORDERED = 'lor:history:repeatOrdered';
