import { createSelector } from 'reselect';

const selectHistoryRadioStore = (state) => state.userhistoryRadio;

export const selectRadios = createSelector(
  [selectHistoryRadioStore],
  (radioHistory) => radioHistory.history
);

export const selectRadioHistoryForPreview = (stationid) =>
  createSelector([selectRadios], (radios) => (radios ? radios[stationid] : {}));
