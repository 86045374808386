export const INIT_YOUR_LOR = 'INIT_YOUR_LOR';
export const INIT_DISCOVERY = 'INIT_DISCOVERY';
export const SET_ARTISTS_DISCOVER = 'SET_ARTISTS_DISCOVER';
export const INELIGBLE_DISCOVERY = 'INELIGBLE_DISCOVERY';
export const DISCOVERY_FAILURE = 'DISCOVERY_FAILURE';
export const SET_DISCOVERY = 'SET_DISCOVERY';
export const SET_DISCOVERY_EXPIRES = 'SET_DISCOVERY_EXPIRES';
export const SET_OLD_DISCOVERY = 'SET_OLD_DISCOVERY';
export const SET_NEXT_ARTIST_SUCCESS = 'SET_NEXT_ARTIST_SUCCESS';
export const REMOVE_DISCOVERY_START = 'REMOVE_DISCOVERY_START';
export const REMOVE_DISCOVERY_SUCCESS = 'REMOVE_DISCOVERY_SUCCESS';
export const REMOVE_DISCOVERY_ARTIST = 'REMOVE_DISCOVERY_ARTIST';
export const SET_INELIGBLE_ARTIST = 'REMOVE_DISCOVERY_ARTIST';
export const VALIDATE_YTIDS = 'VALIDATE_YTIDS';
export const VALIDATE_YTIDS_SUCCESS = 'VALIDATE_YTIDS_SUCCESS';
export const ADD_NEW_DISCOVERY = 'ADD_NEW_DISCOVERY';
export const ADD_NEW_DISCOVERY_SUCCESS = 'ADD_NEW_DISCOVERY_SUCCESS';
export const RESET_DISCOVERY = 'RESET_DISCOVERY';

export const DISCOVERY_PLAYLIST = 'lor:yourdiscovery';
export const DISCOVERY_ANALYTICS_COOKIE = 'analytics-discovery-count';
export const DISCOVERY_LENGTH = 30;

export const DISCOVERY_SAVE = 'discovery_save';
export const OLD_DISCOVERY_SAVE = 'old_discovery_save';
export const REQUIRED_VALID_ARTISTS = 2;
export const DESIRED_VALID_ARTISTS = 15;
export const CHANCE_SAME_ARTIST = 0.4;
export const MAX_GENERARIONS = 100;
export const MAX_HISTORY = 100;
export const WEEKS_TO_SAVE = 4;
