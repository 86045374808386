import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as PodcastType from './podcastPlaylist.types';
import * as podcastActions from './podcastPlaylist.actions';

import axios from 'instances/radioServer';
import { scrollToMainContent } from 'common/ui/utils';

export function* fetchPodcastEpisodeAsync({ payload: { podcastId } }) {
  try {
    const { data } = yield axios.get(
      `/podcastIndex/podcast/episodes/${podcastId}`
    );
    console.log('fetchPodcastEpisodeAsync>>>', data);

    yield put(podcastActions.fetchPodCastEpisodeSuccess(podcastId, data.items));
    scrollToMainContent();
  } catch (err) {
    console.error(err);

    yield put(podcastActions.fetchPodCastEpisodeFail(err));
  }
}

/**
 * watch generator functions
 */
export function* watchFetchPodCastEpisode() {
  yield takeLatest(
    PodcastType.FETCH_PODCAST_EPISODE_START,
    fetchPodcastEpisodeAsync
  );
}

export function* podcastPlaylistSagas() {
  yield all([call(watchFetchPodCastEpisode)]);
}
