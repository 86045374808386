import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  currentYtid: state.player.ytid,
  userState: state.user,
  userhistoryState: state.userhistory,
  videoState: state.video,
  yourLORState: state.yourlor,
});

const container = connect(mapStateToProps);

export default container;
