import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';

import NavigationDrawer from './navigation';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    '&.MuiList-padding': {
      padding: 0,
      paddingBottom: 35
    }
  },
  skeletonRoot: {
    backgroundColor: 'rgb(117, 117, 117)'
  }
}));

const Sidebar = () => {
  const classes = useStyles();

  return (
    <NavigationDrawer open>
      <List className={classes.listContainer}>
        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>

          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <Skeleton
              className={classes.skeletonRoot}
              variant="circle"
              width={20}
              height={20}
            />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton className={classes.skeletonRoot} />}
          />
        </ListItem>
      </List>
    </NavigationDrawer>
  );
};

export default Sidebar;
