import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#eee',
    minWidth: 345,
    marginBottom: theme.spacing(1),
    paddingTop: 40,
    paddingBottom: 40,
  },
  card_root: {
    backgroundColor: '#fff',
    minWidth: 345,
    marginBottom: theme.spacing(1),
  },
  content: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  inner: {
    minWidth: '100%',
    fontFamily: 'Arial',
    fontSize: 14,
    letterSpacing: '0.5px',
    lineHeight: '24px',
    fontWeight: 300,
  },
  content_area: {
    // position: 'inline',
    margin: 'auto',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Arial',
    fontSize: 13,
    fontWeight: 10,
  },
  lor_links: {
    textDecoration: 'none',
    color: theme.palette.pink,
    cursor: 'pointer',
  },
  aboutuscontent: {
    marginLeft: '-27px',
  },
}));

const AboutUsBlock = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const facebookHTML = props.isFacebookSDKLoaded ? (
    <div
      // ref="fb page parent"
      className={classes.parentFbPage}
    >
      <div
        id="like_fb_page"
        className="fb-like"
        data-href="https://www.facebook.com/ListenOnRepeat/"
        data-layout="button_count"
        data-action="like"
        data-size="small"
        data-show-faces="true"
        data-share="false"
      />
    </div>
  ) : null;
  return (
    <CardContent>
      <div className={classes.inner}>
        <div style={{ marginBottom: 12 }}>
          Listenonrepeat is the #1 website to discover, repeat, and share
          Youtube music videos.
        </div>
        <div>
          Discover new music you’ll love based on your music and playlists.
        </div>
        <div>
          Repeat Youtube videos with customizable start and stop times. No more
          full repeat.
        </div>
        <div>
          Share on social media to your friends, followers, fans, etc. with your
          repeat counts.
        </div>
        <div>
          We are the best and largest community of music lovers around the
          world. Listenonrepeat also has new weekly reviews of mainstream and
          lesser known artists across the globe, Top 10 repeats, Discord chat,
          and much more..
        </div>
        <br />

        <div
          className="fb-like"
          data-href="https://www.facebook.com/ListenOnRepeat/"
          data-width=""
          data-layout="button_count"
          data-action="like"
          data-size="small"
          data-share="false"
        ></div>
        <br />
        <br />
        <div>There are several ways to enjoy Listenonrepeat.</div>
        <hr />
        <div className={classes.aboutuscontent}>
          <ol>
            <li>
              Visit our site{' '}
              <a
                className={classes.lor_links}
                href="https://www.listenonrepeat.com"
              >
                www.listenonrepeat.com
              </a>{' '}
              and sign up or login to make and see your playlists.
            </li>
            <li>
              Replace "youtube" with "listenonrepeat" before the ".com" on the
              web address.
              <br />
              For example, simply change
              "https://www.youtube.com/watch?v=Y5WQ6gF-8vA" to
              <br />
              "https://listenonrepeat.com/watch?v=Y5WQ6gF-8vA"
              <br />
              <p>
                <img
                  src="https://assets.listenonrepeat.com/static/img/lor-youtube.gif"
                  width="400px"
                  alt={'lor-youtube'}
                />
              </p>
            </li>
            <li>
              Download our free{' '}
              <a
                href="https://chrome.google.com/webstore/detail/listen-on-repeat-youtube/pgjcgpbffennccofdpganblbjiglnbip?hl=en"
                target="_blank"
              >
                Chrome Extension
              </a>
              <br />
              <p>
                <img
                  src="https://assets.listenonrepeat.com/static/img/lor-chrome.gif"
                  width="400px"
                  alt={'lor-chrome'}
                />
              </p>
            </li>
          </ol>
        </div>
        <div>How to reach us</div>
        <hr />
        <b>
          For technical problems with the site, use the "Contact Us" link from
          the top navbar.
        </b>
        <p>
          <img
            src="https://assets.listenonrepeat.com/static/img/lor-contact.gif"
            width="400px"
            alt="lor-contact"
          />
        </p>
        <div>Additional Info</div>
        <hr />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <div>
            <a
              className={classes.lor_links}
              href="https://listemonrepeat.com/terms/"
            >
              Terms and Conditions
            </a>
            <br />
            <a
              className={classes.lor_links}
              href="https://listemonrepeat.com/privacy/"
            >
              Privacy Policy
            </a>
          </div>
          <div>
            <a
              className={classes.lor_links}
              href="https://chrome.google.com/webstore/detail/listen-on-repeat-youtube/pgjcgpbffennccofdpganblbjiglnbip?hl=en"
            >
              Chrome Extension
            </a>
          </div>
          <div
            style={{
              width: 290,
              marginTop: -17,
              padding: '0px 0.75rem',
              textAlign: 'right',
              float: 'left',
              boxSizing: 'border-box',
            }}
          >
            <p
              style={{
                fontSize: 12,
                margin: '21px 0px 0px',
                padding: 0,
                letterSpacing: 0.5,
              }}
            >
              ListenOnRepeat is not associated with YouTube
            </p>
            <p
              style={{
                fontSize: 12,
                margin: 0,
                padding: 0,
                letterSpacing: 0.5,
              }}
            >
              Do not abuse Google products
            </p>
          </div>
        </div>
        <p style={{ margin: 'auto', textAlign: 'center', padding: 10 }}>
          © 2020 Listenonrepeat, LLC.
        </p>
      </div>
    </CardContent>
  );
};

export default AboutUsBlock;
