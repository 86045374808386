import { connect } from 'react-redux';

import {
  getAutocompleteSuggestionStart,
  performSearchStart,
} from 'store/search/search.actions';
import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';

const mapStateToProps = (state) => ({
  searchState: state.search,
  videoStore: state.video,
});

const mapDispatchToProps = (dispatch) => ({
  onGetAutocompleteSuggestionStart: (value) =>
    dispatch(getAutocompleteSuggestionStart(value)),
  youtubeLookup: (value) => dispatch(performSearchStart(value)),

  onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
