import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardContent from '@material-ui/core/CardContent';

import HistorySort from 'constants/HistorySort';
import ToolTipKeys from 'constants/ToolTipKeys';

import VideoListItem from 'modules/video/components/VideoListItem';
import HistoryFeed from 'modules/playlist/components/PlaylistView';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';
import { HistoryDeleteToolTip } from 'modules/userhistory/tooltip';

import * as UserhistoryType from 'store/userhistory/userhistory.types';
import { scrollToVideo } from 'common/ui/utils';

import container from './HistoryCard.container';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontFamily: theme.typography.fontFamily,
  },
}));

const HistoryCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    userhistoryState,
    videoState,
    playerState: { ytid: currentPlayerYtid },
    playlistState,
    userState,
    tooltipState,
    onSetAutoplay,
    onViewPlayerVideoStart,
    onSetCurrentPlaylistStart,
    onDeletePlayStart,
    onShowSnackbarNotification,
    onHideDeletePlaylistToolTip,
    onEnqueueToolTip,
    onCloseToolTip,
    onGetPlaysPageStart,
    onGetPlaysByRepeatsPageStart,
    onQueueYtid,
    onShowAddPlaylistModal,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
      setOpen1(false);
      onCloseToolTip();
    };
  }, []);

  const onAddToPlaylistClick = (evt, ytid) => {
    var element = evt.currentTarget;
    // console.log('[HistoryCard] onAddToPlaylistClick::', { ytid });
    // console.log(element);

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }

    toggleAddToPlaylistToolTip(ytid, element);

    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    // console.log('[HistoryCard] toggleAddToPlaylistToolTip');

    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const onDeleteFromHistoryClick = (evt, ytid) => {
    let element = evt.currentTarget;
    toggleDeleteToolTip(ytid, element);
  };

  function toggleDeleteToolTip(ytid, element) {
    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.DeleteToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen1(false);
      return;
    }
    window.anchorEl = element;
    setOpen1(true);
    onEnqueueToolTip(ToolTipKeys.DeleteToolTip, null, ytid);
  }

  const confirmDelete = (ytid) => {
    onDeletePlayStart(ytid);
    onShowSnackbarNotification('Deleted video 😢');
    onCloseToolTip();
    window.anchorEl = null;
    setOpen1(false);
  };

  const cancelDelete = (ytid) => {
    onCloseToolTip();
    window.anchorEl = null;
    setOpen1(false);
  };

  const onClickShowMore = () => {
    if (userhistoryState.sortBy === HistorySort.RepeatCount) {
      return onGetPlaysByRepeatsPageStart(
        userhistoryState.repeatOrderedHistoryNext
      );
    }
    return onGetPlaysPageStart(userhistoryState.historyNext, true);
  };

  const onClickRow = (ytid) => {
    // console.log('onClickRow...');
    onQueueYtid(ytid);
    scrollToVideo();
    onViewPlayerVideoStart(ytid);
    if (userhistoryState.sortBy === HistorySort.RepeatCount) {
      onSetCurrentPlaylistStart(
        UserhistoryType.PLAYLIST_NAME_YOUR_REPEAT_ORDERED
      );
      // analytics.actions.trackUIClick('Repeat Count Feed', ytid);
      window._gaq.push(['_trackEvent', 'UI Click', 'Repeat Count Feed', ytid]);

    } else {
      onSetCurrentPlaylistStart(UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS);
      // analytics.actions.trackUIClick('Repeat Feed', ytid);
      window._gaq.push(['_trackEvent', 'UI Click', 'Repeat Feed', ytid]);

    }
  };

  const historyYtids =
    userhistoryState.sortBy === HistorySort.RepeatCount
      ? userhistoryState.repeatOrderedHistoryYtids
      : userhistoryState.historyYtids;
  const items = historyYtids
    .map((ytid) => userhistoryState.history[ytid])
    .filter((x) => x);
  const next =
    userhistoryState.sortBy === HistorySort.RepeatCount
      ? userhistoryState.repeatOrderedHistoryNext
      : userhistoryState.historyNext;

  return (
    <CardContent
      classes={{
        root: classes.root,
      }}
    >
      <HistoryFeed
        items={items}
        next={next}
        onClickShowMore={onClickShowMore}
        elementFactory={(play, i) => (
          <div key={`history-${play.ytid}-${i}`}>
            <VideoListItem
              ytid={play.ytid}
              isPlaying={play.ytid === currentPlayerYtid}
              history={play}
              metadata={videoState.videos[play.ytid]}
              globalStats={videoState.videoStats[play.ytid]}
              playlistState={playlistState}
              userState={userState}
              showControls
              isEditable
              onClick={onClickRow}
              onAddToPlaylistClick={onAddToPlaylistClick}
              onDeleteClick={onDeleteFromHistoryClick}
              showMobileAuthor={false}
              showMobileGlobalRepeats={false}
              showMobileGlobalHearts={false}
              showMobileUserRepeats={true}
              showMobileDuration={false}
              showMobileLastViewed={true}
            />
          </div>
        )}
      />
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
      {open1 && tooltipState.currentEntry.key === ToolTipKeys.DeleteToolTip && (
        <HistoryDeleteToolTip
          toolTipKey={ToolTipKeys.DeleteToolTip}
          title="Delete Video from History?"
          description="Are you sure you want to do this?"
          element={window.anchorEl}
          ytid={tooltipState.currentEntry.ytid}
          confirm={confirmDelete}
          cancel={cancelDelete}
        />
      )}
    </CardContent>
  );
};

export default container(HistoryCard);
