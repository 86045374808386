import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';

const ImageSkeleton = () => {
    return (
        <>
            <Hidden xsDown>
                <Skeleton variant="rect" width={233} height={202} />
            </Hidden>
            <Hidden smUp>
                <Skeleton variant="rect" width={330} height={270} />
            </Hidden>
        </>
    );
};

export default ImageSkeleton;
