import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import HistoryIcon from '@material-ui/icons/History';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  userStatsWidget: {
    display: 'block',
    height: '50px',
    minWidth: '70px',
    paddingTop: '5px',
    color: 'white',
  },
  statsRow: {
    position: 'relative',
    width: '100%',
  },
  statsRowHearts: {
    extend: 'statsRow',
    top: '1px',
  },
  statHearts: {
    color: '#bbb',
    fontSize: 14,
    paddingTop: '1px',
    fontFamily: 'Arial',
  },
  icon: {
    textAlign: 'center',
    position: 'relative',
    height: 14,
    fill: '#fff',
    marginTop: 3,
    marginLeft: 3,
  },
}));

const UserStatsWidget = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.userStatsWidget}>
      <div className={classes.statsRowHearts}>
        <FavoriteBorderIcon className={classes.icon} />
        <span className={classes.statHearts}>{props.hearts}</span>
      </div>
      <div className={clsx(classes.statsRow, classes.repeats)}>
        <HistoryIcon className={classes.icon} />
        <span className={clsx(classes.statHearts, classes.repeats)}>
          {props.repeats}
        </span>
      </div>
    </div>
  );
};

export default UserStatsWidget;
