import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 30,
    height: 30,
    padding: 0,
    marginRight: 10,
    display: 'inline-block',
  },
  icon: {
    display: 'inline-block',
    color: 'rgb(37, 37, 37)',
    fill: 'rgb(176, 176, 176)',
    height: 20,
    width: 20,
    userSelect: 'none',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    '&:hover': {
      color: theme.palette.iconHover,
      fill: theme.palette.iconHover,
    },
  },
}));

const HeartButton = (props) => {
  const classes = useStyles();

  function onClick(evt) {
    props.onClick(evt);
  }

  return (
    <IconButton className={classes.iconButton} onClick={onClick}>
      {props.heart ? (
        <FavoriteIcon className={classes.icon} />
      ) : (
        <FavoriteBorderIcon className={classes.icon} />
      )}
    </IconButton>
  );
};

export default HeartButton;
