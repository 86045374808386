export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';

export const SHOW_MERGE_MODAL = 'SHOW_MERGE_MODAL';
export const HIDE_MERGE_MODAL = 'HIDE_MERGE_MODAL';

export const SHOW_LOGOUT_CONFIRM_MODAL = 'SHOW_LOGOUT_CONFIRM_MODAL';
export const HIDE_LOGOUT_CONFIRM_MODAL = 'HIDE_LOGOUT_CONFIRM_MODAL';

export const MERGE_ACCOUNT_START = 'MERGE_ACCOUNT_START';
export const MERGE_ACCOUNT_SUCCESS = 'MERGE_ACCOUNT_SUCCESS';
export const MERGE_ACCOUNT_FAIL = 'MERGE_ACCOUNT_FAIL';

export const FB_LOGIN_START = 'FB_LOGIN_START';
export const GOOGLE_LOGIN_START = 'GOOGLE_LOGIN_START';
export const EMAIL_LOGIN_START = 'EMAIL_LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const LOAD_USER_START = 'LOAD_USER_START';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const HIDE_GENERATE_PASSWORD_MODAL = 'HIDE_GENERATE_PASSWORD_MODAL';
export const SHOW_GENERATE_PASSWORD_MODAL = 'SHOW_GENERATE_PASSWORD_MODAL';

