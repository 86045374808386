import * as PlaylistType from './playlist.types';

export const initPlaylist = () => ({
  type: PlaylistType.INIT_PLAYLIST_START,
});

export const setCurrentViewdPlaylist = (pid) => ({
  type: PlaylistType.SET_CURRENT_VIEWED_PLAYLIST,
  payload: { pid },
});

export const setCurrentPlaylistStart = (pid) => ({
  type: PlaylistType.SET_CURRENT_PLAYLIST_START,
  payload: { pid },
});

export const setCurrentPlaylistSuccess = (data) => ({
  type: PlaylistType.SET_CURRENT_PLAYLIST_SUCCESS,
  payload: data,
});

export const setCurrentPlaylistFail = () => ({
  type: PlaylistType.SET_CURRENT_PLAYLIST_FAIL,
});

export const getUserPlaylistPageStart = (uid, cursor = '') => ({
  type: PlaylistType.GET_USER_PLAYLIST_PAGE_START,
  payload: { uid, cursor },
});

export const getUserPlaylistPageSuccess = (data) => ({
  type: PlaylistType.GET_USER_PLAYLIST_PAGE_SUCCESS,
  payload: data,
});

export const getUserPlaylistPageFail = (err) => ({
  type: PlaylistType.GET_USER_PLAYLIST_FAIL,
  payload: err,
});

export const getUserPlaylistStart = (pids) => ({
  type: PlaylistType.GET_USER_PLAYLIST_START,
  payload: { pids },
});

export const getUserPlaylistSuccess = (data) => ({
  type: PlaylistType.GET_USER_PLAYLIST_SUCCESS,
  payload: data,
});

export const getUserPlaylistFail = (err) => ({
  type: PlaylistType.GET_USER_PLAYLIST_FAIL,
  payload: err,
});

export const openPlaylistStart = (pid) => ({
  type: PlaylistType.OPEN_PLAYLIST_START,
  payload: { pid },
});

export const openPlaylistSuccess = (data) => ({
  type: PlaylistType.OPEN_PLAYLIST_SUCCESS,
  payload: data,
});

export const openPlaylistFail = (err) => ({
  type: PlaylistType.OPEN_PLAYLIST_FAIL,
  payload: err,
});

export const playPlaylistStart = (pid, ytid) => ({
  type: PlaylistType.PLAY_PLAYLIST_START,
  payload: { pid, ytid },
});

export const playPlaylistSuccess = (data) => ({
  type: PlaylistType.PLAY_PLAYLIST_SUCCESS,
  payload: data,
});

export const playPlaylistFail = (err) => ({
  type: PlaylistType.PLAY_PLAYLIST_FAIL,
  payload: err,
});

export const addYtidsPlaylistStart = (pid, ytids) => ({
  type: PlaylistType.ADD_YTIDS_TO_PLAYLIST_START,
  payload: { pid, ytids },
});

export const addYtidPlaylistSuccess = (data) => ({
  type: PlaylistType.ADD_YTIDS_TO_PLAYLIST_SUCCESS,
  payload: data,
});

export const addYtidPlaylistFail = (err) => ({
  type: PlaylistType.ADD_YTIDS_TO_PLAYLIST_FAIL,
  payload: err,
});

export const setPlaylistYtidsStart = (pid, ytids) => ({
  type: PlaylistType.SET_PLAYLIST_YTIDS_START,
  payload: { pid, ytids },
});

export const setPlaylistYtidsSuccess = (data) => ({
  type: PlaylistType.SET_PLAYLIST_YTIDS_SUCCESS,
  payload: data,
});

export const setPlaylistYtidsFail = (err) => ({
  type: PlaylistType.SET_PLAYLIST_YTIDS_FAIL,
  payload: err,
});

export const renamePlaylistStart = (pid, name) => ({
  type: PlaylistType.RENAME_PLAYLIST_START,
  payload: { pid, name },
});

export const renamePlaylistSuccess = (data) => ({
  type: PlaylistType.RENAME_PLAYLIST_SUCCESS,
  payload: data,
});

export const renamePlaylistFail = (err) => ({
  type: PlaylistType.RENAME_PLAYLIST_FAIL,
  payload: err,
});

export const deletePlaylistStart = (pid) => ({
  type: PlaylistType.DELETE_PLAYLIST_START,
  payload: { pid },
});

export const deletePlaylistSuccess = (data) => ({
  type: PlaylistType.DELETE_PLAYLIST_SUCCESS,
  payload: data,
});

export const deletePlaylistFail = (err) => ({
  type: PlaylistType.DELETE_PLAYLIST_FAIL,
  payload: err,
});

export const getFollowedPlaylistStart = (uid, cursor = '') => ({
  type: PlaylistType.GET_FOLLOWED_PLAYLIST_START,
  payload: { uid, cursor },
});

export const getFollowedPlaylistSuccess = (data) => ({
  type: PlaylistType.GET_FOLLOWED_PLAYLIST_SUCCESS,
  payload: data,
});

export const getFollowedPlaylistFail = (err) => ({
  type: PlaylistType.GET_FOLLOWED_PLAYLIST_FAIL,
  payload: err,
});

export const addFollowedPlaylistStart = (pid) => ({
  type: PlaylistType.ADD_FOLLOWED_PLAYLIST_START,
  payload: { pid },
});

export const addFollowedPlaylistSuccess = (data) => ({
  type: PlaylistType.ADD_FOLLOWED_PLAYLIST_SUCCESS,
  payload: data,
});

export const addFollowedPlaylistFail = (err) => ({
  type: PlaylistType.ADD_FOLLOWED_PLAYLIST_FAIL,
  payload: err,
});

export const deleteFollowedPlaylistStart = (pid) => ({
  type: PlaylistType.DELETE_FOLLOWED_PLAYLIST_START,
  payload: { pid },
});

export const deleteFollowedPlaylistSuccess = (data) => ({
  type: PlaylistType.DELETE_FOLLOWED_PLAYLIST_SUCCESS,
  payload: data,
});

export const deleteFollowedPlaylistFail = (err) => ({
  type: PlaylistType.DELETE_FOLLOWED_PLAYLIST_FAIL,
  payload: err,
});

export const createPlaylistStart = (name) => ({
  type: PlaylistType.CREATE_PLAYLIST_START,
  payload: { name },
});

export const createPlaylistSuccess = (data) => ({
  type: PlaylistType.CREATE_PLAYLIST_SUCCESS,
  payload: data,
});

export const createPlaylistFail = () => ({
  type: PlaylistType.CREATE_PLAYLIST_FAIL,
});

export const createPlaylistAddSongStart = (name, ytids) => ({
  type: PlaylistType.CREATE_PLAYLIST_ADD_SONG_START,
  payload: { name, ytids },
});

export const createPlaylistAddSongSuccess = (data) => ({
  type: PlaylistType.CREATE_PLAYLIST_ADD_SONG_SUCCESS,
  payload: data,
});

export const createPlaylistAddSongFail = () => ({
  type: PlaylistType.CREATE_PLAYLIST_ADD_SONG_FAIL,
});

export const setCurrentYtid = (ytid) => ({
  type: PlaylistType.SET_CURRENT_YTID,
  payload: ytid,
});

export const showAddPlaylistModal = () => ({
  type: PlaylistType.SHOW_ADD_PLAYLIST_MODAL,
});

export const hideAddPlaylistModal = () => ({
  type: PlaylistType.HIDE_ADD_PLAYLIST_MODAL,
});

export const showEditPlaylistModal = (
  pid,
  name,
  publicPlaylist,
  publicLink
) => ({
  type: PlaylistType.SHOW_EDIT_PLAYLIST_MODAL,
  payload: { pid, name, publicPlaylist, publicLink },
});

export const hideEditPlaylistModal = () => ({
  type: PlaylistType.HIDE_EDIT_PLAYLIST_MODAL,
});

export const showDeletePlaylistModal = (pid, name) => ({
  type: PlaylistType.SHOW_DELETE_PLAYLIST_MODAL,
  payload: { pid, name },
});

export const hideDeletePlaylistModal = () => ({
  type: PlaylistType.HIDE_DELETE_PLAYLIST_MODAL,
});

export const setSortType = (sortBy) => ({
  type: PlaylistType.SET_SORT_TYPE,
  payload: { sortBy },
});

export const eviscerateQueue = (index) => ({
  type: PlaylistType.EVISCERATE_QUEUE,
  payload: index,
});

export const removeQueue = (index) => ({
  type: PlaylistType.REMOVE_QUEUE,
  payload: index,
});

export const setQueue = (ytids) => ({
  type: PlaylistType.SET_QUEUE,
  payload: ytids,
});

export const popQueue = () => ({
  type: PlaylistType.POP_QUEUE,
});

export const addToQueueStart = (ytid) => ({
  type: PlaylistType.ADD_QUEUE_START,
  payload: ytid,
});

export const addToQueueSuccess = (ytid) => ({
  type: PlaylistType.ADD_QUEUE_SUCCESS,
  payload: ytid,
});

export const queueYtid = (ytid) => ({
  type: PlaylistType.QUEUE_YTID,
  payload: ytid,
});

export const createInternalPlaylist = (pid, ytids) => ({
  type: PlaylistType.CREATE_INTERNAL_PLAYLIST,
  payload: { pid, ytids },
});

export const setSystemPlaylistHumanName = (pid, name) => ({
  type: PlaylistType.SET_SYSTEM_PLAYLIST_NAME,
  payload: { pid, name },
});

export const setSystemPlaylistYtids = (data) => ({
  type: PlaylistType.SET_SYSTEM_PLAYLIST_YTIDS,
  payload: data,
});

export const clearCurrentlyViewedPlaylist = () => ({
  type: PlaylistType.CLEAR_CURRENTLY_VIEWED_PLAYLIST,
});
