import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import YouTube from '@u-wave/react-youtube';
import * as cookie from 'component-cookie';

import { usePrevious, useInterval } from 'hooks';
import * as YourLorType from 'store/yourlor/yourlor.types';

import PlayerState from 'constants/PlayerState';

import container from './VideoPlayer.container';

const useStyles = makeStyles({
  playerWrapper: {
    width: '100%'
  },
  player: {
    width: '100%',
    paddingBottom: '56.25%',
    height: 0,
    position: 'relative',
    zIndex: 0
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'absolute'
  },
  iframe: {
    width: '100%',
    height: '100%',
    position: 'absolute'
  }
});

const PLAYER_TIME_CHECK_INTERVAL = 1.5; // in seconds

function Player(props) {
  const classes = useStyles();
  const {
    ytid,
    startTime,
    endTime,
    playerStore,
    userhistoryState,
    playlistState,
    streamState,
    onClearSeek,
    onSetIsLoading,
    onAddPlayStart,
    onPlayerIsReady,
    onPlayNextStart,
    onSkipArtistRadio,
    onPlayerDidChangeState,
    onSetCurrentStreamStart,
    onShowSnackbarNotification
  } = props;

  const prevYtid = usePrevious(ytid);
  const prevStartTime = usePrevious(startTime);
  const prevEndTime = usePrevious(endTime);

  const [sliderValue, setSliderValue] = useState({
    sliderStart: 0,
    sliderEnd: 0
  });
  const [isReady, setIsReady] = useState(false);
  const [player, setPlayer] = useState(null);
  const [playerState, setPlayerState] = useState(PlayerState.Unstarted);
  const [atomicLanding, setAtomicLanding] = useState(true);
  const [atomicLandingPlay, setAtomicLandingPlay] = useState(true);
  const [atomicRepeat, setAtomicRepeat] = useState(true);
  const [radioMode, setRadioMode] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  

  useEffect(() => {
    if (prevStartTime !== startTime || prevEndTime !== endTime) {
      setSliderValue({
        sliderStart: startTime,
        sliderEnd: endTime
      });
    }
  }, [prevStartTime, prevEndTime, startTime, endTime]);

  

  useEffect(() => {
    const currentState = playerStore.player.state;

    // console.log('toogle player action.... i.e. play/pause', currentState);

    switch (currentState) {
      case PlayerState.Playing:
        // console.log('play');
        pauseVideo();
        break;

      case PlayerState.Paused:
      case PlayerState.Ended:
      case PlayerState.Unstarted:
      case PlayerState.VideoCued:
        // console.log('pause');
        playVideo();
        break;

      default:
        break;
    }
  }, [playerStore.player.toggle]);

  useEffect(() => {
    if (ytid !== prevYtid) {
      setAtomicRepeat(true);
    }
  }, [prevYtid, ytid]);

  // every `PLAYER_TIME_CHECK_INTERVAL` seconds, we check the current time of the video, and
  // when we're close to the end, we trigger a repeat or a next based on the RepeatMode
  useInterval(
    () => {
      // Your custom logic here
      playerTimeCheck();
    },
    PLAYER_TIME_CHECK_INTERVAL * 1000,
    player,
    prevStartTime,
    startTime,
    prevEndTime,
    endTime
  );

  function playVideo() {
    if (player !== null) {
      player.playVideo();

      if (atomicLanding) {
        // Landing video popularity test
        if (ytid === 'Y5WQ6gF-8vA') {
          // analytics.actions.trackAction('Landing Video', 'Y5WQ6gF-8vA');
          window._gaq.push(['_trackEvent', 'Action', 'Landing Video', ytid]);

          setAtomicLanding(false);
        }
      }
    }
  }

  function pauseVideo() {
    if (player !== null) {
      player.pauseVideo();
    }
  }

  const onReady = (evt) => {
    // grab the YT player object from evt.target
    const ytPlayer = evt.target;
    // console.log('yt player onReady ########');
    // console.log({ ytPlayer });
    // console.log({ evt });
    // console.log('set player....');
    ytPlayer.playVideo();
    setIsReady(true);
    setPlayer(ytPlayer);
    window.ytPlayer = ytPlayer;
    onPlayerIsReady();
    window.ytPlayer.playVideo()

  };

  const onStateChange = (evt) => {
    const playerState = evt.data;

    // console.log('player state did change...', playerState);

    setPlayerState(playerState);
    onPlayerDidChangeState(playerState);

    switch (playerState) {
      case PlayerState.VideoCued:
        if (isReady && playerStore.autoplay) {
          const startTime1 = Math.max(startTime, 0);
          player.seekTo(startTime1, true);
          playVideo();
        }
        break;

      default:
        break;
    }
  };

  function playerTimeCheck() {
    var date = new Date();
    var timestamp = date.getTime();
    // console.log(timestamp);
    // console.log('player time check... :: 1');
    // not initialized yet if state player is null (see this.onReady)
    if (player === null) {
      return;
    }
    // console.log('player time check... :: 1.5');
    if (
      playerStore.autoplay &&
      (playerState === PlayerState.VideoCued ||
        playerState === PlayerState.Unstarted)
    ) {
      playVideo();
    }
    // console.log('player time check... :: 2');
    // ignore the player's buffering state so we don't double-repeat
    if (playerState === PlayerState.Buffering) {
      return;
    }

    if (hasErrored && props.streamMode) {
      onSetIsLoading(false);
      // analytics.actions.trackAction(
      //   'Stream Blocked [' + streams.store.state.streamPlaying + ']',
      //   props.ytid
      // )
      window._gaq.push(['_trackEvent', 'Action', 'Stream Blocked', props.ytid]);

      onPlayNextStart();
      setHasErrored(true);
    }

    // console.log('player time check... :: 3');
    const duration = player.getDuration();
    // not initialized yet if duration is still 0
    if (duration === 0) {
      return;
    }
    // console.log('player time check... :: 4');
    const currentTime = player.getCurrentTime();
    // Return if the current time is undefined (embed is loading)
    if (!currentTime) {
      return;
    }

    // console.log({ currentTime, duration });
    const sliderEnd = props.endTime;
    const sliderStart = props.startTime;

    const endTime = sliderEnd && sliderEnd > 0 ? sliderEnd : duration;
    const startTime = sliderStart && sliderStart >= 0 ? sliderStart : 0;

    // Intentionally left these console.log here as it should surface useful information in bug reports if we receive "repeat broken" bug reports
    let repeatParam = {
      currentTime: currentTime,
      sliderEnd: sliderEnd,
      sliderStart: sliderStart,
      endTime: endTime,
      startTime: startTime,
      state: playerState
    };

    if (
      atomicLandingPlay &&
      ytid === 'Y5WQ6gF-8vA' &&
      playerState === PlayerState.Playing
    ) {
      // analytics.actions.trackAction('Landing Video', 'Playing')
      window._gaq.push(['_trackEvent', 'Action', 'Landing Video', 'Playing']);

      setAtomicLandingPlay(false);
    }

    if (currentTime >= endTime - PLAYER_TIME_CHECK_INTERVAL) {
      console.log('cond 1');
      cueNext();
    } else if (currentTime < startTime) {
      console.log('cond 2');
      player.seekTo(startTime, true);
    } else if (playerState === PlayerState.Ended) {
      console.log('cond 3');
      cueNext();
    } else {
      setAtomicRepeat(false);
      // If we don't want to play a new video or repeat the current, we can assume the video is playing correctly
      if (playerStore.isLoading) {
        onSetIsLoading(false);
        setHasErrored(false);
      }
    }

    if (
      playerStore.ytid === playerStore.skipTo['ytid'] &&
      playerStore.skipTo['duration'] !== 0
    ) {
      player.seekTo(playerStore.skipTo['duration'], true);
      onClearSeek();
    }
  }

  /**
    Either cues the current video to repeat, or cues the next video in the playlist to play,
    depending on the current RepeatMode.
    
    @param when How long to wait (in seconds) before playing the video.
  */

  function cueNext() {
    if (atomicRepeat === true) {
      return;
    }
    let currentUTCDay = Math.floor(
      new Date().getTime() / (1000 * 60 * 60 * 24)
    );

    console.log('cueNext...', currentUTCDay);

    if (props.repeatMode) {
      repeatVideo();
    } else if (props.radioMode) {
      if (!playerStore.isLoading) {
        const oldYtid = ytid;
        onAddPlayStart(oldYtid);
        onSkipArtistRadio();
      }
    } else if (props.shuffleMode) {
      const ytids =
        (playlistState.playlists[playlistState.currentPlaylist] || {}).ytids ||
        [];

      if (ytids.length === 0) {
        // Do nothing
      } else if (ytids.length === 1) {
        if (ytids[0] !== props.ytid || playlistState.queue.length !== 0) {
          onPlayNextStart();
        } else {
          repeatVideo();
        }
      } else {
        const oldYtid = props.ytid;
        onAddPlayStart(oldYtid);
        onPlayNextStart();
      }
    } else if (props.streamMode && props.currentUTCDay !== currentUTCDay) {
      // The stream re-randomizes itself every 24 hours at midnight UTC.  This function
      // re-fetches the stream and continues playback with the new song list.
      onSetCurrentStreamStart(streamState.streamPlaying);
    } else {
      // Check for single song playlist
      const ytids =
        (playlistState.playlists[playlistState.currentPlaylist] || {}).ytids ||
        [];

      if (ytids.length === 0 && playlistState.queue.length === 0) {
        // Do Nothing
      } else if (ytids.length === 1) {
        if (ytids[0] !== props.ytid || playlistState.queue.length !== 0) {
          onPlayNextStart();
        } else {
          repeatVideo();
        }
      } else {
        const oldYtid = props.ytid;
        // Extra repeat increment problem is here
        onAddPlayStart(oldYtid);
        onPlayNextStart();
      }
    }
  }

  function repeatVideo() {
    if (atomicRepeat === true) {
      return;
    }
    if (
      userhistoryState.history[ytid] &&
      userhistoryState.history[ytid].repeats === 9 &&
      !cookie('lor-social-share-prompt-repeat')
    ) {
      // analytics.actions.trackAction('Social Share Prompt (Repeat)', 'Display')
      window._gaq.push([
        '_trackEvent',
        'Action',
        'Social Share Prompt (Repeat)',
        'Display'
      ]);

      // notification.actions.showSocialSharePromptRepeat(true)
      console.log('10 repeats');
    }
    onAddPlayStart(ytid);
    const startTime1 = Math.max(startTime, 0);
    player.seekTo(startTime1, true);
    console.log('before _trackPageview repeat');
    // window._gaq.push(["_set", "title", window.document.title]);
    // window._gaq.push("_trackPageview")
    window._gaq.push(['_trackEvent', 'Action', 'Repeated', ytid]);

    console.log('after _trackPageview repeat');
    playVideo();
  }

  const onError = (err) => {
    console.error('Error::', err);
    // 101 and 150 are the error codes for blocked videos
    // if (err.data == 101 || err.data == 150) {}
    setHasErrored(true);
    onSetIsLoading(false);

    // analytics.actions.trackAction(`Video Blocked`, props.ytid);
    window._gaq.push(['_trackEvent', 'Action', 'Video Blocked', props.ytid]);

    console.log('props');
    console.log(props);

    if (props.streamMode) {
      // analytics.actions.trackAction(
      //   `Stream Blocked [${streams.store.state.streamPlaying}]`,
      //   props.ytid
      // );
      window._gaq.push(['_trackEvent', 'Action', 'Stream Blocked', props.ytid]);

      onPlayNextStart();
    } else if (props.radioMode) {
      // analytics.actions.trackAction(
      //   `Radio Blocked [${artist.store.state.radioName}]`,
      //   props.ytid
      // );
      window._gaq.push(['_trackEvent', 'Action', 'Radio Blocked', props.ytid]);

      onPlayNextStart();
    } else if (
      // skip video if non system playlist or discovery
      playlistState.currentPlaylist &&
      (!_.includes(playlistState.currentPlaylist, 'lor:') ||
        playlistState.currentPlaylist === YourLorType.DISCOVERY_PLAYLIST)
    ) {
      console.log('2nd vs-b');
      onShowSnackbarNotification(`Video skipped - blocked`);
      onPlayNextStart();
    } else {
      console.log('else cnd');
      onShowSnackbarNotification(`Video skipped - blocked`);
      onPlayNextStart();
    }
  };

  return (
    <Grid container className={classes.playerWrapper}>
      <div className={classes.player}>
        <div className={classes.spinner}>
          <CircularProgress style={{ color: '#ff0066' }} />
        </div>
        <YouTube
          className={classes.iframe}
          video={ytid}
          autoplay={true}
          playsInline={true}
          onError={onError}
          onReady={onReady}
          onStateChange={onStateChange}
        />
      </div>
    </Grid>
  );
}

export default container(Player);