import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import PlaylistEmpty from '../PlaylistEmpty';
import { PlaylistCardListContent } from './components';

const useStyles = makeStyles((theme) => ({
  tabItemContainer: {
    color: theme.palette.textColor1,
    fontFamily: 'Arial',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.pink,
    },
    // backgroundColor: 'transparent',
  },
  tab: {
    fontWeight: 300,
    borderBottom: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.palette.borderColor,
    borderRightColor: theme.palette.borderColor,
    borderBottomColor: theme.palette.borderColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const PlaylistCardList = (props) => {
  const classes = useStyles();

  const { playlistState, uid, currentPlaylist } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const myPlaylistsList = _.compact(playlistState.playlistsByUser[uid] || []);
  let myPlaylists = _.map(
    myPlaylistsList,
    (pid) => playlistState.playlists[pid]
  );
  myPlaylists = _.compact(myPlaylists);

  const followedPlaylistsList = playlistState.followedPlaylistsByUser[uid];
  let followedPlaylists = _.map(
    followedPlaylistsList,
    (item) => playlistState.playlists[item.pid]
  );
  followedPlaylists = _.compact(followedPlaylists);

  let allPlaylists = _.concat(myPlaylists, followedPlaylists);
  allPlaylists = _.sortBy(allPlaylists, (p) => {
    return p.name.toLowerCase();
  });

  const hasPlaylists = myPlaylists.length > 0 || followedPlaylists.length > 0;
  return (
    <CardContent style={{ padding: 0 }}>
      {/* Show when the user is viewing their own playlists but has none */}
      {!hasPlaylists && <PlaylistEmpty />}

      {hasPlaylists && (
        <>
          <Tabs
            className={classes.tabItemContainer}
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
          >
            <Tab className={classes.tab} label="All" value={0} />
            <Tab className={classes.tab} label="Mine" value={1} />
            <Tab
              className={classes.tab}
              label="Followed"
              value={2}
              style={{
                borderRight: '0px solid',
              }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <PlaylistCardListContent
              editable
              uid={uid}
              currentPlaylist={currentPlaylist}
              playlists={allPlaylists}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PlaylistCardListContent
              uid={uid}
              editable
              currentPlaylist={currentPlaylist}
              playlists={myPlaylists}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PlaylistCardListContent
              uid={uid}
              editable={false}
              currentPlaylist={currentPlaylist}
              playlists={followedPlaylists}
            />
          </TabPanel>
        </>
      )}
    </CardContent>
  );
};

export default PlaylistCardList;
