export const TONE_FUSE = 'TONE_FUSE';
export const CAMPAIGN_TRACK = 'CAMPAIGN_TRACK';
export const BLACK_LIST_VIDEO = 'BLACK_LIST_VIDEO';
export const DETECT_AD_BLOCK = 'DETECT_AD_BLOCK';
export const LAST_REFRESH = 'LAST_REFRESH';
export const LAST_REFRESH_EXP = 'LAST_REFRESH_EXP';
export const DESTROYED_SINGLE_AD = 'DESTROYED_SINGLE_AD';
export const INIT_SINGLE_AD = 'INIT_SINGLE_AD';
export const INIT_ADS = 'INIT_ADS';
export const NO_ADS = 'NO_ADS';
export const DATA_MARKETING = 'DATA_MARKETING';
export const REFRESH_ADS = 'REFRESH_ADS';
export const SUBMIT_REPORT_DATA = 'SUBMIT_REPORT_DATA';