const styles = {
  root: {
    width: 100,
    height: 100,
  },
  image: {
    width: 50,
    height: 50,
    float: 'left',
    background: '#eee',
    backgroundSize: 'cover',
  },
};

export default styles;
