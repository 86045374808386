import { connect } from 'react-redux';

import { openPlaylistStart } from 'store/playlist/playlist.actions';

const mapDispatchToProps = (dispatch) => ({
  onOpenPlaylistStart: (value) => dispatch(openPlaylistStart(value)),
});

const container = connect(null, mapDispatchToProps);

export default container;
