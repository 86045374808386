import { connect } from 'react-redux';

import { setAutoplay, viewPlayerVideoStart } from 'store/player/player.actions';
import {
  setCurrentPlaylistStart,
  queueYtid,
} from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapStateToProps = (state) => ({
  ownUid: state.user.profile.uid,
  currentYitd: '',
  playlistState: state.playlist,
  videoState: state.video,
  playerState: state.player,
  userState: state.user,
  userhistoryState: state.userhistory,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onSetCurrentPlaylistStart: (value) =>
    dispatch(setCurrentPlaylistStart(value)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

// const container = connect(mapStateToProps);
const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
