import { takeLatest, call, put, all, delay } from "redux-saga/effects";

import axios from "instances/server";

import { GOOGLE_INIT_START, GOOGLE_FETCH_PROFILE_START } from "./google.types";
import {
  loadSDK,
  googleInitSuccess,
  googleFetchProfileSuccess,
  googleFetchProfileFail,
} from "./google.actions";
import { setUserProfileStart } from "store/user/user.actions";
import localStorage from "redux-persist/es/storage";

export function* googleInitAsync() {
  if (!window.gapi) {
    yield call(delay, 2000);
    yield loadSDK();
  }

  yield put(googleInitSuccess());
}

export function* fetchGoogleProfileAsync() {
  try {
    // console.log('fetchGoogleProfileAsync...');
    const { data } = yield axios.get("/google/profile");
    console.log(data, "gooooooooogle");
    yield put(googleFetchProfileSuccess(data.profile));
    yield put(setUserProfileStart());

    if (data.profile.email) {
      console.log("email from google", data.profile.email);
      localStorage.setItem("user_email", data.profile.email);

      // window.conversant.launch(
      //   "publink",
      //   "start",

      //   { siteId: 126629, logging: "debug", email: data.profile.email }
      // );
    }
  } catch (err) {
    yield put(googleFetchProfileFail(err));
  }
}

export function* watchGoogleInit() {
  yield takeLatest(GOOGLE_INIT_START, googleInitAsync);
}

export function* watchFetchFbProfile() {
  yield takeLatest(GOOGLE_FETCH_PROFILE_START, fetchGoogleProfileAsync);
}

export function* googleSagas() {
  yield all([call(watchGoogleInit), call(watchFetchFbProfile)]);
}
