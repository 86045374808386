import React from 'react';
import Popover from '@material-ui/core/Popover';

const UserWidgetPopover = (props) => {
  const { anchorEl, handleClose } = props;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <li className="greeting">Hi, {props.firstName}!</li>

      <li>
        <div className="actions">
          <div className="action">Log out</div>
          <div className="clearfix" />
        </div>
      </li>
    </Popover>
  );
};

export default UserWidgetPopover;
