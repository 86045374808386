import * as NotificationType from './notification.types';

export const showSocialSharePromptRepeat = (show) => ({
  type: NotificationType.SHOW_SOCIAL_SHARE_PROMPT_REPEAT,
  payload: show,
});

export const showRadioRepeatPrompt = (repeatYtid) => ({
  type: NotificationType.SHOW_RADIO_REPEAT_PROMPT,
  payload: { repeatYtid },
});

export const hideRadioRepeatPrompt = () => ({
  type: NotificationType.HIDE_RADIO_REPEAT_PROMPT,
});

export const hardRadioConversion = () => ({
  type: NotificationType.HARD_RADIO_CONVERSION,
});

export const hideFeatureDemo = () => ({
  type: NotificationType.HIDE_FEATURE_DEMO,
});

export const initNotification = () => ({
  type: NotificationType.INIT_NOTIFICATION,
});

export const getNotification = () => ({
  type: NotificationType.GET_NOTIFICATION,
});

export const dismissStart = () => ({
  type: NotificationType.DISMISS_START,
});

export const dismissSuccess = (dismissedAt) => ({
  type: NotificationType.DISMISS_SUCCESS,
  payload: { dismissedAt },
});

export const showSnackbarNotification = (message) => ({
  type: NotificationType.SHOW_SNACKBAR_NOTIFICATION,
  payload: { message },
});

export const hideSnackbarNotification = () => ({
  type: NotificationType.HIDE_SNACKBAR_NOTIFICATION,
});
