import React from 'react';

import SectionTab from 'constants/SectionTab';

import ArticlePage from 'modules/article';
import { FavoritesPage, HistoryPage } from 'modules/userhistory';
import { YourLorPage as YourLORPage } from 'modules/yourlor';
import StreamsPage, { Top10Page } from 'modules/streams';
import AboutUsPage from 'modules/aboutus';
import ContactPage from 'modules/contactform';
import FaqPage from 'modules/faq';
import PlaylistPage from 'modules/playlist';
import QueuePage from 'modules/queue';
import SearchResultsPage from 'modules/search';
import ArtistRadioPage from 'modules/artist';

const ContentArea = (props) => {
  const { currentTab } = props;

  function getActiveTab() {
    switch (currentTab) {
      case SectionTab.Reviews:
        return <ArticlePage />;

      case SectionTab.YourLOR:
        return <YourLORPage />;

      case SectionTab.Streams:
        return <StreamsPage />;

      case SectionTab.Favorites:
        return <FavoritesPage />;

      case SectionTab.History:
        return <HistoryPage />;

      case SectionTab.Top10:
        return <Top10Page />;

      case SectionTab.About:
        return <AboutUsPage />;

      case SectionTab.Search:
        return <SearchResultsPage />;

      case SectionTab.Radio:
        return <ArtistRadioPage />;

      case SectionTab.Playlists:
        return <PlaylistPage />;

      case SectionTab.PlayQueue:
        return <QueuePage />;

      case SectionTab.Contact:
        return <ContactPage />;

      case SectionTab.Faq:
        return <FaqPage />;

      default:
        return <YourLORPage />;
    }
  }

  return (
    <div className="main-content-area" style={{ paddingBottom: 30 }}>
      {getActiveTab()}
    </div>
  );
};

export default ContentArea;
