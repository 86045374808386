import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import * as PlayerRadioType from './playerRadio.types';
import * as playerRadioAction from './playerRadio.actions';
import * as userhistoryRadioAction from '../userhistoryRadio/userhistoryRadio.actions';

const getRadioState = (state) => state.radio;
const getRadioHistoryState = (state) => state.userhistoryRadio;

export function* viewRadioPlayerAsync({ payload: { stationid } }) {
  const radioState = yield select(getRadioState);
  const userRadioHistory = yield select(getRadioHistoryState);
  const radioStateObject = {...radioState.radios, ...userRadioHistory.history};

  const stationData = radioStateObject[stationid];
  // console.log('here is data:: ', value);
  console.log('here is player:: ', stationData);
  window._gaq.push(['_trackPageview']);
  window._gaq.push(['_trackEvent', 'RadioStation', stationData.countrycode.toString(), stationData.name.toString()]);

  yield put(userhistoryRadioAction.addPlayRadioStationStart(stationData));
  yield put(playerRadioAction.setRadioUrl());
}

export function* watchViewRadioPlayer() {
  yield takeLatest(
    PlayerRadioType.VIEW_RADIO_PLAYER_START,
    viewRadioPlayerAsync
  );
}

export function* playerRadioSagas() {
  yield all([call(watchViewRadioPlayer)]);
}
