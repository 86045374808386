import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  results: state.search.results,
  query: state.search.query,
  next: state.search.next,
  searchState: state.search,
  artistState: state.artist,
  videoState: state.video,
  userhistoryState: state.userhistory,
  currentYtid: state.player.ytid,
});

const container = connect(mapStateToProps);

export default container;
