import * as LoginTypes from './login.types';
import LoginStatus from 'constants/LoginStatus';

const INITIAL_STATE = {
  loginStatus: LoginStatus.Anonymous,
  assimilating: false,
  isLoginModalVisible: false,
  isLoginSuccess: false,
  isLoginFailed: false,
  isMergeModalVisible: false,
  isLogoutConfirmationVisible: false,
  isTrackingModeProtectionEnabledVisible: false,
  isTrackingBlockedVisible: false,
  isThirdPartyBlockedVisible: false,
  isCreatePasswordModalVisible: false,
  loginProvider: "",
  authedUid: null,
};

const loginReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LoginTypes.SHOW_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalVisible: true,
        isLoginFailed: false
      };

    case LoginTypes.HIDE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalVisible: false,
        isLoginFailed: false
      };

    case LoginTypes.FB_LOGIN_START:
    case LoginTypes.GOOGLE_LOGIN_START:
    case LoginTypes.EMAIL_LOGIN_START:
      return {
        ...state,
        loginStatus: LoginStatus.LoggingIn
      };

    case LoginTypes.LOGIN_SUCCESS:
      localStorage.setItem('token', payload.accessToken);
      localStorage.setItem('refreshToken', payload.refreshToken);
      return {
        ...state,
        loginStatus: LoginStatus.Authenticated,
        // isMergeModalVisible: true,
        isLoginSuccess: true,
        isCreatePasswordModalVisible: !payload.alreadyGeneratedPassword && (payload.provider === "google" || payload.provider === "facebook"),
        loginProvider: payload.provider,
        authedUid: payload?.userData?.authedUid
      };

    case LoginTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loginStatus: LoginStatus.Anonymous,
        isMergeModalVisible: false,
        isLoginSuccess: false,
        isLoginFailed: false
      };

    case LoginTypes.LOGIN_FAIL:
      return {
        ...state,
        loginStatus: LoginStatus.Anonymous,
        isLoginFailed: true
      };

    case LoginTypes.SHOW_MERGE_MODAL:
      return {
        ...state,
        isMergeModalVisible: true,
        isLoginSuccess: false,
        isLoginFailed: false
      };

    case LoginTypes.HIDE_MERGE_MODAL:
      console.log('on hide merge modal clickedddd');
      return {
        ...state,
        isMergeModalVisible: false,
        isLoginSuccess: false,
        isLoginFailed: false
      };

    case LoginTypes.SHOW_LOGOUT_CONFIRM_MODAL:
      return {
        ...state,
        isLogoutConfirmationVisible: true
      };

    case LoginTypes.HIDE_LOGOUT_CONFIRM_MODAL:
      return {
        ...state,
        isLogoutConfirmationVisible: false
      };

    case LoginTypes.MERGE_ACCOUNT_START:
      console.log('WILL_MERGE_ACCOUNTS clickedddd');
      return {
        ...state,
        assimilating: true,
        isLoginSuccess: false,
        isLoginFailed: false
      };

    case LoginTypes.MERGE_ACCOUNT_SUCCESS:
      console.log('MERGE_ACCOUNTS clickedddd');
      return {
        ...state,
        assimilating: false,
        isLoginSuccess: false,
        isLoginFailed: false
      };

    case LoginTypes.LOAD_USER_SUCCESS:
      return {
        ...state,
        loginStatus: LoginStatus.Authenticated
      };
      case LoginTypes.HIDE_GENERATE_PASSWORD_MODAL:
      return {
        ...state,
        isCreatePasswordModalVisible: false,
      }
      case LoginTypes.SHOW_GENERATE_PASSWORD_MODAL:
      return {
        ...state,
        isCreatePasswordModalVisible: true,
        authedUid: payload.authedUid,
        loginProvider: payload.provider,
      }
    default:
      return state;
  }
};

export default loginReducer;
