import _ from 'lodash';
import * as UserhistoryPodcastType from './userHistoryPodcast.types';
import * as UserhistoryRadioType from '../userhistoryRadio/userhistoryRadio.types';

const INITIAL_STATE = {
  history: {},
  historyPodcastIds: [],
  heartedPodcastIds: [],
  historyNext: 3,
  heartsNext: 3,
  errors: {},
  historyLoading: true,
  heartedLoading: true
};

const userhistoryPodcastReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let updatedState, podcastId, response, merge;
  switch (type) {
    case UserhistoryPodcastType.GET_FAVORITE_PODCAST_START:
      return {
        ...state,
        heartedLoading: true
      };

    case UserhistoryPodcastType.GET_FAVORITE_PODCAST_SUCCESS:
      updatedState = {
        ...state,
        heartedLoading: false
      };
      updatedState.heartedPodcastIds = payload;
      return updatedState;

    case UserhistoryPodcastType.GET_FAVORITE_PODCAST_FAIL:
      return {
        ...state,
        heartedLoading: false,
        errors: payload
      };

    case UserhistoryPodcastType.GET_HISTORY_PODCAST_START:
      return {
        ...state,
        historyLoading: true
      };

    case UserhistoryPodcastType.GET_HISTORY_PODCAST_SUCCESS:
      updatedState = {
        ...state,
        historyLoading: false
      };
      updatedState.historyPodcastIds = payload;
      return updatedState;

    case UserhistoryPodcastType.GET_HISTORY_PODCAST_FAIL:
      return {
        ...state,
        historyLoading: false,
        errors: payload
      };

    case UserhistoryPodcastType.GET_HISTORY_PODCAST_METADATA_SUCCESS:
      updatedState = {
        ...state,
        history: {
          ...state.history
        }
      };
      _.values(payload)
        .filter((m) => !_.isNil(m))
        .forEach((meta) => {
          updatedState.history[meta.id] = meta;
        });
      return updatedState;

    case UserhistoryPodcastType.ADD_PLAY_PODCAST_SUCCESS:
      const playData = payload.podcast;
      updatedState = {
        ...state
      };

      console.log({ playData });

      updatedState.historyPodcastIds = updatedState.historyPodcastIds.concat(
        playData.id
      );
      updatedState.historyPodcastIds = _.uniq(updatedState.historyPodcastIds);

      updatedState.history[playData.id] = playData;
      return updatedState;

    case UserhistoryPodcastType.ADD_PLAY_PODCAST_FAIL:
      return {
        ...state,
        errors: payload
      };

    /*case UserhistoryPodcastType.PODCAST_HEART_START:
      let podcastId = payload.podcastId;
      updatedState = {
        ...state,
      };
      updatedState.heartedPodcastIds = updatedState.heartedPodcastIds.concat(
          podcastId,
      );
      updatedState.history[podcastId] = genresPodcasts[podcastId];
      return updatedState;*/

    case UserhistoryPodcastType.PODCAST_HEART_SUCCESS:
      const data = payload.podcast;
      updatedState = {
        ...state
      };
      updatedState.heartedPodcastIds = updatedState.heartedPodcastIds.concat(
        data.id
      );
      updatedState.history[data.id] = data;
      return updatedState;

    case UserhistoryPodcastType.PODCAST_HEART_FAIL:
      return {
        ...state,
        errors: payload
      };

    case UserhistoryPodcastType.DELETE_FAVORITE_PODCAST_START:
      podcastId = payload.podcastId;
      updatedState = {
        ...state
      };
      if (updatedState.heartedPodcastIds.indexOf(podcastId) === -1) {
        updatedState.heartedPodcastIds.unshift(podcastId);
      }
      const idx = updatedState.heartedPodcastIds.indexOf(podcastId);
      if (idx > -1) {
        updatedState.heartedPodcastIds.splice(idx, 1);
      }
      updatedState.history[podcastId] = updatedState.history[podcastId] || {
        podcastId
      };
      return updatedState;

    case UserhistoryPodcastType.DELETE_HISTORY_PODCAST_START:
      podcastId = payload.podcastId;
      updatedState = {
        ...state
      };
      if (updatedState.historyPodcastIds.indexOf(podcastId) === -1) {
        updatedState.historyPodcastIds.unshift(podcastId);
      }
      const idxh = updatedState.historyPodcastIds.indexOf(podcastId);
      if (idxh > -1) {
        updatedState.historyPodcastIds.splice(idxh, 1);
      }
      updatedState.history[podcastId] = updatedState.history[podcastId] || {
        podcastId
      };
      return updatedState;

    case UserhistoryRadioType.GET_NEXT_HEART_START:
      return {
        ...state,
        heartsNext: payload.cursor + payload.limit
      };

    case UserhistoryRadioType.GET_NEXT_HISTORY_START:
      return {
        ...state,
        historyNext: payload.cursor + payload.limit
      };

    default:
      return state;
  }
};

export default userhistoryPodcastReducer;
