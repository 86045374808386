import { connect } from 'react-redux';

import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';
import {
  setAutoplay,
  viewPlayerVideoStart,
  setRepeatMode,
} from 'store/player/player.actions';
import { setCurrentPlaylistStart } from 'store/playlist/playlist.actions';
import { setupArtistRadio } from 'store/artist/artist.actions';
import { clearCurrentlyViewedStream } from 'store/streams/streams.actions';

const mapDispatchToProps = (dispatch) => ({
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onSetRepeatMode: (value) => dispatch(setRepeatMode(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onSetCurrentPlaylistStart: (value) =>
    dispatch(setCurrentPlaylistStart(value)),
  onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
  onSetupArtistRadio: (artistName, ytid) =>
    dispatch(setupArtistRadio(artistName, ytid)),
  onClearCurrentlyViewedStream: () => dispatch(clearCurrentlyViewedStream()),
});

const container = connect(null, mapDispatchToProps);

export default container;
