import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ArtistRow from '../ArtistRow';

import styles from './RecommendedArtists.styles';

const RecommendedArtists = (props) => {
  const displayCount = 7;

  const artists = _.take(props.artists, displayCount).map((a) => (
    <ArtistRow key={a?.name} name={a?.name} image={a?.image} />
  ));
  return (
    props.artists.length >= 7 && (
      <div style={styles.wrapper}>
        <div style={styles.text}>{props?.title}</div>

        <div style={styles.artistWrapper}>{artists}</div>
      </div>
    )
  );
};

RecommendedArtists.propTypes = {};

export default RecommendedArtists;
