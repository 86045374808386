import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import ErrorBoundry from 'common/ErrorBoundary';
import MinimalLayout from 'layouts/Minimal';

import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  RadioMain as RadioLayout,
  PodcastMain as PodcastLayout
} from '../layouts';
import{ getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react";
import * as reactRouterDom from "react-router-dom";

const LandingPage = lazy(() => import('pages/LandingPage'));
const RadioPage = lazy(() => import('pages/RadioPage'));
const PodcastPage = lazy(() => import('pages/PodcastPage'));
const Privacy = lazy(() => import('pages/Privacy'));
const Terms = lazy(() => import('pages/Terms'));
const FAQ = lazy(() => import('pages/faq'));
const NotFound = lazy(() => import('pages/PageNotFound'));

const Routes = () => {
  return (
    <Suspense fallback={<MinimalLayout />}>
      {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
      <ErrorBoundry>
        <Switch>
          <RouteWithLayout
            exact
            component={LandingPage}
            layout={MainLayout}
            path="/watch"
          />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <RouteWithLayout
            component={RadioPage}
            layout={RadioLayout}
            path="/radio"
          />
          <RouteWithLayout
            component={PodcastPage}
            layout={PodcastLayout}
            path="/podcast"
          />
          <Route exact path="/faq" component={FAQ} />
          <RouteWithLayout
            exact
            component={LandingPage}
            layout={MainLayout}
            path="/"
          />

          <RouteWithLayout path="*" component={NotFound} layout={MainLayout} />
        </Switch>
      </ErrorBoundry>
    </Suspense>
  );
};

export default Routes;
