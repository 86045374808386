import React from 'react';

import { ContentPage } from 'common/ui/content-page';
import { SearchResultsCard } from './components';
import container from './SearchResultsPage.container';

const SearchResultsPage = (props) => {
  // console.log("main props", props);
  const { query } = props;

  return (
    <ContentPage
      header={{
        title: 'Search results',
        description: `${query}`,
      }}
      body={<SearchResultsCard {...props} />}
    />
  );
};

export default container(SearchResultsPage);
