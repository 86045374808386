export const SHOW_SOCIAL_SHARE_PROMPT_REPEAT =
  'SHOW_SOCIAL_SHARE_PROMPT_REPEAT';
export const SHOW_RADIO_REPEAT_PROMPT = 'SHOW_RADIO_REPEAT_PROMPT';
export const HIDE_RADIO_REPEAT_PROMPT = 'HIDE_RADIO_REPEAT_PROMPT';
export const HARD_RADIO_CONVERSION = 'HARD_RADIO_CONVERSION';
export const HIDE_FEATURE_DEMO = 'HIDE_FEATURE_DEMO';
export const INIT_NOTIFICATION = 'INIT_NOTIFICATION';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';

export const DISMISS_START = 'DISMISS_START';
export const DISMISS_SUCCESS = 'DISMISS_SUCCESS';

export const SHOW_SNACKBAR_NOTIFICATION = 'SHOW_SNACKBAR_NOTIFICATION';
export const SHOW_SNACKBAR_NOTIFICATION_SUCCESS =
  'SHOW_SNACKBAR_NOTIFICATION_SUCCESS';
export const HIDE_SNACKBAR_NOTIFICATION = 'HIDE_SNACKBAR_NOTIFICATION';

export const IN_RADIO_PROMPT_TEST = 'IN_RADIO_PROMPT_TEST';
export const SHOW_ARTIST_RADIO_PROMPT = 'SHOW_ARTIST_RADIO_PROMPT';
