import { connect } from 'react-redux';

import { closeToolTip } from 'store/tooltip/tooltip.actions';

const mapDispatchToProps = (dispatch) => ({
  onCloseToolTip: () => dispatch(closeToolTip()),
});

const container = connect(null, mapDispatchToProps);

export default container;
