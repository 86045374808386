import * as PlayerType from './player.types';

export const initPlayer = (video) => ({
  type: PlayerType.INIT_PLAYER,
  payload: { video },
});

export const viewPlayerVideoStart = (ytid, radioMode = false) => ({
  type: PlayerType.VIEW_PLAYER_VIDEO_START,
  payload: { ytid, radioMode },
});

export const viewPlayerVideoSuccess = (data) => ({
  type: PlayerType.VIEW_PLAYER_VIDEO_SUCCESS,
  payload: data,
});

export const viewPlayerVideoFail = (error) => ({
  type: PlayerType.VIEW_PLAYER_VIDEO_FAIL,
  payload: error,
});

export const setPlayerYtidStart = (ytid) => ({
  type: PlayerType.SET_PLAYER_YTID_START,
  payload: { ytid },
});

export const setPlayerYtidSuccess = (ytid) => ({
  type: PlayerType.SET_PLAYER_YTID_SUCCESS,
  payload: ytid,
});

export const playerDidChangeState = (newState) => ({
  type: PlayerType.PLAYER_DID_CHANGE_STATE,
  payload: newState,
});

export const playerIsReady = () => ({
  type: PlayerType.PLAYER_IS_READY,
});

export const setShuffleMode = (newMode) => ({
  type: PlayerType.SET_SHUFFLE_MODE,
  payload: newMode,
});

export const setRepeatMode = (newMode) => ({
  type: PlayerType.SET_REPEAT_MODE,
  payload: newMode,
});

export const setRadioMode = (newMode) => ({
  type: PlayerType.SET_RADIO_MODE,
  payload: newMode,
});

export const setIsLoading = (isLoading) => ({
  type: PlayerType.SET_IS_LOADING,
  payload: isLoading,
});

export const setAutoplay = (autoplay) => ({
  type: PlayerType.SET_AUTOPLAY,
  payload: autoplay,
});

export const getStreamInit = (streamInit) => ({
  type: PlayerType.GET_STREAM_INIT,
  payload: streamInit,
});

export const playFeature = (feature) => ({
  type: PlayerType.PLAY_FEATURE,
  payload: feature,
});

export const clearSeek = () => ({
  type: PlayerType.CLEAR_SEEK,
});

export const togglePlayPause = () => ({
  type: PlayerType.TOGGLE_PLAY_PAUSE,
});

export const toggleRepeatMode = () => ({
  type: PlayerType.TOGGLE_REPEAT_MODE,
});

export const toggleShuffleMode = () => ({
  type: PlayerType.TOGGLE_SHUFFLE_MODE,
});

export const playNextStart = () => ({
  type: PlayerType.PLAY_NEXT_START,
});

export const playNextSuccess = () => ({
  type: PlayerType.PLAY_NEXT_SUCCESS,
});

export const playPrevStart = () => ({
  type: PlayerType.PLAY_PREV_START,
});

export const playPrevSuccess = () => ({
  type: PlayerType.PLAY_PREV_SUCCESS,
});

export const playRandomStart = () => ({
  type: PlayerType.PLAY_RANDOM_START,
});

export const playRandomSuccess = () => ({
  type: PlayerType.PLAY_RANDOM_SUCCESS,
});

export const twitterMetatag = () => ({
  type: PlayerType.TWITTER_METATAG,
});

export const setLastPlayed = (ytid) => ({
  type: PlayerType.SET_LAST_PLAYED,
  payload: ytid,
});
