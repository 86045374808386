import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RepeatUserIcon2 = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g id="Icon">
      <g transform="translate(2.000000, 0.000000)">
        <path
          d="M11.2515809,2.08011194 L11.7930561,0.662718205 C11.7930561,0.0722488064 11.3895733,-0.169168061 10.8961515,0.125971216 L6.74781864,2.60903435 C6.25448893,2.90426904 6.25448893,3.38738904 6.74781864,3.68262374 L10.8961515,6.16568688 C11.3895733,6.46092158 11.7930561,6.2194093 11.7930561,5.62893989 L11.2515809,4.28339869 C15.4699687,4.70363579 18.7794846,8.3978864 18.7794846,12.8787934 C18.7794846,17.6405264 15.042183,21.514456 10.4483897,21.514456 C5.85459638,21.514456 2.11729482,17.6405264 2.11729482,12.8787934 C2.11729482,10.2585139 3.2461812,7.8096117 5.21434483,6.15986616 C5.66901088,5.77875194 5.73952601,5.08789893 5.37194521,4.61661122 C5.00427236,4.1453235 4.33769272,4.0722305 3.88311872,4.4532493 C1.41527351,6.52160978 0,9.59266134 0,12.8787934 C0,18.8506642 4.68713842,23.7091549 10.4483897,23.7091549 C16.2096411,23.7091549 20.8967795,18.8506642 20.8967795,12.8787934 C20.8967795,7.18708061 16.6389915,2.50664688 11.2515809,2.08011194"
          id="Shape"
        />
        <path
          d="M10.4331599,20.4069336 C9.34164168,20.4069336 7.62776958,19.9675535 6.42983307,19.2077148 C5.50516604,18.6212082 4.92328188,17.6912717 4.95204844,17.5742025 C5.38105534,15.828304 7.14207339,14.8984131 7.14207339,14.8984131 L8.99759887,14.0983805 C8.99759887,14.0983805 9.2398295,14.408433 9.62477418,14.5634521 C9.96694148,14.7012446 10.4139264,14.7133482 10.5090443,14.7143366 C10.5090443,14.69601 11.0266533,14.7000712 11.411598,14.5450521 C11.7965426,14.3900329 12.0387733,14.0799805 12.0387733,14.0799805 L13.8942988,14.880013 C13.8942988,14.880013 15.4576587,15.828304 15.8866656,17.5742025 C15.9079206,17.6607026 15.2700248,18.5331392 14.6223387,18.984668 C13.4293655,19.816339 11.6989096,20.4069336 10.4331599,20.4069336 Z"
          id="Combined-Shape"
        />
        <path
          d="M10.5000098,7.20190428 C10.6684334,7.1933365 11.596625,7.18250491 12.251753,7.92471672 C12.4410642,8.13919238 12.7746525,8.63566197 12.8747203,9.16874448 C13.0071352,9.87414762 12.8747203,10.6191925 12.8747203,10.6191925 C12.8747203,10.6191925 13.3819401,10.7312403 13.0563293,11.7551442 C12.9710691,12.0232503 12.7526909,12.3203741 12.54329,12.7341038 C11.6282109,14.5420971 10.6705372,14.5555646 10.5000098,14.5423764 C10.3294824,14.5555646 9.37180876,14.5420971 8.45672961,12.7341038 C8.24732874,12.3203741 8.0289505,12.0232503 7.94369032,11.7551442 C7.61807954,10.7312403 8.12529939,10.6191925 8.12529939,10.6191925 C8.12529939,10.6191925 7.99288442,9.87414762 8.12529939,9.16874448 C8.22536713,8.63566197 8.55895543,8.13919238 8.74826663,7.92471672 C9.40339462,7.18250491 10.3315862,7.1933365 10.5000098,7.20190428 Z"
          id="Combined-Shape"
        />
      </g>
      <rect id="Rectangle" x="0" y="0" width="24" height="24" fill="none" />
    </g>
  </SvgIcon>
);

export default RepeatUserIcon2;
