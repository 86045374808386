import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

export function scrollToVideo(smooth = true) {
  const scroll = window.scroll;
  scroll({ top: 0, behavior: smooth ? 'smooth' : 'auto' });
}

export function scrollToMainContentArea() {
  scrollToContent();
}

export function instantScrollToMainContentArea() {
  scrollToContent(false);
}

function scrollToContent(smooth = true) {
  const elem = document.getElementById('main-content-body');
  const pos = calculatePositionFromElement(elem);

  const scroll = window.scroll;
  const newTop = pos.y < 48 ? 0 : pos.y - 48;
  scroll({ top: newTop, behavior: smooth ? 'smooth' : 'auto' });
}

export function scrollToMainContent(smooth = true) {
  const elem = document.getElementById('main-content-area');
  const pos = calculatePositionFromElement(elem);

  const scroll = window.scroll;
  const newTop = pos.y < 48 ? 0 : pos.y - 48;
  // const newTop = pos.y < 148 ? 0 : pos.y - 148;
  scroll({ top: newTop, behavior: smooth ? 'smooth' : 'auto' });
}

export function scrollToTopContent(smooth = true) {
  const elem = document.getElementById('top-content-area');
  const pos = calculatePositionFromElement(elem);

  const scroll = window.scroll;
  const newTop = pos.y < 48 ? 0 : pos.y - 48;
  scroll({ top: newTop, behavior: smooth ? 'smooth' : 'auto' });
}

export function calculatePositionFromElement(element) {
  // Calc position data
  var htmlElement = element;
  var top = 0,
    left = 0;
  do {
    top += htmlElement ? htmlElement.offsetTop : 0;
    left += htmlElement ? htmlElement.offsetLeft : 0;
    htmlElement = htmlElement ? htmlElement.offsetParent : null;
  } while (htmlElement);

  return {
    x: left,
    y: top,
  };
}

export function fmtHSS (seconds) {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const hours = seconds / 3600;
  const minutes = (seconds % 3600) / 60;
  return [hours, minutes, seconds % 60].map(format).join(':');
}

export const fmtMSS = (s) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + ~~s;
};

export const formatDateTime = (stringDate) => {
  const mydate = new Date(stringDate);
  let hours = mydate.getHours();
  let minutes = mydate.getMinutes();
  // const seconds = mydate.getSeconds();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  // console.log('mydate: ', mydate);
  return mydate.toDateString()+', '+ strTime;
};

export function getTextWidth(text, font) {
  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
}

export function getMarqueeStyle(textWidth) {
  let animationTime = textWidth / 10;
  return {
    animationDuration: animationTime + 's',
    animation: 'leftMenuShadow linear infinite',
    display: 'block',
  };
}
