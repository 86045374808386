import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Tab, Tabs, Divider, Button } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { red } from '@material-ui/core/colors';
import moment from 'moment';

import { usePrevious } from 'hooks';

import {
  LOR as LORIcon,
  TrophyIcon,
  TimerIcon2,
  PersonIcon,
} from 'common/ui/icons';
import { nullish } from 'lib/misc';

import container from './ArticleDetail.container';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 345,
    margin: theme.spacing(1, 0),
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
  },
  avatar: {
    backgroundColor: red[500],
  },
  subtitleIcon: {
    marginTop: 3,
    paddingRight: 6,
    height: 14,
    width: 14,
  },
  titleFontFamily: {
    fontSize: 15,
    fontFamily: 'Arial',
    lineHeight: 0.8,
    [theme.breakpoints.up('xs')]: {
      lineHeight: 1,
    },
  },
  subtitleFontFamily: {
    fontSize: 14,
    fontFamily: 'Arial',
    display: 'flex',
    color: theme.palette.textColor2,
  },
  articleDetail: {
    color: theme.palette.textColor1,
    padding: '0px 4px',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Arial',
    lineHeight: '16px',
    '& h1': {
      lineHeight: '30px',
    },
  },
  tabRoot: {
    color: theme.palette.textColor1,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.pink,
    },
  },
  tabButton: {
    color: theme.palette.textColor,
    fontFamily: 'Arial',
    minHeight: 48,
    '& .MuiTab-wrapper': {
      alignItems: 'initial',
      flexDirection: 'row',
    },
  },
}));

const ArticleDetail = (props) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { articleState, playerState, onSetLyricsStart } = props;

  const newYtid = playerState?.ytid;
  const prevYtid = usePrevious(newYtid);

  const [ytid, setYtid] = useState('');
  useEffect(() => {
    if (prevYtid !== newYtid) {
      setYtid(newYtid);
    }
  }, [prevYtid, newYtid]);

  const newArticle = articleState?.articles[ytid];

  const prevArticle = usePrevious(newArticle);

  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (prevArticle !== newArticle) {
      setArticle(newArticle);
    }
  }, [prevArticle, newArticle]);

  // console.log('[ArticleDetail] article::', article);

  const newContributor = articleState?.playerContributor;
  const prevContributor = usePrevious(newContributor);

  const [contributor, setContributor] = useState(null);

  // console.log('###ArticleDetail...');

  if (nullish(article)) {
    return null;
  }

  const content = article.content
    .replace(/<br>/g, '\n')
    .split('\n')
    .filter((x) => x.trim() !== '')
    .join('<br /><br />');

  const avatar = () => {
    if (!nullish(article.authorPhoto)) {
      return <Avatar src={article.authorPhoto} />;
    } else if (!nullish(article.authorName)) {
      return <Avatar>{article.authorName.charAt(0).toUpperCase()}</Avatar>;
    } else {
      return <Avatar>{article.title.charAt(0).toUpperCase()}</Avatar>;
    }
  };

  const onLyricsClick = () => {
    onSetLyricsStart(ytid, {
      startTime: 17,
      endTime: 181,
      show: true,
    });
  };

  const articleSubtitle = () => {
    const isCreatedFlag = article.created && article.created !== 0;
    const formattedDate =
      isCreatedFlag &&
      moment('' + article.created, 'X').format('MMMM Do, YYYY');

    // console.log({ isCreatedFlag, formattedDate });
    return (
      <div className={classes.subtitleFontFamily}>
        <Hidden xsDown>
          {article.authorName ? (
            <div>
              Reviewed by&nbsp;
              <span style={{ verticalAlign: 'top', paddingRight: 8 }}>
                {article.authorName}
              </span>
            </div>
          ) : ''}
        </Hidden>

        {article.flagLeaderboard && (
          <div className={classes.subtitleItem}>
            <TrophyIcon className={classes.subtitleIcon} />
            <span style={{ verticalAlign: 'top' }}>Most repeated</span>
          </div>
        )}

        {article.flagStaffPick && (
          <div className={classes.subtitleItem}>
            <LORIcon className={classes.subtitleIcon} />
            <span style={{ verticalAlign: 'top', paddingRight: 8 }}>
              Staff picks
            </span>
          </div>
        )}

        {isCreatedFlag && (
          <div className={classes.subtitleItem}>
            <AccessTimeIcon className={classes.subtitleIcon} />
            <span style={{ verticalAlign: 'top' }}>{formattedDate}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <Card className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          className={classes.tabRoot}
        >
          <Tab label="Description" className={classes.tabButton}>
          </Tab>
          <Tab label="Lyrics / Captions" className={classes.tabButton} >
          </Tab>
        </Tabs>
        <Divider />
        <TabPanel value={value} index={0}>
          <CardHeader
            title={article.title}
            subheader={articleSubtitle()}
            avatar={avatar()}
            classes={{
              title: classes.titleFontFamily,
            }}
          />
          <CardContent>
            <Typography
              classes={{
                root: classes.articleDetail,
              }}
              component="div"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </CardContent>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CardContent>
            <Typography
              classes={{
                root: classes.articleDetail,
              }}
              component="div"
              dangerouslySetInnerHTML={{ __html: article.lyrics }}
            />
          </CardContent>
        </TabPanel>
      </Card>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props
  return (
    <>
      {
        value === index && (
          <div>{children}</div>
        )
      }
    </>
  )
}

export default container(ArticleDetail);
