import _ from 'lodash';

import * as ArtistType from './artist.types';

import { normalizeArtistName } from './artist.utils';

const INITIAL_STATE = {
  allArtists: [],
  title: 'Recommend Artists',
  artistRadioRecs: [],
  radioName: '',
  nextVideos: [],
  radioHistory: [],
  searchingQueue: [],
  searchPreview: {
    radioName: null,
    startVideo: null,
    artistRadioRecs: [],
  },
  videoPreview: {
    radioName: null,
    startVideo: null,
    artistRadioRecs: [],
  },
  artistsRecs: {},
  info: {},
  artistsInfo: {},
};

const artistReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let updatedState;

  switch (type) {
    case ArtistType.SET_DATA_SUCCESS:
      return {
        ...state,
        allArtists: payload.artists,
        title: payload.title,
      };

    case ArtistType.SET_RADIO:
      return {
        ...state,
        nextVideos: [],
        artistRadioRecs: payload.artistRadioRecs,
        radioHistory: [],
        radioName: _.unescape(payload.radioName),
      };

    case ArtistType.REMOVE_ARTIST_RADIO_REC:
      updatedState = {
        ...state,
      };
      if (!updatedState.artistRadioRecs) {
        return updatedState;
      }

      updatedState.artistRadioRecs = updatedState.artistRadioRecs.filter(
        (rec) => {
          return rec.name !== payload.name;
        }
      );
      return updatedState;

    case ArtistType.CUE_SEARCHING_SONG:
      updatedState = {
        ...state,
      };

      updatedState.searchingQueue.push(payload.ytid);
      return updatedState;

    case ArtistType.REMOVE_SEARCHING_SONG:
      updatedState = {
        ...state,
      };

      updatedState.searchingQueue = _.filter(
        updatedState.searchingQueue,
        (item) => item !== payload.ytid
      );
      return updatedState;

    case ArtistType.GET_ARTIST_RECS_SUCCESS:
      return {
        ...state,
        artistRadioRecs: payload,
      };

    case ArtistType.GET_ARTIST_RECS_DATA_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.artistsRecs[normalizeArtistName(payload.name)] =
        payload.recs;
      return updatedState;

    case ArtistType.GET_ARTIST_INFO_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.info = payload.info;
      updatedState.artistsInfo[payload.name] = payload.info;

      return updatedState;

    case ArtistType.GET_SEARCH_PREVIEW_SUCCESS:
      updatedState = {
        ...state,
      };

      if (Object.keys(payload).length < 4) {
        updatedState.searchPreview = {
          radioName: null,
          startVideo: null,
          artistRadioRecs: null,
        };
      } else {
        updatedState.searchPreview.artistRadioRecs = payload;
      }

      return updatedState;

    case ArtistType.GET_VIEW_VIDEO_SUGGESTION_SUCCESS:
      updatedState = {
        ...state,
      };

      if (Object.keys(payload).length < 4) {
        updatedState.videoPreview = {
          radioName: null,
          startVideo: null,
          artistRadioRecs: null,
        };
      } else {
        updatedState.videoPreview.artistRadioRecs = payload;
      }

      return updatedState;

    case ArtistType.SET_RADIO_SEARCH:
      updatedState = {
        ...state,
      };

      updatedState.searchPreview.radioName = _.unescape(
        payload.artistName.trim()
      );
      updatedState.searchPreview.startVideo = payload.ytid;

      return updatedState;

    case ArtistType.SET_RADIO_VIDEO:
      updatedState = {
        ...state,
      };

      updatedState.videoPreview.radioName = _.unescape(
        payload.artistName.trim()
      );
      updatedState.videoPreview.startVideo = payload.ytid;
      return updatedState;

    case ArtistType.CUE_SONG:
      updatedState = {
        ...state,
      };
      updatedState.nextVideos.push({
        ytid: payload.ytid,
        artist: payload.nextArtist,
      });

      return updatedState;

    case ArtistType.REMOVE_ITEM_CUE:
      updatedState = {
        ...state,
      };

      updatedState.nextVideos = updatedState.nextVideos.filter((item) => {
        return item.ytid !== payload.ytid;
      });
      updatedState.radioHistory.push(payload.ytid);

      return updatedState;

    default:
      return state;
  }
};

export default artistReducer;
