import * as ArtistType from './artist.types';

export const setDataStart = (artistAPIData, title) => ({
  type: ArtistType.SET_DATA_START,
  payload: { artistAPIData, title },
});

export const setDataSuccess = (data) => ({
  type: ArtistType.SET_DATA_SUCCESS,
  payload: data,
});

export const getViewVideoSuggestionStart = (videoName, ytid) => ({
  type: ArtistType.GET_VIEW_VIDEO_SUGGESTION_START,
  payload: { videoName, ytid },
});

export const getViewVideoSuggestionSuccess = (data) => ({
  type: ArtistType.GET_VIEW_VIDEO_SUGGESTION_SUCCESS,
  payload: data,
});

export const getViewVideoSuggestionFail = (err) => ({
  type: ArtistType.GET_VIEW_VIDEO_SUGGESTION_FAIL,
  payload: [err],
});

export const getSearchPreviewStart = (artistName, ytid) => ({
  type: ArtistType.GET_SEARCH_PREVIEW_START,
  payload: { artistName, ytid },
});

export const getSearchPreviewSuccess = (data) => ({
  type: ArtistType.GET_SEARCH_PREVIEW_SUCCESS,
  payload: data,
});

export const getSearchPreviewFail = (err) => ({
  type: ArtistType.GET_SEARCH_PREVIEW_FAIL,
  payload: [err],
});

export const getArtistInfoFromTitle = (title) => ({
  type: ArtistType.GET_ARTIST_INFO_TITLE,
  payload: title,
});

export const getArtistInfoStart = (artistName, ytid) => ({
  type: ArtistType.GET_ARTIST_INFO_START,
  payload: { artistName, ytid },
});

export const getArtistInfoSuccess = (data) => ({
  type: ArtistType.GET_ARTIST_INFO_SUCCESS,
  payload: data,
});

export const getArtistInfoFail = (err) => ({
  type: ArtistType.GET_ARTIST_INFO_FAIL,
  payload: [err],
});

export const getArtistRecsSuccess = (data) => ({
  type: ArtistType.GET_ARTIST_RECS_SUCCESS,
  payload: data,
});

export const getArtistRecsDataSuccess = (data) => ({
  type: ArtistType.GET_ARTIST_RECS_DATA_SUCCESS,
  payload: data,
});

/** Set the radio for the search suggestion **/
export const setRadioFromSearch = (ytid, artistName) => ({
  type: ArtistType.SET_RADIO_SEARCH,
  payload: { ytid, artistName },
});

/** Set the radio for the video suggestion **/
export const setRadioFromVideo = (ytid, artistName) => ({
  type: ArtistType.SET_RADIO_VIDEO,
  payload: { ytid, artistName },
});

export const setRadio = (data) => ({
  type: ArtistType.SET_RADIO,
  payload: data,
});

export const setupArtistRadio = (artistName, ytid) => ({
  type: ArtistType.SETUP_ARTIST_RADIO,
  payload: { artistName, ytid },
});

/** Put a song in the cue **/
export const repeatCue = (amount) => ({
  type: ArtistType.REPEAT_CUE,
  payload: amount,
});

/** Put a song in the cue **/
export const cueSongSuccess = (ytid, nextArtist) => ({
  type: ArtistType.CUE_SONG,
  payload: { ytid, nextArtist },
});

/** Cue searching song **/
export const cueSearchingSong = (ytid) => ({
  type: ArtistType.CUE_SEARCHING_SONG,
  payload: { ytid },
});

export const removeArtistRadioRec = (name) => ({
  type: ArtistType.REMOVE_ARTIST_RADIO_REC,
  payload: { name },
});

export const skipArtistRadio = () => ({
  type: ArtistType.SKIP_ARTIST_RADIO,
});

/** Remove item from the queue */
export const removeItemFromCue = (ytid) => ({
  type: ArtistType.REMOVE_ITEM_CUE,
  payload: { ytid },
});

export const playArtistRadio = (ytid, artistName) => ({
  type: ArtistType.PLAY_ARTIST_RADIO,
  payload: { ytid, artistName },
});
