import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const HeaderLeft = (props) => {
  const { sidebarOpen } = props;

  return !sidebarOpen ? (
    <IconButton color="inherit" aria-label="open drawer">
      <MenuIcon />
    </IconButton>
  ) : (
    <IconButton color="inherit" aria-label="close drawer">
      <CloseIcon />
    </IconButton>
  );
};

export default HeaderLeft;
