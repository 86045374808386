import { createSelector } from 'reselect';

const selectRadioStore = (state) => state.radio;

export const selectRadios = createSelector(
  [selectRadioStore],
  (radio) => radio.radios
);

export const selectRadioForPreview = (stationid) =>
  createSelector([selectRadios], (radios) => (radios ? radios[stationid] : {}));
