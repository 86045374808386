import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';

import './VideoListItemLoading.scss';

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: '1px solid',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 8px 8px 12px',
  },
  a: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 8,
    width: '100%',
  },
  b: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 8,
    width: '100%',
  },
  c: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    top: 0,
    width: 16,
  },
  d: {
    position: 'absolute',
    bottom: 0,
    left: 90,
    top: 0,
    width: 8,
  },
  e: {
    position: 'absolute',
    top: 26,
    bottom: 28,
    left: 90,
    right: 0,
  },
  f: {
    position: 'absolute',
    height: 10,
    bottom: 8,
    left: 98,
    right: 0,
  },
  g: {
    position: 'absolute',
    height: 7,
    top: 8,
    left: 98,
    right: 0,
  },
  h: {
    position: 'absolute',
    height: 18,
    width: '40%',
    top: 12,
    right: 0,
  },
  i: {
    position: 'absolute',
    height: 16,
    width: '65%',
    bottom: 12,
    right: 0,
  },
  image: {
    marginLeft: 5,
    marginRight: 10,
    minWidth: 50,
    width: 75,
    height: 50,
    borderRadius: 2,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    flexShrink: 0,
  },
  textWrapper: {
    flexGrow: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  buttonWrapper: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 13,
    textAlign: 'left',
    marginRight: 15,
    fontWeight: 500,
  },
  subInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 5,
  },
}));

const VideoListItemLoading = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { onClick } = props;

  const canvasColor = theme.palette.canvasColor;

  const end = fade(theme.palette.accent1Color, 0.0);
  const bgColor = fade(theme.palette.accent1Color, 0.2);
  const frontColor = fade(theme.palette.accent1Color, 0.3);

  const backgroundImage = `linear-gradient(to right, ${end} 0%, ${frontColor} 20%, ${bgColor} 40%, ${end} 100%)`;
  const rootStyle = {
    borderColor: theme.palette.accent3Color,
    backgroundImage: backgroundImage,
    backgroundColor: bgColor,
  };

  return (
    <div
      className={clsx('video-item-loading-background', classes.root)}
      onClick={onClick}
      style={rootStyle}
    >
      <div
        className={classes.a}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.b}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.c}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.d}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.e}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.f}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.g}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.h}
        style={{
          background: canvasColor,
        }}
      />
      <div
        className={classes.i}
        style={{
          background: canvasColor,
        }}
      />
      <div className={classes.image} />

      <div className={classes.textWrapper}>
        <div>
          <div className={clsx('video-list-item-title', classes.title)} />
        </div>
        <div className={classes.subInfo} />
      </div>
      <div className={classes.buttonWrapper} />
    </div>
  );
};

export default VideoListItemLoading;
