import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Divider,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden/Hidden';

import { usePrevious } from 'hooks';

import { nonemptyString } from 'lib/misc';
import { LOR as LORIcon } from 'common/ui/icons';
import { scrollToVideo } from 'common/ui/utils';
import { ArticleSkeleton } from 'common/ui/skeleton-ui';

import { ArticleListItem } from './components';
import container from './Article.container';

import * as ArticleType from 'store/article/article.types';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 345,
    marginBottom: theme.spacing(1),
    background: theme.palette.paperColor,
    color: theme.palette.textColor,
  },
  tabRoot: {
    color: theme.palette.textColor1,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.pink,
    },
  },
  tabButton: {
    color: theme.palette.textColor,
    fontFamily: 'Arial',
    minHeight: 48,
    '& .MuiTab-wrapper': {
      alignItems: 'initial',
      flexDirection: 'row',
    },
  },
  staffPickTabIcon: {
    marginRight: 10,
  },
  articleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  nextBtn: {
    backgroundColor: theme.palette.buttonBackground,
    color: '#ffffff',
    width: 300,
    fontWeight: 400,
    fontFamily: 'Arial',
    textTransform: 'uppercase',
    paddingTop: 10,
    marginBottom: 10,
    '&:hover': {
      backgroundColor: theme.palette.nextBtnHover,
    },
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'absolute',
  },
  cardTitle: {
    fontFamily: 'Arial',
    padding: '6px 16px 5px 15px',
  },
  cardHeader: {
    fontSize: 20,
    lineHeight: '28px',
    color: theme.palette.textColor1,
  },
  cardSubHeader: {
    fontSize: 14,
    color: theme.palette.textColor2,
  },
}));

function ArticlePage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [currentFilter, setCurrentFilter] = useState(ArticleType.NONE);
  const [limit, setLimit] = useState(6);
  const [loadDisplay, setLoadDisplay] = useState(true);

  const {
    article: {
      articleYtidsOrdered,
      staffPickYtidsOrdered,
      articlesCursor,
      staffPicksCursor,
      loading,
    },
    playerState: { ytid: currentPlayerYtid },
    onGetArticlesPageStart,
    onGetStaffpicksPageStart,
    onSetAutoplay,
    onSetLastPlayed,
    onSetPlayerYtidStart,
    onSetCurrentPlaylistStart,
    onInitBaseUrl,
  } = props;

  console.log(staffPickYtidsOrdered,'staff picks')

  function getYtidsForCurrentTab() {
    switch (currentFilter) {
      case ArticleType.NONE:
        return articleYtidsOrdered;
      case ArticleType.STAFF:
        return staffPickYtidsOrdered;
      default:
        console.warn(
          `Unknown case for ArticleListCard.Filter: ${currentFilter}`
        );
        return [];
    }
  }

  function onChangeTab(event, activeIdx) {
    // console.log({ activeIdx });
    setCurrentFilter(activeIdx);
    setLoadDisplay(true);
  }

  function onClickRow(ytid) {
    // console.log('clicked view details', ytid);
    // console.log(data);
    scrollToVideo();
    onSetAutoplay(true);
    onSetLastPlayed(ytid);
    onSetPlayerYtidStart(ytid);
    onInitBaseUrl(ytid);
    let currentFilterPlaylistName = null;
    switch (currentFilter) {
      case ArticleType.NONE:
        currentFilterPlaylistName = ArticleType.PLAYLIST_ARTICLES;
        break;
      case ArticleType.STAFF:
        currentFilterPlaylistName = ArticleType.PLAYLIST_STAFFPICKS;
        break;
      default:
        console.warn('Unknown case for Articles.Filter:', currentFilter);
        break;
    }
    onSetCurrentPlaylistStart(currentFilterPlaylistName);

    // analytics.actions.trackUIClick('Reviews', ytid);
  }

  const displayArticleList = (num) => {
    if (
      loading &&
      articleYtidsOrdered.length === 0 &&
      staffPickYtidsOrdered.length === 0
    ) {
      return [...Array(num)].map((_, idx) => {
        return <ArticleSkeleton key={idx} />;
      });
    }

    const latestStaffPickYtid =
      staffPickYtidsOrdered.length <= 0 ? null : staffPickYtidsOrdered[0];

    // console.log({ latestStaffPickYtid });

    const otherArticleYtids = getYtidsForCurrentTab().filter(
      (ytid) => ytid !== latestStaffPickYtid
    );

    let filterArticles = [latestStaffPickYtid, ...otherArticleYtids].filter(
      (ytid) => !_.isEmpty(ytid) && ytid !== 'Y5WQ6gF-8vA'
    );
    // console.log({ filterArticles });

    let displayArticles = filterArticles.slice(
      0,
      Math.floor(filterArticles.length / 3) * 3
    );
    // console.log({ displayArticles });

    return displayArticles.map((a) => {
      return (
        <ArticleListItem
          key={a.ytid}
          isPlayed={a.ytid === currentPlayerYtid}
          isPinned={a.ytid === latestStaffPickYtid?.ytid}
          article={a}
          onClick={onClickRow}
        />
      );
    });
  };

  function moreOnServerForCurrentTab() {
    switch (currentFilter) {
      case ArticleType.NONE:
        return articlesCursor ? articlesCursor : null;
      case ArticleType.STAFF:
        return staffPicksCursor ? staffPicksCursor : null;
      default:
        console.warn(
          `Unknown case for ArticleListCard.Filter: ${currentFilter}`
        );
        return false;
    }
  }

  const onClickShowMore = () => {
    if (!moreOnServerForCurrentTab()) {
      return;
    }

    switch (currentFilter) {
      case ArticleType.NONE:
        return onGetArticlesPageStart(articlesCursor, 6);

      case ArticleType.STAFF:
        return onGetStaffpicksPageStart(staffPicksCursor, 6);

      default:
        console.warn(
          `Unknown case for ArticleListCard.Filter: ${currentFilter}`
        );
        return;
    }
  };

  return (
    <Card className={classes.root}>
      <div className={classes.cardTitle}>
        <div className={classes.cardHeader}>Our Reviews</div>
        <div className={classes.cardSubHeader}>
          Check out the freshest music reviews from our editorial team and find
          out what’s going viral
        </div>
      </div>
      {/* <CardHeader
        title="Our Reviews"
        subheader="Check out the freshest music reviews from our editorial team and find out what’s going viral"
      /> */}
      <Divider />

      <CardContent style={{ padding: 0 }}>
        <Tabs
          value={currentFilter}
          onChange={onChangeTab}
          className={classes.tabRoot}
          variant="fullWidth"
        >
          <Tab
            label="All"
            className={classes.tabButton}
            value={ArticleType.NONE}
          />
          <Tab
            label="Staff Picks"
            value={ArticleType.STAFF}
            className={classes.tabButton}
            icon={<LORIcon />}
          />
        </Tabs>

        <Divider />

        <Hidden xsDown>
          <div className={classes.articleContainer}>
            {displayArticleList(3)}
          </div>
        </Hidden>
        <Hidden smUp>
          <div className={classes.articleContainer}>
            {displayArticleList(1)}
          </div>
        </Hidden>

        <div style={{ textAlign: 'center' }}>
          {moreOnServerForCurrentTab() && (
            <Button
              variant="contained"
              disableElevation
              className={classes.nextBtn}
              onClick={onClickShowMore}
            >
              Show more
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default container(ArticlePage);
