import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles(theme => ({
  speaker: {},
  speakerOverlay: {
    width: 100,
    height: 75,
    borderRadius: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'relative',
    top: -5,
    marginTop: 19,
    textAlign: 'center'
  }
}));

const SpeakerImageOverlay = props => {
  const classes = useStyles();
  const { className, overlayStyle, innerStyle } = props;
  return (
    <div className={clsx(classes.speaker, className)}>
      <div
        className={classes.speakerOverlay}
        title="Now Playing"
        style={overlayStyle}
      >
        <PlayArrowIcon style={innerStyle} />
      </div>
    </div>
  );
};

export default SpeakerImageOverlay;
