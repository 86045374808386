import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SkipNextIcon from '@material-ui/icons/SkipNext';

const NextControl = props => {
  const { showTooltip, onClick } = props;
  return (
    <Tooltip title={showTooltip ? 'Next video' : null}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        <SkipNextIcon style={{ color: '#ccc' }} />
      </IconButton>
    </Tooltip>
  );
};

export default NextControl;
