import { takeEvery, call, put, all, select } from "redux-saga/effects";
import axios from "axios";
import _ from "lodash";

import mainAxios from "instances/server";

import { YOUTUBE_DAPI_KEY } from "store/search/search.types";

import * as VideoType from "./video.types";
import * as UserhistoryType from "../userhistory/userhistory.types";
import {
  getVideoMetadataSuccess,
  getVideoStatsSuccess,
  updateGlobalCount,
} from "./video.actions";
import { selectVideos } from "./video.selectors";

import { nullish, nonemptyString, flatten } from "lib/misc";

const getVideo = (state) => state.video;

export function* fetchVideoFromYTAPI(ytid) {
  // analytics.actions.trackAction('Data API Query', ytid)
  window._gaq.push(["_trackEvent", "Action", "Data API Query", ytid]);

  const resp = yield axios.get("https://www.googleapis.com/youtube/v3/videos", {
    params: {
      id: ytid,
      key: YOUTUBE_DAPI_KEY,
      part: "snippet,contentDetails,statistics",
      fields: "items(contentDetails,id,snippet,statistics)",
    },
  });
  const data = resp.data;

  // if youtube doesn't recognize the ytid, it conveniently returns an empty array to us (brilliant)
  if (data.items.length <= 0) {
    return null;
  }
  // console.log('yt data response>>>>>>>>>>>>>. #########>>>>>>>>>>>>>');
  // console.log(data);

  return {
    ytid: ytid,
    duration: data.items[0].contentDetails.duration,
    rating: data.items[0].contentDetails.contentRating ? 1 : 0,
    title: data.items[0].snippet.title,
    views: data.items[0].statistics.viewCount,
  };
}

export function* getVideoMetadataAsync({ payload: { ytids } }) {
  const filteredYtids = ytids.filter((ytid) => nonemptyString(ytid));

  const videosList = yield select(selectVideos);

  const missingYtids = filteredYtids.filter((ytid) => {
    return (
      nullish(videosList[ytid]) ||
      !nonemptyString(((videosList[ytid] || {}).title || "").trim()) ||
      !nonemptyString(videosList[ytid].ytid)
    );
  });

  const videos = {};

  ytids.forEach((y) => {
    videos[y] = videosList[y];
  });

  if (missingYtids.length === 0) {
    return videos;
  }

  // fetch video metadata from LoR API
  const data1 = yield all(
    _.chunk(missingYtids, 20).map((batch) =>
      call(getBatchRequest, "/video/meta", batch)
    )
  );
  // const data1 = [];

  const flatArr = flatten(data1);

  // console.log(flatArr);

  flatArr
    .filter(
      (meta) => meta && meta.ytid && meta.title !== "" && meta.title?.trim()
    )
    .forEach((meta) => (videos[meta.ytid] = meta));

  // for any videos the LoR API could not find, we fetch them from the YT API, send them up to LoR, and cache them
  const fetchResults = yield all(
    flatArr.map((value, idx) =>
      call(fetchAndSetMetadata, missingYtids[idx], value)
    )
  );

  // console.log({ fetchResults });

  fetchResults.forEach((meta, i) => {
    if (!nullish(meta)) {
      videos[meta.ytid] = meta;
    }
  });

  yield put(getVideoMetadataSuccess(videos));
  return videos;
}

function* fetchAndSetMetadata(ytid, data) {
  // console.log({ ytid, data });
  let metadata;
  if (nullish(data) || !nonemptyString(data.title)) {
    try {
      metadata = yield call(fetchVideoFromYTAPI, ytid);
      if (_.isEmpty(metadata)) {
        return null;
      }

      const body = {
        ytid,
        metadata,
      };

      yield mainAxios.post("/video/meta", body);
    } catch (err) {
      console.error(err);
    }
  }
  return metadata;
}

/**
    Posts the given metadata to the LoR API.
 */
export function* setVideoMetadata(ytid, metadata) {
  const data = {
    ytid,
    metadata,
  };

  yield axios.post("/video/meta", data);
}

export function* getVideoStatsAsync({ payload: { ytids } }) {
  // console.log('here...');
  const filteredYtids = ytids.filter((ytid) => nonemptyString(ytid));

  const videoState = yield select(getVideo);

  let missingYtids = ytids.filter((ytid) => {
    return (
      nullish(videoState.videoStats[ytid]) ||
      !nonemptyString(videoState.videoStats[ytid].ytid)
    );
  });

  // console.log('here...');
  if (missingYtids.length === 0) {
    const stats = ytids.map((ytid) => videoState.videoStats[ytid]);
    yield put(getVideoStatsSuccess(stats));
  }

  // console.log({ filteredYtids });
  const data1 = yield all(
    _.chunk(filteredYtids, 20).map((batch) =>
      call(getBatchRequest, "/video/stats", batch)
    )
  );

  const flatArr = flatten(data1);

  yield put(getVideoStatsSuccess(flatArr));
}

function* getBatchRequest(url, batch) {
  // console.log('getBatchRequest');
  const { data } = yield mainAxios.get(url, {
    params: {
      ytids: batch.join(","),
    },
  });
  // console.log({ data });
  return [...data];
}

// optimistic update to increment global repeat count so we don't have to fetch
// stats from the API again
export function* updateGlobalRepeatSaga({ payload: reply }) {
  console.log("updateGlobalCount>>>", reply);
  if (_.isNil(reply.history) || _.keys(reply.history).length === 0) {
    return;
  }

  const ytid = _.keys(reply.history)[0];

  yield put(updateGlobalCount(ytid));
}

export function* watchGetVideoMetadata() {
  yield takeEvery(VideoType.GET_VIDEO_METADATA_START, getVideoMetadataAsync);
}

export function* watchGetVideoStats() {
  yield takeEvery(VideoType.GET_VIDEO_STATS_START, getVideoStatsAsync);
}

export function* watchUpdateGlobalRepeat() {
  yield takeEvery(UserhistoryType.ADD_PLAY_SUCCESS, updateGlobalRepeatSaga);
}

export function* videoSagas() {
  yield all([
    call(watchGetVideoMetadata),
    call(watchGetVideoStats),
    call(watchUpdateGlobalRepeat),
  ]);
}
