import { connect } from 'react-redux';

import {
  createPlaylistStart,
  showAddPlaylistModal,
} from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapDispatchToProps = (dispatch) => ({
  onCreatePlaylistStart: (value) => dispatch(createPlaylistStart(value)),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

const container = connect(null, mapDispatchToProps);

export default container;
