import React from 'react';
import {
    ExpandLess,
    ExpandMore,
} from '@material-ui/icons';

const SidebarMoreItem = (props) => {
    return props.openList ? (
        <ExpandLess
            style={{ color: 'rgb(169, 169, 169)', paddingRight: 16 }}
            onClick={props.handleClick}
        />
    ) : (
        <ExpandMore
            style={{ color: 'rgb(169, 169, 169)', paddingRight: 16 }}
            onClick={props.handleClick}
        />
    );
}

export default SidebarMoreItem;