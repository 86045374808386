import * as SearchType from './search.types';

export const initSearch = () => ({
  type: SearchType.INIT_SEARCH,
});

export const getAutocompleteSuggestionStart = (partialQuery) => ({
  type: SearchType.GET_AUTOCOMPLETE_SUGGESTION_START,
  payload: { partialQuery },
});

export const getAutocompleteSuggestionSuccess = (data) => ({
  type: SearchType.GET_AUTOCOMPLETE_SUGGESTION_SUCCESS,
  payload: data,
});

export const getAutocompleteSuggestionFail = (error) => ({
  type: SearchType.GET_AUTOCOMPLETE_SUGGESTION_FAIL,
  payload: error,
});

export const youtubeLookupStart = (query) => ({
  type: SearchType.YOUTUBE_LOOKUP_START,
  payload: query,
});

export const youtubeLookupSuccess = (data) => ({
  type: SearchType.YOUTUBE_LOOKUP_SUCCESS,
  payload: data,
});

export const youtubeLookupFail = (error) => ({
  type: SearchType.YOUTUBE_LOOKUP_FAIL,
  payload: error,
});

export const performSearchStart = (partialQuery) => ({
  type: SearchType.PERFORM_SEARCH_START,
  payload: partialQuery,
});

export const performSearchSuccess = (data) => ({
  type: SearchType.PERFORM_SEARCH_SUCCESS,
  payload: data,
});

export const performSearchFail = (error) => ({
  type: SearchType.PERFORM_SEARCH_FAIL,
  payload: error,
});

export const getNextResultsPageStart = (query, cursor) => ({
  type: SearchType.GET_NEXT_RESULTS_PAGE_START,
  payload: { query, cursor },
});

export const getNextResultsPageSuccess = (data) => ({
  type: SearchType.GET_NEXT_RESULTS_PAGE_SUCCESS,
  payload: data,
});

export const getNextResultsPageFail = (error) => ({
  type: SearchType.GET_NEXT_RESULTS_PAGE_FAIL,
  payload: error,
});
