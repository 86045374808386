import React from 'react';

import { ContentPageBlank } from 'common/ui/content-page';

import { FaqCard } from './components';

const FaqPage = () => {
  return <ContentPageBlank body={<FaqCard />} />;
};

export default FaqPage;
