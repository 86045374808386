import { connect } from 'react-redux';
import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';
import { showLoginModal } from 'store/login/login.actions';

const mapStateToProps = (state) => ({
    loginState: state.login,
    userState: state.user,
    siteCoordinator: state.siteCoordinator,
    playerState: state.player,
    showSearchTab: state.searchRadio.query,
});

const mapDispatchToProps = (dispatch) => ({
    onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
    onShowLoginModal: () => dispatch(showLoginModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
