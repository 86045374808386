import {
  FB_INIT_START,
  FB_INIT_SUCCESS,
  FB_FETCH_PROFILE_START,
  FB_FETCH_PROFILE_SUCCESS,
  FB_FETCH_PROFILE_FAIL,
} from "./facebook.types";

const FB_APP_ID = "1169184690783381";

export const fbInitStart = () => ({
  type: FB_INIT_START,
});

export const fbInitSuccess = () => ({
  type: FB_INIT_SUCCESS,
});

export const fbFetchProfileStart = () => ({
  type: FB_FETCH_PROFILE_START,
});

export const fbFetchProfileSuccess = (data) => ({
  type: FB_FETCH_PROFILE_SUCCESS,
  payload: data,
});

export const fbFetchProfileFail = (err) => ({
  type: FB_FETCH_PROFILE_FAIL,
  payload: err,
});

/**
    Do not call this function.  It is called by the FB Javascript SDK when the SDK finishes loading.
 */
export const fbAsyncInit = () => {
  window.FB.init({
    appId: FB_APP_ID,
    status: true, // should immediately check login status upon init?
    cookie: true, // enable cookies to allow the server to access the session
    xfbml: false, // parse XFBML
    version: "v6.0",
  });
};

/**
    This function is called once, in the constructor, to begin loading the Facebook Javascript SDK on the page.  When
    loading is complete, the Facebook API calls [[facebook.actions.fbAsyncInit]].
 */
export const asyncLoadSDK = (document) => {
  const id = "facebook-jssdk";
  if (document.getElementById(id)) {
    return;
  }
  const js = document.createElement("script");
  js.id = id;
  js.async = true;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  document.getElementsByTagName("head")[0].appendChild(js);
};

/** This doesn't fetch the user's FB profile, it fetches the FB section of the user's LoR profile. */
export const fetchProfile = () => {
  // return api.fb.GetProfile.request(void 0);
};

/**
    Do not call this function.  It is called by the LoR delegated login system (see user/actions.ts).
    This action begins the FB login process via the Facebook API.
 */
export const fbAPILogin = (params = { scope: "public_profile,email" }) => {
  return new Promise(async (resolve) => {
    window.FB.login((response) => {
      resolve(response);
    }, params);
  });
};

export const getFbLoginParams = async (data) => {
  const { accessToken, signedRequest } = data.authResponse;
  return { signedRequest, accessToken };
};
