import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import container from './PlaylistNew.container';
import styles from './PlaylistNew.styles';

const useStyles = makeStyles((theme) => ({
  addButton: {
    ...styles.addButton,
  },
  label: {
    ...styles.addButton.label,
  },
  icon: {
    width: 14,
    height: 14,
    color: '#FF0066',
  },
  newPlaylist: {
    marginLeft: 0,
    padding: '0px 10px 0px 25px',
    position: 'relative',
    '&:hover': {
      background: theme.palette.playlistHover,
    },
  },
  playlistBox: {
    fontSize: 12,
    lineHeight: '24px',
    width: '100%',
    height: 30,
    display: 'inline-block',
    position: 'relative',
    backgroundColor: 'transparent',
    fontFamily: 'Arial',
    transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    cursor: 'auto',
    borderLeft: '1px solid rgb(64, 64, 64)',
    paddingLeft: 10,
  },
  input: {
    fontSize: 12,
    padding: 0,
    paddingTop: 9,
    fontFamily: 'Arial',
    position: 'relative',
    width: '100%',
    border: 'none',
    outline: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'rgb(255, 0, 102)',
    // color: theme.palette.pink,
    cursor: 'inherit',
    font: 'inherit',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    height: '100%',
    '&::placeholder': {
      position: 'absolute',
      opacity: 1,
      color: 'rgb(102, 102, 102)',
      transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      bottom: 2,
    },
  },
}));

const PlaylistNew = (props) => {
  const classes = useStyles();

  const {
    onCreatePlaylistStart,
    onShowAddPlaylistModal,
    onShowSnackbarNotification,
  } = props;

  const [playlistName, setPlaylistName] = useState('');

  const onNewPlaylistChange = (event) => {
    setPlaylistName(event.target.value);
  };

  const onNewPlaylistKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }
    // console.log('Enter pressed...');

    if (playlistName.length === 0 || playlistName.length > 50) {
      onShowSnackbarNotification(`Playlist name too long`);
      return;
    }

    onCreatePlaylistStart(playlistName);

    onShowSnackbarNotification(`Created playlist ${playlistName} 🎉`);

    setPlaylistName('');
  };

  const handlePlaylistOpen = () => {
    onShowAddPlaylistModal();
  };

  return (
    <>
      {/* show on mobile and tablet */}
      <Hidden mdUp>
        <Button
          component="span"
          classes={{
            root: classes.addButton,
            label: classes.label,
          }}
          endIcon={<AddCircleIcon className={classes.icon} />}
          onClick={handlePlaylistOpen}
        >
          New Playlist
        </Button>
      </Hidden>
      {/* hide on mobile and tablet */}
      <Hidden smDown>
        <div className={classes.newPlaylist}>
          <div className={classes.playlistBox}>
            <InputBase
              value={playlistName}
              classes={{
                input: classes.input,
              }}
              placeholder="New Playlist +"
              onChange={onNewPlaylistChange}
              onKeyDown={onNewPlaylistKeyDown}
            />
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default container(PlaylistNew);
