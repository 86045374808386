import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalWidth: {
    width: '75%',
    marginTop: -100,
    maxWidth: '768px',
    color: theme.palette.textColor,
    backgroundColor: theme.palette.paperColor,
  },
  title: {
    margin: 0,
    padding: '24px 24px 20px',
    fontSize: 22,
    fontFamily: 'Arial',
    lineHeight: '32px',
    fontWeight: 400,
    borderBottom: 'none',
  },
  buttonStyle: {
    color: theme.palette.pink,
  },
}));

const LogoutConfirmationModal = (props) => {
  const classes = useStyles();
  const {
    open,
    onLogoutStart,
    onShowSnackbarNotification,
    onHideLogoutConfirmationModal,
  } = props;

  const handleClose = () => {
    onHideLogoutConfirmationModal();
  };

  const onConfirmTap = () => {
    onLogoutStart();
    onHideLogoutConfirmationModal();
    onShowSnackbarNotification('Goodbye!😢');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.modalWidth,
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        disableTypography
        className={classes.title}
      >
        {'Are you sure you want to logout? 😢'}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} className={classes.buttonStyle}>
          No
        </Button>
        <Button
          onClick={onConfirmTap}
          className={classes.buttonStyle}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutConfirmationModal;
