const styles = {
  hr: {
    margin: 0,
    borderTop: '1px solid #ff0066',
  },

  description: {
    fontSize: 12,
    padding: '0px 10px',
    textAlign: 'center',
  },

  image: {
    marginTop: 25,
    marginBottom: 25,
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)',
    borderRadius: 20,
  },

  gettingStarted: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 5,
    textAlign: 'center',
  },

  textfield: {
    height: 35,
    fontSize: 12,
    width: '100%',
    borderTop: '1px solid',
  },

  addButton: {
    textAlign: 'left',
    width: '100%',

    label: {
      textTransform: 'none',
      fontSize: 12,
      paddingLeft: 12,
      color: '#ff0066',
    },
  },
};

export default styles;
