import * as UserType from './user.types';

export const fetchUserProfileStart = () => ({
  type: UserType.FETCH_USER_PROFILE_START,
});

export const fetchUserProfileSuccess = (data) => ({
  type: UserType.FETCH_USER_PROFILE_SUCCESS,
  payload: data,
});

export const fetchUserProfileFail = (err) => ({
  type: UserType.FETCH_USER_PROFILE_FAIL,
  payload: err,
});

export const setUserProfileStart = () => ({
  type: UserType.SET_USER_PROFILE_START,
});

export const setUserProfileSuccess = (data) => ({
  type: UserType.SET_USER_PROFILE_SUCCESS,
  payload: data,
});

export const setProfileDataStart = () => ({
  type: UserType.SET_PROFILE_DATA_START,
});

export const setProfileData = (data) => ({
  type: UserType.SET_PROFILE_DATA_SUCCESS,
  payload: data,
});

export const setFbProfile = (data) => ({
  type: UserType.SET_FB_PROFILE,
  payload: data,
});

export const setGoogleProfile = (data) => ({
  type: UserType.SET_GOOGLE_PROFILE,
  payload: data,
});

export const clearProfileData = () => ({
  type: UserType.CLEAR_PROFILE_DATA,
});
