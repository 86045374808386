export const FETCH_RADIO_LIST_START = 'FETCH_RADIO_LIST_START';
export const FETCH_RADIO_LIST_SUCCESS = 'FETCH_RADIO_LIST_SUCCESS';
export const FETCH_RADIO_LIST_FAIL = 'FETCH_RADIO_LIST_FAIL';

export const FETCH_GENRES_RADIO_LIST_START = 'FETCH_GENRES_RADIO_LIST_START';
export const FETCH_GENRES_RADIO_LIST_SUCCESS = 'FETCH_GENRES_RADIO_LIST_SUCCESS';
export const FETCH_GENRES_RADIO_LIST_FAIL = 'FETCH_GENRES_RADIO_LIST_FAIL';

export const SET_CURRENT_STATES = 'SET_CURRENT_STATES';

export const SET_CURRENT_GENRES = 'SET_CURRENT_GENRES';

export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';

export const RATING_START = 'RATING_START';
