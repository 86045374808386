import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { HeaderIconLeft } from 'layouts/Main/components/Topbar/components';
import { Logo, HeaderTitle, HeaderRight } from './components';
import clsx from 'clsx';
import container from './Topbar.container';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    zIndex: theme.zIndex.modal + 1,
  },
  toolbar: {
    minHeight: 50,
    marginLeft: -4,
  },
  logoContainer: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  logo: {
    height: '19px',
    marginLeft: -2,
    marginRight: 0,
    [theme.breakpoints.down('xl')]: {
      marginRight: 10,
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: 10,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: -2,
      marginRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  searchBar: {
    width: '73%',
    overflow: 'visible',
    borderRadius: '2px',
    // backgroundColor: 'rgba(88, 88, 88, 0.7)',
    /*'&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },*/
    [theme.breakpoints.down('xl')]: {
      width: '83%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '72%',
    },
    [theme.breakpoints.down('md')]: {
      width: '55%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: -2,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: -2,
    },
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'visible',
    textOverflow: 'ellipsis',
    margin: '0px',
    paddingTop: '0px',
    letterSpacing: '0px',
    fontSize: '24px',
    fontWeight: 400,
    // color: 'rgb(48, 48, 48)',
    height: '50px',
    lineHeight: '50px',
    flex: '1 1 0%',
  },
  inputRoot: {
    // color: '#FFF',
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    minWidth: '25%',
    color: 'rgb(255, 255, 255)',
    // backgroundColor: 'rgba(88, 88, 88, 0.7)',
    height: '2.2rem',
    lineHeight: '2.2rem',
    borderRadius: '2px',
    marginTop: '8px',
  },
  inputRootSmall: {
    display: 'inline-block',
    position: 'relative',
    width: '25%',
    minWidth: '25%',
    color: 'rgb(255, 255, 255)',
    // backgroundColor: 'rgba(88, 88, 88, 0.7)',
    height: '2.2rem',
    lineHeight: '2.2rem',
    borderRadius: '2px',
    marginTop: '8px',
  },
  inputBase: {
    width: '100%',
    paddingTop: 0,
    height: 36,
    paddingLeft: 15,
    paddingRight: 15,
    fontWeight: 300,
    opacity: 1,
    borderRadius: '2px',
    backgroundColor: 'rgba(88, 88, 88, 0.7)',
  },
  inputInput: {
    fontFamily: 'Arial',
    padding: '0px 0px !important',
    width: '90% !important',
    position: 'absolute',
    opacity: 1,
    // color: 'rgb(169, 169, 169)',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    bottom: 9,
    left: 15,
  },
  headerRight: {
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 13,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 21,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 7,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 3,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 1,
    },
  },
  loginButton: {
    color: theme.palette.white,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Arial',
    letterSpacing: 0,
    border: '10px',
    boxSizing: 'border-box',
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    margin: '0px -50px 0px 0px',
    padding: '0px 50px 0px 0px',
    outline: 'none',
    // fontSize: 'inherit'
    // fontWeight: 'inherit',
    // color: 'white',
    position: 'relative',
    height: '50px',
    lineHeight: '36px',
    minWidth: '88px',
    borderRadius: '2px',
    userSelect: 'none',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    textAlign: 'center',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: 14,
    // },
    '&:hover': {
      background: 'rgba(153, 153, 153, 0.2)',
    },
  },
  smallIconMainContainer: {
    marginRight: 20,
    display: 'inline-flex',
    position: 'relative',
    fontSize: 10,
    width: 30,
    height: 50,
    color: '#bbb',
  },
  smallIconSubContainer1: {
    height: 14,
    position: 'absolute',
    left: 0,
    top: 10,
  },
  smallIcon: {
    width: 14,
    height: 14,
    float: 'left',
    color: '#bbb',
  },
  smallIconText: {
    fontFamily: 'Arial',
    float: 'left',
    position: 'absolute',
    left: 20,
    top: 1,
  },
  small: {
    width: 28,
    height: 28,
    margin: '10px 6px',
  },
  icon: {
    // width: 20,
    // height: 20,
    cursor: 'pointer',
    fill: theme.palette.iconColor,
  },
}));

const Topbar = (props) => {
  const classes = useStyles();
  const {
    searchRadioState: { toggle },
    siteCoordinatorState,
    userState,
    userhistoryState,
    sidebarOpen,
    onSidebarOpen,
    onSidebarClose,
    onToggleSearch,
    onSetSwitchTab,
    className,
    ...rest
  } = props;

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="secondary"
      {...rest}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <div
          style={{
            position: 'fixed',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flex: '1 1 0%' }}>
            <HeaderIconLeft
              search={toggle}
              onToggleSearch={onToggleSearch}
              sidebarOpen={sidebarOpen}
              onSidebarOpen={onSidebarOpen}
              onSidebarClose={onSidebarClose}
            />
            <Logo
                style={classes}
                onSetSwitchTab={onSetSwitchTab}
            />
            <HeaderTitle
              style={classes}
              search={toggle}
              onToggleSearch={onToggleSearch}
              onSidebarClose={onSidebarClose}
            />
          </div>
          <div>
            <HeaderRight darkMode={siteCoordinatorState.darkMode} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default container(Topbar);
