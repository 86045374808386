import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

import container from './Notification.container';
import {makeStyles} from "@material-ui/core/styles";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyle = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      width: '100%',
      bottom: 0,
      left: 0,
    },
  },
}));

const Notification = (props) => {
  const classes = useStyle();
  const { notificationState, onHideSnackbarNotification } = props;
  return (
    <Snackbar
      open={notificationState.snackbarNotification.open}
      onClose={onHideSnackbarNotification}
      autoHideDuration={3000}
      TransitionComponent={TransitionUp}
      message={notificationState.snackbarNotification.message}
      classes={{
        root: classes.paper,
      }}
    />
  );
};

export default container(Notification);
