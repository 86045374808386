import * as AdType from './ad.types';

const INITIAL_STATE={
    adsInit: false,
    blacklistYtids: [],
    blacklistPhrases: [],
    adBlock: false,
    adsFinish: false,
    adsPending: [],
    adsActive: [],
    lastRefresh: 0,
    lastRefreshExp: 0,
    tonefuseID: 0,
    title:''
}

const adReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case AdType.TONE_FUSE:
            return {
                ...state,
                tonefuseID: payload.tonefuseID,
                title: payload.title
            };
        case AdType.LAST_REFRESH:
            return {
                ...state,
                lastRefresh: payload.lastRefresh
            };

        default:
            return state;



    }

};

export default adReducer;
