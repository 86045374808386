import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ShuffleIcon from '@material-ui/icons/Shuffle';

const ShuffleControl = props => {
  const { shuffleMode, showTooltip, onClick } = props;

  const tooltip = shuffleMode ? 'Disable Shuffle' : 'Enable Shuffle';

  return (
    <Tooltip title={showTooltip ? tooltip : null}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        {shuffleMode ? (
          <ShuffleIcon style={{ color: 'rgb(204, 204, 204)', width: 22, height: 22 }} />
        ) : (
          <ShuffleIcon style={{ color: 'rgb(95, 95, 95)', width: 22, height: 22 }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ShuffleControl;
