import { takeLatest, call, all, select } from "redux-saga/effects";
import * as url from "url";
import _ from "lodash";
import SectionTab from "constants/SectionTab";
import { SET_SLIDER_START } from "../userhistory/userhistory.types";
import * as VideoType from "../video/video.types";
import * as PlayerType from "../player/player.types";
import * as ArticleType from "store/article/article.types";
import * as PlayerRadioType from "../playerRadio/playerRadio.types";
import * as PlayerPodCastType from "../playerPodCast/playerPodCast.types";

import { selectArticleForPreview } from "../article/article.selectors";
import { selectVideosForPreview } from "../video/video.selectors";
import * as cookie from "component-cookie";

const getPlayer = (state) => state.player;
const getArticle = (state) => state.article;
const getVideo = (state) => state.video;
const getUserhistory = (state) => state.userhistory;
const getStreams = (state) => state.streams;
const getRadioState = (state) => state.radio;
const getPlayerRadio = (state) => state.playerRadio;
const getRadioUserHistory = (state) => state.userhistoryRadio;
const getPodcastState = (state) => state.podcast;
const getPlayerPodCastState = (state) => state.playerPodCast;
const getSiteCoordinatorState = (state) => state.siteCoordinator;

export function* baseUrlSaga({ payload: ytid }) {
  const { newState, newURL } = yield call(
    currentDocumentState,
    null,
    ytid,
    null
  );
  console.log({ newState, newURL });
  console.log("baseurlsaga>", cookie("user.email"), newURL);
  const link = !!document.querySelector("link[rel='canonical']")
    ? document.querySelector("link[rel='canonical']")
    : document.createElement("link");
  link.setAttribute("rel", "canonical");
  // link.setAttribute('href', location.protocol + '//' + location.host + location.pathname);
  // link.setAttribute('href', location.href);
  link.setAttribute("href", newURL);
  document.head.appendChild(link);

  yield window.history.replaceState(newState, "", newURL);
}

export function* articleVideoUrl() {
  // console.log('URL change... :: 1');
  const playerState = yield select(getPlayer);
  const articleState = yield select(getArticle);

  const ytid = playerState.ytid;
  // console.log('URL change... :: 2 :: ytid:', ytid);

  let slider = null;
  console.log("articleVideoUrl", cookie("user.email"));

  if (!ytid) {
    return null;
  }
  // console.log('URL change... :: 2');

  const videoTitle = (articleState.articles[ytid] || {}).title;
  if (!videoTitle) {
    return null;
  }
  // console.log('URL change... :: 3 :: title:', videoTitle);
  const { newState, newURL } = yield call(
    currentDocumentState,
    videoTitle,
    ytid,
    slider
  );

  const newTitle = generatePageTitle(ytid, videoTitle);

  // Update title
  document.title = newTitle;

  // Update page History
  if (ytid === (window.history.state || {}).ytid) {
    // if the ytid hasn't changed, we call .replaceState
    window.history.replaceState(newState, newTitle, newURL);
  } else {
    // if the ytid has changed, we push a new state onto the history stack to represent the new video
    window.history.pushState(newState, newTitle, newURL);
  }
}

export function* activeVideoUrl() {
  // console.log('URL change... :: 1');
  const playerState = yield select(getPlayer);
  const videoState = yield select(getVideo);
  const userhistoryState = yield select(getUserhistory);

  const ytid = playerState.ytid;
  // console.log('URL change... :: 2 :: ytid:', ytid);

  let slider = null;

  if (!ytid) {
    return null;
  }
  // console.log('URL change... :: 2');

  const videoTitle = (videoState.videos[ytid] || {}).title;
  if (!videoTitle) {
    return null;
  }
  // console.log('URL change... :: 3 :: title:', videoTitle);

  if (ytid in userhistoryState.history) {
    slider = userhistoryState.history[ytid].slider;
  }
  // console.log('URL change... :: 4');

  // if (userhistoryState.urlSlider) {
  //   // userhistory.actions.urlSlider(false);
  //   return;
  // }
  // console.log('URL change... :: 5');

  const { newState, newURL } = yield call(
    currentDocumentState,
    videoTitle,
    ytid,
    slider
  );

  const newTitle = generatePageTitle(ytid, videoTitle);

  // Update title
  document.title = newTitle;

  // Update page History
  if (ytid === (window.history.state || {}).ytid) {
    // if the ytid hasn't changed, we call .replaceState
    window.history.replaceState(newState, newTitle, newURL);
  } else {
    // if the ytid has changed, we push a new state onto the history stack to represent the new video
    window.history.pushState(newState, newTitle, newURL);
  }
}

export function* activeRadioUrl() {
  const radioState = yield select(getRadioState);
  const radioPlayer = yield select(getPlayerRadio);
  const userHistoryRadioState = yield select(getRadioUserHistory);

  const countrycode = radioState.currentCountryCode;
  const stationid = radioPlayer.stationid;
  const radioName = (userHistoryRadioState.history[stationid] || {}).name;

  const { newState, newURL } = yield call(
    currentDocumentRadioState,
    radioName,
    countrycode,
    stationid
  );
  console.log("activeRadioUrl", cookie("user.email"));

  const newTitle = generatePageTitle(stationid, radioName);
  // Update title
  document.title = newTitle;
  window.history.replaceState(newState, newTitle, newURL);
}

function* currentDocumentRadioState(newTitle, countrycode, stationid) {
  // This encoding removes ?, spaces, and email sensitive text which may trigger ad compliance issues
  let encodedHash;
  if (newTitle) {
    encodedHash = yield encodeURIComponent(
      newTitle.replace(/[" ?@.']|gmail|com|org|net/g, "_")
    );
  }
  let query = null;
  query = {
    stationId: stationid,
    geo: countrycode,
  };
  const newURL = url.parse(document.location.href, true);
  newURL.query = query;
  newURL.hash = encodedHash;
  // we have to make sure `.search` is not present on `newURL` or `url.format` will not parse `query`
  delete newURL.search;
  if (!stationid) {
    delete newURL.query;
    delete newURL.hash;
  }
  let newURLStr = url.format(newURL);
  return {
    newState: { stationuuid: stationid },
    newURL: newURLStr,
  };
}

export function* viewPodcastUrl({ payload: podcastId }) {
  const { newState, newURL } = yield call(
    currentDocumentViewPodCastState,
    podcastId
  );
  const podcastState = yield select(getPodcastState);
  const title = (podcastState.genresPodcasts[podcastId] || {}).title;
  const newTitle = generatePageTitle(podcastId, title);
  window.history.replaceState(newState, newTitle, newURL);
}

function* currentDocumentViewPodCastState(podcastId) {
  const newURL = url.parse(document.location.href, true);
  // console.log('newURL: ', newURL);
  newURL.pathname = `podcast/${podcastId}`;
  // console.log('newURL2: ', newURL);
  let newURLStr = url.format(newURL);
  return {
    newState: { podcastid: podcastId },
    newURL: newURLStr,
  };
}

export function* activeEpisodePlayUrl() {
  const podcastState = yield select(getPodcastState);
  const playerPodCastState = yield select(getPlayerPodCastState);
  const podcastid = playerPodCastState.podcastid;
  const episodeid = playerPodCastState.episodeid;
  const podcastName = (podcastState.genresPodcasts[podcastid] || {}).title;
  const episodeName = (podcastState.podcastEpisodes[episodeid] || {}).title;
  const { newState, newURL } = yield call(
    currentDocumentPodCastState,
    episodeName,
    podcastid,
    episodeid
  );
  const title = episodeName || podcastName;
  const newTitle = generatePageTitle(podcastid, title);
  // Update title
  document.title = newTitle;
  window.history.replaceState(newState, newTitle, newURL);
}

function* currentDocumentPodCastState(newTitle, podcastid, episodeid) {
  const siteCoordinatorState = yield select(getSiteCoordinatorState);
  const currentTab = siteCoordinatorState.currentTab;
  let encodedHash;
  if (newTitle) {
    encodedHash = yield encodeURIComponent(
      newTitle.replace(/[" ?@.']|gmail|com|org|net/g, "_")
    );
  }
  let query = null;
  query = {
    podcastid: podcastid,
    episodeid: episodeid,
  };
  const newURL = url.parse(document.location.href, true);
  newURL.query = query;
  newURL.hash = encodedHash;
  delete newURL.search;
  if (!podcastid && !episodeid) {
    delete newURL.query;
    delete newURL.hash;
    if (currentTab !== SectionTab.PodCastDetail) {
      newURL.pathname = `podcast`;
    }
  }
  let newURLStr = url.format(newURL);
  return {
    newState: { podcastid: podcastid },
    newURL: newURLStr,
  };
}

export function* sliderGrabber({ payload: { ytid, slider } }) {
  // console.log('Slider change...');
  console.log("sliderGrabber", cookie("user.email"));

  if (!ytid) {
    return null;
  }

  const selectedVideo = yield select(selectVideosForPreview(ytid));
  // console.log({ selectedVideo });

  if (!selectedVideo) {
    return null;
  }

  const videoTitle = selectedVideo.title;
  // console.log({ videoTitle });

  if (!videoTitle) {
    return null;
  }

  const { newState, newURL } = yield call(
    currentDocumentState,
    videoTitle,
    ytid,
    slider
  );
  // console.log({ newState, newURL });

  const newTitle = generatePageTitle(ytid, videoTitle);

  // Update title
  document.title = newTitle;

  yield window.history.replaceState(newState, newTitle, newURL);
}

function* currentDocumentState(newTitle, ytid, slider) {
  const streamState = yield select(getStreams);

  // This encoding removes ?, spaces, and email sensitive text which may trigger ad compliance issues
  let encodedHash;
  if (newTitle) {
    encodedHash = yield encodeURIComponent(
      newTitle.replace(/[" ?@.']|gmail|com|org|net/g, "_")
    );
  }
  let query = null;

  // console.log({ encodedHash, ytid, slider });
  if (streamState.streamPlaying === streamState.currentPlaylist) {
    query = {
      p: "stream/" + streamState.streamPlaying,
    };
    encodedHash = "";
  } else {
    if (slider) {
      query = {
        v: ytid,
        s: slider["startTime"],
        e: slider["endTime"],
      };
    } else {
      query = {
        v: ytid,
      };
    }
  }

  const newURL = url.parse(document.location.href, true);

  newURL.query = query;
  newURL.hash = encodedHash;

  // we have to make sure `.search` is not present on `newURL` or `url.format` will not parse `query`
  delete newURL.search;

  let newURLStr = url.format(newURL);

  return {
    newState: { ytid: ytid },
    newURL: newURLStr,
  };
}

function generatePageTitle(currentYtid, currentVideoName) {
  console.log("generatePageTitle", cookie("user.email"));

  const path = document.location.pathname.split("/").slice(1, 2).toString();
  if (path === "radio") {
    return `${
      currentVideoName ||
      "ListenOnRepeat Radio | Free Internet Radio | Free Radio Online"
    }`;
  } else if (path === "podcast") {
    return `${
      currentVideoName ||
      "ListenOnRepeat Podcast | Explore and Play Trending Podcasts"
    }`;
  } else {
    if (
      !currentYtid ||
      !currentVideoName ||
      currentYtid === "" ||
      currentVideoName === ""
    ) {
      return "Listen On Repeat | YouTube Loop | Replay and Repeater";
    }
    if (currentYtid === "Y5WQ6gF-8vA") {
      return "Listen On Repeat | YouTube Loop | Replay and Repeater";
    }
    // if (
    //   streams.store.state.streamPlaying == playlist.store.state.currentPlaylist
    // ) {
    //   return (
    //     streams.store.state.streams[streams.store.state.streamPlaying].name +
    //     ' - 24/7 Live Stream'
    //   );
    // }
    return `${currentVideoName} - Listen On Repeat`;
  }
}

export function* watchVideoSelected() {
  yield takeLatest(
    [PlayerType.SET_PLAYER_YTID_START, VideoType.GET_VIDEO_METADATA_SUCCESS],
    activeVideoUrl
  );
}

export function* watchArticleSelected() {
  yield takeLatest(
    [ArticleType.GET_ARTICLES_START, ArticleType.GET_ARTICLES_SUCCESS],
    articleVideoUrl
  );
}

export function* watchSliderGrabber() {
  yield takeLatest([SET_SLIDER_START], sliderGrabber);
}

export function* watchInitBaseUrl() {
  yield takeLatest("INIT_BASE_URL", baseUrlSaga);
}

export function* watchInitializeStore() {
  yield takeLatest("INIT_URL", activeVideoUrl);
}

export function* watchInitializeRadioUrl() {
  yield takeLatest(PlayerRadioType.SET_RADIO_URL, activeRadioUrl);
}

export function* watchCurrentViewPodcastUrl() {
  yield takeLatest(PlayerPodCastType.SET_VIEW_PODCAST_URL, viewPodcastUrl);
}

export function* watchEpisodePlayUrl() {
  yield takeLatest(
    PlayerPodCastType.SET_EPISODE_PLAY_URL,
    activeEpisodePlayUrl
  );
}

export function* urlSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchSliderGrabber),
    call(watchVideoSelected),
    call(watchArticleSelected),
    call(watchInitBaseUrl),
    call(watchInitializeRadioUrl),
    call(watchCurrentViewPodcastUrl),
    call(watchEpisodePlayUrl),
  ]);
}
