export const GET_HISTORY_RADIO_STATION_START =
  'GET_HISTORY_RADIO_STATION_START';
export const GET_HISTORY_RADIO_STATION_SUCCESS =
  'GET_HISTORY_RADIO_STATION_SUCCESS';
export const GET_HISTORY_RADIO_STATION_FAIL = 'GET_HISTORY_RADIO_STATION_FAIL';

export const ADD_PLAY_RADIO_STATION_START = 'ADD_PLAY_RADIO_STATION_START';
export const ADD_PLAY_RADIO_STATION_SUCCESS = 'ADD_PLAY_RADIO_STATION_SUCCESS';
export const ADD_PLAY_RADIO_STATION_FAIL = 'ADD_PLAY_RADIO_STATION_FAIL';

export const DELETE_PLAY_RADIO_STATION_START =
  'DELETE_PLAY_RADIO_STATION_START';
export const DELETE_PLAY_RADIO_STATION_SUCCESS =
  'DELETE_PLAY_RADIO_STATION_SUCCESS';
export const DELETE_PLAY_RADIO_STATION_FAIL = 'DELETE_PLAY_RADIO_STATION_FAIL';

export const GET_HEARTS_RADIO_STATION_START = 'GET_HEARTS_RADIO_STATION_START';
export const GET_HEARTS_RADIO_STATION_SUCCESS =
  'GET_HEARTS_RADIO_STATION_SUCCESS';
export const GET_HEARTS_RADIO_STATION_FAIL = 'GET_HEARTED_RADIO_STATION_FAIL';

export const RADIO_STATION_HEART_START = 'RADIO_STATION_HEART_START';
export const RADIO_STATION_HEART_SUCCESS = 'RADIO_STATION_HEART_SUCCESS';
export const RADIO_STATION_HEART_FAIL = 'RADIO_STATION_HEART_FAIL';

export const DELETE_HEART_RADIO_STATION_START =
  'DELETE_HEART_RADIO_STATION_START';
export const DELETE_HEART_RADIO_STATION_SUCCESS =
  'DELETE_HEART_RADIO_STATION_SUCCESS';
export const DELETE_HEART_RADIO_STATION_FAIL =
  'DELETE_HEART_RADIO_STATION_FAIL';

export const GET_NEXT_HEART_START = 'GET_NEXT_HEART_START';
export const GET_NEXT_HISTORY_START = 'GET_NEXT_HISTORY_START';