export const GET_FAVORITE_PODCAST_START = 'GET_FAVORITE_PODCAST_START';
export const GET_FAVORITE_PODCAST_SUCCESS = 'GET_FAVORITE_PODCAST_SUCCESS';
export const GET_FAVORITE_PODCAST_FAIL = 'GET_FAVORITE_PODCAST_FAIL';

export const GET_HISTORY_PODCAST_START = 'GET_HISTORY_PODCAST_START';
export const GET_HISTORY_PODCAST_SUCCESS = 'GET_HISTORY_PODCAST_SUCCESS';
export const GET_HISTORY_PODCAST_FAIL = 'GET_HISTORY_PODCAST_FAIL';

export const GET_HISTORY_PODCAST_METADATA_SUCCESS = 'GET_HISTORY_PODCAST_METADATA_SUCCESS';
export const GET_HISTORY_PODCAST_METADATA_FAIL = 'GET_HISTORY_PODCAST_METADATA_FAIL';

export const ADD_PLAY_PODCAST_START = 'ADD_PLAY_PODCAST_START';
export const ADD_PLAY_PODCAST_SUCCESS = 'ADD_PLAY_PODCAST_SUCCESS';
export const ADD_PLAY_PODCAST_FAIL = 'ADD_PLAY_PODCAST_FAIL';

export const PODCAST_HEART_START = 'PODCAST_HEART_START';
export const PODCAST_HEART_SUCCESS = 'PODCAST_HEART_SUCCESS';
export const PODCAST_HEART_FAIL = 'PODCAST_HEART_FAIL';

export const DELETE_FAVORITE_PODCAST_START = 'DELETE_FAVORITE_PODCAST_START';
export const DELETE_FAVORITE_PODCAST_SUCCESS = 'DELETE_FAVORITE_PODCAST_SUCCESS';
export const DELETE_FAVORITE_PODCAST_FAIL = 'DELETE_FAVORITE_PODCAST_FAIL';

export const DELETE_HISTORY_PODCAST_START = 'DELETE_HISTORY_PODCAST_START';
export const DELETE_HISTORY_PODCAST_SUCCESS = 'DELETE_HISTORY_PODCAST_SUCCESS';
export const DELETE_HISTORY_PODCAST_FAIL = 'DELETE_HISTORY_PODCAST_FAIL';

export const GET_NEXT_HEART_START = 'GET_NEXT_HEART_START';
export const GET_NEXT_HISTORY_START = 'GET_NEXT_HISTORY_START';
