import React, { useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ReorderIcon from '@material-ui/icons/Reorder';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { SortableHandle } from 'react-sortable-hoc';

import ToolTipKeys from 'constants/ToolTipKeys';
import {
  Author,
  GlobalRepeats,
  GlobalHearts,
  UserRepeats,
  Duration,
  LastViewed,
  HeartButton,
  QueueButton,
  AddToPlaylistButton,
  DeleteButton,
  MoreActionsDropdownButton,
} from './components';
import { VideoActionTooltip } from '../VideoActionTooltip';
import container from './VideoListItem.container';

import styles from './VideoListItem.styles';

const useStyles = makeStyles((theme) => ({
  item: {
    ...styles.item,
    borderColor: theme.palette.borderColor,
    borderBottomColor: theme.palette.borderColor,
    '&:hover': {
      backgroundColor: theme.palette.hoverColorItem,
    },
    '&:hover $actionOptions': {
      opacity: 1,
    },
    '& $showOption': {
      opacity: 1,
    },
  },
  sortHandle: {
    ...styles.item.sortHandle,
  },
  sortHandleIcon: {
    ...styles.item.sortHandle.icon,
  },
  image: {
    ...styles.item.image,
  },
  textWrapper: {
    ...styles.item.textWrapper,
  },
  layer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'block',
    backgroundColor: 'rgba(0,0,0,.7)',
    // backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1))',
  },
  icon: {
    position: 'absolute',
    left: 45,
    width: 34,
    height: 34,
    fill: '#FFF',
    marginTop: 20,
  },
  title: {
    ...styles.item.title,
    color: theme.palette.textColor,
  },
  activeTitle: {
    color: '#ff0066',
  },
  subInfo: {
    ...styles.item.subInfo,
  },
  buttonWrapper: {
    ...styles.item.buttonWrapper,
  },
  actionOptions: {
    opacity: 0,
    whiteSpace: 'nowrap',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      color: theme.palette.black,
    },
    [theme.breakpoints.down('xs')]: {
      opacity: 1,
    },
  },
  showOption: {
    opacity: 1,
    whiteSpace: 'nowrap',
    transition: 'all .2s ease-in-out',
  },
}));

const SortHandle = SortableHandle((props) => (
  <Avatar className={props.className}>
    <ReorderIcon style={{ color: props.color, fontSize: '1rem' }} />
  </Avatar>
));

const VideoListItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    tooltipState,
    onAddHeartStart,
    onDeleteHeartStart,
    onShowSnackbarNotification,
    onClearCurrentlyViewedStream,
    onEnqueueToolTip,
    onCloseToolTip,
    onAddToQueueStart,
    onSetLastPlayed,
  } = props;

  const [openAction, setOpenAction] = React.useState({});

  const ytid = props.ytid;
  const metadata = props.metadata || {};
  const title = _.unescape(metadata.title) || 'NA';

  // Video Info
  const hasPlay = props.history;
  const hasStats = props.globalStats;

  // console.log({ hasPlay, hasStats });

  const isHearted = hasPlay && props.history.star;
  const isSortMode = props.isSortMode;
  const isPlaying = props.isPlaying;
  const isEditable = props.isEditable;

  // Colours
  const accent1Color = theme.palette.accent1Color;
  const accent2Color = theme.palette.accent2Color;
  const accent3Color = theme.palette.accent3Color;
  const canvasColor = theme.palette.canvasColor;
  const hoverColor = theme.palette.alternateTextColor;
  const fillColor = theme.palette.fillColor;

  const end = fade(accent1Color, 0.0);
  const bgColor = fade(accent1Color, 0.2);
  const frontColor = fade(accent1Color, 0.2);

  const backgroundImage = `linear-gradient(to right, ${end} 0%, ${frontColor} 20%, ${bgColor} 40%, ${end} 100%)`;
  const rootStyle = {
    borderColor: theme.palette.accent3Color,
    backgroundImage: backgroundImage,
    backgroundColor: bgColor,
  };

  useEffect(() => {
    return () => {
      onCloseToolTip();
    };
  }, []);

  const onClickHandler = (evt) => {
    onSetLastPlayed(props.ytid);
    onClearCurrentlyViewedStream();
    props.onClick(ytid);
  };

  const onHeartClick = (evt) => {
    // console.log('onHeartClick...');
    if (props.history != null && props.history.star === true) {
      onDeleteHeartStart(props.ytid);
      onShowSnackbarNotification(`Video Un-hearted 😢`);
      window._gaq.push(['_trackEvent', 'Action', 'Unheart Video', ytid]);

    } else {
      onAddHeartStart(props.ytid);
      onShowSnackbarNotification(`Video Hearted ❤️`);
      window._gaq.push(['_trackEvent', 'Action', 'Heart Video', ytid]);

    }

    evt.stopPropagation();
    evt.preventDefault();
  };

  const onDeleteClick = (evt) => {
    // console.log('onDeleteClick...');
    props.onDeleteClick(evt, props.ytid);

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    evt.preventDefault();
  };

  const onAddToQueue = (evt) => {
    // console.log('onAddToQueue...');
    onAddToQueueStart(props.ytid);
    onShowSnackbarNotification(`Video Added to Queue`);
    // analytics.actions.trackUIClick('Queue Song', props.ytid)

    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    evt.preventDefault();
  };

  const onAddToPlaylistClick = (evt) => {
    // console.log('onAddToPlaylistClick...');
    evt.stopPropagation();
    evt.preventDefault();

    props.onAddToPlaylistClick(evt, props.ytid);
  };

  const onClick = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    onSetLastPlayed(props.ytid);
    onClearCurrentlyViewedStream();
    props.onClick(props.ytid);
  };

  const onMoreItemClick = (evt, ytid) => {
    // console.log('onMoreItemClick...', ytid);
    setOpenAction({
      [ytid]: true,
    });
    onEnqueueToolTip(ToolTipKeys.VideoAction, null, ytid);

    evt.stopPropagation();
    evt.preventDefault();
  };

  const handleClose = () => {
    setOpenAction({});
  };

  return (
    <div
      className={clsx('video-item', classes.item)}
      style={{
        // borderColor: accent3Color,
        // background: props.showNoBackground ? null : canvasColor,
        position: 'relative',
      }}
      onClick={onClickHandler}
    >
      {isSortMode && (
        <div
          className={clsx('video-list-handle', classes.sortHandle)}
          style={{
            backgroundColor: accent2Color,
          }}
        >
          <SortHandle className={classes.sortHandleIcon} color={canvasColor} />
        </div>
      )}
      <div
        className={clsx(
          'video-list-item-image-loading-background',
          classes.image
        )}
        style={rootStyle}
      >
        <div style={{ position: 'relative' }}>
          <img
            id={'yt-' + ytid}
            // style={{ display: 'none' }}
            // ref={(img) => {
            //   onImageRef(img);
            // }}
            src={`//i.ytimg.com/vi/${ytid}/default.jpg`}
            width="120"
            height="70"
            alt=""
          />
        </div>
        {isPlaying && (
          <div className={clsx(classes.layer)}>
            <PlayArrowIcon className={classes.icon} />
          </div>
        )}
      </div>

      <div className={classes.textWrapper}>
        <div>
          <div
            className={clsx('video-list-item-title', classes.title, {
              [classes.activeTitle]: isPlaying,
            })}
          >
            {title}
          </div>
        </div>

        <div className={classes.subInfo}>
          <Author
            name={metadata.author}
            show={metadata.author}
            showMobile={props.showMobileAuthor}
            color={fillColor}
          />
          <GlobalRepeats
            n={hasStats ? props.globalStats.totalRepeats || 0 : 0}
            show={hasStats}
            showMobile={props.showMobileGlobalRepeats}
            color={fillColor}
          />
          <GlobalHearts
            n={hasStats ? props.globalStats.totalHearts || 0 : 0}
            show={hasStats}
            showMobile={props.showMobileGlobalHearts}
            color={fillColor}
          />
          <UserRepeats
            n={hasPlay ? props.history.repeats || 0 : 0}
            showMobile={props.showMobileUserRepeats}
            color={fillColor}
          />
          <Duration
            show={metadata.duration}
            str={metadata.duration}
            showMobile={props.showMobileDuration}
            color={fillColor}
          />
          <LastViewed
            show={hasPlay && props.history.last_viewed}
            date={hasPlay ? props.history.last_viewed || 0 : 0}
            showMobile={props.showMobileLastViewed}
            color={fillColor}
          />
        </div>
      </div>

      {props.showControls && (
        <div className={classes.buttonWrapper}>
          <Hidden mdUp>
            <MoreActionsDropdownButton
              onClick={(e) => onMoreItemClick(e, ytid)}
              color={accent2Color}
              hoverColor={hoverColor}
            />
          </Hidden>
          <Hidden smDown>
            <div className={classes.actionOptions}>
              <AddToPlaylistButton
                onClick={onAddToPlaylistClick}
                color={fillColor}
                hoverColor={hoverColor}
                ytid={ytid}
                playlistState={props.playlistState}
                userState={props.userState}
              />
              {isEditable && (
                <DeleteButton
                  onDeleteClick={onDeleteClick}
                  anchorRef={props.deleteRef}
                  color={accent2Color}
                  hoverColor={hoverColor}
                />
              )}
            </div>

            <div className={classes.actionOptions}>
              <QueueButton
                onClick={onAddToQueue}
                color={fillColor}
                hoverColor={hoverColor}
              />
            </div>
            <div
              className={clsx(classes.actionOptions, {
                [classes.showOption]: isHearted,
              })}
            >
              {!props.noHearts && (
                <HeartButton
                  onClick={onHeartClick}
                  heart={isHearted}
                  color={fillColor}
                  hoverColor={hoverColor}
                />
              )}
            </div>
          </Hidden>
        </div>
      )}
      {openAction[ytid] &&
        tooltipState.currentEntry.key === ToolTipKeys.VideoAction && (
          <VideoActionTooltip
            ytid={ytid}
            open={openAction[ytid]}
            toolTipKey={ToolTipKeys.VideoAction}
            noHearts={props.noHearts}
            heart={(props.history || {}).star || false}
            onPlayClick={onClick}
            onHeartClick={!props.noHearts && onHeartClick}
            onDeleteClick={props.isEditable && onDeleteClick}
            onAddToPlaylistClick={onAddToPlaylistClick}
            onAddToQueueClick={onAddToQueue}
            onHandleClose={handleClose}
          />
        )}
    </div>
  );
};

export default container(VideoListItem);
