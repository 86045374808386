const styles = {
  artistRow: {
    width: 85,
    height: 85,
    // marginRight: 15,
    // marginBottom: 16,
    cursor: 'pointer',
    // float: 'left',
    // textAlign: 'center',
  },

  artistImage: {
    display: 'block',
    width: 84,
    height: 84,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundOrigin: 'border-box',
    backgroundSize: '84px 84px',
    borderRadius: 2,
    boxSizing: 'border-box',
    // transition: 'all 0.2s ease',
  },

  artistNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    width: 85,
    height: 85,
    top: -84,
    left: 0,
    borderRadius: 2,
    background: 'rgba(0, 0, 0, 0.6)',
  },

  artistName: {
    cursor: 'pointer',
    fontWeight: 400,
    // textShadow: '0px 1px 1px rgba(0, 0, 0, 1)',
    color: '#ffffff',
    // lineHeight: 20,
    fontSize: 13,
    width: 85,
    textOverflow: 'clip',
    overflow: 'hidden',
  },
};

export default styles;
