const styles = {
  mainArea: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    marginTop: 10,
  },
  mainColumn: {
    position: 'relative',
    maxWidth: '728px',
    width: '100%',
    marginLeft: 10,
    marginRight: 10,
  },
  rightColumn: {
    position: 'relative',
    width: 300,
    marginLeft: 10,
    marginRight: 5,
  },
};

export default styles;
