import React from 'react';

import { ContentPage } from 'common/ui/content-page';
import { AboutUsBlock } from './components';

const AboutUsPage = () => {
  return (
    <ContentPage
      header={{
        title: 'About Us',
        description: 'Learn a bit more about ListenOnRepeat',
      }}
      body={<AboutUsBlock />}
    />
  );
};

export default AboutUsPage;
