import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'instances/radioServer';
import _ from 'lodash';

import * as UserhistoryRadioType from './userhistoryRadio.types';
import * as userhistoryRadioAction from './userhistoryRadio.actions';

import { selectRadioForPreview } from '../radio/radio.selectors';
import { selectRadioHistoryForPreview } from './userhistoryRadio.selectors';

const getUserState = (state) => state.user;

export function* addHeartAsync({ payload: { stationid } }) {
  const userState = yield select(getUserState);
  let selectedRadioStats = yield select(selectRadioForPreview(stationid));

  if (_.isEmpty(selectedRadioStats)) {
    selectedRadioStats = yield select(selectRadioHistoryForPreview(stationid));
  }
  // console.log('selectedRadioStats:: ', selectedRadioStats);
  try {
    // console.log('addHeartAsync...');
    const body = {
      stationid,
      uid: userState.profile.uid,
      name: selectedRadioStats?.name,
      tags: selectedRadioStats?.tags,
      country: selectedRadioStats?.country,
      countrycode: selectedRadioStats?.countrycode,
      state: selectedRadioStats?.state,
      url: selectedRadioStats?.url,
      url_resolved: selectedRadioStats?.url_resolved,
      votes: selectedRadioStats?.votes,
      clickcount: selectedRadioStats?.clickcount,
      homepage: selectedRadioStats?.homepage,
      language: selectedRadioStats?.language,
      favicon: selectedRadioStats?.favicon,
      codec: selectedRadioStats?.codec,
    };
    // console.log({ body });
    // console.log('body:: ', body);
    const { data } = yield axios.post('/radio/heart/set', body);
    // console.log('data:: ', data);
    window._gaq.push([
      '_trackEvent',
      'RadioAction',
      'Hearted',
      body.name.toString(),
    ]);

    yield put(userhistoryRadioAction.radioStationHeartSuccess(data));
  } catch (err) {
    yield put(userhistoryRadioAction.radioStationHeartFail(err));
  }
}

export function* deleteHeartAsync({ payload: { stationid } }) {
  // console.log('deleteHeartAsync...', stationid);
  const userState = yield select(getUserState);

  try {
    const body = {
      uid: userState.profile.uid,
      stationid,
    };

    const { data } = yield axios.post(`/radio/heart/clear`, body);
    window._gaq.push([
      '_trackEvent',
      'RadioAction',
      'UnHearted',
      stationid.toString(),
    ]);

    // console.log(data);
    yield put(userhistoryRadioAction.deleteHeartRadioStationSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(userhistoryRadioAction.deleteHeartRadioStationFail(err));
  }
}

export function* getHeartsPageAsync({ payload: { next, merge } }) {
  const userState = yield select(getUserState);
  const uid = userState.profile.uid;

  try {
    // console.log('getHeartsPageAsync...', uid);
    const { data } = yield axios.get(`/radio/heart/${uid}`);
    // console.log('here.....');
    // console.log(data);
    // const ytids = data.res.map((x) => x.ytid);
    // // console.log(ytids);
    // if (ytids.length > 0) {
    //   const videoData = {
    //     payload: {
    //       ytids,
    //     },
    //   };
    //   yield all([
    //     call(getVideoMetadataAsync, videoData),
    //     call(getVideoStatsAsync, videoData),
    //   ]);
    // }
    yield put(
      userhistoryRadioAction.getHeartsRadioStationSuccess({
        response: {
          res: data,
        },
        merge,
      })
    );
  } catch (err) {
    console.error(err);
    yield put(userhistoryRadioAction.getHeartsRadioStationFail(err));
  }
}

export function* addHistoryAsync({ payload: { stationid } }) {
  // console.log('addHistory...');
  const userState = yield select(getUserState);
  let selectedRadioStats = yield select(selectRadioForPreview(stationid));

  if (_.isEmpty(selectedRadioStats)) {
    selectedRadioStats = yield select(selectRadioHistoryForPreview(stationid));
  }
  try {
    const body = {
      stationid,
      uid: userState.profile.uid,
      name: selectedRadioStats?.name,
      tags: selectedRadioStats?.tags,
      country: selectedRadioStats?.country,
      countrycode: selectedRadioStats?.countrycode,
      state: selectedRadioStats?.state,
      url: selectedRadioStats?.url,
      url_resolved: selectedRadioStats?.url_resolved,
      votes: selectedRadioStats?.votes,
      clickcount: selectedRadioStats?.clickcount,
      homepage: selectedRadioStats?.homepage,
      language: selectedRadioStats?.language,
      favicon: selectedRadioStats?.favicon,
      codec: selectedRadioStats?.codec,
    };
    // console.log('body: ', body);
    const { data } = yield axios.post('/radio/history/set', body);
    window._gaq.push([
      '_trackEvent',
      'RadioAction',
      'HistoryAdded',
      body.name.toString(),
    ]);

    // console.log(data);
    yield put(userhistoryRadioAction.addPlayRadioStationSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(userhistoryRadioAction.addPlayRadioStationFail(err));
  }
}

export function* deleteHistoryAsync({ payload: { stationid } }) {
  // console.log('deleteHistory...', stationid);
  const userState = yield select(getUserState);
  try {
    const body = {
      uid: userState.profile.uid,
      stationid,
    };

    const { data } = yield axios.post(`/radio/history/clear`, body);
    window._gaq.push([
      '_trackEvent',
      'RadioAction',
      'HistoryDeleted',
      body.stationid.toString(),
    ]);

    yield put(userhistoryRadioAction.deletePlayRadioStationSuccess(data));
  } catch (err) {
    yield put(userhistoryRadioAction.deletePlayRadioStationFail(err));
  }
}

export function* getHistoryPageAsync({ payload: { next, merge } }) {
  const userState = yield select(getUserState);
  const uid = userState.profile.uid;

  try {
    // console.log('getHistoryPage...', uid);
    const { data } = yield axios.get(`/radio/history/${uid}`);
    // console.log('here.....');
    // console.log({ data });
    // const ytids = data.res.map((x) => x.ytid);
    // // console.log(ytids);
    // if (ytids.length > 0) {
    //   const videoData = {
    //     payload: {
    //       ytids,
    //     },
    //   };
    //   yield all([
    //     call(getVideoMetadataAsync, videoData),
    //     call(getVideoStatsAsync, videoData),
    //   ]);
    // }
    yield put(
      userhistoryRadioAction.getHistoryRadioStationSuccess(
        {
          history: data,
        },
        merge
      )
    );
  } catch (err) {
    console.error(err);
    yield put(userhistoryRadioAction.getHistoryRadioStationFail(err));
  }
}

/**
 * watch generator functions
 */
export function* watchGetHeartsRadioStation() {
  yield takeLatest(
    UserhistoryRadioType.GET_HEARTS_RADIO_STATION_START,
    getHeartsPageAsync
  );
}

export function* watchAddHeartRadioStation() {
  yield takeLatest(
    UserhistoryRadioType.RADIO_STATION_HEART_START,
    addHeartAsync
  );
}

export function* watchDeleteHeartRadioStation() {
  yield takeLatest(
    UserhistoryRadioType.DELETE_HEART_RADIO_STATION_START,
    deleteHeartAsync
  );
}
export function* watchGetPlays() {
  yield takeLatest(
    UserhistoryRadioType.GET_HISTORY_RADIO_STATION_START,
    getHistoryPageAsync
  );
}

export function* watchAddPlay() {
  yield takeLatest(
    UserhistoryRadioType.ADD_PLAY_RADIO_STATION_START,
    addHistoryAsync
  );
}

export function* watchDeletePlay() {
  yield takeLatest(
    UserhistoryRadioType.DELETE_PLAY_RADIO_STATION_START,
    deleteHistoryAsync
  );
}

export function* userHistoryRadioSagas() {
  yield all([
    call(watchGetPlays),
    call(watchAddPlay),
    call(watchDeletePlay),
    call(watchGetHeartsRadioStation),
    call(watchAddHeartRadioStation),
    call(watchDeleteHeartRadioStation),
  ]);
}
