import { connect } from 'react-redux';

import {
  setSortType,
  showEditPlaylistModal,
  showDeletePlaylistModal,
  addFollowedPlaylistStart,
  deleteFollowedPlaylistStart,
} from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapStateToProps = (state) => ({
  article: state.article,
  playerState: state.player,
  isLoading: state.article.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onSetSortType: (value) => dispatch(setSortType(value)),
  onShowEditPlaylistModal: (pid, name, publicPlaylist, publicLink) =>
    dispatch(showEditPlaylistModal(pid, name, publicPlaylist, publicLink)),
  onShowDeletePlaylistModal: (pid, name) =>
    dispatch(showDeletePlaylistModal(pid, name)),
  onAddFollowedPlaylistStart: (pid) => dispatch(addFollowedPlaylistStart(pid)),
  onDeleteFollowedPlaylistStart: (pid) =>
    dispatch(deleteFollowedPlaylistStart(pid)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
