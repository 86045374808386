import { connect } from 'react-redux';

import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';
import { setCurrentStreamStart } from 'store/streams/streams.actions';


const mapStateToProps = (state) => ({
  playerStore: state.player,
  streamState: state.streams,
  videoStore: state.video,
});

const mapDispatchToProps = (dispatch) => ({
  onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
  onSetCurrentStreamStart: (pid) => dispatch(setCurrentStreamStart(pid)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
