import React from 'react';
import PropTypes from 'prop-types';

import PlaylistDeleteTooltip from './PlaylistDeleteTooltip';

const ShowDeletePlaylist = (props) => {
  const {
    title,
    description,
    element,
    ytid,
    confirmDelete,
    cancelDelete,
  } = props;

  return (
    <PlaylistDeleteTooltip
      title={title}
      description={description}
      open={true}
      id={'playlist-tooltip-delete-' + title}
      ytid={ytid}
      element={element}
      confirmDelete={confirmDelete}
      cancelDelete={cancelDelete}
    />
  );
};

ShowDeletePlaylist.propTypes = {};

export default ShowDeletePlaylist;
