import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Hidden,
  Slide,
  TextField
} from '@material-ui/core';
import axios from 'instances/server';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  loginModal: {
    zIndex: `${theme.zIndex.modal + 2} !important`,
  },
  modalContent: {
    color: 'rgba(37, 37, 37, 0.6)',
    fontSize: 16,
    padding: 22,
    textAlign: 'center',
    background: theme.palette.paperColor,
    zIndex: theme.zIndex.modal + 2,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  facebookBtnColor: {
    width: '33.44%',
    marginLeft: 0,
    padding: '8px 0px',
    zIndex: 0,
    fontWeight: 400,
    borderRadius: 0,
    color: 'rgb(37, 92, 149)',
    background: theme.palette.facebookColor,
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  googleBtnColor: {
    width: '33.44%',
    marginLeft: 0,
    padding: '8px 0px',
    zIndex: 0,
    fontWeight: 400,
    borderRadius: 0,
    color: 'rgb(241, 64, 58)',
    background: theme.palette.googleColor,
    '&:hover': {
      backgroundColor: 'rgba(153, 153, 153, 0.2)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  mailBtnColor: {
    width: '33.44%',
    marginLeft: 0,
    padding: '8px 0px',
    zIndex: 0,
    fontWeight: 400,
    borderRadius: 0,
    color: '#fff',
    background: theme.palette.mailColor,
    '&:hover': {
      backgroundColor: 'rgba(16, 5, 11, 0.69)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0px',
    },
  },
  cancelBtnColor: {
    width: '33.44%',
    marginLeft: 0,
    zIndex: 0,
    fontWeight: 400,
    height: 40,
    borderRadius: 0,
    color: theme.palette.textColor,
    background: theme.palette.cancelColor,
    /*[theme.breakpoints.down('sm')]: {
            backgroundColor: 'rgba(153, 153, 153, 0.2)',
        },*/
    '&:hover': {
      backgroundColor: theme.palette.cancelHoverColor,
    },
  },
  modalWidth: {
    width: '768px',
    marginTop: -100,
    maxWidth: '75%',
  },
  loginText: {
    color: 'rgb(255, 0, 102)',
    fontSize: 22,
    fontWeight: 400,
    fontFamily: 'Arial',
    lineHeight: 1.2,
    [theme.breakpoints.down('xs')]: {
      fontWeight: 500,
    },
  },
  modalMainText: {
    color: theme.palette.textColor1,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1,
    marginTop: 10,
    fontFamily: 'Arial',
  },
  modalText: {
    color: 'grey',
    fontFamily: 'Arial',
    fontSize: 14,
  },
  label: {
    color: theme.palette.darkGray,
    fontFamily: 'Arial',
    '&$focusedLabel': {
      color: theme.palette.darkGray,
    },
    '&$erroredLabel': {
      color: '#f44336',
    },
  },
  underline: {
    '&$error:after': {
      borderBottomColor: '#f44336',
    },
    '&:after': {
      borderBottom: `2px solid rgb(255, 0, 102)`,
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  error: {},
  inputBase: {
    color: theme.palette.alternateTextColor,
    fontFamily: 'Arial',
  },
}));


const CreatePasswordModal = ({
  open,
  handleClose,
  provider,
  authedUid,
}) => {

  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onSubmit = () => {
    if (password !== "") {
      setError("Password requried")
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({
        password,
        provider,
        authedUid,
      });

      axios
        .post('/auth/generatePassword', body, config)
        .then(function (response) {
          // console.log(response);
          if (response.status == 200) {
            handleClose();
            console.log("response", response)
          }
        });
      // console.log(res)
    } catch (e) {
      console.error(e);
    }

  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // keepMounted
      onClose={() => { }}
      // fullWidth
      // maxWidth={matchesMD ? 'sm' : 'md'}
      classes={{
        root: classes.loginModal,
        paper: classes.modalWidth,
      }}
    >

      <DialogContent className={classes.modalContent} style={{ paddingTop: 0, margin: "15px 15px 0 15px" }}>
        <Typography component="h5" variant="h6" className={classes.loginText}>
          Generate password for {provider} on ListenOnRepeat
        </Typography>
        <Typography
          component="h6"
          variant="h6"
          className={classes.modalMainText}
        >
          Never lose your saved music and access it everywhere!
        </Typography>
        <Hidden smUp>
          <p className={classes.modalText}>
            <span role="img" aria-label="rocket">
              🚀
            </span>
            <br />
            Save your repeats, favorites, and playlists forever
          </p>
          <p className={classes.modalText}>
            <span role="img" aria-label="earth">
              🌍
            </span>
            <br />
            Watch &amp; listen to your music from any device
          </p>
        </Hidden>
      </DialogContent>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <TextField
          type="password"
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          InputProps={{
            classes: {
              input: classes.inputBase,
              root: classes.underline,
              error: classes.error,
            },
          }}
          label="Password"
          errorText={error}
          style={{ width: '50%', color: '#000' }}
          errorStyle={{ bottom: 18 }}
          onChange={(e) => {
            setPassword(e.target.value);
            if (e.target.value.length > 0) {
              setError("");
            } else {
              setError("Password requried");
            }
          }}
          onBlur={() => {
            if (password === "") {
              setError("Password requried");
            } else {
              setError("");
            }
          }}
        />
        <br />
        <br />

        <Button
          variant='outlined'
          color="primary"
          disabled={error !== ""}
          onClick={onSubmit}
        >
          <Hidden xsDown>Generate Password</Hidden>
        </Button>
        <br />
        <br />
      </div>

    </Dialog>
  );
};

export default CreatePasswordModal;
