import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import container from './PlaylistCardSharingInfo.container'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderColor: theme.palette.accent3Color
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    cursor: 'pointer',
  },
  textfield: {
    display: 'inline',
  },
  inputRoot: {
    width: '100%', 
    overflow: 'hidden', 
    height: 26,
  },
  inputInput: {
    fontSize: 13,
    cursor: 'pointer',
    paddingLeft: 140,
    color: '#ff0066',
  },
}));


const PlaylistCardSharingInfo = (props) => {
  const classes = useStyles();
  const copyAnchorElement = React.useRef(null);

  const {
    onShowSnackbarNotification,
  } = props;
  

  const playlistLink = () => `${window.location.origin}/?p=playlist/${props.pid}`;


  const onTextfieldOverlayClick = e => {
    let textField = document.createElement('textarea')
    textField.innerText = playlistLink()
    document.body.appendChild(textField)
    if (textField.select) {
      textField.select()
      try {
        document.execCommand('copy')
        onShowSnackbarNotification('Copied Link ✂️')
      } catch (err) {
        // no-op
      }
    }
    document.body.removeChild(textField)
    textField.remove()

    if (copyAnchorElement.current) {
      console.log(copyAnchorElement.current.value);
      console.log(copyAnchorElement.current)
      copyAnchorElement.current.select()
    }
    return false
  }



  return (
    <div className={classes.root}>
      <div style={{ position: 'absolute', top: 5, left: 16 }}>
          Share this playlist:
        </div>

        <div className={classes.textfield}>
          <div
            className={classes.overlay}
            onClick={onTextfieldOverlayClick}
          />
          <TextField
            id="playlist-detail-link"
            inputRef={copyAnchorElement}
            value={playlistLink()}
            classes={{
              root: classes.inputRoot,
            }}
            InputProps={{
              classes: {
                input: classes.inputInput,
              },
            }}
          />
          </div>
      
    </div>
  )
}

export default container(PlaylistCardSharingInfo)
