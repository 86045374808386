import { connect } from 'react-redux';
import { toggleSidebar } from 'store/siteCoordinator/siteCoordinator.actions';

const mapStateToProps = (state) => ({
    siteCoordinator: state.siteCoordinator,
});

const mapDispatchToProps = (dispatch) => ({
    onToggleSidebar: (value) => dispatch(toggleSidebar(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
