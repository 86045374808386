import * as LoginType from './login.types';

export const loadUserStart = () => ({
  type: LoginType.LOAD_USER_START
});

export const loadUserSuccess = (currentUser) => ({
  type: LoginType.LOAD_USER_SUCCESS,
  payload: currentUser
});

export const loadUserFail = (error) => ({
  type: LoginType.LOAD_USER_FAILURE,
  payload: error
});

export const showLoginModal = () => ({
  type: LoginType.SHOW_LOGIN_MODAL
});

export const hideLoginModal = () => ({
  type: LoginType.HIDE_LOGIN_MODAL
});

export const showMergeModal = (data) => ({
  type: LoginType.SHOW_MERGE_MODAL,
  payload: data
});

export const hideMergeModal = () => ({
  type: LoginType.HIDE_MERGE_MODAL
});

export const mergeAccountStart = () => ({
  type: LoginType.MERGE_ACCOUNT_START
});

export const mergeAccountSuccess = () => ({
  type: LoginType.MERGE_ACCOUNT_SUCCESS
});

export const mergeAccountFail = (err) => ({
  type: LoginType.MERGE_ACCOUNT_FAIL,
  payload: err
});

export const showLogoutConfirmationModal = () => ({
  type: LoginType.SHOW_LOGOUT_CONFIRM_MODAL
});

export const hideLogoutConfirmationModal = () => ({
  type: LoginType.HIDE_LOGOUT_CONFIRM_MODAL
});

export const fbLoginStart = () => ({
  type: LoginType.FB_LOGIN_START
});

export const googleLoginStart = () => ({
  type: LoginType.GOOGLE_LOGIN_START
});

export const emailLoginStart = (userId) => ({
  type: LoginType.EMAIL_LOGIN_START,
  payload:{
    userId
  }
});

export const loginSuccess = (data) => ({
  type: LoginType.LOGIN_SUCCESS,
  payload: data
});

export const loginFail = (error) => ({
  type: LoginType.LOGIN_FAIL,
  payload: error
});

export const logoutStart = () => ({
  type: LoginType.LOGOUT_START
});

export const logoutSuccess = () => ({
  type: LoginType.LOGOUT_SUCCESS
});

export const logoutFail = (error) => ({
  type: LoginType.LOGOUT_FAIL,
  payload: error
});

export const hideGeneratePasswordModal = () => ({
  type: LoginType.HIDE_GENERATE_PASSWORD_MODAL
});

export const showGeneratePasswordModal = (data) => ({
  type: LoginType.SHOW_GENERATE_PASSWORD_MODAL,
  payload: data,
});