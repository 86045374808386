const styles = {
  featureCard: {
    position: 'relative',
    marginBottom: 20,

    highlight: {
      color: '#ff0066',
    },
  },

  streams: {
    title: {
      color: '#ff0066',
      padding: 10,
      paddingLeft: 20,
      pointerEvents: 'none',
      fontSize: 16,
    },
  },
};

export default styles;
