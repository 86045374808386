import { connect } from 'react-redux';

import {
  setAutoplay,
  setRepeatMode,
  viewPlayerVideoStart,
  playFeature,
} from 'store/player/player.actions';
import {
  setCurrentPlaylistStart,
  queueYtid,
  showAddPlaylistModal,
} from 'store/playlist/playlist.actions';
import {
  removeItemFromCue,
  playArtistRadio,
  repeatCue,
} from 'store/artist/artist.actions';
import {
  enqueueToolTip,
  closeToolTip,
  hideDeletePlaylistToolTip,
} from 'store/tooltip/tooltip.actions';

const mapStateToProps = (state) => ({
  playlistState: state.playlist,
  userState: state.user,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onSetRepeatMode: (value) => dispatch(setRepeatMode(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onSetCurrentPlaylistStart: (value) =>
    dispatch(setCurrentPlaylistStart(value)),
  onPlayFeature: (value) => dispatch(playFeature(value)),
  onRemoveItemFromCue: (value) => dispatch(removeItemFromCue(value)),
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onPlayArtistRadio: (ytid, artistName) =>
    dispatch(playArtistRadio(ytid, artistName)),
  onHideDeletePlaylistToolTip: () => dispatch(hideDeletePlaylistToolTip()),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
  onRepeatCue: (value) => dispatch(repeatCue(value)),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
