import React from 'react';
import { ContentPage } from 'common/ui/content-page';

import ContactForm from './ContactForm';

const ContactPage = () => {
  return (
    <ContentPage
      header={{
        title: 'Contact Us',
        description:
          'Spotted a bug? Want to request a feature? Or just say hey? Drop us a message and we’ll get back to you ASAP',
      }}
      body={<ContactForm />}
    />
  );
};

export default ContactPage;
