import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  artistState: state.artist,
  videoState: state.video,
  userhistoryState: state.userhistory,
  currentYtid: state.player.ytid,
  artistName: state.artist.radioName,
});

const container = connect(mapStateToProps);

export default container;
