import _ from 'lodash';

import * as PlayerRadioType from './playerRadio.types';

const INITIAL_STATE = {
  stationid: null,
  player: {
    playing: true,
    isReady: false,
    state: -1,
    playPrevOrRestart: null,
  },
  isLoading: false,
  autoplay: true,
  playHistory: [],
};

const playerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case PlayerRadioType.TOGGLE_RADIO_PLAY_PAUSE:
      return {
        ...state,
        player: {
          ...state.player,
          playing: !state.player.playing,
        },
      };

    case PlayerRadioType.VIEW_RADIO_PLAYER_START:
      return {
        ...state,
        stationid: payload.stationid,
      };

    default:
      return state;
  }
};

export default playerReducer;
