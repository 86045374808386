import { takeLatest, call, put, all, select } from "redux-saga/effects";
import jsonp from "jsonp";
import qs from "querystring";
import _ from "lodash";
import mainAxios from "axios";

import axios from "instances/server";

import * as SearchType from "./search.types";
import SectionTab from "constants/SectionTab";

import { getSearchPreviewAsync } from "../artist/artist.sagas";
import {
  setSystemPlaylistHumanName,
  setSystemPlaylistYtids,
  clearCurrentlyViewedPlaylist,
} from "../playlist/playlist.actions";
import {
  getAutocompleteSuggestionSuccess,
  youtubeLookupSuccess,
  performSearchSuccess,
  getNextResultsPageSuccess,
  getNextResultsPageFail,
} from "./search.actions";
import { setCurrentTab } from "../siteCoordinator/siteCoordinator.actions";
import {
  getVideoMetadataStart,
  getVideoStatsStart,
} from "../video/video.actions";
import { getPlaysStart } from "store/userhistory/userhistory.actions";
import {
  getVideoMetadataAsync,
  getVideoStatsAsync,
} from "../video/video.sagas";
import { getPlaysAsync } from "../userhistory/userhistory.sagas";

import { normalizeSearchQuery } from "./search.utils";
import { scrollToMainContentArea } from "common/ui/utils";

const getSearch = (state) => state.search;

export function* initializeStore() {
  yield call(createPlaylistListeners);
}

/**
 * Lookup the youtube search for the given query.
 * Has two levels of cache. Local caching for when user has made the request previously.
 * Local cache is filled by request to LOR backend cache.
 * If it does not exist in backend then request is made to youtube and then cached.
 */
export function* youtubeLookupAsync({ payload: query }) {
  // console.log('discovery:: youtubeLookupAsync:: 1', query);
  const searchState = yield select(getSearch);
  const normalizedQuery = normalizeSearchQuery(query);

  // check local cache
  const cacheItem = searchState.cache[normalizedQuery];
  // console.log('discovery:: youtubeLookupAsync:: 2', { cacheItem });
  if (cacheItem) {
    return {
      query,
      res: cacheItem.results,
      next: cacheItem.next,
    };
  }

  let result;
  try {
    const { data } = yield axios.get("/search/query", {
      params: {
        q: normalizedQuery,
        query,
      },
    });
    // console.log('discovery:: youtubeLookupAsync:: 3', { result });
    // if we have cached search results (including an empty list), resp.res is non-null.  otherwise,
    // receiving a null here indicates that we need to fetch results from the Youtube dAPI.
    if (!_.isEmpty(data.res) || !_.isNull(data.res)) {
      // console.log('discovery:: youtubeLookupAsync:: 4');
      result = { query, res: data.res, next: data.next };
      yield put(youtubeLookupSuccess(result));
      return result;
    }

    // we have no cached search results (resp.res is null)
    result = yield call(queryYoutubeAPIAndCacheResults, query, normalizedQuery);

    // console.log('discovery:: youtubeLookupAsync:: 5', { result });

    yield put(youtubeLookupSuccess(result));
    return result;
  } catch (e) {
    result = yield call(queryYoutubeAPIAndCacheResults, query, normalizedQuery);

    // console.log('discovery:: youtubeLookupAsync:: 6', { result });

    yield put(performSearchSuccess(result));
    return result;
  }
}

export function* performSearchAsync({ payload: query }) {
  // Block Adsense/AdX CP
  if (
    _.includes(query.toLowerCase(), "jan dara") ||
    _.includes(query.toLowerCase(), "jan_dara")
  ) {
    query = "Beyonce";
  }

  // analytics.actions.trackAction('Search', query);
  // console.log("before _trackEvent Search"+ query );
  window._gaq.push(["_trackEvent", "Action", "Search", query]);
  // console.log("after _trackEvent Search");
  const queryPayload = {
    payload: query,
  };
  const data = yield call(youtubeLookupAsync, queryPayload);

  // asynchronously grab the video stats and user plays for each video in the search results, and then pass through the original data
  let artistCounts = searchListForArtist(data.res);

  if (artistCounts && artistCounts.length > 0) {
    let ytid = "";
    let queryParts = query.split("-");
    const chosenArtist = artistCounts[0]["artist"];

    if (queryParts.length === 2) {
      ytid = data.res[0].ytid;
    } else {
      let randomArtistPos = Math.floor(
        Math.random() * artistCounts[0]["allYtids"].length
      );
      ytid = artistCounts[0]["allYtids"][randomArtistPos];
    }

    const payloadPreview = {
      payload: {
        artistName: chosenArtist,
        ytid,
      },
    };
    const data1 = yield call(getSearchPreviewAsync, payloadPreview);

    if (Object.keys(data1).length > 0) {
      window._gaq.push([
        "_trackEvent",
        "Action",
        "Artist Radio Display",
        artistCounts[0]["artist"],
      ]);

      // yield analytics.actions.trackAction(
      //   'Artist Radio Display',
      //   artistCounts[0]['artist']
      // );
    }
  }

  let ytids = data.res.map((item) => item.ytid);

  yield all([
    put(getVideoStatsStart(ytids)),
    put(getVideoMetadataStart(ytids)),
    // put(getPlaysStart(ytids)),
  ]);

  yield put(performSearchSuccess(data));
  return data;
}

export function searchListForArtist(list) {
  let itemCount = {};

  list.forEach((video) => {
    const titleParts = video.title.split("-");

    // Skip if not two parts
    if (titleParts.length !== 2) {
      return;
    }

    // Makes an assumption that the video is in ARTIST - NAME format
    const likelyAristName = titleParts[0].trim();
    const codedWord = likelyAristName
      .replace(new RegExp(" ", "g"), "")
      .toLowerCase();

    // Collate count for title
    if (itemCount[codedWord]) {
      itemCount[codedWord].count = itemCount[codedWord].count + 1;
      itemCount[codedWord].allYtids.push(video.ytid);
    } else {
      itemCount[codedWord] = {
        count: 1,
        artist: likelyAristName,
        songName: titleParts[0],
        title: video.title,
        ytid: video.ytid,
        allYtids: [video.ytid],
      };
    }
  });

  return _.sortBy(_.values(itemCount), "count").reverse();
}

export function* hiddenSearch(query) {
  const queryPayload = {
    payload: query,
  };
  const data = yield call(youtubeLookupAsync, queryPayload);
  // asynchronously grab the video stats and user plays for each video in the search results, and then pass through the original data
  const ytids = (data?.res || []).map((video) => video.ytid);

  const videoData = {
    payload: {
      ytids,
    },
  };
  yield all([
    call(getVideoMetadataAsync, videoData),
    call(getVideoStatsAsync, videoData),
    call(getPlaysAsync, videoData),
  ]);

  return data;
}

// MUST USE NON NORMALISED QUERY
export function* onlySearch(query) {
  const queryPayload = {
    payload: query,
  };
  return yield call(youtubeLookupAsync, queryPayload);
}

export function* getNextResultsPageSaga({ payload: { query, cursor } }) {
  // As loading from local cache returns all results, the next page cannot be in it so no lookup done
  const normalizedQuery = normalizeSearchQuery(query);

  try {
    const { data } = yield axios.get("/search/query", {
      params: {
        q: normalizedQuery,
        cursor,
        query: query,
      },
    });

    const response = {
      query,
      res: data.res || [],
      next: data.next,
    };
    // asynchronously grab the video stats and user plays for each video in the search results, and then pass through the original data
    const ytids = (response.res || []).map((video) => video.ytid);
    yield all([
      put(getVideoStatsStart(ytids)),
      // put(getVideoMetadataStart(ytids)),
      put(getPlaysStart(ytids)),
    ]);

    yield put(getNextResultsPageSuccess(response));
  } catch (err) {
    yield put(getNextResultsPageFail(err));
  }
}

export function* getAutocompleteSuggestionAsync({ payload: { partialQuery } }) {
  // console.log('getAutocompleteSuggestionAsync...');

  const query = {
    hl: "en",
    q: partialQuery,
    client: "youtube",
    ds: "yt",
  };

  // console.log({ query });

  const snapshot = yield suggestQuery(query);
  // console.log('snapshot');
  // console.log(snapshot);

  yield put(getAutocompleteSuggestionSuccess(snapshot));
}

function suggestQuery(query) {
  return new Promise((resolve, reject) => {
    jsonp(
      "//suggestqueries.google.com/complete/search?" + qs.stringify(query),
      null,
      (err, data) => {
        if (err) {
          reject(err);
        } else {
          const items = data[1].map((pair) => pair[0]);
          const tenItems = _.take(items, 10);
          resolve(tenItems);
        }
      }
    );
  });
}

function* queryYoutubeAPIAndCacheResults(query, normalizedQuery) {
  const youtubeAPIResponse = yield call(queryYoutubeAPI, query);

  const transformedItems = youtubeAPIResponse.items.map(
    rawYoutubeSearchItemToLORSearchItem
  );

  const body = {
    q: normalizedQuery,
    res: transformedItems,
  };
  yield axios("/search/store/", body);

  return { query, res: transformedItems, next: "" };
}

function rawYoutubeSearchItemToLORSearchItem(item) {
  return {
    ytid: item.id.videoId,
    title: item.snippet.title,
    description: item.snippet.description,
    views: item.snippet.views,
    duration: item.snippet.duration,
    author: item.snippet.channelTitle,
  };
}

function* queryYoutubeAPI(searchQuery) {
  const query = {
    type: "video",
    part: "snippet",
    q: searchQuery,
    maxResults: 50,
    key: SearchType.YOUTUBE_DAPI_KEY,
  };

  const opts = {
    url: "https://www.googleapis.com/youtube/v3/search?" + qs.stringify(query),
    method: "get",
    responseType: "json",
    contentType: "application/json",
  };
  // analytics.actions.trackAction('API Query', searchQuery);
  window._gaq.push(["_trackEvent", "Action", "API Query", searchQuery]);

  const { data } = yield mainAxios(opts);

  return data;
}

export function* getYoutubeLookup(query) {
  // console.log("youtubeLookup>??????>>>"+query)
  query = query.payload;
  const normalizedQuery = normalizeSearchQuery(query);
  // console.log("query>>>"+query)
  // console.log("normalizedQuery>>>"+normalizedQuery)
  // http://localhost:5000/search/query?q=hello&query=hello
  try {
    const { data } = yield axios.get(
      `/search/query?q=${normalizedQuery}&query=${query}`
    );
    // console.log("data>1ccc>>"+data)
    // console.log(data)
    const ytids = data.res.map((x) => x.ytid);
    // console.log(ytids);
    // yield put(performSearchSuccess(data))
    yield all([
      put(performSearchSuccess(data)),
      put(getVideoMetadataStart(ytids)),
      put(getVideoStatsStart(ytids)),
    ]);

    return data;
  } catch (e) {
    console.error("error::", e);
    return null;
  }
}

export function* performSearchComplete() {
  yield put(clearCurrentlyViewedPlaylist());
  yield put(setCurrentTab(SectionTab.Search));
  yield scrollToMainContentArea();
}

function* createPlaylistListeners() {
  // console.log('createPlaylistListeners...');
  const state = yield select(getSearch);

  yield put(
    setSystemPlaylistHumanName(SearchType.PLAYLIST_SEARCH, "Search Results")
  );

  let searchPlaylist = {};
  searchPlaylist[SearchType.PLAYLIST_SEARCH] = state.results.map(
    (result) => result.ytid
  );

  yield put(setSystemPlaylistYtids(searchPlaylist));
}

export function* watchGetAutocompleteSuggestions() {
  yield takeLatest(
    SearchType.GET_AUTOCOMPLETE_SUGGESTION_START,
    getAutocompleteSuggestionAsync
  );
}

export function* watchYoutubeLookup() {
  yield takeLatest(SearchType.PERFORM_SEARCH_START, performSearchAsync);
}

export function* watchPerformSearchComplete() {
  yield takeLatest(SearchType.PERFORM_SEARCH_SUCCESS, performSearchComplete);
}

export function* watchSearchPlaylist() {
  yield takeLatest(
    [SearchType.PERFORM_SEARCH_SUCCESS, SearchType.YOUTUBE_LOOKUP_SUCCESS],
    createPlaylistListeners
  );
}

export function* watchGetNextResultsPage() {
  yield takeLatest(
    SearchType.GET_NEXT_RESULTS_PAGE_START,
    getNextResultsPageSaga
  );
}

export function* watchInitializeStore() {
  yield takeLatest(SearchType.INIT_SEARCH, initializeStore);
}

export function* searchSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchGetAutocompleteSuggestions),
    call(watchYoutubeLookup),
    call(watchGetNextResultsPage),
    call(watchPerformSearchComplete),
    call(watchSearchPlaylist),
  ]);
}
