import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {IconButton, Hidden} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import SwitchTab from 'constants/SwitchTab';
import {
  Radio as RadioIcon,
  WifiTethering as WifiTetheringIcon,
} from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import lorImage from 'assets/images/lor.png';
import container from "./SwitchTab.container";
import {TabCard} from './components';
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#999999',
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.pink,
    },
    '&:active': {
        color: theme.palette.pink,
    },
  },
  textUnderline: {
    width: 40,
    bottom: 2,
    height: 2,
    // marginLeft: -42,
    position: 'absolute',
    backgroundColor: '#FF0066',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    opacity: 1,
  },
  rootPaper: {
    width: 245,
    color: '#999999',
    background: '#252525',
    paddingLeft: 15,
  },
  small: {
    width: 28,
    height: 28,
    margin: '10px 6px',
  },
  icon: {
    // width: 20,
    // height: 20,
    cursor: 'pointer',
    fill: theme.palette.iconColor,
  },
}));

const SwitchTabComponent = (props) => {
  const classes = useStyles();
  const {siteCoordinator, onSetSwitchTab} = props;
  const currentSwitchTab = siteCoordinator.switchTab;

  const handleChange = (event, newValue) => {
    onSetSwitchTab(newValue);
  };

  const list = [
    {
      id: 0,
      link: '/',
      name: SwitchTab.LOR,
      item: (
        <Avatar
          alt="lor"
          src={lorImage}
          className={classes.small}
        />
      ),
    },
    {
      id: 1,
      link: '/podcast',
      name: SwitchTab.Podcast,
      item: (
        <IconButton aria-label="podcast">
          <WifiTetheringIcon className={classes.icon}/>
        </IconButton>
      ),
    }, {
      id: 2,
      link: '/radio',
      name: SwitchTab.Radio,
      item: (
        <IconButton aria-label="radio">
          <RadioIcon className={classes.icon}/>
        </IconButton>
      ),
    },
  ];

  /*
  <Tab key={index} component={"a"} href={tab.link} icon={tab.item} style={{width: 20, minWidth: 50, padding: 0}}/>
  */
  return (
    <>
      <Hidden smDown>
        <Tabs
          value={list.findIndex(obj => obj.name === currentSwitchTab)}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
          className={classes.rootPaper}
        >
          {list.map((tab, index) => {
            return (
              <Tab key={index} component={"a"} href={tab.link} label={tab.name} style={{minWidth: 80}} className={classes.link}/>
            );
          })}
        </Tabs>
      </Hidden>
      <Hidden mdUp>
        {list.map((tab, index) => {
          return (
            <a href={tab.link} key={index}>
              <TabCard
                key={index}
                currentSwitchTab={currentSwitchTab}
                tabName={tab.name}
                tabItem={tab.item}
                tabLink={tab.link}
                style={classes}
                handleChange={handleChange}
              />
            </a>
          );
        })}
      </Hidden>
    </>
  );
};

export default container(SwitchTabComponent);