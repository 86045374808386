import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CardContent from '@material-ui/core/CardContent';
import VideoListItem from 'modules/video/components/VideoListItem';
import FavoritesFeed from 'modules/playlist/components/PlaylistView';

import { scrollToVideo } from 'common/ui/utils';
import * as UserhistoryType from 'store/userhistory/userhistory.types';

import ToolTipKeys from 'constants/ToolTipKeys';
import { ShowAddToPlaylistTooltip } from 'modules/playlist/tooltips';

import container from './FavoritesCard.container';

const FavoritesCard = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    userhistoryState,
    videoState,
    playerState: { ytid: currentPlayerYtid },
    playlistState,
    userState,
    tooltipState,
    onViewPlayerVideoStart,
    onSetCurrentPlaylistStart,
    onGetHeartsPageStart,
    onDeleteHeartStart,
    onShowSnackbarNotification,
    onHideDeletePlaylistToolTip,
    onCloseToolTip,
    onEnqueueToolTip,
    onQueueYtid,
    onShowAddPlaylistModal,
  } = props;

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
      onCloseToolTip();
    };
  }, []);

  const onAddToPlaylistClick = (evt, ytid) => {
    var element = evt.currentTarget;

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }

    toggleAddToPlaylistToolTip(ytid, element);
    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const onDeleteClick = (evt, ytid) => {
    onDeleteHeartStart(ytid);
    onShowSnackbarNotification(`Video Un-hearted ❤️`);
    window._gaq.push(['_trackEvent', 'Action', 'Unheart Video', ytid]);
    evt.stopPropagation();
    evt.preventDefault();
  };

  const onClickShowMore = () => {
    onGetHeartsPageStart(userhistoryState.heartsNext, true);
  };

  const onClickRow = (ytid) => {
    // console.log('onClickRow...');
    onQueueYtid(ytid);
    scrollToVideo();
    onViewPlayerVideoStart(ytid);
    let currentFilterPlaylistName =
      UserhistoryType.PLAYLIST_NAME_YOUR_FAVORITES;
    onSetCurrentPlaylistStart(currentFilterPlaylistName);
    // analytics.actions.trackUIClick('Repeat Feed', ytid)
  };

  const items = userhistoryState.heartedYtids
    .map((ytid) => userhistoryState.history[ytid])
    .filter((x) => x);

  return (
    <CardContent
      style={{ padding: 0, fontFamily: theme.typography.fontFamily }}
    >
      <FavoritesFeed
        items={items}
        next={userhistoryState.heartsNext}
        onClickShowMore={onClickShowMore}
        elementFactory={(play, i) => (
          <div key={`hearted-${play.ytid}-${i}`}>
            <VideoListItem
              ytid={play.ytid}
              isPlaying={play.ytid === currentPlayerYtid}
              history={play}
              metadata={videoState.videos[play.ytid]}
              globalStats={videoState.videoStats[play.ytid]}
              playlistState={playlistState}
              userState={userState}
              showControls
              isEditable
              noHearts
              onClick={onClickRow}
              onAddToPlaylistClick={onAddToPlaylistClick}
              onDeleteClick={onDeleteClick}
              showMobileAuthor={false}
              showMobileGlobalRepeats={false}
              showMobileGlobalHearts={false}
              showMobileUserRepeats={true}
              showMobileDuration={false}
              showMobileLastViewed={true}
            />
          </div>
        )}
      />
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
    </CardContent>
  );
};

export default container(FavoritesCard);
