import { createMuiTheme } from '@material-ui/core';

import overrides from './overrides';

const white = '#FFFFFF';
const black = '#000000';
const lightGray = '#616161';
const lightSlateGray = 'rgb(97, 97, 97)';
const darkGray = '#A9A9A9';
const gray = '#2b2b2b';
const eclipse = '#404040';
const eclipseGray = 'rgb(52, 52, 52)';
const pink = '#FF0066'; //rgb(255, 0, 102)
const darkPink = 'rgba(255,0,102,.8)';
const borderGray = 'rgb(45, 45, 45)';
const outerCircle = '#212121';

const darkTheme = createMuiTheme({
  palette: {
    common: {
      blue: '#3C59C3',
    },
    black,
    white,
    pink,
    lightGray,
    darkGray,
    lightSlateGray,
    gray,
    eclipse,
    eclipseGray,
    darkPink,
    borderGray,
    outerCircle,
    accent1Color: '#b0b0b0',
    accent2Color: '#b0b0b0',
    accent3Color: '#eee',
    bodyColor: '#363537',
    modalColor: 'rgb(33, 33, 33)',
    textColor: '#FAFAFA',
    // textColor: 'rgba(255, 255, 255, 0.6)',
    globalColor: '#FAFAFA',
    canvasColor: '#212121',
    textColor0: 'rgba(255, 255, 255, 0.87)',
    textColor1: 'rgb(204, 204, 204)',
    textColor2: 'rgba(255, 255, 255, 0.54)',
    textColor3: 'rgb(110, 110, 110)',
    // hoverColorItem: 'rgba(153, 153, 153, 0.2)',
    hoverColorItem: '#333',
    sliderBorder: gray,
    border: '1px solid rgb(55, 55, 55, 1)',
    borderColor: 'rgb(55, 55, 55, 1)',
    fillColor: 'rgb(170, 170, 170)',
    iconColor: 'rgb(176, 176, 176)',
    playlistHover: 'rgba(255, 255, 255, 0.1)',
    alternateTextColor: white,
    paperColor: 'rgb(33, 33, 33)',
    personBackground: 'rgb(90, 90, 90)',
    personIcon: 'rgb(33, 33, 33)',
    subHeaderColor: lightGray,
    pinnedBackground: borderGray,
    buttonBackground: eclipse,
    facebookColor:
      'linear-gradient(45deg, #173e64 100%, #0072ff4d 100%, #00000000 100%)',
    googleColor:
      'linear-gradient(45deg, #644136 100%, #ff90684d 100%, #00000000 100%)',
    mailColor: 'rgb(227, 0, 194)',
    cancelColor: 'rgb(52, 52, 52)',
    cancelHoverColor: 'rgb(49, 49, 49)',
    nextBtnHover: 'rgba(153, 153, 153, 0.2)',
    iconHover: 'rgb(204, 204, 204)',
    primary: {
      main: pink,
    },
    secondary: {
      light: '#343434',
      main: '#252525',
    },
    background: {
      default: '#EEEEEE',
      paper: white,
    },
    shadow: 'none',
    borderRight: '1px solid rgb(43, 43, 43)',
  },
  typography: {
    fontFamily: 'Roboto,sans-serif',
    tab: {
      fontFamily: 'Roboto,sans-serif',
      fontSize: '14px',
    },
  },
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default darkTheme;
