export const INIT_PLAYER = 'INIT_PLAYER';

export const SET_CURRENT_PLAYLIST = 'SET_CURRENT_PLAYLIST';
export const PLAYER_DID_CHANGE_STATE = 'PLAYER_DID_CHANGE_STATE';
export const PLAYER_IS_READY = 'PLAYER_IS_READY';
export const SET_SHUFFLE_MODE = 'SET_SHUFFLE_MODE';
export const SET_REPEAT_MODE = 'SET_REPEAT_MODE';
export const SET_RADIO_MODE = 'SET_RADIO_MODE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_AUTOPLAY = 'SET_AUTOPLAY';
export const GET_STREAM_INIT = 'GET_STREAM_INIT';
export const CLEAR_SEEK = 'CLEAR_SEEK';
export const PLAY_FEATURE = 'PLAY_FEATURE';
export const TOGGLE_PLAY_PAUSE = 'TOGGLE_PLAY_PAUSE';
export const TOGGLE_REPEAT_MODE = 'TOGGLE_REPEAT_MODE';
export const TOGGLE_SHUFFLE_MODE = 'TOGGLE_SHUFFLE_MODE';

export const SET_PLAYER_YTID_START = 'SET_PLAYER_YTID_START';
export const SET_PLAYER_YTID_SUCCESS = 'SET_PLAYER_YTID_SUCCESS';

export const PLAY_NEXT_START = 'PLAY_NEXT_START';
export const PLAY_NEXT_SUCCESS = 'PLAY_NEXT_SUCCESS';

export const PLAY_PREV_START = 'PLAY_PREV_START';
export const PLAY_PREV_SUCCESS = 'PLAY_PREV_SUCCESS';

export const PLAY_RANDOM_START = 'PLAY_RANDOM_START';
export const PLAY_RANDOM_SUCCESS = 'PLAY_RANDOM_SUCCESS';

export const VIEW_PLAYER_VIDEO_START = 'VIEW_PLAYER_VIDEO_START';
export const VIEW_PLAYER_VIDEO_SUCCESS = 'VIEW_PLAYER_VIDEO_SUCCESS';
export const VIEW_PLAYER_VIDEO_FAIL = 'VIEW_PLAYER_VIDEO_FAIL';

export const SET_LAST_PLAYED = 'SET_LAST_PLAYED';

export const TWITTER_METATAG = 'TWITTER_METATAG';
