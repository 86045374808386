export const normalizeArtistName = (name) => {
  return name.toString().trim().toLowerCase().replace(/ /g, '_');
};

export const generateHeader = (artistRecommendations, count = 4) => {
  let roughDescription = '';
  let images = [];

  if (!artistRecommendations) {
    return { images, description: roughDescription };
  }

  if (artistRecommendations.length > count) {
    roughDescription = `${artistRecommendations[0].name}, ${artistRecommendations[1].name}, ${artistRecommendations[2].name}`;
    for (let i = 0; i < count; i++) {
      images.push(artistRecommendations[i].image);
    }
  }

  let description =
    roughDescription.length > 0
      ? roughDescription + ', and more...'
      : 'And more...';

  return { images: images, description: description };
};
