import {
  GOOGLE_INIT_START,
  GOOGLE_INIT_SUCCESS,
  GOOGLE_FETCH_PROFILE_START,
  GOOGLE_FETCH_PROFILE_SUCCESS,
  GOOGLE_FETCH_PROFILE_FAIL,
} from "./google.types";

const CLIENT_ID =
  "1096325717619-dp3l2rp4225v41d5bmfb09eddfgr1d87.apps.googleusercontent.com";

export const googleInitStart = () => ({
  type: GOOGLE_INIT_START,
});

export const googleInitSuccess = () => ({
  type: GOOGLE_INIT_SUCCESS,
});

export const googleFetchProfileStart = () => ({
  type: GOOGLE_FETCH_PROFILE_START,
});

export const googleFetchProfileSuccess = (data) => ({
  type: GOOGLE_FETCH_PROFILE_SUCCESS,
  payload: data,
});

export const googleFetchProfileFail = (err) => ({
  type: GOOGLE_FETCH_PROFILE_FAIL,
  payload: err,
});

export const loadSDK = () => {
  return new Promise((res, rej) => {
    const hasGoogleSDKLoaded = () => {
      if (window.gapi) {
        window.gapi.load("auth2", () => {
          window.gapi.auth2.init({
            client_id: CLIENT_ID,
            cookie_policy: "single_host_origin",
          });
        });
        res();
      } else {
        setTimeout(hasGoogleSDKLoaded, 300);
      }
    };
    hasGoogleSDKLoaded();
  });
};

export const fetchProfile = () => {
  // return api.google.GetProfile.request(void 0);
};

export const googleAPILogin = () => {
  return new Promise(async (resolve, reject) => {
    window.gapi.auth2
      .getAuthInstance()
      .signIn({ scope: "profile email", prompt: "select_account" })
      .then(
        (authUser) => {
          console.log(authUser, "google auth user");
          const params = getLoginParams(authUser);
          resolve(params);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
  });
};

const getLoginParams = (user) => {
  const idToken = user.getAuthResponse().access_token;
  const p = user.getBasicProfile();

  return {
    idToken: idToken,
    profile: {
      firstName: p.getGivenName(),
      email: p.getEmail(),
      imageURL: p.getImageUrl(),
    },
  };
};
