import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';

import { usePrevious } from 'hooks';
import SortType from 'constants/SortType';
import ToolTipKeys from 'constants/ToolTipKeys';

import VideoListItem from 'modules/video/components/VideoListItem';
import VideoListItemLoading from 'modules/video/components/VideoListItemLoading';

import {
  ShowAddToPlaylistTooltip,
  ShowDeletePlaylistToolTip,
} from 'modules/playlist/tooltips';

import container from './PlaylistSortableView.container';
import { scrollToVideo } from 'common/ui/utils';

const SortableItem = SortableElement(
  ({
    i,
    currentYtid,
    ytid,
    isPlaying,
    videoMetadata,
    videoGlobalStats,
    playlistState,
    userState,
    userhistoryPlay,
    sortingEnabled,
    onClick,
    onAddToPlaylistClick,
    editable,
    onDeleteFromPlaylistClick,
  }) => (
    <VideoListItem
      key={ytid + '-' + i}
      ytid={ytid}
      isSortMode={sortingEnabled}
      isPlaying={isPlaying}
      isEditable={editable}
      playlistState={playlistState}
      userState={userState}
      showControls
      metadata={videoMetadata}
      globalStats={videoGlobalStats}
      history={userhistoryPlay}
      onClick={onClick}
      onAddToPlaylistClick={onAddToPlaylistClick}
      onDeleteClick={onDeleteFromPlaylistClick}
      showMobileAuthor={false}
      showMobileGlobalRepeats={false}
      showMobileGlobalHearts={false}
      showMobileUserRepeats={true}
      showMobileDuration={true}
      showMobileLastViewed={false}
    />
  )
);

const SortableListContainer = SortableContainer(
  ({
    ytids,
    currentYtid,
    videoMetadata,
    videoGlobalStats,
    userhistoryState,
    onClickVideo,
    onAddToPlaylistClick,
    editable,
    sortingEnabled,
    onDeleteFromPlaylistClick,
    onlyPlayFirst,
  }) => {
    const filteredYtids = ytids.filter((ytid) => videoMetadata[ytid]);
    // console.log({ ytids, filteredYtids });

    let loadingItems = [];
    for (
      let i = 0;
      i < Math.min(ytids.length - filteredYtids.length, 20);
      i++
    ) {
      loadingItems.push(<VideoListItemLoading key={i + ''} />);
    }

    return (
      <List component="div" disablePadding>
        {filteredYtids.map((ytid, i) => (
          <SortableItem
            key={ytid + '-' + i}
            index={i}
            i={i}
            ytid={ytid}
            isPlaying={currentYtid === ytid && (!onlyPlayFirst || i === 0)}
            videoMetadata={videoMetadata[ytid]}
            videoGlobalStats={videoGlobalStats[ytid]}
            userhistoryPlay={userhistoryState.history[ytid]}
            onClick={onClickVideo}
            onAddToPlaylistClick={onAddToPlaylistClick}
            editable={editable && (!onlyPlayFirst || i !== 0)}
            sortingEnabled={sortingEnabled}
            onDeleteFromPlaylistClick={(evt, ytid) =>
              onDeleteFromPlaylistClick(evt, ytid, i)
            }
          />
        ))}
        {loadingItems}
      </List>
    );
  }
);

const PlaylistSortableView = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    pid,
    currentYtid,
    customOrderedYtids,
    videoState,
    userState,
    userhistoryState,
    playlistState,
    tooltipState,
    sortBy,
    editable,
    onlyPlayFirst,
    onSetPlaylistYtidsStart,
    onShowSnackbarNotification,
    onHideAddToPlaylistToolTip,
    onHideDeletePlaylistToolTip,
    onCloseToolTip,
    onEnqueueToolTip,
    onGetPlaysStart,
    onQueueYtid,
    onPlayPlaylistStart,
    onShowAddPlaylistModal,
  } = props;

  // The active list of ytids that is shown to the user
  const [workingYtids, setWorkingYtids] = useState([]);

  const prevSortBy = usePrevious(sortBy);
  const prevCustomOrderedYtids = usePrevious(customOrderedYtids);

  useEffect(() => {
    const ytids = sortYtids(sortBy, customOrderedYtids) ?? [];
    setWorkingYtids(ytids);

    // Grab userPlays for all videos in playlist
    onGetPlaysStart(ytids);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  useEffect(() => {
    return () => {
      setOpen(false);
      setOpen1(false);
      onCloseToolTip();
    };
  }, []);

  useEffect(() => {
    if (prevSortBy !== sortBy) {
      const ytids = sortYtids(sortBy, customOrderedYtids) ?? [];
      setWorkingYtids(ytids);
    }
    if (prevCustomOrderedYtids !== customOrderedYtids) {
      const ytids = sortYtids(sortBy, customOrderedYtids) ?? [];
      setWorkingYtids(ytids);
    }
  }, [prevSortBy, sortBy, prevCustomOrderedYtids, customOrderedYtids]);

  /** --- UI Functions --- */
  const onAddToPlaylistClick = (evt, ytid) => {
    if (props.onAddToPlaylistClick) {
      props.onAddToPlaylistClick(evt, ytid);
      return;
    }

    const uid = userState.profile.uid;
    const userPlaylists = playlistState.playlistsByUser[uid] || [];

    // If no playlist and a mobile user then show create playlist modal
    if (userPlaylists.length === 0 && !matches) {
      onShowAddPlaylistModal();
      return;
    }

    const element = evt.currentTarget;
    toggleAddToPlaylistToolTip(ytid, element);

    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleAddToPlaylistToolTip(ytid, element) {
    onHideDeletePlaylistToolTip();

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen(true);
    onEnqueueToolTip(ToolTipKeys.AddToPlaylistToolTip, null, ytid);
  }

  const onDeleteFromPlaylistClick = (evt, ytid, index) => {
    if (props.onDeleteFromPlaylistClick) {
      // console.log('props delete playlist click...');
      props.onDeleteFromPlaylistClick(evt, ytid, index);
      return;
    }
    // console.log('delete playlist click...');
    const element = evt.currentTarget;
    toggleDeletePlaylistToolTip(ytid, element);

    evt.stopPropagation();
    evt.preventDefault();
  };

  function toggleDeletePlaylistToolTip(ytid, element) {
    onHideAddToPlaylistToolTip();
    // console.log(element);

    if (
      tooltipState.currentEntry.ytid === ytid &&
      tooltipState.currentEntry.key === ToolTipKeys.DeletePlaylistToolTip
    ) {
      onCloseToolTip();
      window.anchorEl = null;
      setOpen1(false);
      return;
    }
    // console.log('here');
    window.anchorEl = element;
    setOpen1(true);
    onEnqueueToolTip(ToolTipKeys.DeletePlaylistToolTip, null, ytid);
  }

  const confirmDeletePlaylist = (ytid) => {
    deleteFromPlaylist(ytid);
    onShowSnackbarNotification('Deleted video 😢');
    onCloseToolTip();
    window.anchorEl = null;
    setOpen1(false);
  };

  const cancelDeletePlaylist = () => {
    onCloseToolTip();
    window.anchorEl = null;
    setOpen1(false);
  };

  const onClickVideo = (ytid) => {
    // console.log('onClickVideo...');
    if (props.pid) {
      onQueueYtid(ytid);
      onPlayPlaylistStart(props.pid, ytid);
    }

    scrollToVideo();

    if (props.onVideoClick) {
      props.onVideoClick(ytid);
    }
  };

  /** --- Meaty Sorting & Editing Functions --- */

  // Called when the user has finished sorting
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Assumes that workingYtids == customOrderedYtids
    const newYtids = arrayMove(workingYtids, oldIndex, newIndex);

    // Optional - Update UI List for speed gains
    setWorkingYtids(newYtids);

    // Crucially customOrderedYtids is only changed once the setPlaylistYtids is complete
    if (pid) {
      onSetPlaylistYtidsStart(props.pid, newYtids);
    }

    if (props.onSortEnd) {
      props.onSortEnd(oldIndex, newIndex, newYtids);
    }
  };

  // Delete a song from a playlist but maintain the existing order
  const deleteFromPlaylist = (ytid) => {
    // Remove from ordered list
    const newYtids = _.remove(
      [...customOrderedYtids] || [],
      (id) => id !== ytid
    );
    console.log({ ytid, newYtids });
    onSetPlaylistYtidsStart(props.pid, newYtids);
  };

  // Sort based on a SortType
  const sortYtids = (type, ytids) => {
    switch (type) {
      case SortType.Alphabetical: {
        return _.orderBy(
          ytids,
          (o) => {
            let index = o;
            let vidInfo = videoState.videos[index];
            if (!vidInfo) {
              return [0];
            }
            return [vidInfo.title];
          },
          'asc'
        );
      }
      case SortType.RepeatCount: {
        return ytids.sort((a, b) => {
          const itemA = userhistoryState.history[a] || {
            repeats: 0,
          };
          const itemB = userhistoryState.history[b] || {
            repeats: 0,
          };
          return itemB.repeats - itemA.repeats;
        });
      }
      case SortType.LastPlayed: {
        return _.orderBy(
          ytids,
          (o) => {
            let index = o;
            let vidHistory = userhistoryState.history[index];
            if (!vidHistory) {
              return [0];
            }
            return [vidHistory.last_viewed];
          },
          'desc'
        );
      }
      case SortType.Custom: {
        return ytids;
      }
      default: {
        return ytids;
      }
    }
  };

  return (
    <>
      <SortableListContainer
        axis="y"
        lockAxis="y"
        lockToContainerEdges={true}
        helperClass="playlist-sortable-list"
        ytids={workingYtids}
        currentYtid={currentYtid}
        videoMetadata={videoState.videos}
        videoGlobalStats={videoState.videoStats}
        userhistoryState={userhistoryState}
        onClickVideo={onClickVideo}
        onAddToPlaylistClick={onAddToPlaylistClick}
        onDeleteFromPlaylistClick={editable && onDeleteFromPlaylistClick}
        useDragHandle={true}
        editable={editable}
        sortingEnabled={editable && sortBy === SortType.Custom}
        onSortEnd={editable ? onSortEnd : null}
        onlyPlayFirst={onlyPlayFirst}
      />
      {open &&
        tooltipState.currentEntry.key === ToolTipKeys.AddToPlaylistToolTip && (
          <ShowAddToPlaylistTooltip
            toolTipKey={ToolTipKeys.AddToPlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            playlistState={playlistState}
            userState={userState}
          />
        )}
      {open1 &&
        tooltipState.currentEntry.key === ToolTipKeys.DeletePlaylistToolTip && (
          <ShowDeletePlaylistToolTip
            title={'Delete Video'}
            description={'Are you sure you want to do this?'}
            toolTipKey={ToolTipKeys.DeletePlaylistToolTip}
            element={window.anchorEl}
            ytid={tooltipState.currentEntry.ytid}
            confirmDelete={confirmDeletePlaylist}
            cancelDelete={cancelDeletePlaylist}
          />
        )}
    </>
  );
};

PlaylistSortableView.defaultProps = {
  onlyPlayFirst: false,
};

PlaylistSortableView.propTypes = {
  pid: PropTypes.string,
  currentYtid: PropTypes.string.isRequired,
  customOrderedYtids: PropTypes.array.isRequired,
  videoState: PropTypes.object.isRequired,
  userhistoryState: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  onlyPlayFirst: PropTypes.bool,
};

export default container(PlaylistSortableView);
