const styles = {
  wrapper: {
    padding: 0,
    fontFamily: 'Roboto, sans-serif',
    height: 220,
    overflow: 'auto',
  },

  hr: {
    margin: 0,
    borderTop: '1px solid #ff0066',
  },

  list: {
    height: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    padding: 0,
    borderTop: '1px solid',
    // paddingBottom: 20,

    item: {
      textDecoration: 'none',
      textAlign: 'left',
      fontSize: 14,
      cursor: 'pointer',
    },
  },

  addButton: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'none',

    label: {
      textTransform: 'none',
      fontSize: 12,
      paddingLeft: 12,
      color: '#ff0066',
    },
  },

  stickyTextfield: {
    position: 'relative',
    bottom: 5,
    left: 0,
    height: 38,
    right: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },

  textfield: {
    height: 30,
    fontSize: 12,
    width: '100%',
  },
};

export default styles;
