import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalWidth: {
    width: '768px',
    // marginTop: -100,
    maxWidth: '75%',
  },
  dialogBody: {
    fontFamily: 'Arial',
    backgroundColor: theme.palette.modalColor,
    color: theme.palette.textColor,
  },
  textColor: {
    fontFamily: 'Arial',
    color: theme.palette.textColor2,
  },
  noButtonStyle: {
    fontWeight: 300,
    fontFamily: 'Arial',
    color: theme.palette.textColor2,
  },
  buttonStyle: {
    fontWeight: 300,
    fontFamily: 'Arial',
    color: theme.palette.pink,
  },
}));

const MergeModal = ({
  open,
  onSubmit,
  handleClose,
  firstName,
}) => {
  const classes = useStyles();

  // const onSubmit = () => {
  //   console.log('onsubmit');
  // };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.modalWidth,
      }}
    >
      <div className={classes.dialogBody}>
        <DialogTitle id="alert-dialog-title">
          {'Do you want to save the repeats from this device? 🤔'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            classes={{ root: classes.textColor }}
          >
            Hey {firstName}, it looks like there are already repeats on this
            device.
            <br />
            We can save these to your account, or leave them so they're here
            when you log out.
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-options"
            classes={{ root: classes.textColor }}
          >
            <strong>Would you like to save these to your account?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.noButtonStyle}>
            No. Leave them
          </Button>
          <Button onClick={onSubmit} className={classes.buttonStyle}>
            Yes. Save them
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default MergeModal;
