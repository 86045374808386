import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  empty: {
    fontSize: '1rem',
    textAlign: 'center',
    paddingTop: 0,
    color: '#999',
    lineHeight: 35,
    margin: '0px auto 20px',
  },
  button: {
    width: '100%',
    color: theme.palette.textColor1,
  },
}));

const PlaylistView = (props) => {
  const classes = useStyles();

  const showMore = props.next ? (
    <Button onClick={props.onClickShowMore} className={classes.button} >Show More</Button>
  ) : null;

  const filteredVideos = () => {
    let contents = props.items.filter((p) => p);

    // Remove empty items
    if (props.filter) {
      contents = contents.filter((p) => p.ytid);
      contents = contents.filter(props.filter);
    }

    return contents;
  };

  let filteredVideosList = filteredVideos();
  let contentsElements = filteredVideosList.map(props.elementFactory);

  return (
    <div>
      {contentsElements.length > 0 ? (
        <List style={{ paddingTop: 0, paddingBottom: 0 }}>
          {contentsElements}
          {showMore}
        </List>
      ) : (
        <div>
          {props.emptyContents || (
            <div className={classes.empty}>No videos to show</div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlaylistView;
