import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux"
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import headerBg from 'assets/images/header-background.jpg';

import { Sidebar, Topbar } from './components';
import container from './Main.container';

import { useSessionContext } from "supertokens-auth-react/recipe/session";
import LoginStatus from 'constants/LoginStatus';
import {hideLoginModal, emailLoginStart} from "../../store/login/login.actions";
const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 50,
    height: '100%',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  background: {
    position: 'absolute',
    background: `url("${headerBg}") 0% 0% / cover`,
    height: '370px',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    left: 0,
    right: 0,
  },
  bgShift: {
    [theme.breakpoints.up('lg')]: {
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      left: drawerWidth,
    },
  },
}));

const Main = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    children,
    userState: { loginStatus },
    siteCoordinator: { sidebarOpen },
    onToggleSidebar,
  } = props;

  const matchesMD = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const sessionContext = useSessionContext();
  const isLoggedIn = loginStatus === LoginStatus.Authenticated;
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!sessionContext.loading && sessionContext.userId){
      const token = localStorage.getItem('token');
      if (!isLoggedIn && !token) {
        try {
          dispatch(hideLoginModal());
          dispatch(emailLoginStart(sessionContext.userId));
        } catch (error) {
          console.log("error",error) 
        } 
      }
    }
  },[sessionContext])
  useEffect(() => {
    onToggleSidebar(!matchesSM);
  }, [onToggleSidebar, matchesSM]);

  const handleSidebarOpen = () => {
    onToggleSidebar(true);
  };

  const handleSidebarClose = () => {
    onToggleSidebar(false);
  };

  return (
    <div className={classes.root}>
      <Topbar
        sidebarOpen={sidebarOpen}
        onSidebarOpen={handleSidebarOpen}
        onSidebarClose={handleSidebarClose}
      />
      <Sidebar
        open={sidebarOpen}
        onSidebarOpen={handleSidebarOpen}
        onSidebarClose={handleSidebarClose}
      />
      <Hidden xsDown>
        <div
          className={clsx(classes.background, {
            [classes.bgShift]: sidebarOpen && matchesMD,
          })}
        />
      </Hidden>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen && matchesMD,
        })}
      >
        {children}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default container(Main);
