import * as SearchType from './search.types';
import { normalizeSearchQuery } from './search.utils';

const INITIAL_STATE = {
  loading: false,
  query: null,
  queryNormalized: null,
  results: [],
  next: null,
  autocompleteSuggestions: [],
  cache: {},
};

const searchReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let updatedState, normalisedQuery;
  switch (type) {
    case SearchType.GET_AUTOCOMPLETE_SUGGESTION_START:
      return {
        ...state,
        loading: true,
      };

    case SearchType.GET_AUTOCOMPLETE_SUGGESTION_SUCCESS:
      return {
        ...state,
        autocompleteSuggestions: payload,
      };

    case SearchType.YOUTUBE_LOOKUP_SUCCESS:
      normalisedQuery = normalizeSearchQuery(payload.query);
      updatedState = {
        ...state,
      };

      if (!updatedState.cache[normalisedQuery]) {
        updatedState.cache[normalisedQuery] = {
          results: payload.res,
          next: payload.next,
        };
      }
      return updatedState;

    case SearchType.PERFORM_SEARCH_START:
      return {
        ...state,
        loading: true,
        query: payload,
      };

    case SearchType.PERFORM_SEARCH_SUCCESS:
      return {
        ...state,
        query: payload.query,
        results: payload.res,
        next: payload.next,
        loading: false,
      };

    case SearchType.GET_NEXT_RESULTS_PAGE_SUCCESS:
      updatedState = {
        ...state,
      };

      normalisedQuery = normalizeSearchQuery(payload.query);

      updatedState.results = [].concat(updatedState.results, payload.res);
      updatedState.next = payload.next;

      const cacheItem = updatedState.cache[normalisedQuery];
      if (cacheItem && cacheItem.results.length < updatedState.results.length) {
        cacheItem.results = updatedState.results;
        cacheItem.next = updatedState.next;
      }
      return updatedState;

    default:
      return state;
  }
};

export default searchReducer;
