import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    boxSizing: 'border-box',
    // width leaving space for player
    maxWidth: 'calc(100% - 480px)',
    padding: 20,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  title: {
    color: '#fff',
    textShadow: '0 0 2px rgba(0, 0, 0, .5)',
  },
  description: {
    paddingTop: 10,
    color: '#eee',
    textShadow: '0 0 2px rgba(0, 0, 0, .5)',
  },

  textMobile: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 16,
    lineHeight: '20px',
  },
  withControls: {
    maxWidth: 'calc(100% - 150px)',
  },
  controls: {
    textAlign: 'right',
    marginRight: 9,
    [theme.breakpoints.down('xs')]: {
      marginRight: -6,
    },
  },
  controlsMobile: {
    position: 'absolute',
    top: 1,
    right: 5,
  },
  controlsLargePlayer: {
    position: 'absolute',
    top: 1,
    right: 20,
  },
  body: {
    position: 'relative',
  },
    rootBody: {
        background: theme.palette.paperColor,
        color: theme.palette.textColor
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: theme.typography.fontFamily,
        padding: '6px 16px 5px 16px',
    },
    cardHeader: {
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.textColor1,
      fontFamily: 'Arial',
    },
    cardSubHeader: {
      fontSize: 14,
      color: theme.palette.textColor2,
      fontFamily: 'Arial',
    }
}));

export default useStyles;
