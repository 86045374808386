import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlined';

import styles from './AddPlaylist.styles';
import container from './AddPlaylist.container';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    ...styles.wrapper,
  },
  list: {
    ...styles.list,
    borderColor: theme.palette.borderColor,
  },
  item: {
    ...styles.list.item,
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.borderColor,
    },
  },
  listItem: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.borderColor,
    },
  },
  stickyTextfield: {
    ...styles.stickyTextfield,
  },
  addButton: {
    ...styles.addButton,
    color: theme.palette.pink,
  },
  icon: {
    width: 14,
    height: 14,
    color: '#FF0066',
  },
  label: {
    ...styles.addButton.label,
  },
  textfield: {
    ...styles.textfield,
    borderColor: theme.palette.borderColor,
    color: theme.palette.textColor,
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.textColor,
    },
  },
  inputLabel: {
    color: theme.palette.darkGray,
    fontFamily: 'Arial',
  },
  underline: {
    '&:after': {
      borderBottom: `2px solid rgb(255, 0, 102)`,
    },
  },
  inputBase: {
    fontSize: 12,
    color: theme.palette.alternateTextColor,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Arial',
  },
}));

const AddPlaylist = (props) => {
  const classes = useStyles();

  const {
    onAddYtidsPlaylistStart,
    onShowAddPlaylistModal,
    onCreatePlaylistAddSongStart,
    onShowSnackbarNotification,
    onCloseToolTip,
  } = props;

  const [newPlaylist, setNewPlaylist] = useState({
    value: '',
    isValid: false,
    errorText: '',
  });

  const onPlaylistNameChange = (event) => {
    let isValid =
      event.target.value.length > 0 && event.target.value.length < 50;
    setNewPlaylist({
      value: event.target.value,
      isValid: isValid,
      errorText: '',
    });
  };

  const onPlaylistNameBlur = (event) => {
    let isValid =
      event.target.value.length > 0 && event.target.value.length < 50;
    setNewPlaylist({
      errorText: !isValid && 'Invalid Name',
      value: event.target.value,
      isValid: isValid,
    });
  };

  const onNewPlaylistKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    let newName = newPlaylist.value;

    let isValid =
      event.target.value.length > 0 && event.target.value.length < 50;
    if (!isValid) {
      onShowSnackbarNotification(`Invalid playlist name`);
      return;
    }

    let pid = onCreatePlaylistAddSongStart(newName, [props.ytid]);

    onShowSnackbarNotification(`Created playlist ${newName} 🎉`);

    setNewPlaylist({
      value: '',
      isValid: false,
      errorText: '',
    });

    onCloseToolTip();
  };

  const onAddToPlaylist = (pid, name) => {
    // console.log({ pid, name });
    onAddYtidsPlaylistStart(pid, [props.ytid]);
    onCloseToolTip();
    onShowSnackbarNotification(`Added to playlist ${name} 🎉`);
  };

  const onCreatePlaylist = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    onCloseToolTip();
    onShowAddPlaylistModal();
  };

  let playlists = _.sortBy(_.values(props.playlistList), (p) =>
    p.name.toLowerCase()
  );

  // console.log({ playlistProps: props.playlistList, playlists });

  return (
    <div>
      <section className={classes.wrapper}>
        <List className={classes.list} dense>
          {playlists.map((x) => {
            return (
              <ListItem
                key={x.pid}
                button
                className={clsx('tooltip-list-item', classes.item)}
                onClick={() => onAddToPlaylist(x.pid, x.name)}
              >
                <ListItemText className={classes.listItem} primary={x.name} />
              </ListItem>
            );
          })}
        </List>
      </section>
      <Hidden mdUp>
        <div className={classes.stickyTextfield}>
          <Button
            component="span"
            className={classes.addButton}
            endIcon={<AddCircleIcon className={classes.icon} />}
            onClick={onCreatePlaylist}
          >
            New Playlist
          </Button>
          {/*<Button
            variant="contained"
            classes={{
              root: classes.button,
              label: classes.label,
            }}
            endIcon={
              <AddCircleIcon
                style={{ width: 14, height: 14 }}
                color="#ff0066"
              />
            }
          >
            New Playlist
          </Button>*/}
        </div>
      </Hidden>

      <Hidden smDown>
        <TextField
          className={classes.textfield}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
            },
          }}
          InputProps={{
            classes: {
              input: classes.inputBase,
              root: classes.underline,
            },
          }}
          label=""
          // autoFocus
          placeholder="New Playlist +"
          onChange={onPlaylistNameChange}
          onBlur={onPlaylistNameBlur}
          onKeyDown={onNewPlaylistKeyDown}
        />
      </Hidden>
    </div>
  );
};

AddPlaylist.propTypes = {
  ytid: PropTypes.string.isRequired,
  playlistList: PropTypes.array.isRequired,
};

export default container(AddPlaylist);
