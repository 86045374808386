import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(() => ({
  cardInner: {
    display: 'flex',
    // justifyContent: 'space-between',
  },
  image: {
    paddingRight: 10,
  },
  textWrapper: {},
  textWrapperInner: {},
  streamName: {
    fontFamily: 'Arial',
  },
  subInfoContainer: {},
  nowPlayingLabel: {
    fontSize: 12,
    opacity: 0.5,
    fontWeight: 'bold',
    marginBottom: 1,
    fontFamily: 'Arial',
  },
  nowPlaying: {
    fontSize: 11,
    opacity: 0.5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Arial',
  },
  focused : {
    background: "gray"
  }
}));

const StreamsGridItem = (props) => {
  const classes = useStyles();
  return (
    <Card
      key={props.pid}
      className={clsx('hover', props.className)}
      style={props.pid == props.playingStream.pid ? {background:"#f69b9b6e"} : {}}
      onClick={(e) => props.onStreamSelect(e, props.pid)}
    >
      <div className={classes.cardInner}>
        <div className={classes.image}>
          {props.pid == props.playingStream.pid ? 
              <img
              src={`//i.ytimg.com/vi/${props.playingStream.ytid}/default.jpg`}
              width="75"
              height="50"
              alt=""
            />
          :
            <img
              src={`//i.ytimg.com/vi/${props.playingYtid}/default.jpg`}
              width="75"
              height="50"
              alt=""
            />
          }
        </div>
        <div className={classes.textWrapper}>
          <div className={classes.textWrapperInner}>
            <div className={classes.streamName}>
              {props.name}
            </div>
            <div className={classes.subInfoContainer}></div>
          </div>
        </div>
      </div>
      <div className={classes.nowPlayingLabel}>Now Playing:</div>
      <div className={classes.nowPlaying}>
        {props.pid == props.playingStream.pid ? 
          props.playingStream.ytname
        :
          props.playingTitle
        }
      </div>
    </Card>
  );
};

export default StreamsGridItem;
