import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from "@material-ui/core/Box";

const ListViewSkeleton = () => {
    return (
        <>
            <Box width={'100%'} alignItems="center" style={{display: 'flex', padding: 8}}>
                <Skeleton variant="rect" width={160} height={80} />
                <Box width={'50%'} style={{paddingLeft: 10}}>
                    <Skeleton />
                    <Skeleton width="60%" />
                </Box>
            </Box>
        </>
    );
};

export default ListViewSkeleton;
