import { takeLatest, call, put, all, delay } from "redux-saga/effects";

import axios from "instances/server";

import { FB_INIT_START, FB_FETCH_PROFILE_START } from "./facebook.types";
import {
  fbAsyncInit,
  fbInitSuccess,
  fbFetchProfileSuccess,
  fbFetchProfileFail,
} from "./facebook.actions";
import { setUserProfileStart } from "store/user/user.actions";

export function* fbInitAsync() {
  if (!window.FB) {
    yield call(delay, 2000);
    yield fbAsyncInit();
  }
  yield put(fbInitSuccess());
}

export function* fetchFbProfileAsync() {
  try {
    // console.log('fetchFbProfileAsync...');
    const { data } = yield axios.get("/fb/profile");
    console.log(data, "yield fb profile");
    if (data.profile.email) {
      console.log("email from facebook", data.profile.email);
      localStorage.setItem("user_email", data.profile.email);
      // window.conversant.launch(
      //   "publink",
      //   "start",

      //   { siteId: 126629, logging: "debug", email: data.profile.email }
      // );
    }

    yield put(fbFetchProfileSuccess(data.profile));
    yield put(setUserProfileStart());
  } catch (err) {
    yield put(fbFetchProfileFail(err));
  }
}

export function* watchFbInit() {
  yield takeLatest(FB_INIT_START, fbInitAsync);
}

export function* watchFetchFbProfile() {
  yield takeLatest(FB_FETCH_PROFILE_START, fetchFbProfileAsync);
}

export function* fbSagas() {
  yield all([call(watchFbInit), call(watchFetchFbProfile)]);
}
