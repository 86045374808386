import React from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';

const ArticleSkeleton = () => {
  return (
    <>
      <Hidden xsDown>
        <Box width={210}>
          <Skeleton variant="rect" width={210} height={118} />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box width={'100%'}>
          <Skeleton variant="rect" width={'100%'} height={118} />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </Box>
      </Hidden>
    </>
  );
};

export default ArticleSkeleton;
