import * as actions from 'store/facebook/facebook.actions';

export function init() {
  // begin loading the FB Javascript SDK
  // Facebook's SDK calls `window.fbAsyncInit` when it finishes loading
  window['fbAsyncInit'] = () => {
    actions.fbAsyncInit();
  };

  actions.asyncLoadSDK(document);
  // actions.fetchProfile();
}
