import { takeLatest, call, put, all, select } from "redux-saga/effects";
import _ from "lodash";

import axios from "instances/server";

import * as UserhistoryType from "./userhistory.types";

import {
  fetchHistoryProfileSuccess,
  fetchHistoryProfileFail,
  getPlaysPageSuccess,
  getPlaysPageFail,
  getPlaysSuccess,
  setSliderSuccess,
  getHeartsPageSuccess,
  getHeartsPageFail,
  addHeartFail,
  deleteHeartFail,
  addHeartSuccess,
  deleteHeartSuccess,
  addPlayFail,
  addPlaySuccess,
  getPlaysByRepeatsPageSuccess,
  getPlaysByRepeatsPageFail,
  getPlaysByRepeatsPageStart,
  changeSortSuccess,
} from "./userhistory.actions";

import {
  setSystemPlaylistHumanName,
  setSystemPlaylistYtids,
} from "../playlist/playlist.actions";
import { initDiscovery } from "../yourlor/yourlor.actions";

import {
  getVideoMetadataAsync,
  getVideoStatsAsync,
} from "store/video/video.sagas";
import {
  getVideoMetadataStart,
  getVideoStatsStart,
} from "store/video/video.actions";
import { setLastPlayed } from "store/player/player.actions";

import { parseDuration } from "common/yt_utils";

const getVideo = (state) => state.video;
const getUserhistory = (state) => state.userhistory;

export function* initializeStore() {
  const data = {
    payload: {
      next: "",
      merge: true,
    },
  };
  yield all([
    call(fetchHistoryProfileAsync),
    call(getPlaysPageAsync, data),
    call(getHeartsPageAsync, data),
  ]);

  yield put(initDiscovery());
}

export function* fetchHistoryProfileAsync() {
  try {
    // console.log('fetchHistoryProfileAsync...');
    const { data } = yield axios.get("/history/profile");

    // console.log(data);

    yield put(fetchHistoryProfileSuccess(data));
  } catch (err) {
    yield put(fetchHistoryProfileFail(err));
  }
}

/**
    Plays sorted by repeats
 */

// Load the repeat ordered history if not already loaded
export function* changeSortAsync({ payload: { typeID } }) {
  const userhistoryState = yield select(getUserhistory);

  if (!userhistoryState.repeatOrderedHistoryLoaded) {
    yield put(getPlaysByRepeatsPageStart(""));
  }
  yield put(changeSortSuccess(typeID));
  return typeID;
}

export function* getPlaysPageAsync({ payload: { next, merge } }) {
  try {
    // console.log('getPlaysPageAsync...');
    const { data } = yield axios.get("/history", {
      params: {
        next,
      },
    });

    // console.log(data);

    const ytids = data.history?.map((x) => x.ytid);
    // console.log(ytids);

    if (ytids.length > 0) {
      const videoData = {
        payload: {
          ytids,
        },
      };
      yield all([
        call(getVideoMetadataAsync, videoData),
        call(getVideoStatsAsync, videoData),
      ]);
    }

    yield put(getPlaysPageSuccess({ response: data, merge }));
  } catch (err) {
    console.error(err);
    yield put(getPlaysPageFail(err));
  }
}

export function* getHeartsPageAsync({ payload: { next, merge } }) {
  try {
    // console.log('getHeartsPageAsync...');
    const { data } = yield axios.get("/star/page", {
      params: {
        next,
      },
    });

    // console.log(data);

    const ytids = data.res?.map((x) => x.ytid);
    // console.log(ytids);

    if (ytids.length > 0) {
      const videoData = {
        payload: {
          ytids,
        },
      };
      yield all([
        call(getVideoMetadataAsync, videoData),
        call(getVideoStatsAsync, videoData),
      ]);
    }

    yield put(getHeartsPageSuccess({ response: data, merge }));
  } catch (err) {
    yield put(getHeartsPageFail(err));
  }
}

export function* getPlaysAsync({ payload: { ytids } }) {
  const userhistoryState = yield select(getUserhistory);

  // console.log('discovery:: getPlaysAsync:: BEFORE', { ytids });
  ytids = ytids?.filter((ytid) => !_.isEmpty(ytid));
  // console.log('discovery:: getPlaysAsync:: AFTER', { ytids });

  let missingYtids = [];
  let presentYtids = [];
  for (let ytid of ytids) {
    if (userhistoryState.history[ytid] === undefined) {
      missingYtids.push(ytid);
    } else {
      presentYtids.push(ytid);
    }
  }

  if (missingYtids.length === 0) {
    let plays = {};
    ytids.forEach((y) => (plays[y] = userhistoryState.history[y]));
    return Promise.resolve(plays);
  }

  // fetch video plays from LoR API, send in batches no larger than 500
  const responses = yield all(
    _.chunk(missingYtids, 500).map((batch) =>
      call(getBatchRequest, "/history/get-plays", batch)
    )
  );
  // const responses = [];
  console.log(responses, "batch responseee");

  const plays = {};
  const resp = [].concat(...responses);
  resp
    .filter((video) => !_.isNull(video) && !_.isNull(video.ytid))
    .forEach((video) => (plays[video.ytid] = video));

  for (let ytid of presentYtids) {
    plays[ytid] = userhistoryState.history[ytid];
  }

  yield put(getPlaysSuccess(plays));
  return plays;
}

function* getBatchRequest(url, batch) {
  console.log(url);
  const { data } = yield axios.get(url, {
    params: {
      ytid: batch,
    },
  });
  // const data = [];
  console.log(data, "batchhhhhhhhhhhhhhhhhhhhhhhh");
  return [...data];
}

/**
    Plays sorted by repeats
 */

// Load the repeat ordered history if not already loaded
export function* changeSort(typeID) {
  const userhistoryState = yield select(getUserhistory);

  if (!userhistoryState.repeatOrderedHistoryLoaded) {
    yield call();
  }
  return typeID;
}

export function* getPlaysByRepeatsPageAsync({ payload: { next } }) {
  // console.log('getPlaysByRepeatsPageAsync...');
  try {
    const { data } = yield axios.get("/history/repeats.page", {
      params: {
        next,
      },
    });
    const ytids = data.history?.map((x) => x.ytid);
    yield all([
      put(getVideoMetadataStart(ytids)),
      put(getVideoStatsStart(ytids)),
    ]);

    yield put(getPlaysByRepeatsPageSuccess(data));
  } catch (err) {
    console.warn(err);
    yield put(getPlaysByRepeatsPageFail(err));
  }
}

/**
    Slider
 */
export function* setSliderAsync({ payload: { ytid, slider } }) {
  if (slider.startTime < 0 || slider.endTime < 0) {
    return new Error("Invalid slider start/end times");
  } else if (slider.startTime > slider.endTime) {
    let [start, end] = [slider.startTime, slider.endTime];
    slider.startTime = end;
    slider.endTime = start;
  }

  const videoState = yield select(getVideo);

  const newDuration = parseDuration(videoState.videos[ytid]?.duration || 0);
  if (slider.endTime === 0) {
    if (newDuration === 0) {
      console.warn("slider endTime = 0, and video metadata cannot be found");
    }
    slider.endTime = newDuration;
  }

  if (newDuration !== 0 && slider.endTime > newDuration) {
    slider.endTime = newDuration;
  }

  try {
    const body = {
      ytid,
      slider,
    };
    const { data } = yield axios.post("/slider/set", body);

    yield put(setSliderSuccess(data));
  } catch (err) {
    console.warn(err);
  }
}

export function* addHeartAsync({ payload: { ytid } }) {
  try {
    // console.log('addHeartAsync...');
    const body = { ytid };
    const { data } = yield axios.post("/star/set", body);

    // console.log(data);
    yield put(addHeartSuccess(data));
  } catch (err) {
    yield put(addHeartFail(err));
  }
}

export function* deleteHeartAsync({ payload: { ytid } }) {
  try {
    // console.log('deleteHeartAsync...');
    const body = { ytid };
    const { data } = yield axios.post("/star/clear", body);

    // console.log(data);
    yield put(deleteHeartSuccess(data));
  } catch (err) {
    yield put(deleteHeartFail(err));
  }
}

export function* addPlayAsync({ payload: { ytid } }) {
  yield put(setLastPlayed(ytid));
  try {
    // console.log('addPlayAsync...');
    const body = { ytid };
    const { data } = yield axios.post("/history/add", body);

    // console.log('addPlayAsync::', data);
    yield put(addPlaySuccess(data));
  } catch (err) {
    yield put(addPlayFail(err));
  }
}

export function* deletePlayAsync({ payload: { ytid } }) {
  try {
    // console.log('deletePlayAsync...');
    const body = { ytid };
    const { data } = yield axios.post("/history/del", body);

    // console.log('deletePlayAsync::', data);
    yield put(deleteHeartSuccess(data));
  } catch (err) {
    yield put(deleteHeartFail(err));
  }
}

export function* createPlaylistListeners() {
  const state = yield select(getUserhistory);
  // console.log('createPlaylistListeners...');

  yield put(
    setSystemPlaylistHumanName(
      UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS,
      "Your Repeats"
    )
  );
  yield put(
    setSystemPlaylistHumanName(
      UserhistoryType.PLAYLIST_NAME_YOUR_FAVORITES,
      "Your Favorites"
    )
  );
  yield put(
    setSystemPlaylistHumanName(
      UserhistoryType.PLAYLIST_NAME_YOUR_REPEAT_ORDERED,
      "Your Most Repeated"
    )
  );

  yield put(
    setSystemPlaylistYtids({
      [UserhistoryType.PLAYLIST_NAME_YOUR_REPEATS]: state.historyYtids,
      [UserhistoryType.PLAYLIST_NAME_YOUR_FAVORITES]: state.heartedYtids,
      [UserhistoryType.PLAYLIST_NAME_YOUR_REPEAT_ORDERED]:
        state.repeatOrderedHistoryYtids,
    })
  );
}

export function* watchFetchHistoryProfile() {
  yield takeLatest(
    UserhistoryType.FETCH_HISTORY_PROFILE_START,
    fetchHistoryProfileAsync
  );
}

export function* watchGetPlaysPage() {
  yield takeLatest(UserhistoryType.GET_PLAYS_PAGE_START, getPlaysPageAsync);
}

export function* watchGetPlays() {
  yield takeLatest(UserhistoryType.GET_PLAYS_START, getPlaysAsync);
}

export function* watchSetSlider() {
  yield takeLatest(UserhistoryType.SET_SLIDER_START, setSliderAsync);
}

export function* watchGetHeartsPage() {
  yield takeLatest(UserhistoryType.GET_HEARTS_PAGE_START, getHeartsPageAsync);
}

export function* watchAddHeart() {
  yield takeLatest(UserhistoryType.ADD_HEART_START, addHeartAsync);
}

export function* watchDeleteHeart() {
  yield takeLatest(UserhistoryType.DELETE_HEART_START, deleteHeartAsync);
}

export function* watchAddPlay() {
  yield takeLatest(UserhistoryType.ADD_PLAY_START, addPlayAsync);
}

export function* watchDeletePlay() {
  yield takeLatest(UserhistoryType.DELETE_PLAY_START, deletePlayAsync);
}

export function* watchGetPlaysByRepeatsPage() {
  yield takeLatest(
    UserhistoryType.GET_PLAYS_PAGE_REPEAT_START,
    getPlaysByRepeatsPageAsync
  );
}

export function* watchChangeSort() {
  yield takeLatest(UserhistoryType.CHANGE_SORT_START, changeSortAsync);
}

export function* watchUserhistoryPlaylist() {
  yield takeLatest(
    [
      UserhistoryType.GET_PLAYS_PAGE_SUCCESS,
      UserhistoryType.GET_HEARTS_PAGE_SUCCESS,
      UserhistoryType.ADD_HEART_SUCCESS,
      UserhistoryType.DELETE_HEART_SUCCESS,
    ],
    createPlaylistListeners
  );
}

export function* watchInitializeStore() {
  yield takeLatest(UserhistoryType.INIT_USER_HISTORY_START, initializeStore);
}

export function* userhistorySagas() {
  yield all([
    call(watchInitializeStore),
    call(watchFetchHistoryProfile),
    call(watchGetPlaysPage),
    call(watchGetPlays),
    call(watchGetHeartsPage),
    call(watchSetSlider),
    call(watchAddHeart),
    call(watchDeleteHeart),
    call(watchAddPlay),
    call(watchDeletePlay),
    call(watchGetPlaysByRepeatsPage),
    call(watchChangeSort),
    call(watchUserhistoryPlaylist),
  ]);
}
