import React from 'react';

import PlaylistTooltip from './PlaylistTooltip';

const ShowAddToPlaylist = (props) => {
  const { ytid, userState, playlistState, element } = props;

  // console.log('[ShowAddToPlaylist]');

  const uid = userState.profile.uid;
  const userPlaylists = playlistState.playlistsByUser[uid] || [];

  // If no playlist and a mobile user then show create playlist modal
  // if (userPlaylists.length === 0 && matches) {
  //   onShowAddPlaylistModal();
  //   return <div />;
  // }

  const playlistNames = userPlaylists.map((pid) => ({
    name: (playlistState.playlists[pid] ?? {}).name,
    pid: pid,
  }));

  // console.log({ playlistNames });

  return (
    <PlaylistTooltip
      open={true}
      id={'playlist-tooltip-' + ytid}
      ytid={ytid}
      playlistList={playlistNames}
      element={element}
    />
  );
};

export default ShowAddToPlaylist;
