import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const HeartControl = (props) => {
  const { hearted, showTooltip, onClick } = props;

  const title = hearted ? 'Remove from favorites' : 'Add to favorites';

  return (
    <Tooltip title={showTooltip ? title : undefined}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        {hearted ? (
          <FavoriteIcon style={{ color: '#ff0066' }} />
        ) : (
          <FavoriteBorderIcon style={{ color: '#ccc' }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default HeartControl;
