import * as PlayerRadioType from './playerRadio.types';

export const viewRadioPlayerStart = (stationid) => ({
  type: PlayerRadioType.VIEW_RADIO_PLAYER_START,
  payload: { stationid },
});

export const viewRadioPlayerSuccess = (data) => ({
  type: PlayerRadioType.VIEW_RADIO_PLAYER_SUCCESS,
  payload: data,
});

export const viewRadioPlayerFail = (error) => ({
  type: PlayerRadioType.VIEW_RADIO_PLAYER_FAIL,
  payload: error,
});

export const toggleRadioPlayPause = () => ({
  type: PlayerRadioType.TOGGLE_RADIO_PLAY_PAUSE,
});

export const setRadioUrl = () => ({
  type: PlayerRadioType.SET_RADIO_URL,
  payload: { },
});
