import * as StreamsType from './streams.types';

export const initStreams = () => ({
  type: StreamsType.INIT_STREAMS,
});

export const randomizeViewerCountStart = () => ({
  type: StreamsType.RANDOMIZE_VIEWER_COUNT_START,
});

export const randomizeViewerCountSuccess = (count) => ({
  type: StreamsType.RANDOMIZE_VIEWER_COUNT_SUCCESS,
  payload: count,
});

export const randomizeViewerCountFail = (error) => ({
  type: StreamsType.RANDOMIZE_VIEWER_COUNT_FAIL,
  payload: error,
});

export const getTop10Start = () => ({
  type: StreamsType.GET_TOP10_START,
});

export const getTop10Success = (data) => ({
  type: StreamsType.GET_TOP10_SUCCESS,
  payload: data,
});

export const getTop10Fail = (error) => ({
  type: StreamsType.GET_TOP10_FAIL,
  payload: error,
});

export const getStreamInit = () => ({
  type: StreamsType.GET_STREAM_INIT_START,
});

export const getStreamListStart = () => ({
  type: StreamsType.GET_STREAM_LIST_START,
});

export const getStreamListSuccess = (data) => ({
  type: StreamsType.GET_STREAM_LIST_SUCCESS,
  payload: data,
});

export const getStreamListFail = (error) => ({
  type: StreamsType.GET_STREAM_LIST_FAIL,
  payload: error,
});

export const setCurrentStreamStart = (pid) => ({
  type: StreamsType.SET_CURRENT_STREAM_START,
  payload: { pid },
});

export const setCurrentStreamSuccess = (pid) => ({
  type: StreamsType.SET_CURRENT_STREAM_SUCCESS,
  payload: { pid },
});

export const clearCurrentlyViewedStream = () => ({
  type: StreamsType.CLEAR_CURRENT_VIEW_STREAM,
});
