import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import SectionTab from 'constants/SectionTab';
import {
  getRadioAutocompleteSuccess,
  getRadioAutocompleteFailed,
  performSearchRadioStationSuccess,
  performSearchRadioStationFailed,
} from './searchRadio.actions';
import { setCurrentTab } from '../siteCoordinator/siteCoordinator.actions';
import { scrollToMainContent } from 'common/ui/utils';
import * as SearchRadioType from './searchRadio.types';
const getRadioState = (state) => state.radio;
const RADIO_URL_SERVER = `https://de1.api.radio-browser.info`;
// const RADIO_URL_SERVER = `https://fr1.api.radio-browser.info`;
// const RADIO_URL_SERVER = `https://nl1.api.radio-browser.info`;

export function* getRadioAutocompleteSuggestions({ payload: { name } }) {
  try {
    const titleList = [];
    const radioState = yield select(getRadioState);
    const countrycode = radioState.currentCountryCode;
    const limit = 20;
    const offset = 0;
    const { data } = yield axios.get(
      `${RADIO_URL_SERVER}/json/stations/search`,
      {
        params: {
          countrycode,
          name,
          offset,
          limit,
        },
      }
    );
    data.map((value, index) => {
      titleList.push(value.name);
    });
    window._gaq.push(['_trackEvent', 'RadioAutoCompleteAction', countrycode, name.toString()]);

    // console.log('here is data on search: ', titleList);
    yield put(getRadioAutocompleteSuccess(titleList));
  } catch (err) {
    console.log('Error: ', err);
    yield put(getRadioAutocompleteFailed(err));
  }
}

export function* performRadioLookup({ payload: name }) {
  try {
    const radioState = yield select(getRadioState);
    const countrycode = radioState.currentCountryCode;
    const limit = 50;
    const offset = 0;
    const { data } = yield axios.get(
      `${RADIO_URL_SERVER}/json/stations/search`,
      {
        params: {
          countrycode,
          name,
          // limit,
          // offset,
        },
      }
    );
    window._gaq.push(['_trackEvent', 'RadioSearchAction', countrycode, name.toString()]);

    yield put(performSearchRadioStationSuccess(data, name));
  } catch (err) {
    console.log('Error: ', err);
    yield put(performSearchRadioStationFailed(err));
  }
}

export function* performSearchComplete() {
  yield put(setCurrentTab(SectionTab.SearchRadio));
  yield scrollToMainContent();
}

export function* watchGetRadioAutocompleteSuggestions() {
  yield takeLatest(
    SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_START,
    getRadioAutocompleteSuggestions
  );
}

export function* watchPerformSearchComplete() {
  yield takeLatest(SearchRadioType.RADIO_LOOKUP_SUCCESS, performSearchComplete);
}

export function* watchRadioLookupStart() {
  yield takeLatest(SearchRadioType.RADIO_LOOKUP_START, performRadioLookup);
}

export function* searchRadioSagas() {
  yield all([
    call(watchGetRadioAutocompleteSuggestions),
    call(watchRadioLookupStart),
    call(watchPerformSearchComplete),
  ]);
}
