import * as SearchPodcastType from './searchPodcast.types';

const INITIAL_STATE = {
    toggle: false,
    loading: false,
    query: null,
    autocompleteSuggestions: [],
    results: [],
    errors: {},
};

const searchPodcastReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case SearchPodcastType.TOGGLE_SEARCH:
            return {
                ...state,
                toggle: payload,
            };

        case SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_START:
            return {
                ...state,
                loading: true,
            };

        case SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                autocompleteSuggestions: payload,
            };

        case SearchPodcastType.GET_PODCAST_AUTOCOMPLETE_SUGGESTION_FAIL:
            return {
                ...state,
                loading: false,
                autocompleteSuggestions: [],
            };

        case SearchPodcastType.SEARCH_PODCAST_START:
            return {
                ...state,
                query: payload.query,
                loading: true,
            };

        case SearchPodcastType.SEARCH_PODCAST_SUCCESS:
            return {
                ...state,
                loading: false,
                results: payload,
            };

        case SearchPodcastType.SEARCH_PODCAST_FAIL:
            return {
                ...state,
                loading: false,
                results: [],
                errors: payload,
            };

        default:
            return state;
    }
};

export default searchPodcastReducer;