export const SET_CURRENT_VIEWED_POD_PLAYLIST =
  'SET_CURRENT_VIEWED_POD_PLAYLIST';
export const CLEAR_CURRENT_VIEWED_POD_PLAYLIST =
  'CLEAR_CURRENT_VIEWED_POD_PLAYLIST';
export const SET_POD_QUEUE = 'SET_POD_QUEUE';
export const SET_CURRENT_POD_ID = 'SET_CURRENT_POD_ID';
export const SET_SYSTEM_PLAYLIST_POD_IDS = 'SET_SYSTEM_PLAYLIST_POD_IDS';
export const QUEUE_POD_EPISODE_ID = 'QUEUE_POD_EPISODE_ID';

export const SET_CURRENT_POD_PLAYLIST_START = 'SET_CURRENT_POD_PLAYLIST_START';
export const SET_CURRENT_POD_PLAYLIST_SUCCESS =
  'SET_CURRENT_POD_PLAYLIST_SUCCESS';
export const SET_CURRENT_POD_PLAYLIST_FAIL = 'SET_CURRENT_POD_PLAYLIST_FAIL';

export const REQUEST_GENRES_PODCAST_START = 'REQUEST_GENRES_PODCAST_START';
export const REQUEST_GENRES_PODCAST_SUCCESS = 'REQUEST_GENRES_PODCAST_SUCCESS';
export const REQUEST_GENRES_PODCAST_FAIL = 'REQUEST_GENRES_PODCAST_FAIL';

export const PODCAST_EPISODE_DATA_EXISTS = 'PODCAST_EPISODE_DATA_EXISTS';

export const FETCH_PODCAST_EPISODE_START = 'FETCH_PODCAST_EPISODE_START';
export const FETCH_PODCAST_EPISODE_SUCCESS = 'FETCH_PODCAST_EPISODE_SUCCESS';
export const FETCH_PODCAST_EPISODE_FAIL = 'FETCH_PODCAST_EPISODE_FAIL';

export const FETCH_EPISODE_LIST_START = 'FETCH_EPISODE_LIST_START';
export const FETCH_EPISODE_LIST_SUCCESS = 'FETCH_EPISODE_LIST_SUCCESS';
export const FETCH_EPISODE_LIST_FAIL = 'FETCH_EPISODE_LIST_FAIL';
