import {
  GOOGLE_INIT_SUCCESS,
  GOOGLE_FETCH_PROFILE_SUCCESS,
} from './google.types';

const INITIAL_STATE = {
  sdkIsLoaded: false,
  profile: {
    firstName: null,
    email: null,
    imageURL: null,
  },
};

const googleReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GOOGLE_INIT_SUCCESS:
      return {
        ...state,
        sdkIsLoaded: true,
      };

    case GOOGLE_FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default googleReducer;
