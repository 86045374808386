import _ from 'lodash';
import moment from 'moment';

/** Returns a `Date` object representing the date in exactly one year. */
export function inOneYear() {
  const today = new Date();
  today.setFullYear(today.getFullYear() + 1);
  return today;
}

export function formatSeconds(sec) {
  const d = moment.duration({ seconds: sec });
  const h = d.hours();
  const m = d.minutes();
  const s = d.seconds();

  let pad = (n) => _.padStart('' + n, 2, '0');

  if (h > 0) {
    return h + ':' + pad(m) + ':' + pad(s);
  } else {
    return m + ':' + pad(s);
  }
}

export function shortNumberString(num) {
  if (num === null || num === undefined) {
    return '';
  }

  if (num > 1000 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'k';
  } else if (num >= 1000000 && num < 100000000) {
    return (num / 1000000).toFixed(1) + 'm';
  } else if (num >= 100000000) {
    return '10m+';
  } else {
    return num.toString();
  }
}

export function longNumberString(num) {
  if (num === null || num === undefined) {
    return '';
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function pluralizeUnits(n, oneString, manyString) {
  return '' + n + ' ' + (n === 1 ? oneString : manyString);
}
