import * as PodcastPlaylistType from './podcastPlaylist.types';

export const setCurrentPodcastid = (id) => ({
  type: PodcastPlaylistType.SET_CURRENT_POD_ID,
  payload: id
});

export const setPodcastQueue = (podcastId, episodeId) => ({
  type: PodcastPlaylistType.SET_POD_QUEUE,
  payload: { podcastId, episodeId }
});

export const setCurrentViewdPodPlaylist = (pid) => ({
  type: PodcastPlaylistType.SET_CURRENT_VIEWED_POD_PLAYLIST,
  payload: { pid }
});

export const fetchPodCastEpisodeStart = (podcastId) => ({
  type: PodcastPlaylistType.FETCH_PODCAST_EPISODE_START,
  payload: { podcastId }
});

export const fetchPodCastEpisodeSuccess = (podcastId, data) => ({
  type: PodcastPlaylistType.FETCH_PODCAST_EPISODE_SUCCESS,
  payload: { podcastId, data }
});

export const fetchPodCastEpisodeFail = (err) => ({
  type: PodcastPlaylistType.FETCH_PODCAST_EPISODE_FAIL,
  payload: err
});

export const queuePodEpisodeId = (id) => ({
  type: PodcastPlaylistType.QUEUE_POD_EPISODE_ID,
  payload: id
});
