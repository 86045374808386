import React from 'react';
import PropTypes from 'prop-types';

import { ContentPage } from 'common/ui/content-page';
import { ArtistRadioCardSelected } from './components';

import { generateHeader } from 'store/artist/artist.utils';

import container from './ArtistRadioPage.container';

const ArtistRadioPage = (props) => {
  const { artistState } = props;

  const title = () => {
    return artistState.radioName
      ? `${artistState.radioName} Artist Radio`
      : 'Artist Radio';
  };

  const description = () => {
    const { description, images } = generateHeader(artistState.artistRadioRecs);
    return description;
  };

  return (
    <ContentPage
      header={{
        title: title(),
        description: description(),
      }}
      body={<ArtistRadioCardSelected {...props} />}
    />
  );
};

ArtistRadioPage.propTypes = {};

export default container(ArtistRadioPage);
