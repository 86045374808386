import { createSelector } from 'reselect';

const selectVideoStore = (state) => state.video;

export const selectVideos = createSelector(
  [selectVideoStore],
  (video) => video.videos
);

export const selectVideoStats = createSelector(
  [selectVideoStore],
  (video) => video.videoStats
);

export const selectVideosForPreview = (ytid) =>
  createSelector([selectVideos], (videos) => (videos ? videos[ytid] : {}));

export const selectVideoStatsForPreview = (ytid) =>
  createSelector([selectVideoStats], (videoStats) =>
    videoStats ? videoStats[ytid] : {}
  );
