import _ from "lodash";

import * as UserhistoryType from "./userhistory.types";

const INITIAL_STATE = {
  profile: {
    totalRepeats: 0,
    totalStars: 0,
    totalVideos: 0,
  },
  history: {},
  historyYtids: [],
  repeatOrderedHistoryYtids: [],
  heartedYtids: [],
  historyNext: "",
  heartsNext: "",
  repeatOrderedHistoryNext: "",
  repeatOrderedHistoryLoaded: false,
  sortBy: "LastPlayed",
  urlSlider: false,
};

const userhistoryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let response, merge, updatedState, ytid;
  switch (type) {
    case UserhistoryType.URL_SLIDER:
      return {
        ...state,
        urlSlider: payload.skip,
      };

    case UserhistoryType.FETCH_HISTORY_PROFILE_SUCCESS:
      return {
        ...state,
        profile: payload.profile,
      };

    case UserhistoryType.GET_PLAYS_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          ...payload,
        },
      };

    case UserhistoryType.GET_PLAYS_PAGE_SUCCESS:
      response = payload.response;
      merge = payload.merge;

      updatedState = {
        ...state,
      };
      if (response.history.length > 0) {
        if (!merge) {
          updatedState.history = _.keyBy(response.history, (play) => play.ytid);

          updatedState.historyYtids = response.history.map((play) => play.ytid);
          updatedState.historyNext = response.has_more || "";
        } else {
          response.history.forEach((play) => {
            updatedState.history[play.ytid] = play;
          });

          const newYtids = response.history.map((play) => play.ytid);
          updatedState.historyYtids = state.historyYtids.concat(newYtids);

          updatedState.historyNext = response.has_more || "";
        }
      } else {
        // if we get no history back, make sure we set 'has_more' (which should be '')
        updatedState.history = {};
        updatedState.historyNext = response.has_more || "";
      }

      return updatedState;

    case UserhistoryType.GET_HEARTS_PAGE_SUCCESS:
      response = payload.response;
      merge = payload.merge;

      updatedState = {
        ...state,
      };
      if (response.res.length > 0) {
        if (!merge) {
          updatedState.heartedYtids = response.res.map((play) => play.ytid);
          updatedState.heartedYtids = _.uniq(updatedState.heartedYtids);
          updatedState.heartsNext = response.next || "";
        } else {
          response.res.forEach((play) => {
            updatedState.history[play.ytid] = play;
          });

          const newHeartedYtids = response.res.map((play) => play.ytid);
          updatedState.heartedYtids =
            updatedState.heartedYtids.concat(newHeartedYtids);

          updatedState.heartsNext = response.next || "";
        }
      } else {
        // if we get no history back, make sure we set 'has_more' (which should be '')
        updatedState.heartsNext = response.next || "";
      }
      return updatedState;

    case UserhistoryType.GET_PLAYS_PAGE_REPEAT_SUCCESS:
      updatedState = {
        ...state,
      };

      if (payload.history.length > 0) {
        payload.history.forEach((play) => {
          updatedState.history[play.ytid] = play;
        });

        const newrepeatOrderedHistoryYtids = payload.history.map(
          (play) => play.ytid
        );
        updatedState.repeatOrderedHistoryYtids = _.uniq(
          updatedState.repeatOrderedHistoryYtids.concat(
            newrepeatOrderedHistoryYtids
          )
        );
      }

      updatedState.repeatOrderedHistoryNext = payload.has_more || "";
      updatedState.repeatOrderedHistoryLoaded = true;

      return updatedState;

    case UserhistoryType.ADD_HEART_START:
      ytid = payload.ytid;
      updatedState = {
        ...state,
      };

      updatedState.profile.totalStars = updatedState?.profile?.totalStars + 1;
      if (updatedState.historyYtids.indexOf(ytid) === -1) {
        updatedState.historyYtids.unshift(ytid);
      }

      if (updatedState.heartedYtids.indexOf(ytid) === -1) {
        updatedState.heartedYtids.unshift(ytid);
      }

      updatedState.history[ytid] = updatedState.history[ytid] || { ytid };
      updatedState.history[ytid].star = true;

      return updatedState;

    case UserhistoryType.DELETE_HEART_START:
      ytid = payload.ytid;

      updatedState = {
        ...state,
      };

      updatedState.profile.totalStars = updatedState?.profile?.totalStars - 1;

      if (updatedState.heartedYtids.indexOf(ytid) === -1) {
        updatedState.heartedYtids.unshift(ytid);
      }

      const idx = updatedState.heartedYtids?.indexOf(ytid);
      if (idx > -1) {
        updatedState.heartedYtids.splice(idx, 1);
      }

      updatedState.history[ytid] = updatedState.history[ytid] || { ytid };
      updatedState.history[ytid].star = false;

      return updatedState;

    case UserhistoryType.ADD_PLAY_SUCCESS:
    case UserhistoryType.DELETE_PLAY_SUCCESS:
    case UserhistoryType.ADD_HEART_SUCCESS:
    case UserhistoryType.DELETE_HEART_SUCCESS:
    case UserhistoryType.SET_SLIDER_SUCCESS:
      const profile = payload.profile;
      const history = payload.history;
      updatedState = {
        ...state,
      };

      updatedState.profile = _.assign(updatedState.profile, profile);

      ytid = _.keys(history)[0];

      updatedState.history = _.assign(updatedState.history, history);
      let plays;
      if (updatedState.historyYtids.indexOf(ytid) === -1) {
        plays = updatedState.historyYtids
          .concat(ytid)
          .map((y) => updatedState.history[y]);
      } else {
        plays = updatedState.historyYtids.map((y) => updatedState.history[y]);
      }

      const asdf = _.chain(plays)
        .filter((play) => play)
        .sortBy((play) => play.last_viewed)
        .map((play) => play.ytid)
        // .reverse()
        .value();

      const i = asdf.indexOf(ytid);
      if (
        i !== -1 &&
        (updatedState.historyYtids.length === 0 ||
          i <= updatedState.historyYtids.length - 1)
      ) {
        updatedState.historyYtids = asdf;
      } else {
        // either the item was deleted or it's outside of the range we're currently viewing
        updatedState.historyYtids = updatedState.historyYtids.filter(
          (y) => y !== ytid
        );
      }

      // update in history ordered by repeats store
      updatedState = updateHistoryRepeatOrdered(updatedState, ytid);

      return updatedState;

    case UserhistoryType.CHANGE_SORT_SUCCESS:
      return {
        ...state,
        sortBy: payload,
      };

    case UserhistoryType.CLEAR_USER_HISTORY_DATA:
      return {
        ...state,
        profile: {
          totalRepeats: 0,
          totalStars: 0,
          totalVideos: 0,
        },
        history: {},
        historyYtids: [],
        repeatOrderedHistoryYtids: [],
        heartedYtids: [],
        historyNext: "",
        heartsNext: "",
        repeatOrderedHistoryNext: "",
        repeatOrderedHistoryLoaded: false,
        sortBy: "LastPlayed",
        urlSlider: false,
      };

    default:
      return state;
  }
};

// After a history change action update the state of history repeat ordered
function updateHistoryRepeatOrdered(state, ytid) {
  // When not loaded do not update
  if (!state.repeatOrderedHistoryLoaded) {
    return state;
  }

  // Check for repeats collision
  let isRepeatsCollision = false;
  const lastInRepeatsHistory =
    state.history[_.last(state.repeatOrderedHistoryYtids)];
  if (lastInRepeatsHistory) {
    isRepeatsCollision =
      lastInRepeatsHistory.repeats === state.history[ytid].repeats;
  }

  let repeatPlays;
  if (state.repeatOrderedHistoryYtids.indexOf(ytid) === -1) {
    repeatPlays = state.repeatOrderedHistoryYtids
      .concat(ytid)
      .map((y) => state.history[y]);
  } else {
    repeatPlays = state.repeatOrderedHistoryYtids.map((y) => state.history[y]);
  }

  const reorderedHistoryRepeats = _.chain(repeatPlays)
    .filter((play) => !_.isNil(play))
    .orderBy("repeats", "desc")
    .map((play) => play.ytid)
    .value();

  const newPlayLocation = reorderedHistoryRepeats.indexOf(ytid);
  if (
    newPlayLocation !== -1 &&
    (state.repeatOrderedHistoryYtids.length === 0 ||
      newPlayLocation <= state.repeatOrderedHistoryYtids.length - 1 ||
      isRepeatsCollision ||
      state.repeatOrderedHistoryNext === "")
  ) {
    state.repeatOrderedHistoryYtids = reorderedHistoryRepeats;
  } else {
    // either the item was deleted or it's outside of the range we're currently viewing
    // and is not a repeat count collision
    state.repeatOrderedHistoryYtids = state.repeatOrderedHistoryYtids.filter(
      (y) => y !== ytid
    );
  }

  return state;
}

export default userhistoryReducer;
