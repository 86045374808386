import cookie from 'component-cookie';

import {
  SET_HOME_TAB,
  SET_CURRENT_TAB,
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_DARKMODE,
  ACTIVATE_LOADING,
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL,
  SCROLL_TO_MAIN_CONTENT_ONLOAD,
  SET_SWITCH_TAB,
} from './siteCoordinator.types';

import SectionTab from 'constants/SectionTab';
import SwitchTab from 'constants/SwitchTab';

function colorMode() {
  let darkMode = cookie('dark-mode');
  return darkMode && darkMode === 'true';
}

function switchState() {
  return cookie('switch-state');
}

const INITIAL_STATE = {
  currentTab: SectionTab.None,
  switchTab: SwitchTab.None,
  sidebarOpen: false,
  darkMode: colorMode(),
  loading: false,
  scrollToMainContentOnLoad: false,
  generalError: {
    visible: false,
    title: null,
    text: null,
  },
};

const siteCoordinatorReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_HOME_TAB:
      console.log ("SET_HOME_TAB");
      return {
        ...state,
        currentTab: SectionTab.YourLOR,
      };

    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload,
      };

    case SET_SWITCH_TAB:
      let switchTab = payload;
      cookie('switch-state', switchTab, {
        maxage: 730 * 24 * 3600 * 1000,
        path: '/',
      });
      return {
        ...state,
        switchTab: switchTab,
      };

    case TOGGLE_SIDEBAR:
      const newSidebarState = payload;
      // console.log({ newSidebarState });

      // Store for next time
      cookie('sidebar-state', newSidebarState ? 'opened' : 'closed', {
        maxage: 730 * 24 * 3600 * 1000,
        path: '/',
      });
      return {
        ...state,
        sidebarOpen: newSidebarState,
      };

    case CLOSE_SIDEBAR:
      cookie('sidebar-state', 'closed', {
        maxage: 730 * 24 * 3600 * 1000,
        path: '/',
      });
      return {
        ...state,
        sidebarOpen: false,
      };

    case TOGGLE_DARKMODE:
      let newMode = !state.darkMode;

      let newModeString = newMode ? 'true' : 'false';
      cookie('dark-mode', newModeString, {
        maxage: 730 * 24 * 3600 * 1000,
        path: '/',
      });
      return {
        ...state,
        darkMode: newMode,
      };

    case ACTIVATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SHOW_ERROR_MODAL:
      return {
        ...state,
        generalError: {
          visible: true,
          title: payload.title,
          text: payload.text,
        },
      };

    case HIDE_ERROR_MODAL:
      return {
        ...state,
        generalError: {
          visible: false,
          title: null,
          text: null,
        },
      };

    case SCROLL_TO_MAIN_CONTENT_ONLOAD:
      return {
        ...state,
        scrollToMainContentOnLoad: true,
      };

    default:
      return state;
  }
};

export default siteCoordinatorReducer;
