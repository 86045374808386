import { connect } from 'react-redux';

import {
  getTop10Start,
  clearCurrentlyViewedStream,
} from 'store/streams/streams.actions';
import {
  setAutoplay,
  viewPlayerVideoStart,
  playFeature,
  setLastPlayed,
} from 'store/player/player.actions';
import {
  setCurrentPlaylistStart,
  queueYtid,
} from 'store/playlist/playlist.actions';

const mapStateToProps = (state) => ({
  streams: state.streams,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTop10Start: () => dispatch(getTop10Start()),
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onSetLastPlayed: (value) => dispatch(setLastPlayed(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onSetCurrentPlaylistStart: (value) =>
    dispatch(setCurrentPlaylistStart(value)),
  onPlayFeature: (value) => dispatch(playFeature(value)),
  onClearCurrentlyViewedStream: () => dispatch(clearCurrentlyViewedStream()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
