import * as PlayerPodCastType from './playerPodCast.types';

const INITIAL_STATE = {
  podcastid: null,
  episodeid: null,
  player: {
    playing: false,
    isReady: false,
    state: 0,
    playPrevOrRestart: null
  },
  isLoading: false,
  autoplay: true
};

const playerReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let updatedState;
  switch (type) {
    case PlayerPodCastType.VIEW_EPISODE_PLAYER_START:
      return {
        ...state,
        podcastid: payload.podcastid,
        episodeid: payload.episodeid,
        player: {
          ...state.player,
          playing: true
        }
      };

    case PlayerPodCastType.SET_AUDIO_STATUS:
      // console.log('SET_AUDIO_STATUS: ', payload.status);
      updatedState = {
        ...state
      };
      if (payload.status === 'loading') {
        updatedState.isLoading = true;
        updatedState.player.state = -1;
      } else if (payload.status === 'playing') {
        updatedState.player.playing = true;
        updatedState.player.state = 1;
      } else if (payload.status === 'paused') {
        // updatedState.player.playing = false;
        updatedState.player.state = 0;
      }
      return updatedState;

    default:
      return state;
  }
};

export default playerReducer;
