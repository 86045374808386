import { connect } from 'react-redux';

import {
  addHeartStart,
  deleteHeartStart,
} from 'store/userhistory/userhistory.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';
import { clearCurrentlyViewedStream } from 'store/streams/streams.actions';
import { addToQueueStart } from 'store/playlist/playlist.actions';
import { setLastPlayed } from 'store/player/player.actions';
import { enqueueToolTip, closeToolTip } from 'store/tooltip/tooltip.actions';

const mapStateToProps = (state) => ({
  userhistoryState: state.userhistory,
  videoState: state.video,
  playerState: state.player,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onAddHeartStart: (ytid) => dispatch(addHeartStart(ytid)),
  onSetLastPlayed: (ytid) => dispatch(setLastPlayed(ytid)),
  onDeleteHeartStart: (ytid) => dispatch(deleteHeartStart(ytid)),
  onAddToQueueStart: (ytid) => dispatch(addToQueueStart(ytid)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onClearCurrentlyViewedStream: () => dispatch(clearCurrentlyViewedStream()),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
