import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

import PlaylistItemRow from '../PlaylistItemRow';

import container from './PlaylistCardListContent.container';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    margin: 0,
    fontFamily: 'Arial',
  },
  newPlaylist: {
    listStyle: 'none',
    margin: '0 10px',
    borderBottom: '1px solid #F1F1F1',
  },
  inputBase: {
    fontSize: 13,
    lineHeight: '24px',
    width: '100%',
    height: 35,
    display: 'inline-block',
    position: 'relative',
    color: theme.palette.textColor,
    backgroundColor: 'transparent',
    fontFamily: 'Arial',
    transition: 'height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    cursor: 'auto',
    marginLeft: 6,
  },
}));

const PlaylistCardListContent = (props) => {
  const classes = useStyles();

  const {
    playlists,
    uid,
    editable,
    currentPlaylist,
    onCreatePlaylistStart,
    onShowSnackbarNotification,
  } = props;

  const [newPlaylistName, setNewPlaylistName] = useState({
    value: '',
    isValid: false,
    errorText: '',
  });

  /** Playlist Name Events **/
  const onPlaylistNameChange = (event) => {
    let isValid = event.target.value.length > 0;

    setNewPlaylistName({
      value: event.target.value,
      isValid: isValid,
      errorText: '',
    });
  };

  const onPlaylistNameBlur = (event) => {
    setNewPlaylistName({
      ...newPlaylistName,
      errorText: '',
    });
  };

  const onPlaylistNameKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return true;
    }

    let newName = newPlaylistName.value;

    if (newName.length > 50) {
      setNewPlaylistName({
        ...newPlaylistName,
        errorText: 'Name too long',
      });
      return;
    }

    if (newName.length < 3) {
      setNewPlaylistName({
        ...newPlaylistName,
        errorText: 'Invalid Playlist Name',
      });

      return;
    }

    onCreatePlaylistStart(newName);

    onShowSnackbarNotification(`Created playlist ${newName} 🎉`);

    setNewPlaylistName({
      value: '',
      isValid: false,
      errorText: '',
    });
  };

  if (playlists.length === 0) {
    return (
      <div style={{ margin: 20, textAlign: 'center', fontFamily: 'Arial' }}>
        No playlists found
      </div>
    );
  }

  return (
    <div>
      <ul className={classes.list}>
        {_.sortBy(playlists, (p) => p.name.toLowerCase()).map((p) => {
          return (
            <PlaylistItemRow
              key={p.uid + ':' + p.pid}
              uid={p.uid}
              pid={p.pid}
              name={p.name}
              length={p?.ytids?.length}
              isFollowing={p.uid !== uid}
              isPlaying={currentPlaylist === p.pid}
              firstYtid={p?.ytids?.[0] || null}
            />
          );
        })}
        {editable && (
          <li className={classes.newPlaylist}>
            <InputBase
              value={newPlaylistName.value}
              classes={{
                root: classes.inputBase,
              }}
              placeholder="New Playlist Name"
              onChange={onPlaylistNameChange}
              onKeyDown={onPlaylistNameKeyDown}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

export default container(PlaylistCardListContent);
