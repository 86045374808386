import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import axios from 'instances/server';

import { SET_USER_PROFILE_START, FETCH_USER_PROFILE_START } from './user.types';
import {
  setUserProfileSuccess,
  fetchUserProfileSuccess,
  fetchUserProfileFail,
} from './user.actions';
import { initDiscovery } from 'store/yourlor/yourlor.actions';

const getFb = (state) => state.fb;
const getGoogle = (state) => state.google;

export function* setUserProfile() {
  const fb = yield select(getFb);
  const google = yield select(getGoogle);

  const newState = {
    fb: fb.profile,
    google: google.profile,
  };
  yield put(setUserProfileSuccess(newState));
}

export function* fetchUserProfileAsync() {
  try {
    // console.log('fetchUserProfileAsync...');
    const { data } = yield axios.get('/user/profile');
    // console.log({ data });

    yield put(fetchUserProfileSuccess(data));
    // fetch discovery here
    yield put(initDiscovery());
  } catch (err) {
    console.error(err);
    yield put(fetchUserProfileFail(err));
  }
}

export function* watchSetUserProfile() {
  yield takeLatest(SET_USER_PROFILE_START, setUserProfile);
}

export function* watchFetchUserProfile() {
  yield takeLatest(FETCH_USER_PROFILE_START, fetchUserProfileAsync);
}

export function* userSagas() {
  yield all([call(watchSetUserProfile), call(watchFetchUserProfile)]);
}
