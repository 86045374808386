import React from 'react';

import { ContentPage } from 'common/ui/content-page';
import { FavoritesCard } from './components';

const FavoritesPage = () => {
  const description = () => {
    return 'Heart your favorite songs to make sure you never forget them';
  };

  return (
    <ContentPage
      header={{
        title: 'Hearted',
        description: description(),
      }}
      body={<FavoritesCard />}
    />
  );
};

export default FavoritesPage;
