import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

const PrevControl = props => {
  const { showTooltip, onClick } = props;
  return (
    <Tooltip title={showTooltip ? 'Previous video' : undefined}>
      <IconButton
        style={{ verticalAlign: 'top', width: 30, height: 36, padding: 0 }}
        onClick={onClick}
      >
        <SkipPreviousIcon style={{ color: '#ccc' }} />
      </IconButton>
    </Tooltip>
  );
};

export default PrevControl;
