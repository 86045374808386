import * as PodCastType from './podcast.types';

export const fetchInitialStart = () => ({
    type: PodCastType.FETCH_INITIAL_START,
    payload: { },
});

export const fetchInitialSuccess = (data) => ({
    type: PodCastType.FETCH_INITIAL_SUCCESS,
    payload: data,
});

export const fetchInitialFail = (err) => ({
    type: PodCastType.FETCH_INITIAL_FAIL,
    payload: err,
});

export const setCurrentGenres = (value) => ({
    type: PodCastType.SET_CURRENT_GENRES,
    payload: value,
});

export const setPodcastCurrentPage = (value) => ({
    type: PodCastType.SET_PODCAST_CURRENT_PAGE,
    payload: value,
});

export const getGenresStart = () => ({
    type: PodCastType.GET_GENRES_START,
    payload: { },
});

export const getGenresSuccess = (data) => ({
    type: PodCastType.GET_GENRES_SUCCESS,
    payload: data,
});

export const getGenresFail = (err) => ({
    type: PodCastType.GET_GENRES_FAIL,
    payload: err,
});


export const requestGenresPodcastStart = (genresId, currentPage) => ({
    type: PodCastType.REQUEST_GENRES_PODCAST_START,
    payload: { genresId, currentPage },
});

export const requestGenresPodcastSuccess = (data) => ({
    type: PodCastType.REQUEST_GENRES_PODCAST_SUCCESS,
    payload: data,
});

export const requestGenresPodcastFail = (err) => ({
    type: PodCastType.REQUEST_GENRES_PODCAST_FAIL,
    payload: err,
});

// for apple episode request

export const fetchApplePodcastEpisodeStart = (podcastId) => ({
    type: PodCastType.REQUEST_APPLE_PODCAST_EPISODE_START,
    payload: { podcastId },
});

export const fetchApplePodcastEpisodeFail = (err) => ({
    type: PodCastType.REQUEST_APPLE_PODCAST_EPISODE_FAIL,
    payload: err,
});

// for listennote episode request

export const fetchListenNotesPodcastEpisodeStart = (podcastId) => ({
    type: PodCastType.REQUEST_PODCAST_EPISODE_START,
    payload: { podcastId },
});

export const fetchListenNotesPodcastEpisodeFail = (err) => ({
    type: PodCastType.REQUEST_PODCAST_EPISODE_FAIL,
    payload: err,
});

export const requestNextPodcastEpisode = (podcastId, nextEpisodeValue) => ({
    type: PodCastType.REQUEST_NEXT_PODCAST_EPISODE,
    payload: { podcastId, nextEpisodeValue },
});

export const requestNextPodcastEpisodeFail = (err) => ({
    type: PodCastType.REQUEST_NEXT_PODCAST_EPISODE_FAIL,
    payload: err,
});

export const viewPodCastStart = (podcastId) => ({
    type: PodCastType.SET_CURRENT_VIEW_PODCAST,
    payload: { podcastId },
});

export const setCurrentViewPodcastSuccess = (podcastId) => ({
    type: PodCastType.SET_CURRENT_VIEW_PODCAST_SUCCESS,
    payload: { podcastId },
});

export const fetchPodCastEpisodeStart = (podcastId) => ({
    type: PodCastType.FETCH_POD_CAST_EPISODE_START,
    payload: { podcastId }
});

export const fetchPodCastEpisodeSuccess = (podcastId, data) => ({
    type: PodCastType.FETCH_POD_CAST_EPISODE_SUCCESS,
    payload: {podcastId, data},
});

export const podcastEpisodeDataExists = (podcastId) => ({
    type: PodCastType.PODCAST_EPISODE_DATA_EXISTS,
    payload: { podcastId },
});

export const fetchPodCastEpisodeFail = (err) => ({
    type: PodCastType.FETCH_POD_CAST_EPISODE_FAIL,
    payload: err,
});

export const setPlayerPlaylist = (list) => ({
    type: PodCastType.SET_PLAYER_PLAYLIST,
    payload: list,
});

// --------------------------------------------

export const ratingHandleStart = (stationid) => ({
    type: PodCastType.RATING_START,
    payload: stationid,
});

// move to podcast history actions
export const onAddPlayPodcastStart = (stationid) => ({
    type: PodCastType.ADD_PLAY_POD_CAST_START,
    payload: { stationid },
});

export const onClickPodcastEpisode = (data) => ({
    type: PodCastType.SET_POD_CAST_EPISODE_CLICK,
    payload: data,
});