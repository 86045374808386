import * as UpshellType from './upsell.types';

const INITIAL_STATE = {
  featureDemo: {
    show: false,
    feature: null,
  },
  featureDemos: [],
};

const upshellReduver = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case UpshellType.INITIALIZE_UPSHELL_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case UpshellType.SHOW_FEATURE_UPSELL:
      return {
        ...state,
        featureDemo: {
          show: true,
          feature: payload.name,
        },
      };

    case UpshellType.SET_FEATURE_DEMO:
      return {
        ...state,
        featureDemo: {
          show: false,
          feature: payload.name,
        },
      };

    case UpshellType.HIDE_FEATURE_UPSELL:
      if (payload.name !== state.featureDemo.feature) {
        return state;
      }
      return {
        ...state,
        featureDemo: {
          show: false,
        },
      };

    default:
      return state;
  }
};

export default upshellReduver;
