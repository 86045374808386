import { connect } from 'react-redux';

import { changeSortStart } from 'store/userhistory/userhistory.actions';

const mapDispatchToProps = (dispatch) => ({
  onChangeSortStart: (value) => dispatch(changeSortStart(value)),
});

const container = connect(null, mapDispatchToProps);

export default container;
