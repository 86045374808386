import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import container from './TooltipDesktop.container';

import styles from './TooltipDesktop.styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 270,
    // height: 280,
    overflow: 'auto',
    fontFamily: 'Arial',
    color: theme.palette.textColor,
    backgroundColor: theme.palette.paperColor,
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      width: '100%',
      bottom: 0,
      left: 0,
    },
  },
  popper: {
    backgroundColor: theme.palette.paperColor,
    borderRadius: 2,
    zIndex: 10,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.paperColor} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.paperColor} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.paperColor} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.paperColor}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '10px',
    height: '10px',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  title: {
    ...styles.container.title,
  },
  insideContent: {
    ...styles.container.insideContent,
  },
  iconButton: {
    ...styles.iconButton,
  },
  icon: {
    ...styles.iconButton.icon,
  },
}));

const TooltipDesktop = (props) => {
  const classes = useStyles();
  const { id, anchorRef, title, children, onCloseToolTip } = props;

  const [open, setOpen] = React.useState(true);
  const [arrowRef, setArrowRef] = React.useState(null);

  const handleClickAway = () => {
    setOpen(false);
    // console.log('handleClickAway');
    onCloseToolTip();
    window.anchorEl = null;
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef}
        placement="bottom"
        disablePortal={false}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Paper className={classes.paper}>
          <div className={classes.title}>{title}</div>
          <IconButton
            className={classes.iconButton}
            onClick={handleClickAway}
            tooltip="Close"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
          <Divider />
          <div className={classes.insideContent}>{children}</div>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default container(TooltipDesktop);
