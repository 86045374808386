import * as SearchRadioType from './searchRadio.types';

const INITIAL_STATE = {
    toggle: false,
    loading: false,
    query: null,
    autocompleteSuggestions: [],
    results: {},
};

const searchRadioReducer = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    let updatedState;
    switch (type) {
        case SearchRadioType.TOGGLE_SEARCH:
            return {
                ...state,
                toggle: payload,
            };

        case SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_START:
            return {
                ...state,
                loading: true,
            };

        case SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                autocompleteSuggestions: payload,
            };

        case SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_FAILED:
            return {
                ...state,
                loading: false,
                autocompleteSuggestions: [],
            };

        case SearchRadioType.RADIO_LOOKUP_SUCCESS:
            updatedState = {
                ...state,
                query: payload.name,
                results: payload.data,
            };
            return updatedState;

        case SearchRadioType.RADIO_LOOKUP_FAILED:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default searchRadioReducer;