import { connect } from 'react-redux';

import {
  setCurrentTab,
  toggleDarkMode,
} from 'store/siteCoordinator/siteCoordinator.actions';

import { logoutStart } from 'store/login/login.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapStateToProps = (state) => ({
  siteCoordinator: state.siteCoordinator,
});
const mapDispatchToProps = (dispatch) => ({
  onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
  onToggleDarkMode: () => dispatch(toggleDarkMode()),
  onLogoutStart: () => dispatch(logoutStart()),
  onShowSnackbarNotification: (message) => dispatch(showSnackbarNotification(message)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
