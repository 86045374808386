import { connect } from 'react-redux';

import {
  openPlaylistStart,
  playPlaylistStart,
  deletePlaylistStart,
} from 'store/playlist/playlist.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapDispatchToProps = (dispatch) => ({
  onOpenPlaylistStart: (pid) => dispatch(openPlaylistStart(pid)),
  onPlayPlaylistStart: (pid) => dispatch(playPlaylistStart(pid)),
  onDeletePlaylistStart: (pid) => dispatch(deletePlaylistStart(pid)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
});

const container = connect(null, mapDispatchToProps);

export default container;
