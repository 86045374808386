import { connect } from 'react-redux';

import {
  setAutoplay,
  viewPlayerVideoStart,
  playFeature,
} from 'store/player/player.actions';
import {
  eviscerateQueue,
  removeQueue,
  setQueue,
} from 'store/playlist/playlist.actions';

const mapStateToProps = (state) => ({
  videoState: state.video,
  userhistoryState: state.userhistory,
  playlistState: state.playlist,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onPlayFeature: (value) => dispatch(playFeature(value)),
  onEviscerateQueue: (index) => dispatch(eviscerateQueue(index)),
  onRemoveQueue: (index) => dispatch(removeQueue(index)),
  onSetQueue: (ytid) => dispatch(setQueue(ytid)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
