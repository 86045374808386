import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';

import styles from './SuggestionImage.styles';

const useStyles = makeStyles({
  root: {
    ...styles.root,
  },
  image: {
    ...styles.image,
  },
  '@keyframes placeHolderShimmerVideoImage': {
    '0%': {
      backgroundPosition: '-200px 0',
    },
    '100%': {
      backgroundPosition: '200px 0',
    },
  },
  artisRadioImg: {
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeHolderShimmerVideoImage',
    animationTimingFunction: 'linear',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px 100px',
    position: 'relative',
  },
});

const SuggestionImage = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const images = [
    '//static.listenonrepeat.com/static/artists/76/76707144.jpg',
    '//static.listenonrepeat.com/static/artists/78/78887661.png',
    '//static.listenonrepeat.com/static/artists/89/89687083.png',
    '//static.listenonrepeat.com/static/artists/80/80643501.jpg',
  ];

  const [imagesLoaded, setImagesLoaded] = React.useState(false);

  let imageRefs = [];
  let loadedImages = [];

  const { className, itemClassName } = props;

  const end = fade(theme.palette.accent1Color, 0.0);
  const bgColor = fade(theme.palette.accent1Color, 0.2);
  const frontColor = fade(theme.palette.accent1Color, 0.2);

  const backgroundImage = `linear-gradient(to right, ${end} 0%, ${frontColor} 20%, ${bgColor} 40%, ${end} 100%)`;
  const rootStyle = {
    borderColor: theme.palette.accent3Color,
    backgroundImage: backgroundImage,
    backgroundColor: bgColor,
  };

  const onImageRef = (img) => {
    if (img && !img.onload) {
      img.addEventListener('load', onImageLoaded);
    }
  };

  const onImageLoaded = (e) => {
    loadedImages.push(e.target);

    if (loadedImages.length === 4) {
      setImagesLoaded(true);
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div
        className={classes.artisRadioImg}
        style={{ height: '100%', width: '100%', ...rootStyle }}
      >
        {props.images.map((item, i) => (
          <div
            key={`img-${item}-${i + 1}`}
            className={clsx(classes.image, itemClassName)}
            style={{ visibility: imagesLoaded ? 'visible' : 'hidden' }}
          >
            <img
              ref={(img) => {
                onImageRef(img);
                imageRefs[i] = img;
              }}
              src={item}
              style={{ width: '100%', height: '100%' }}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestionImage;
