import * as NotificationType from './notification.types';

const INITIAL_STATE = {
  data: null,
  dismissedAt: null,
  isShown: false,

  snackbarNotification: {
    open: false,
    message: '',
    actionLabel: null,
    onActionTouchTap: null,
    onRequestClose: null,
  },

  radioRepeatPrompt: {
    isShown: false,
    repeatedYtid: null,
    // Randomly show after 3, 4 or 5 repeats
    showAfter: 1,
    hasTriggered: false,
  },

  socialSharePromptRepeat: {
    isShown: false,
  },
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  let updatedState;

  switch (type) {
    case NotificationType.SHOW_SNACKBAR_NOTIFICATION:
      updatedState = {
        ...state,
      };
      updatedState.snackbarNotification.open = true;
      updatedState.snackbarNotification.message = payload.message;
      return updatedState;

    case NotificationType.HIDE_SNACKBAR_NOTIFICATION:
      updatedState = {
        ...state,
      };
      state.snackbarNotification.open = false;
      return updatedState;

    default:
      return state;
  }
};

export default notificationReducer;
