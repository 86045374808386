import React from 'react';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from "@material-ui/core/Box";

const ListSkeleton = () => {
    return (
        <>
            <Box width={'100%'} alignItems="center" style={{padding: 8}}>
            <Typography component="div" key={'h3'} variant={'h3'}>
                <Skeleton variant="text" width="100%" />
            </Typography>
            </Box>
        </>
    );
};

export default ListSkeleton;
