const styles = {
  container: {
    zIndex: 10000,
    position: 'absolute',
    width: 270,
    height: 280,
    transform: 'translateX(-127px) translateY(-295px)',

    title: {
      fontSize: 14,
      color: '#ff0066',
      textAlign: 'left',
      margin: '6px 10px 6px 10px',
      fontWeight: 500,
    },

    triangle: {
      position: 'absolute',
      left: 127,
      top: -10,
      transform: 'rotate(-45deg)',
      background:
        'linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49.9%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%)',
      width: 20,
      height: 20,
    },

    triangleTop: {
      top: -20,
      transform: 'rotate(-180deg)',
    },

    triangleBottom: {
      bottom: -20,
    },

    triangleCover: {
      position: 'absolute',
      left: 0,
      width: 270,
      overflow: 'hidden',
      height: 20,
    },

    insideContent: {
      position: 'relative',
      top: 0,
      left: 0,
      overflow: 'auto',
    },
  },

  iconButton: {
    width: 30,
    height: 30,
    padding: 0,
    position: 'absolute',
    right: 0,
    top: 0,

    icon: {
      width: 18,
      height: 18,
      color: '#b0b0b0',
    },
  },
};

export default styles;
