import { connect } from 'react-redux';

import { showLoginModal } from 'store/login/login.actions';

const mapStateToProps = (state) => ({
  siteCoordinator: state.siteCoordinator,
  userState: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onShowLoginModal: () => dispatch(showLoginModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
