import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import {
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  userButtonConnexion: {
    height: '32px',
    borderRadius: '2px',
    width: '100px',
    backgroundColor: 'rgba(255, 0, 102, 0.8)',
    lineHeight: '32px',
    textAlign: 'center',
    fontSize: '17px',
    letterSpacing: '0.5px',
    color: '#fff',
    paddingTop: 0,
    marginTop: '10px',
    marginLeft: '14px',
    fontFamily: 'Source Sans Pro,sans-serif',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12)',
    textShadow: '1px 1px 0px rgba(0, 0, 0, 0.3)',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 102, 0.6)',
      color: '#fff',
    },
  },
  icon: {
    width: 24,
    height: 24,
    marginLeft: -18,
    fill: '#2b2b2b',
  },
  login: {
    paddingLeft: 5,
    fontSize: 16,
    fontFamily: 'Arial',
    textTransform: 'none',
  },
}));

const LoginButton = (props) => {
  const classes = useStyles();
  const onClickLogin = () => {
    props.onShowLoginModal();
  };

  return (
    <Button
      title="Connect to Facebook or Google to save your videos"
      className={classes.userButtonConnexion}
      onClick={onClickLogin}
    >
      <PersonIcon className={classes.icon}/>
      <div className={classes.login}>Log in</div>
    </Button>
  );
};

export default LoginButton;
