import { createSelector } from 'reselect';

const selectPodcastStore = (state) => state.podcast;

export const selectGenresPodcasts = createSelector(
  [selectPodcastStore],
  (podcast) => podcast.genresPodcasts
);

export const selectPodcastForPreview = (podcastid) =>
  createSelector([selectGenresPodcasts], (podcasts) =>
    podcasts ? podcasts[podcastid] : {}
  );
