module.exports = Object.freeze({
  History: 1,
  Favorites: 'Favorites',
  Reviews: 'Reviews',
  MostRepeated: 'MostRepeated',
  VideoFeed: 'VideoFeed',
  Contact: 'Contact',
  About: 'About',
  Faq: 'Faq',
  Search: 'Search',
  Playlists: 'Playlists',
  None: 'None',
  Profile: 'Profile',
  Radio: 'Radio',
  ChatFullscreen: '',
  Streams: 'Streams',
  YourLOR: 'YourLOR',
  PlayQueue: 'PlayQueue',
  Top10: 'Top10',
  Features: 'Features',
  PopularChannel: 'PopularChannel',
  WorkPlaceChannel: 'WorkPlaceChannel',
  FiveStar: 'FiveStar',
  Location: 'Location',
  Station: 'Station',
  Genres: 'Genres',
  GenreStation: 'GenreStation',
  RadioHistory: 'RadioHistory',
  RadioFavourite: 'RadioFavourite',
  RadioTop10: 'RadioTop10',
  SearchRadio: 'SearchRadio',
  PopularPodCast: 'PopularPodCast',
  SearchPodCast: 'SearchPodCast',
  PodCastPopular: 'PodCastPopular',
  PodCastDetail: 'PodCastDetail',
  PodCastFavourite: 'PodCastFavourite',
  SearchListenNote: 'SearchListenNote',
});
