import { takeLatest, call, put, all, select } from "redux-saga/effects";
import _ from "lodash";
import * as cookie from "component-cookie";

import axios from "instances/server";

import * as StreamsType from "./streams.types";
import {
  getTop10Success,
  getTop10Fail,
  getStreamListSuccess,
  getStreamListFail,
  randomizeViewerCountSuccess,
  randomizeViewerCountFail,
  setCurrentStreamSuccess,
  getStreamListStart,
  getTop10Start,
} from "./streams.actions";

import {
  setRepeatMode,
  setShuffleMode,
  viewPlayerVideoStart,
} from "../player/player.actions";
import {
  setCurrentPlaylistStart,
  createInternalPlaylist,
  setSystemPlaylistHumanName,
  setSystemPlaylistYtids,
} from "../playlist/playlist.actions";

const getStreams = (state) => state.streams;

export function* initializeStore() {
  yield all([put(getStreamListStart()), put(getTop10Start())]);
  yield call(createPlaylistListeners);
}

export function* getTop10Async() {
  // console.log('getTop10Async....');

  try {
    const { data } = yield axios.get(
      "https://admin.listenonrepeat.com/api/leaderboard"
    );
    console.log(data, "top10data");
    yield put(getTop10Success(data?.top10));
  } catch (err) {
    console.error("error::", err);
    yield put(getTop10Fail(err));
  }
}

export function* getStreamListAsync() {
  // console.log('getStreamListAsync....');

  try {
    const { data } = yield axios.get(
      "https://admin.listenonrepeat.com/api/streams/list"
    );
    // console.log(data, "streammmmmmmmmmmmmmmmmm");
    // const data = [
    //   {
    //     pid: "YPnMIu",
    //     playing_ytid: "I1jGG9bT3dY",
    //     name: "Nightcore",
    //     viewers: "1600",
    //     playing_title: "Nightcore | Stitches (Switching Vocals)",
    //   },
    //   {
    //     pid: "oBbNxp",
    //     playing_ytid: "ezgXRoJB_5k",
    //     name: "Lofi Chill",
    //     viewers: "1500",
    //     playing_title: "DRWN. - Estate",
    //   },
    //   {
    //     pid: "NEzvei",
    //     playing_ytid: "s2lX3LjORyQ",
    //     name: "Vaporwave",
    //     viewers: "1400",
    //     playing_title: "TOPAZ GANG: PALM_TREE",
    //   },
    //   {
    //     pid: "RQUkZv",
    //     playing_ytid: "phaJXp_zMYM",
    //     name: "Old School Hip hop",
    //     viewers: "1380",
    //     playing_title: 'The Notorious B.I.G. - "Big Poppa"',
    //   },
    //   {
    //     pid: "AWNATZ",
    //     playing_ytid: "kON_KRmFRKk",
    //     name: "Glitch Hop Gaming",
    //     viewers: "1360",
    //     playing_title: "The Glitch Mob - Drive It Like You Stole It",
    //   },
    //   {
    //     pid: "ksKzrK",
    //     playing_ytid: "n2XXPIlhcIs",
    //     name: "Deep tropical house",
    //     viewers: "1330",
    //     playing_title: "KLYMVX - After Midnight ft. Emily Zeck",
    //   },
    //   {
    //     pid: "rZEXtS",
    //     playing_ytid: "KEtH7W2JJMU",
    //     name: "Jazzhop",
    //     viewers: "1320",
    //     playing_title: "jinsang - Heart",
    //   },
    //   {
    //     pid: "MCPrwm",
    //     playing_ytid: "DwHpDOWhkGk",
    //     name: "Classical Study",
    //     viewers: "1310",
    //     playing_title:
    //       "Bach - Cello Suite No. 1 in G Major BWV1007 - Mov. 1-3/6",
    //   },
    //   {
    //     pid: "ovPmiA",
    //     playing_ytid: "8x6y8l7oV5U",
    //     name: "Vibe refrigerator",
    //     viewers: "1303",
    //     playing_title: "Jason Nolan - Late Night City Hawks EP",
    //   },
    //   {
    //     pid: "XMzuIY",
    //     playing_ytid: "xvZqHgFz51I",
    //     name: "Fresh Hip Hop",
    //     viewers: "1302",
    //     playing_title: "Future - Mask Off (Official Music Video)",
    //   },
    //   {
    //     pid: "lMNntd",
    //     playing_ytid: "WTrNsAsjEmY",
    //     name: "Electro swingin'",
    //     viewers: "1298",
    //     playing_title:
    //       "Parov Stelar - The Sun (feat. Graham Candy) (Official Video)",
    //   },
    //   {
    //     pid: "ENcgRK",
    //     playing_ytid: "L4sbDxR22z4",
    //     name: "LOR & Chill",
    //     viewers: "1283",
    //     playing_title: "K. - Cigarettes After Sex",
    //   },
    //   {
    //     pid: "dpDMos",
    //     playing_ytid: "Rbm6GXllBiw",
    //     name: "80s rock",
    //     viewers: "1277",
    //     playing_title: "Guns N' Roses - Paradise City",
    //   },
    //   {
    //     pid: "ocwljb",
    //     playing_ytid: "M-W8dJss5fo",
    //     name: "Classic EDM",
    //     viewers: "1256",
    //     playing_title: "Delerium Silence - Tiesto feat. Sarah McLachlan",
    //   },
    //   {
    //     pid: "WssIAT",
    //     playing_ytid: "RBumgq5yVrA",
    //     name: "Study Time",
    //     viewers: "1233",
    //     playing_title: "Passenger | Let Her Go (Official Video)",
    //   },
    //   {
    //     pid: "BzdXsA",
    //     playing_ytid: "BzYWgQo56R0",
    //     name: "Feel Good Indie",
    //     viewers: "1214",
    //     playing_title: "The RockAteers - Satellites",
    //   },
    //   {
    //     pid: "NNiXce",
    //     playing_ytid: "3d5fQL_91fU",
    //     name: "Goa Trance",
    //     viewers: "1204",
    //     playing_title: "Avigmati & KhetzaL & Chai - Babylone Beach",
    //   },
    //   {
    //     pid: "zVzARz",
    //     playing_ytid: "OfrL0XVFXHo",
    //     name: "Just Pop",
    //     viewers: "1199",
    //     playing_title: "Old Habits Die Hard (Official Lyric Video)",
    //   },
    //   {
    //     pid: "OBUjkN",
    //     playing_ytid: "GQS9GCMdIg8",
    //     name: "Psytrance All Day",
    //     viewers: "1194",
    //     playing_title: "Liquid Soul - Revolution (Future Frequencies Remix)",
    //   },
    //   {
    //     pid: "QNNoEh",
    //     playing_ytid: "OFdgK2GbHmo",
    //     name: "Guilty Pleasures",
    //     viewers: "1185",
    //     playing_title: "Captain Jack - Captain Jack (HQ) -",
    //   },
    //   {
    //     pid: "yOPnNv",
    //     playing_ytid: "9meo3vazXcw",
    //     name: "70s Rock",
    //     viewers: "1180",
    //     playing_title: "Journey - Who's Crying Now ",
    //   },
    //   {
    //     pid: "URpyop",
    //     playing_ytid: "0S43IwBF0uM",
    //     name: "Classic Electronic",
    //     viewers: "1170",
    //     playing_title:
    //       "The Chemical Brothers - Star Guitar (Official Music Video)",
    //   },
    //   {
    //     pid: "jNEpHG",
    //     playing_ytid: "k9LpwtWkiAc",
    //     name: "Chilled Electronic",
    //     viewers: "1160",
    //     playing_title: "Tennyson - Lay-by",
    //   },
    //   {
    //     pid: "BNIoRs",
    //     playing_ytid: "ATIVuynOPTM",
    //     name: "Metal Essentails",
    //     viewers: "1152",
    //     playing_title: "Children Of Bodom - Are You Dead Yet?",
    //   },

    //   {
    //     pid: "vjXhPI",
    //     playing_ytid: "KyR0FDjNKKE",
    //     name: "Jazz and Blues",
    //     viewers: "1147",
    //     playing_title: "Cedar Walton Trio - Afternoon In Paris",
    //   },
    //   {
    //     pid: "jiAIGY",
    //     playing_ytid: "y62WX2LJDig",
    //     name: "Essential Folk",
    //     viewers: "1140",
    //     playing_title: "Martin Carthy - Scarborough Fair",
    //   },
    //   {
    //     pid: "HdvAYS",
    //     playing_ytid: "Mdh2p03cRfw",
    //     name: "Just Country",
    //     viewers: "1132",
    //     playing_title: "Sam Hunt - Body Like A Back Road (Audio)",
    //   },
    // ];

    const streamData = {};
    data.forEach((item) => {
      streamData[item.pid] = item;
    });
    // console.log({ streamData });
    yield put(getStreamListSuccess(data));
  } catch (err) {
    console.error("error::", err);
    yield put(getStreamListFail(err));
  }
}

export function* randomizeViewerCount() {
  const streamsState = yield select(getStreams);
  if (
    !streamsState.streamPlaying ||
    !streamsState.streams[streamsState.streamPlaying]
  ) {
    yield put(randomizeViewerCountFail("No Stream Available"));
    return null;
  }

  let max = streamsState.streams[streamsState.streamPlaying].viewers + 3;
  let min = streamsState.streams[streamsState.streamPlaying].viewers - 3;

  const random = Math.floor(Math.random() * (max - min + 1) + min);

  yield put(randomizeViewerCountSuccess(random));

  return random;
}

export function* setCurrentStreamAsync({ payload: { pid } }) {
  yield put(setRepeatMode(false));
  yield put(setShuffleMode(false));

  let stream_event = Number(cookie("analytics-stream-count") || 0) + 1;

  yield cookie("analytics-stream-count", String(stream_event), {
    maxage: 1000 * 3600 * 24 * 365,
    path: "/",
  });

  if (stream_event === 1) {
    // analytics.actions.trackAction('First Streamer', String(stream_event))
    window._gaq.push([
      "_trackEvent",
      "Action",
      "First Streamer",
      String(stream_event),
    ]);
  }

  const data = yield call(getStreamInitAsync, pid);

  if (data) {
    yield all([
      put(viewPlayerVideoStart(data.playing_ytid)),
      put(setCurrentPlaylistStart(pid)),
      put(createInternalPlaylist(pid, _.map(data.ytids, "ytid"))),
    ]);
  }

  yield put(setCurrentStreamSuccess(pid));
  return pid;
}

export function* setCurrentStreamCompleteAsync({ payload: { pid } }) {}

function* getStreamInitAsync(pid) {
  try {
    const { data } = yield axios.get(
      `https://admin.listenonrepeat.com/api/streams/detail/${pid}`
    );

    return data;
  } catch (err) {
    console.error("error::", err);
    return null;
  }
}

export function* createPlaylistListeners() {
  const state = yield select(getStreams);
  // console.log('createPlaylistListeners...');

  yield put(setSystemPlaylistHumanName(StreamsType.PLAYLIST_TOP10, "Top 10"));
  const items = state.top10.map((result) => result.ytid);
  let top10Playlist = {};
  top10Playlist[StreamsType.PLAYLIST_TOP10] = items;
  yield put(setSystemPlaylistYtids(top10Playlist));
}

export function* watchGetTop10() {
  yield takeLatest(StreamsType.GET_TOP10_START, getTop10Async);
}

export function* watchGetStreamInit() {
  yield takeLatest(StreamsType.GET_STREAM_INIT_START, getStreamInitAsync);
}

export function* watchGetStreamList() {
  yield takeLatest(StreamsType.GET_STREAM_LIST_START, getStreamListAsync);
}

export function* watchRandomizeViewerCount() {
  yield takeLatest(
    StreamsType.RANDOMIZE_VIEWER_COUNT_START,
    randomizeViewerCount
  );
}

export function* watchSetCurrentStream() {
  yield takeLatest(StreamsType.SET_CURRENT_STREAM_START, setCurrentStreamAsync);
}

export function* watchSetCurrentStreamComplete() {
  yield takeLatest(
    StreamsType.SET_CURRENT_STREAM_SUCCESS,
    setCurrentStreamCompleteAsync
  );
}

export function* watchTop10Playlist() {
  yield takeLatest([StreamsType.GET_TOP10_SUCCESS], createPlaylistListeners);
}

export function* watchInitializeStore() {
  yield takeLatest(StreamsType.INIT_STREAMS, initializeStore);
}

export function* streamsSagas() {
  yield all([
    call(watchInitializeStore),
    call(watchGetTop10),
    call(watchTop10Playlist),
    call(watchGetStreamList),
    call(watchRandomizeViewerCount),
    call(watchSetCurrentStream),
  ]);
}
