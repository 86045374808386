import React from 'react';

import { ContentPage } from 'common/ui/content-page';
import { QueueCard } from './components';
import container from './QueuePage.container';

const QueuePage = (props) => {
  return (
    <ContentPage
      header={{
        title: 'Play Queue',
        description: 'Queue up your tracks to play',
      }}
      body={<QueueCard {...props} />}
    />
  );
};

export default container(QueuePage);
