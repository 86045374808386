import { connect } from 'react-redux';

import { performSearchStart } from 'store/search/search.actions';

const mapDispatchToProps = (dispatch) => ({
  onPerformSearchStart: (name) => dispatch(performSearchStart(name)),
});

const container = connect(null, mapDispatchToProps);

export default container;
