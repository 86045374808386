import React from 'react';

import { ContentPageBlank } from 'common/ui/content-page';
import { StreamsCard } from './components';

const StreamsPage = () => {
  return <ContentPageBlank body={<StreamsCard />} />;
};

export default StreamsPage;
