import { all, call } from 'redux-saga/effects';

import { siteCoordinatorSagas } from './siteCoordinator/siteCoordinator.sagas';
import { loginSagas } from './login/login.sagas';
import { userSagas } from './user/user.sagas';
import { userhistorySagas } from './userhistory/userhistory.sagas';
import { fbSagas } from './facebook/facebook.sagas';
import { googleSagas } from './google/google.sagas';
import { articleSagas } from './article/article.sagas';
import { playerSagas } from './player/player.sagas';
import { videoSagas } from './video/video.sagas';
import { streamsSagas } from './streams/streams.sagas';
import { playlistSagas } from './playlist/playlist.sagas';
import { searchSagas } from './search/search.sagas';
import { urlSagas } from './url/url.sagas';
import { upshellSagas } from './upsell/upsell.sagas';
import { yourlorSagas } from './yourlor/yourlor.sagas';
import { artistSagas } from './artist/artist.sagas';
import { routeSagas } from './routes/routes.sagas';
import { tooltipSagas } from './tooltip/tooltip.sagas';
import { radioSagas } from './radio/radio.sagas';
import { playerRadioSagas } from './playerRadio/playerRadio.sagas';
import { searchRadioSagas } from './searchRadio/searchRadio.sagas';
import { userHistoryRadioSagas } from './userhistoryRadio/userhistoryRadio.sagas';
import { playerPodCastSagas } from './playerPodCast/playerPodCast.sagas';
import { podCastSagas } from './podcast/podcast.sagas';
import { userHistoryPodcastSagas } from './userHistoryPodcast/userHistoryPodcast.sagas';
import { searchPodcastSagas } from './searchPodcast/searchPodcast.sagas';
import { podcastPlaylistSagas } from './podcastPlaylist/podcastPlaylist.sagas';

export default function* rootSaga() {
  yield all([
    call(siteCoordinatorSagas),
    call(loginSagas),
    call(userSagas),
    call(fbSagas),
    call(userhistorySagas),
    call(googleSagas),
    call(articleSagas),
    call(artistSagas),
    call(playerSagas),
    call(videoSagas),
    call(streamsSagas),
    call(playlistSagas),
    call(searchSagas),
    call(urlSagas),
    call(upshellSagas),
    call(yourlorSagas),
    call(routeSagas),
    call(tooltipSagas),
    call(radioSagas),
    call(playerRadioSagas),
    call(searchRadioSagas),
    call(userHistoryRadioSagas),
    call(playerPodCastSagas),
    call(podCastSagas),
    call(userHistoryPodcastSagas),
    call(searchPodcastSagas),
    call(podcastPlaylistSagas)
  ]);
}
