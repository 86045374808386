import _ from 'lodash';

import * as UserhistoryRadioType from './userhistoryRadio.types';

const INITIAL_STATE = {
  history: {},
  historyStationIds: [],
  heartedStationIds: [],
  historyNext: 3,
  heartsNext: 3,
  historyLoading: false,
  heartedLoading: false
};

const userhistoryRadioReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  let response, merge, updatedState, stationid;
  switch (type) {
    case UserhistoryRadioType.GET_HEARTS_RADIO_STATION_START:
      return {
        ...state,
        heartedLoading: true,
      };

    case UserhistoryRadioType.GET_HEARTS_RADIO_STATION_SUCCESS:
      response = payload.response;
      merge = payload.merge;
      updatedState = {
        ...state,
        heartedLoading: false,
      };
      if (response.res.length > 0) {
        if (merge) {
          updatedState.heartedStationIds = response.res.map(
              (play) => play.stationuuid
          );
          updatedState.heartedStationIds = _.uniq(
              updatedState.heartedStationIds
          );
          updatedState.heartsNext = response.next || 3;
        } else {
          response.res.forEach((play) => {
            updatedState.history[play.stationuuid] = play;
          });
          const newheartedStationIds = response.res.map(
              (play) => play.stationuuid
          );
          updatedState.heartedStationIds = updatedState.heartedStationIds.concat(
              newheartedStationIds
          );
          updatedState.heartedStationIds = _.uniq(
              updatedState.heartedStationIds
          );
          updatedState.heartsNext = response.next || 3;
        }
      } else {
        // if we get no history back, make sure we set 'has_more' (which should be '')
        updatedState.heartsNext = response.next || 3;
      }
      return updatedState;

    case UserhistoryRadioType.GET_HEARTS_RADIO_STATION_FAIL:
      return {
        ...state,
        heartedLoading: false,
      };

    case UserhistoryRadioType.GET_HISTORY_RADIO_STATION_START:
      return {
        ...state,
        historyLoading: true,
      };

    case UserhistoryRadioType.GET_HISTORY_RADIO_STATION_SUCCESS:
      response = payload.response;
      merge = payload.merge;
      updatedState = {
        ...state,
        historyLoading: false,
      };
      // console.log(response);
      if (response.history.length > 0) {
        if (merge) {
          updatedState.history = _.keyBy(
            response.history,
            (play) => play.stationid
          );
          updatedState.historyStationIds = response.history.map(
            (play) => play.stationid
          );
          updatedState.historyNext = response.has_more || 3;
        } else {
          response.history.forEach((play) => {
            updatedState.history[play.stationuuid] = play;
          });
          const newStationIds = response.history.map(
            (play) => play.stationuuid
          );
          updatedState.historyStationIds = state.historyStationIds.concat(
            newStationIds
          );
          updatedState.historyStationIds = _.uniq(
            updatedState.historyStationIds
          );
          updatedState.historyNext = response.has_more || 3;
        }
      } else {
        // if we get no history back, make sure we set 'has_more' (which should be '')
        updatedState.history = {};
        updatedState.historyNext = response.has_more || 3;
      }
      return updatedState;

    case UserhistoryRadioType.GET_HISTORY_RADIO_STATION_FAIL:
      return {
        ...state,
        historyLoading: false,
      };

    case UserhistoryRadioType.ADD_PLAY_RADIO_STATION_SUCCESS:
      updatedState = {
        ...state,
      };
      // console.log({ payload });
      updatedState.historyStationIds = updatedState.historyStationIds.concat(
        payload.stationuuid
      );
      updatedState.historyStationIds = _.uniq(updatedState.historyStationIds);

      updatedState.history[payload.stationuuid] = payload;
      return updatedState;

    case UserhistoryRadioType.RADIO_STATION_HEART_SUCCESS:
      updatedState = {
        ...state,
      };
      updatedState.heartedStationIds = [
        ...updatedState.heartedStationIds,
        payload.stationuuid,
      ];
      updatedState.history[payload.stationuuid] = payload;
      return updatedState;

    case UserhistoryRadioType.DELETE_HEART_RADIO_STATION_START:
      stationid = payload.stationid;

      updatedState = {
        ...state,
      };

      if (updatedState.heartedStationIds.indexOf(stationid) === -1) {
        updatedState.heartedStationIds.unshift(stationid);
      }

      const idx = updatedState.heartedStationIds.indexOf(stationid);
      if (idx > -1) {
        updatedState.heartedStationIds.splice(idx, 1);
      }

      updatedState.history[stationid] = updatedState.history[stationid] || {
        stationid,
      };
      updatedState.history[stationid].star = false;

      return updatedState;

    case UserhistoryRadioType.DELETE_PLAY_RADIO_STATION_START:
      stationid = payload.stationid;

      updatedState = {
        ...state,
      };

      if (updatedState.historyStationIds.indexOf(stationid) === -1) {
        updatedState.historyStationIds.unshift(stationid);
      }

      const idhdx = updatedState.historyStationIds.indexOf(stationid);
      if (idhdx > -1) {
        updatedState.historyStationIds.splice(idhdx, 1);
      }

      updatedState.history[stationid] = updatedState.history[stationid] || {
        stationid,
      };
      updatedState.history[stationid].star = false;

      return updatedState;

    case UserhistoryRadioType.GET_NEXT_HEART_START:
      return {
        ...state,
        heartsNext: payload.cursor + payload.limit,
      };

    case UserhistoryRadioType.GET_NEXT_HISTORY_START:
      return {
        ...state,
        historyNext: payload.cursor + payload.limit,
      };

    case UserhistoryRadioType.DELETE_PLAY_RADIO_STATION_SUCCESS:
      stationid = payload.stationuuid;

      updatedState = {
        ...state,
      };
      if (updatedState.historyStationIds.indexOf(stationid) === -1) {
        updatedState.historyStationIds.unshift(stationid);
      }

      const idhx = updatedState.historyStationIds.indexOf(stationid);
      if (idhx > -1) {
        updatedState.historyStationIds.splice(idhx, 1);
      }

      updatedState.history[stationid] = updatedState.history[stationid] || {
        stationid,
      };
      updatedState.history[stationid].star = false;

      return updatedState;

    default:
      return state;
  }
};

export default userhistoryRadioReducer;
