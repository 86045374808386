import React from "react";
import Tab from "@material-ui/core/Tab";
import {useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import clsx from "clsx";
import SwitchTab from 'constants/SwitchTab';

const TabCard = (props) => {
  const classes = props.style;
  const { currentSwitchTab, tabName, tabItem, handleChange } = props;
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const clickHandler = () => {
    handleChange(tabName);
  };

  return(
    currentSwitchTab === tabName ?
      (
        matchesSM ? (
          <div onClick={clickHandler}>
            {tabItem}
            {tabName === SwitchTab.LOR ?
              (
                <span className={clsx('textUnderline', classes.textUnderline)}/>
              ) :
              (
                <span style={{marginLeft: -42}} className={clsx('textUnderline', classes.textUnderline)}/>
              )
            }
          </div>
        ) : (
          <Tab label={tabName} style={{minWidth: 80, color: '#FF0066'}} onClick={clickHandler}/>
        )
      ) :
      (
        matchesSM ? (
          <div onClick={clickHandler}>
            {tabItem}
          </div>
        ) : (
          <Tab label={tabName} style={{minWidth: 80}} onClick={clickHandler}/>
        )
      )
  );
};

export default TabCard;