import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

import Spinner from 'common/ui/spinner';
import LoginStatus from 'constants/LoginStatus';

import { LoginButton } from 'modules/login/components';
import { UserStatsWidget, UserWidgetPopover, UserPicture } from './components';

import container from './UserWidget.container';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: '#2B2B2B',
  },
  userWidget: {
    display: 'flex',
    justifyContent: 'space-around',
    height: 50,
  },
  connecting: {
    color: '#ddd',
    height: 20,
    top: 15,
    marginLeft: 15,
    fontFamily: 'Arial',
    position: 'relative',
  },
  logout: {
    color: 'rgb(155, 155, 155)',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: '14px',
    border: '1px solid rgba(136, 136, 136, 0.52)',
    padding: '5px 52px',
    margin: '0px 20px 10px 20px',
    borderRadius: 5,
    textAlign: 'center',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  spinner: {
    height: 0,
    position: 'absolute',
    left: 45,
    top: 70,
    border: '3px solid #dedede',
    borderTopColor: 'rgba(255,0,102,.8)',
    borderRadius: '100%',
    animationName: '$spinner',
    animationDuration: '1000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

const UserWidget = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleUserPopoverClick = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleUserPopoverClose = (evt) => {
    setAnchorEl(null);
  };

  switch (props.loginStatus) {
    case LoginStatus.Anonymous:
      return (
        <div
          className={clsx('user-widget', classes.wrapper, {
            'always-expanded': !props.usesPopover,
          })}
        >
          <div style={{display: 'flex'}}>
            <LoginButton onShowLoginModal={props.onShowLoginModal} />
            {props.lor &&
              <UserStatsWidget hearts={props.hearts} repeats={props.repeats} />
            }
            {/*loginTooltip*/}
          </div>
        </div>
      );

    case LoginStatus.LoggingIn:
      return (
        <div
          className={clsx('user-widget', classes.wrapper, {
            'always-expanded': !props.usesPopover,
          })}
        >
          <div className={classes.userWidget}>
            <Spinner hidden={false} className={classes.spinner} />
            <span className={classes.connecting}>Connecting...</span>
          </div>
        </div>
      );

    case LoginStatus.Authenticated:
      let contents = props.assimilating ? (
        <div className="assimilate-spinner-wrapper">
          <Spinner
            className="assimilate-spinner"
            style={{ width: 25, height: 25 }}
            hidden={false}
          />
          <span className="connecting">Importing...</span>
        </div>
      ) : (
        props.lor &&
          <UserStatsWidget hearts={props.hearts} repeats={props.repeats} />
      );

      return (
        <div
          className={clsx('user-widget', classes.wrapper, {
            'always-expanded': !props.usesPopover,
          })}
          onClick={props.usesPopover ? handleUserPopoverClick : null}
        >
          {props.usesPopover && (
            <UserWidgetPopover
              anchorEl={anchorEl}
              handleClose={handleUserPopoverClose}
              firstName={props.firstName}
            />
          )}

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {props.usesPopover && (
              <DownIcon style={{ fill: '#fff', marginTop: 15 }} />
            )}

            <UserPicture pictureUrl={props.pictureUrl} firstName={props.firstName}/>
            {contents}
          </div>

          {!props.usesPopover && (
            <Button
              className={classes.logout}
              onClick={props.onShowLogoutConfirmationModal}
            >
              LOGOUT
            </Button>
          )}
        </div>
      );

    default:
      throw new Error('Unknown case for login.types.LoginStatus');
  }
};

export default container(UserWidget);
