import axios from "axios";

const instance = axios.create({
  baseURL: "https://dev-system.listenonrepeat.com",
  withCredentials: true,
  // xsrfCookieName: 'csrftoken',
  // xsrfHeaderName: 'X-CSRFToken',
});

export default instance;
