import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, useMediaQuery } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Hidden from '@material-ui/core/Hidden';

import SectionTab from 'constants/SectionTab';
import { SwitchTab } from 'layouts/RadioMain/components';
import { scrollToMainContentArea } from 'common/ui/utils';

import container from './HeaderTitle.container';
import { Search as SearchIcon } from '@material-ui/icons';

const HeaderTitle = (props) => {
  const classes = props.style;
  const theme = useTheme();

  const ref = useRef();

  const {
    search,
    onToggleSearch,
    searchState: { autocompleteSuggestions },
    youtubeLookup,
    onSetCurrentTab,
    onSidebarClose,
    onGetAutocompleteSuggestionStart
  } = props;

  const matchesMD = useMediaQuery(theme.breakpoints.down('sm'));

  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event, value, reason) => {
    setInputValue(value);
  };

  const handleSearch = () => {
    onToggleSearch(!search);
    if (matchesMD) {
      onSidebarClose();
      setTimeout(() => ref.current.focus());
    }
  };

  const fetch = React.useMemo(
    () =>
      _.debounce((request) => {
        onGetAutocompleteSuggestionStart(request.input);
      }, 500),
    []
  );

  React.useEffect(() => {
    if (inputValue.length < 3 || matchesMD) {
      return;
    }
    fetch({ input: inputValue });
  }, [inputValue, fetch, matchesMD]);

  const getSearchHint = () => {
    return matchesMD ? 'Search for a video...' : 'Search for a video...';
  };

  // function onSearchRequest(event, option, reason) {
  function onSearchRequest(chosenRequest) {
    // console.log('onSearchRequest::', { chosenRequest });

    const isUrlMatch = chosenRequest.match(
      /https?:\/\/(?:youtube.com|www.youtube.com|youtu.be)\/watch/
    );
    // scrollToMainContentArea();

    // // I'm feeling lucky search or regular search
    // if (isUrlMatch) {
    //   const parsedURL = url.parse(chosenRequest, true);
    //   // if (π.nonemptyString(parsedURL.query.v)) {
    //   //   // TODO: Cancel Page Loading
    //   //   player.actions.viewVideo(parsedURL.query.v);
    //   //   return;
    //   // }
    // } else {
    //   performSearch(chosenRequest).then((x) => {
    //     scrollToMainContentArea();
    //   });
    // }
    //
    // if (window.isMobileDevice()) {
    //   siteActions.closeSidebar();
    // }
  }

  function onChange(event, value, reason) {
    youtubeLookup(value);
    onSetCurrentTab(SectionTab.Search);
    scrollToMainContentArea();
  }

  return (
    <div className={classes.searchBar}>
      {search ? (
        <Autocomplete
          id="asynchronous-demo"
          openOnFocus
          clearOnBlur
          autoComplete
          freeSolo
          disableClearable
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.description
          }
          getOptionSelected={(option) => {
            console.log('getOptionSelected::', option);
          }}
          options={autocompleteSuggestions}
          onInputChange={handleChange}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              id="search-main"
              inputRef={ref}
              autoFocus={search}
              placeholder={getSearchHint()}
              margin="dense"
              onBlur={(e) => {
                if (!matchesMD) {
                  handleSearch();
                }
              }}
              onClick={(e) => {
                if (matchesMD) {
                  onSidebarClose();
                  setTimeout(() => ref.current.focus());
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && e.target.value) {
                  onSetCurrentTab(SectionTab.Search);
                  scrollToMainContentArea();
                }
              }}
              classes={{
                root: classes.inputRoot
              }}
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: classes.inputBase,
                  input: classes.inputInput
                }
              }}
            />
          )}
        />
      ) : (
        <div>
          <Hidden smDown>
            <div style={{ display: 'flex' }}>
              {/*<IconButton aria-label="search" onClick={handleSearch}>
                  <SearchIcon className={classes.icon}/>
                </IconButton>*/}
              <TextField
                placeholder={'Search...'}
                onClick={handleSearch}
                classes={{
                  root: classes.inputRootSmall
                }}
                InputProps={{
                  classes: {
                    root: classes.inputBase,
                    input: classes.inputInput
                  }
                }}
              />
              <SwitchTab />
            </div>
          </Hidden>
          <Hidden mdUp>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <IconButton aria-label="search" onClick={handleSearch}>
                <SearchIcon className={classes.icon} />
              </IconButton>
              <SwitchTab />
            </div>
          </Hidden>
        </div>
      )}
    </div>
  );
};

export default container(HeaderTitle);
