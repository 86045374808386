const styles = {
  wrapper: {
    marginBottom: 15,
    fontFamily: 'Arial',
  },

  text: {
    fontSize: 16,
    textAlign: 'left',
    marginTop: -1,
    // marginBottom: 12,
    // -webkit-font-smoothing: antialiased,
    // -moz-osx-font-smoothing: grayscale,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    letterSpacing: 0.5,
    width: '100%',
  },

  artistWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxHeight: 85,
    overflow: 'hidden',
  },
};

export default styles;
