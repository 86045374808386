import * as UserhistoryPodcastType from './userHistoryPodcast.types';

export const getHeartsPodcastStart = (next, merge = false) => ({
  type: UserhistoryPodcastType.GET_FAVORITE_PODCAST_START,
  payload: { },
});

export const getHeartsPodcastSuccess = (data) => ({
  type: UserhistoryPodcastType.GET_FAVORITE_PODCAST_SUCCESS,
  payload: data,
});

export const getHeartsPodcastFail = (err) => ({
  type: UserhistoryPodcastType.GET_FAVORITE_PODCAST_FAIL,
  payload: err,
});

export const getHistoryPodcastStart = (next, merge = false) => ({
  type: UserhistoryPodcastType.GET_HISTORY_PODCAST_START,
  payload: { },
});

export const getHistoryPodcastSuccess = (data) => ({
  type: UserhistoryPodcastType.GET_HISTORY_PODCAST_SUCCESS,
  payload: data,
});

export const getHistoryPodcastFail = (err) => ({
  type: UserhistoryPodcastType.GET_HISTORY_PODCAST_FAIL,
  payload: err,
});

export const getHistoryPodcastMetadataSuccess = (data) => ({
  type: UserhistoryPodcastType.GET_HISTORY_PODCAST_METADATA_SUCCESS,
  payload: data,
});

export const getHistoryPodcastMetadataFail = (err) => ({
  type: UserhistoryPodcastType.GET_HISTORY_PODCAST_METADATA_FAIL,
  payload: err,
});

export const addPlayPodcastStart = (podcastId) => ({
  type: UserhistoryPodcastType.ADD_PLAY_PODCAST_START,
  payload: { podcastId }
});

export const addPlayPodcastSuccess = (data) => ({
  type: UserhistoryPodcastType.ADD_PLAY_PODCAST_SUCCESS,
  payload: data
});

export const addPlayPodcastFail = (err) => ({
  type: UserhistoryPodcastType.ADD_PLAY_PODCAST_FAIL,
  payload: err
});

export const podcastHeartStart = (podcastId) => ({
  type: UserhistoryPodcastType.PODCAST_HEART_START,
  payload: { podcastId }
});

export const podcastHeartSuccess = (data) => ({
  type: UserhistoryPodcastType.PODCAST_HEART_SUCCESS,
  payload: data
});

export const podcastHeartFail = (err) => ({
  type: UserhistoryPodcastType.PODCAST_HEART_FAIL,
  payload: err
});

export const removeFavoritePodcastStart = (podcastId) => ({
  type: UserhistoryPodcastType.DELETE_FAVORITE_PODCAST_START,
  payload: { podcastId }
});

export const removeFavoritePodcastSuccess = (data) => ({
  type: UserhistoryPodcastType.DELETE_FAVORITE_PODCAST_SUCCESS,
  payload: data
});

export const removeFavoritePodcastFail = (err) => ({
  type: UserhistoryPodcastType.DELETE_FAVORITE_PODCAST_FAIL,
  payload: err
});

export const removeHistoryPodcastStart = (podcastId) => ({
  type: UserhistoryPodcastType.DELETE_HISTORY_PODCAST_START,
  payload: { podcastId },
});

export const removeHistoryPodcastSuccess = (data) => ({
  type: UserhistoryPodcastType.DELETE_HISTORY_PODCAST_SUCCESS,
  payload: data,
});

export const removeHistoryPodcastFail = (err) => ({
  type: UserhistoryPodcastType.DELETE_HISTORY_PODCAST_FAIL,
  payload: err,
});

export const getNextFavoriteStart = (cursor, limit) => ({
  type: UserhistoryPodcastType.GET_NEXT_HEART_START,
  payload: { cursor, limit },
});

export const getNextHistoryStart = (cursor, limit) => ({
  type: UserhistoryPodcastType.GET_NEXT_HISTORY_START,
  payload: { cursor, limit },
});