import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';

import { Top10Row } from './components';
import container from './Top10Card.container';
import { scrollToVideo } from 'common/ui/utils';

import * as StreamsType from 'store/streams/streams.types';
import Feature from 'constants/Feature';

const Top10Card = (props) => {
  const theme = useTheme();
  const {
    streams,
    onGetTop10Start,
    onSetAutoplay,
    onSetLastPlayed,
    onViewPlayerVideoStart,
    onSetCurrentPlaylistStart,
    onClearCurrentlyViewedStream,
    onPlayFeature,
    onQueueYtid,
  } = props;

  useEffect(() => {
    onGetTop10Start();
  }, [onGetTop10Start]);

  const onClickVideo = (ytid) => {
    onQueueYtid(ytid);
    scrollToVideo();
    onClearCurrentlyViewedStream();
    onSetLastPlayed(ytid);
    onSetAutoplay(true);
    onViewPlayerVideoStart(ytid);
    onSetCurrentPlaylistStart(StreamsType.PLAYLIST_TOP10);
    onPlayFeature(Feature.Top10);
    // analytics.actions.trackAction('Top 10', ytid)
    window._gaq.push(['_trackEvent', 'Action', 'Top 10', ytid]);
    console.log("top 10 ga")

  };
  const newTop10List = streams?.top10;
  console.log(newTop10List, 'newtotptpptpt')
  // const newTop10List = [
  //   {
  //     title: "Taylor Swift - All Too Well (10 Minute Version) (Lyric Video)",
  //     ytid: "sRxrwjOtIag"
  //   },
  //   {
  //     title: "Dua Lipa - Don't Start Now (Official Music Video)",
  //     ytid: "oygrmJFKYZY"
  //   },
  //   {
  //     title: "韋禮安 WeiBird《如果可以 Red Scarf》MV - 電影「月老」主題曲導演親剪音樂視角版",
  //     ytid: "8MG--WuNW1Y"
  //   },
  //   {
  //     title: "The Kid LAROI, Justin Bieber - Stay (Official Video)",
  //     ytid: "kTJczUoc26U"
  //   },
  //   {
  //     title: "Terry Zhong - Play It Cool (feat. Conro) [Monstercat Release]",
  //     ytid: "MI6t9x4lYv0"
  //   },
  //   {
  //     title: "Iron Maiden - Futureal (Official Video)",
  //     ytid: "E9yLBNa-iCQ"
  //   },
  //   {
  //     title: "The Weeknd - Save Your Tears (Audio)",
  //     ytid: "u6lihZAcy4s"
  //   },
  //   {
  //     title: "George Ezra - Shotgun (Lyric Video)",
  //     ytid: "v_B3qkp4nO4"
  //   },
  //   {
  //     title: "Eurielle - Carry Me [Emotional Vocal Orchestral]",
  //     ytid: "vyn8gAYtNu4"
  //   },
  //   {
  //     title: "Linkin Park - New Divide (Official Video)",
  //     ytid: "v=ysSxxIqKNN0"
  //   }
  // ]
  return (
    <CardContent
      style={{
        padding: 0,
        fontFamily: theme.typography.fontFamily,
        fontSize: 14,
      }}
    >
      {newTop10List?.map((item, index) => {
        return (
          <Top10Row
            key={index + ''}
            ytid={item?.ytid}
            title={item?.title}
            index={index}
            onClick={onClickVideo}
          />
        );
      })}
    </CardContent>
  );
};

export default container(Top10Card);
