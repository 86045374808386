import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PlayIcon from '@material-ui/icons/PlayArrow';

import RecommendedArtists from '../RecommendedArtists';
import ArtistRow from '../ArtistRow';
import ArtistSuggestionImage from '../SuggestionImage';

import { generateHeader } from 'store/artist/artist.utils';

import container from './RadioPlayerSuggestion.container';

import styles from './RadioPlayerSuggestion.styles';
import { scrollToMainContentArea } from 'common/ui/utils';

const useStyles = makeStyles(() => ({
  suggestion: {
    ...styles.wrapper.suggestion,
  },
  cover: {
    ...styles.wrapper.suggestion.cover,
  },
  image: {
    ...styles.wrapper.suggestion.image,
  },
  imgItem: {
    ...styles.wrapper.suggestion.image.item,
  },
  infoWrapper: {
    ...styles.wrapper.info,
  },
  title: {
    ...styles.wrapper.info.title,
  },
  highlight: {
    ...styles.wrapper.info.title.highlight,
  },
  button: {
    ...styles.wrapper.actions.button,
  },
  icon: {
    ...styles.wrapper.actions.button.icon,
  },
}));

const RadioPlayerSuggestion = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  if (!props.artists) {
    return null;
  }

  const {
    artistState,
    onClearCurrentlyViewedStream,
    onSetAutoplay,
    onSetRepeatMode,
    onSetupArtistRadio,
    onSetCurrentTab,
  } = props;

  // Show recommend artists if no radio suggestion
  const hasVideoSuggestion =
    props.artistState.videoPreview.radioName &&
    props.artistState.videoPreview.artistRadioRecs;
  const isVideoSuggestionPlaying =
    props.artistState.radioName === props.artistState.videoPreview.radioName;

  const recommendedArtist = () => {
    return (
      <RecommendedArtists
        title={props.artistSectionTitle}
        artists={props.artists}
      />
    );
  };

  if (!hasVideoSuggestion || isVideoSuggestionPlaying) {
    return recommendedArtist();
  }

  const { description, images } = generateHeader(
    artistState.videoPreview.artistRadioRecs,
    8
  );

  const displayCount = matchesSM ? 3 : 5;

  const currentArtist = props.artists[0];

  const alternativeArtists = props.artists.slice(1, props.artists.length - 1);

  const artists = _.take(alternativeArtists, displayCount).map((a) => (
    <ArtistRow key={a.name} name={a.name} image={a.image} />
  ));

  const onClickRow = (e) => {
    onClearCurrentlyViewedStream();
    onSetRepeatMode(false);
    onSetAutoplay(true);
    onSetupArtistRadio(
      artistState.videoPreview.radioName,
      artistState.videoPreview.startVideo
    );
    scrollToMainContentArea();

    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div style={styles.wrapper}>
      {artists.length >= displayCount && (
        <Hidden smDown>
          <div
            title={description}
            className={classes.suggestion}
            onClick={onClickRow}
          >
            <div>
              {currentArtist && (
                <div style={{ display: 'block', float: 'left', width: 84 }}>
                  <img
                    src={currentArtist.image}
                    width="84"
                    height="84"
                    alt=""
                  />
                </div>
              )}
              <div className={classes.cover} />
              <ArtistSuggestionImage
                images={images}
                className={classes.image}
                itemClassName={classes.imgItem}
              />
            </div>
            <div className={classes.infoWrapper}>
              <div style={{ textAlign: 'center' }}>
                <h5 className={classes.title}>
                  <span className={classes.highlight}>
                    {props.artistState.videoPreview.radioName}
                  </span>
                  Radio
                </h5>
                <Tooltip title="Play radio">
                  <IconButton className={classes.button} onClick={() => {}}>
                    <PlayIcon
                      className={clsx('tip-box-radio-icon', classes.icon)}
                      style={{ color: '#FFF' }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </Hidden>
      )}

      {artists}
    </div>
  );
};

export default container(RadioPlayerSuggestion);
