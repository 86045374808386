import { connect } from 'react-redux';

import { setAutoplay, viewPlayerVideoStart } from 'store/player/player.actions';
import {
  setCurrentPlaylistStart,
  queueYtid,
  showAddPlaylistModal,
} from 'store/playlist/playlist.actions';
import {
  deleteHeartStart,
  getHeartsPageStart,
} from 'store/userhistory/userhistory.actions';
import {
  enqueueToolTip,
  closeToolTip,
  hideDeletePlaylistToolTip,
} from 'store/tooltip/tooltip.actions';
import { showSnackbarNotification } from 'store/notification/notification.actions';

const mapStateToProps = (state) => ({
  userhistoryState: state.userhistory,
  videoState: state.video,
  playerState: state.player,
  playlistState: state.playlist,
  userState: state.user,
  tooltipState: state.tooltip,
});

const mapDispatchToProps = (dispatch) => ({
  onSetAutoplay: (value) => dispatch(setAutoplay(value)),
  onViewPlayerVideoStart: (ytid) => dispatch(viewPlayerVideoStart(ytid)),
  onDeleteHeartStart: (ytid) => dispatch(deleteHeartStart(ytid)),
  onSetCurrentPlaylistStart: (value) =>
    dispatch(setCurrentPlaylistStart(value)),
  onQueueYtid: (ytid) => dispatch(queueYtid(ytid)),
  onGetHeartsPageStart: (next, merge) =>
    dispatch(getHeartsPageStart(next, merge)),
  onShowSnackbarNotification: (message) =>
    dispatch(showSnackbarNotification(message)),
  onHideDeletePlaylistToolTip: () => dispatch(hideDeletePlaylistToolTip()),
  onEnqueueToolTip: (key, element, ytid) =>
    dispatch(enqueueToolTip(key, element, ytid)),
  onCloseToolTip: () => dispatch(closeToolTip()),
  onShowAddPlaylistModal: () => dispatch(showAddPlaylistModal()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
