import * as SearchRadioType from "./searchRadio.types";

export const getRadioAutocompleteSuggestionStart = (name) => ({
    type: SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_START,
    payload: { name },
});

export const getRadioAutocompleteSuccess = (data) => ({
    type: SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_SUCCESS,
    payload: data,
});

export const getRadioAutocompleteFailed = (error) => ({
    type: SearchRadioType.GET_RADIO_AUTOCOMPLETE_SUGGESTION_FAILED,
    payload: error,
});

export const performSearchRadioStationStart = (value) => ({
    type: SearchRadioType.RADIO_LOOKUP_START,
    payload: value
});

export const performSearchRadioStationSuccess = (data, name) => ({
    type: SearchRadioType.RADIO_LOOKUP_SUCCESS,
    payload: { data, name }
});

export const performSearchRadioStationFailed = (value) => ({
    type: SearchRadioType.RADIO_LOOKUP_FAILED,
    payload: value
});

export const toggleSearch = (value) => ({
    type: SearchRadioType.TOGGLE_SEARCH,
    payload: value
});
