import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function Stream(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M12.6236134,14.1996078 L16.6693122,22.2910053 L7.33068783,22.2910053 L11.3763866,14.1996078 C10.4171291,13.9281826 9.71428571,13.0461702 9.71428571,12 C9.71428571,10.7376349 10.7376349,9.71428571 12,9.71428571 C13.2623651,9.71428571 14.2857143,10.7376349 14.2857143,12 C14.2857143,13.0461702 13.5828709,13.9281826 12.6236134,14.1996078 Z M14.9924159,16.08007 L14.4305999,14.9564379 C15.9915541,14.8544245 17.2270843,13.5690024 17.2270843,12 C17.2270843,10.3644444 15.8845149,9.03703704 14.2302777,9.03703704 L14.2302777,7.85185185 C16.549806,7.85185185 18.425807,9.70666667 18.425807,12 C18.425807,14.0359662 16.9472441,15.7263157 14.9924159,16.08007 Z M15.985563,18.0663642 L15.4443286,16.9838953 C17.7891239,16.4043157 19.5263361,14.3072346 19.5263361,11.8095238 C19.5263361,8.86433862 17.1109099,6.47619048 14.1320841,6.47619048 L14.1320841,5.29100529 C17.7702074,5.29100529 20.7250588,8.21248677 20.7250588,11.8095238 C20.7250588,14.770444 18.7228968,17.273618 15.985563,18.0663642 Z M9.62646808,14.8423019 L9.07483387,15.9455703 C7.24232439,15.5193728 5.87929334,13.8736816 5.87929334,11.9047619 C5.87929334,9.61142857 7.72849431,7.75661376 10.0148866,7.75661376 L10.0148866,8.94179894 C8.38428123,8.94179894 7.0608914,10.2692063 7.0608914,11.9047619 C7.0608914,13.408264 8.17920927,14.6513685 9.62646808,14.8423019 Z M8.53301474,17.0292086 L7.99604707,18.103144 C5.39476739,17.2514303 3.51251009,14.7963808 3.51251009,11.9047619 C3.51251009,8.30772487 6.42514931,5.38624339 10.0112994,5.38624339 L10.0112994,6.57142857 C7.07502825,6.57142857 4.69410815,8.95957672 4.69410815,11.9047619 C4.69410815,14.3354354 6.31581688,16.386696 8.53301474,17.0292086 Z"
        id="Combined-Shape"
      />
      <path d="M12,24 C5.372583,24 0,18.627417 0,12 C0,5.37258301 5.372583,7.10542736e-15 12,7.10542736e-15 C18.6274169,7.10542736e-15 24,5.37258301 24,12 C24,18.627417 18.6274169,24 12,24 Z M12,21.8181818 C17.422432,21.8181818 21.8181818,17.4224321 21.8181818,12 C21.8181818,6.57756792 17.422432,2.18181819 12,2.18181819 C6.57756791,2.18181819 2.18181818,6.57756792 2.18181818,12 C2.18181818,17.4224321 6.57756791,21.8181818 12,21.8181818 Z" />
    </SvgIcon>
  );
}
