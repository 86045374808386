import * as TooltipType from './tooltip.types';

const INITIAL_STATE = {
  queue: [],
  currentEntry: {
    key: '',
    element: null,
    ytid: '',
  },
  radioCurrentEntry: {
    key: '',
    element: null,
    stationid: '',
  },
  podcastCurrentEntry: {
    key: '',
    element: null,
    podcastid: '',
  }
};

const tooltipReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case TooltipType.ENQUEUE_TOOLTIP:
      return {
        ...state,
        currentEntry: payload,
      };

    case TooltipType.ENQUEUE_RADIO_TOOLTIP:
      return {
        ...state,
        radioCurrentEntry: payload,
      };

    case TooltipType.ENQUE_POD_CAST_TOOLTIP:
      return {
        ...state,
        podcastCurrentEntry: payload,
      };

    case TooltipType.CLOSE_TOOLTIP:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default tooltipReducer;
