import { connect } from 'react-redux';

import { getStreamListStart } from 'store/streams/streams.actions';

const mapStateToProps = (state) => ({
  streamsState: state.streams,
});

const mapDispatchToProps = (dispatch) => ({
  onGetStreamListStart: () => dispatch(getStreamListStart()),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
