import { connect } from 'react-redux';
import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';

const mapStateToProps = (state) => ({
    siteCoordinator: state.siteCoordinator,
    playerState: state.player,
    showSearchTab: state.searchPodcast.query,
});

const mapDispatchToProps = (dispatch) => ({
    onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
