import React, {useRef} from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IconButton, Hidden, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import _ from "lodash";
import SectionTab from 'constants/SectionTab';
import { SwitchTab } from 'layouts/RadioMain/components';
import { scrollToMainContent } from 'common/ui/utils';
import { Search as SearchIcon } from '@material-ui/icons';
import container from './HeaderTitle.container';

const HeaderTitleComponent = (props) => {
  const classes = props.style;
  const theme = useTheme();
  const ref = useRef();
  const matchesMD = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    search,
    onToggleSearch,
    searchRadioState: { autocompleteSuggestions },
    onSetCurrentTab,
    onSidebarClose,
    onGetRadioAutocompleteSuggestionStart,
    onPerformSearchRadioStationStart,
  } = props;
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event, value, reason) => {
    setInputValue(value);
  };

  const handleSearch = () => {
    onToggleSearch(!search);
    if (matchesMD) {
      onSidebarClose();
      setTimeout(() => ref.current.focus());
    }
  };

  const fetch = React.useMemo(
    () =>
      _.debounce((request) => {
        onGetRadioAutocompleteSuggestionStart(request.input);
      }, 500),
    []
  );

  React.useEffect(() => {
    if (inputValue.length < 3) {
      return;
    }
    fetch({ input: inputValue });
  }, [inputValue, fetch, matchesMD]);

  function onChangeHandler(event, value, reason) {
    onPerformSearchRadioStationStart(value);
    onSetCurrentTab(SectionTab.Search);
    scrollToMainContent();
  }

  return(
    <div className={classes.searchBar}>
      {search ?
        (
          <Autocomplete
            id="asynchronous-demo"
            openOnFocus
            clearOnBlur
            autoComplete
            freeSolo
            disableClearable
            options={autocompleteSuggestions}
            onInputChange={handleChange}
            onChange={onChangeHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={ref}
                autoFocus={search}
                margin="dense"
                placeholder={'Search for a radio...'}
                onBlur={(e) => {
                  if (!matchesMD) {
                    handleSearch();
                  }
                }}
                onClick={(e) => {
                  if (matchesMD) {
                    onSidebarClose();
                    setTimeout(() => ref.current.focus());
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && e.target.value) {
                    onSetCurrentTab(SectionTab.SearchRadio);
                    scrollToMainContent();
                  }
                }}
                classes={{
                  root: classes.inputRoot,
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: classes.inputBase,
                    input: classes.inputInput,
                  },
                }}
              />
            )}
          />
        ) :
        (
          <div>
            <Hidden smDown>
              <div style={{display: 'flex'}}>
                {/*<IconButton aria-label="search" onClick={handleSearch}>
                  <SearchIcon className={classes.icon}/>
                </IconButton>*/}
                <TextField
                    placeholder={'Search...'}
                    onClick={handleSearch}
                    classes={{
                      root: classes.inputRootSmall,
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputBase,
                        input: classes.inputInput,
                      },
                    }}
                />
                <SwitchTab />
              </div>
            </Hidden>
            <Hidden mdUp>
              <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <IconButton aria-label="search" onClick={handleSearch}>
                  <SearchIcon className={classes.icon}/>
                </IconButton>
                <SwitchTab />
              </div>
            </Hidden>
          </div>
        )
      }
    </div>
  );
};

export default container(HeaderTitleComponent);