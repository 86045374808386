import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import QueueIcon from '@material-ui/icons/Queue';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 30,
    height: 30,
    padding: 0,
    marginRight: 10,
    display: 'inline-block',
  },
  icon: {
    display: 'inline-block',
    color: 'rgb(37, 37, 37)',
    fill: 'rgb(176, 176, 176)',
    height: 20,
    width: 20,
    userSelect: 'none',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    '&:hover': {
      color: theme.palette.iconHover,
      fill: theme.palette.iconHover,
    },
  },
}));

const QueueButton = (props) => {
  const classes = useStyles();

  function onClick(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    props.onClick(evt);
  }

  return (
    <IconButton className={classes.iconButton} onClick={onClick}>
      <QueueIcon className={classes.icon}/>
    </IconButton>
  );
};

export default QueueButton;
