import { connect } from 'react-redux';
import { toggleSidebar } from 'store/siteCoordinator/siteCoordinator.actions';
import { setCurrentState } from 'store/radio/radio.actions';

const mapStateToProps = (state) => ({
  siteCoordinator: state.siteCoordinator,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleSidebar: (value) => dispatch(toggleSidebar(value)),
  onSetCurrentState: (value) => dispatch(setCurrentState(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
