import { connect } from 'react-redux';

import {
    getRadioAutocompleteSuggestionStart,
    performSearchRadioStationStart,
} from 'store/searchRadio/searchRadio.actions';
import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';

const mapStateToProps = (state) => ({
    searchRadioState: state.searchRadio,
    videoStore: state.video,
});

const mapDispatchToProps = (dispatch) => ({
    onGetRadioAutocompleteSuggestionStart: (value) =>
        dispatch(getRadioAutocompleteSuggestionStart(value)),
    onSetCurrentTab: (value) => dispatch(setCurrentTab(value)),
    onPerformSearchRadioStationStart: (value) => dispatch(performSearchRadioStationStart(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
