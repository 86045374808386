import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

import { SidebarPlaylistListItem, SidebarPlaylistNew } from './components';

import container from './PlaylistList.container';

import styles from './PlaylistList.styles';

const useStyles = makeStyles((theme) => ({
  item: {
    ...styles.item,
  },
}));

const PlaylistList = (props) => {
  const classes = useStyles();
  const {
    playlistState,
    uid,
    isViewingPlaylists,
    onListItemClick,
    onClick,
    playerIsPlaying,
  } = props;

  const myPlaylistList = playlistState.playlistsByUser[uid] || [];
  let myPlaylists = _.map(
    myPlaylistList,
    (pid) => playlistState.playlists[pid]
  );
  
  myPlaylists = _.compact(myPlaylists);

  const followedPlaylistsList = playlistState.followedPlaylistsByUser[uid];
  let followedPlaylists = _.map(
    followedPlaylistsList,
    (item) => playlistState.playlists[item.pid]
  );
  followedPlaylists = _.compact(followedPlaylists);

  let allPlaylists = _.concat(myPlaylists, followedPlaylists);
  allPlaylists = _.sortBy(allPlaylists, (p) => {
    return p.name.toLowerCase();
  });

  return (
    <>
      {allPlaylists.map((playlistItem) => {
        let pid = playlistItem.pid;
        let currentlyViewedPlaylist = playlistState.currentlyViewedPlaylist;
        let isSelected =
          currentlyViewedPlaylist && isViewingPlaylists
            ? pid === currentlyViewedPlaylist
            : false;
        let songIsPlaying = pid === playlistState.currentPlaylist;

        return (
          <SidebarPlaylistListItem
            shared={playlistItem.uid !== uid}
            key={playlistItem.pid}
            uid={playlistItem.uid}
            pid={playlistItem.pid}
            name={playlistItem.name}
            songIsPlaying={songIsPlaying}
            playerIsPlaying={playerIsPlaying}
            isSelected={isSelected}
            onListItemClick={onListItemClick}
            onClick={() => onClick()}
          />
        );
      })}
      <ListItem className={classes.item}>
        <SidebarPlaylistNew />
      </ListItem>
    </>
  );
};

export default container(PlaylistList);
