import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Tonefuse = (props) => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));

  const { tonefuseID, title } = props;
  if (
    matchesMD ||
    !tonefuseID ||
    !title ||
    title == 'Welcome to Listenonrepeat'
  ) {
    return null;
  }
  var titleParts = title.split('-');

  if (title.includes('Welcome to ListenOnRepeat - World\'s #1 YouTube')){
    titleParts='Welcome to ListenOnRepeat - Video Looper'.split('-');
  }
console.log("title>"+title);
console.log("titleParts>"+titleParts);
console.log("tonefuseID>"+tonefuseID);
  const likelyAristName = titleParts[0].trim();
  const likelySongName = titleParts.length >= 2 ? titleParts[1].trim() : '';

  let opts = {
    artist: likelyAristName,
    song: likelySongName,
    adunit_id: 39382091,
    // div_id: 'cf_async_' + tonefuseID
    div_id: 'amplified_' + tonefuseID
  };
  console.log("opts tonefuse");
  console.log(opts);
  if(!document.getElementsByClassName("tonefusealready")[0]){
    tonefusecode()
  }else {
    var id = document.querySelector('.tonefusealready').id
    if(id=='amplified_'+tonefuseID){
      console.log("match >>"+id)

    }else {
      console.log("nomatch >>"+id)
      console.log("nomatchele >>"+document.querySelector('.tonefusealready').innerHTML)
      console.log(document.querySelector('.tonefusealready'))
      const myNode=document.querySelector('.tonefusealready');
      myNode.innerHTML='';
      // myNode.classList.remove('tonefusealready');
      tonefusecode();
      console.log((document.querySelector('.tonefusealready')))


    }

  }

  function tonefusecode() {
    let c = function() {
      window.cf.showAsyncAd(opts);
    };

    if (typeof window.cf !== 'undefined') c();
    else {
      let cf_async = !0;

      let r = document.createElement('script');
      let s = document.getElementsByTagName('script')[0];
      r.async = !0;
      r.src = '//srv.clickfuse.com/showads/showad.js';
      r.readyState? (r.onreadystatechange = function() {
            if ('loaded' == r.readyState || 'complete' == r.readyState){
              console.log("onreadystatechange")
              (r.onreadystatechange = null);
              c();

            }
          })
          : (r.onload = c);
      s.parentNode.insertBefore(r, s);
    }
  }


  return <div id={opts.div_id} className={"tonefusealready"}/>;
};

export default Tonefuse;
