import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const EpisodeSkeleton = () => {
    return (
        <>
            <Box display="flex" alignItems="center" style={{padding: 8}}>
                <Box margin={1}>
                    <Skeleton variant="circle" width={40} height={40} style={{margin: 'auto'}} />
                </Box>
                <Box width={'60%'}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="60%" />
                </Box>
            </Box>
        </>
    );
};

export default EpisodeSkeleton;
