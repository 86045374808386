import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';

import PublicIcon from '@material-ui/icons/Public';
import { NowPlayingIcon } from 'common/ui/icons';
import SectionTab from 'constants/SectionTab';

import container from './PlaylistListItem.container';
import styles from './PlaylistListItem.styles';

const useStyles = makeStyles((theme) => ({
  subItem: {
    ...styles.subItem,
  },
  regularStyle: {
    ...styles.regularStyle,
  },
  rightIcon: {
    ...styles.rightIcon,
  },
  playingTextStyle: {
    color: theme.palette.pink,
  },
}));

const PlaylistListItem = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    isSelected,
    shared,
    songIsPlaying,
    playerIsPlaying,
    name,
    pid,
    onListItemClick,
    onOpenPlaylistStart,
  } = props;

  const rightIcon = (isShared, songIsPlaying, isPlayerPlaying) => {
    if (songIsPlaying && isPlayerPlaying) {
      return (
        <NowPlayingIcon
          playerIsPlaying={isPlayerPlaying}
          style={{ width: 16, height: 16, color: theme.palette.lightGray }}
        />
      );
    } else if (isShared) {
      return (
        <PublicIcon
          style={{ width: 13, height: 13, color: theme.palette.lightGray }}
        />
      );
    } else {
      return null;
    }
  };

  function onPlaylistItemClick() {
    // scroll to playlist div
    onListItemClick(SectionTab.Playlists);
    // open playist
    onOpenPlaylistStart(pid);
  }

  function onPlaylistItemDoubleClick() {
    // open and play
  }

  return (
    <ListItem
      button
      onClick={onPlaylistItemClick}
      onDoubleClick={onPlaylistItemDoubleClick}
      style={{
        backgroundColor: isSelected ? theme.palette.gray : undefined,
        fontFamily: 'Arial',
        padding: '0px 10px 0px 20px',
      }}
    >
      <span
        className={clsx(classes.regularStyle, {
          [classes.playingTextStyle]: isSelected,
        })}
      >
        {name}
      </span>

      <div className="clear" />

      <div className={classes.rightIcon}>
        {rightIcon(shared, songIsPlaying, playerIsPlaying)}
      </div>
    </ListItem>
  );
};

export default container(PlaylistListItem);
