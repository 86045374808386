import { combineReducers } from 'redux';

import siteCoordinatorReducer from './siteCoordinator/siteCoordinator.reducer';
import playerReducer from './player/player.reducer';
import adReducer from './ad/ad.reducer';
import articleReducer from './article/article.reducer';
import userhistoryReducer from './userhistory/userhistory.reducer';
import videoReducer from './video/video.reducer';
import userReducer from './user/user.reducer';
import loginReducer from './login/login.reducer';
import fbReducer from './facebook/facebook.reducer';
import googleReducer from './google/google.reducer';
import streamsReducer from './streams/streams.reducer';
import playlistReducer from './playlist/playlist.reducer';
import searchReducer from './search/search.reducer';
import yourlorReducer from './yourlor/yourlor.reducer';
import upsellReducer from './upsell/upsell.reducer';
import artistReducer from './artist/artist.reducer';
import notificationReducer from './notification/notification.reducer';
import tooltipReducer from './tooltip/tooltip.reducer';
import radioReducer from './radio/radio.reducer';
import userhistoryRadioReducer from './userhistoryRadio/userhistoryRadio.reducer';
import playerRadioReducer from './playerRadio/playerRadio.reducer';
import searchRadioReducer from './searchRadio/searchRadio.reducer';
import playerPodCastReducer from './playerPodCast/playerPodCast.reducer.js';
import podcastReducer from './podcast/podcast.reducer';
import userhistoryPodcastReducer from './userHistoryPodcast/userHistoryPodcast.reducer';
import podcastPlaylistReducer from './podcastPlaylist/podcastPlaylist.reducer';
import searchPodcastReducer from './searchPodcast/searchPodcast.reducer';

const rootReducer = combineReducers({
  ad: adReducer,
  article: articleReducer,
  artist: artistReducer,
  fb: fbReducer,
  google: googleReducer,
  login: loginReducer,
  notification: notificationReducer,
  player: playerReducer,
  playlist: playlistReducer,
  search: searchReducer,
  siteCoordinator: siteCoordinatorReducer,
  streams: streamsReducer,
  tooltip: tooltipReducer,
  upsell: upsellReducer,
  user: userReducer,
  userhistory: userhistoryReducer,
  video: videoReducer,
  yourlor: yourlorReducer,
  radio: radioReducer,
  playerRadio: playerRadioReducer,
  userhistoryRadio: userhistoryRadioReducer,
  searchRadio: searchRadioReducer,
  playerPodCast: playerPodCastReducer,
  podcast: podcastReducer,
  userhistoryPodcast: userhistoryPodcastReducer,
  searchPodcast: searchPodcastReducer,
  podcastPlaylist: podcastPlaylistReducer
});
export default rootReducer;
