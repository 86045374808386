const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 120,
    padding: '8px 8px 8px 12px',
    borderBottom: '1px solid',
    cursor: 'pointer',

    image: {
      width: 100,
      height: 100,
      borderRadius: 2,
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    },

    info: {
      flex: '1 1 0%',
      fontFamily: 'Arial',
      flexGrow: 1,
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      title: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 1,
        marginTop: 5
      },

      message: {
        opacity: 0.8,
        display: 'inline',
        fontSize: 14,
        fontWeight: 400,
      },

      info: {
        fontSize: 14,
        fontWeight: 400,
        opacity: 0.8,
        marginTop: 10,
      },
    },

    actions: {
      button: {
        width: 40,
        height: 40,
        padding: 0,
        marginRight: 10,
        display: 'inline-block',
        marginTop: 40,

        icon: {
          width: 40,
          height: 40,
        },
      },
    },
  },
};

export default styles;
