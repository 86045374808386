import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import DateRangeIcon from '@material-ui/icons/DateRange';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginRight: 10,
    fontSize: 12,
    lineHeight: '16px',
  },
  iconStyle: {
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 1,
    marginBottom: -2,
    display: 'inline-block',
    color: 'rgb(37, 37, 37)',
    userSelect: 'none',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
}));

const styles = {
  iconStyle: {
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 1,
  },
};

const LastViewed = (props) => {
  const classes = useStyles();
  if (!props.show) {
    return null;
  }

  return (
    <Hidden smDown={!props.showMobile} className={classes.container}>
      <DateRangeIcon
        className={classes.iconStyle}
        style={{
          fill: props.color,
        }}
      />
      <span style={{ color: props.color, marginRight: 10, marginTop: 2 }}>
        {moment((props.date || 0) + '', 'X').fromNow()}
      </span>
    </Hidden>
  );
};

export default LastViewed;
