const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',

    firstItem: {
      width: '100%',
      maxWidth: 350,
      textAlign: 'center',
      height: 200,

      image: {
        width: 250,
        marginTop: 10,
        borderRadius: 28,
      },

      description: {
        fontSize: 12,
        color: '#9B9B9B',
        fontWeight: 300,
        fontFamily: 'Arial',
        textAlign: 'center',
        lineHeight: '14px',
        margin: '10px auto',
        display: 'block',
      },
    },

    secondItem: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 350,
      height: 200,

      input: {
        fontFamily: 'Arial',
        color: 'black',
      },

      textfield: {
        width: '80%',
        top: '50%',
        transform: 'translateY(-50%)',
      },

      button: {
        color: 'rgb(255, 0, 102)',
        height: 48,
        width: '20%',
        minWidth: '20%',
        top: '50%',
        transform: 'translateY(-50%)',
      },

      description: {
        display: 'block',
        fontSize: 12,
        color: '#9B9B9B',
        fontWeight: 300,
        textAlign: 'center',
        lineHeight: '14px',
        margin: '10px auto',
        marginTop: 15,
      },
    },
  },
};

export default styles;
