import { connect } from 'react-redux';
import { toggleSearch } from 'store/searchRadio/searchRadio.actions';
import { setSwitchTab } from 'store/siteCoordinator/siteCoordinator.actions';

const mapStateToProps = (state) => ({
  searchRadioState: state.searchRadio,
  siteCoordinatorState: state.siteCoordinator,
  // userState: state.user,
  userhistoryState: state.userhistory,
});

const mapDispatchToProps = (dispatch) => ({
  onToggleSearch: (value) => dispatch(toggleSearch(value)),
  onSetSwitchTab: (value) => dispatch(setSwitchTab(value)),
});

const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
