import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RepeatIcon2 = props => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g id="ic_repeat">
        <path d="M12.8110867,2.51789455 L13.3313633,1.14289591 C13.3313633,0.570087802 12.9436767,0.335891856 12.4695722,0.622203342 L8.48364464,3.03099659 C8.00962857,3.31740063 8.00962857,3.78607023 8.48364464,4.07247428 L12.4695722,6.48126753 C12.9436767,6.76767159 13.3313633,6.53338308 13.3313633,5.96057496 L12.8110867,4.65527969 C16.8643265,5.06294726 20.0442764,8.64670064 20.0442764,12.993581 C20.0442764,17.6128878 16.4532885,21.3709459 12.0393401,21.3709459 C7.62539163,21.3709459 4.03440371,17.6128878 4.03440371,12.993581 C4.03440371,10.4516756 5.1190947,8.07602158 7.01020563,6.47562091 C7.44707172,6.10590604 7.51482621,5.43571685 7.16163603,4.97852564 C6.8083574,4.52133442 6.16787405,4.45042767 5.73109641,4.82004997 C3.3598662,6.82654456 2,9.80573916 2,12.993581 C2,18.7868296 6.50363911,23.5 12.0393401,23.5 C17.5750411,23.5 22.0786802,18.7868296 22.0786802,12.993581 C22.0786802,7.47211077 17.9875827,2.93167159 12.8110867,2.51789455" />
        <rect id="Rectangle" fill="none" x="0" y="0" width="24" height="24" />
      </g>
    </SvgIcon>
  );
};

export default RepeatIcon2;
