import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import LoginStatus from 'constants/LoginStatus';

import { PlayerControls } from 'components/MainColumn/components';
import { HeaderUserIcon, HeaderMenu } from './components';
import { RepeatUserIcon2 } from 'common/ui/icons';

import container from './HeaderRight.container';

const useStyles = makeStyles((theme) => ({
  headerRight: {
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 13,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 21,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 7,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 3,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 1,
    },
  },
  loginButton: {
    color: theme.palette.white,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Arial',
    letterSpacing: 0,
    border: '10px',
    boxSizing: 'border-box',
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    margin: '0px -50px 0px 0px',
    padding: '0px 50px 0px 0px',
    outline: 'none',
    // fontSize: 'inherit'
    // fontWeight: 'inherit',
    // color: 'white',
    position: 'relative',
    height: '50px',
    lineHeight: '36px',
    minWidth: '88px',
    borderRadius: '2px',
    userSelect: 'none',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    textAlign: 'center',
    // [theme.breakpoints.up('sm')]: {
    //   marginLeft: 14,
    // },
    '&:hover': {
      background: 'rgba(153, 153, 153, 0.2)',
    },
  },
  smallIconMainContainer: {
    marginRight: 20,
    display: 'inline-flex',
    position: 'relative',
    fontSize: 10,
    width: 30,
    height: 50,
    color: '#bbb',
  },
  smallIconSubContainer1: {
    height: 14,
    position: 'absolute',
    left: 0,
    top: 10,
  },
  smallIconSubContainer2: {
    height: 14,
    position: 'absolute',
    left: 0,
    top: 27,
  },
  smallIcon: {
    width: 14,
    height: 14,
    float: 'left',
    color: '#bbb',
  },
  smallIconText: {
    fontFamily: 'Arial',
    float: 'left',
    position: 'absolute',
    left: 20,
    top: 1,
  },
}));

const HeaderRight = (props) => {
  const classes = useStyles();

  const {
    userState: { loginStatus, firstName, avatarUrl },
    repeats,
    hearts,
    onShowLoginModal,
  } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const isLoggedIn = loginStatus === LoginStatus.Authenticated;

  const onLoginTap = () => {
    if (isLoggedIn) {
      // TODO: Public profile page
      // router.route('profile')
    } else {
      onShowLoginModal();
    }
  };

  return (
    <div className={classes.headerRight}>
      <Hidden xsDown>
        <PlayerControls />
      </Hidden>

      {!isLoggedIn && (
        <Button classes={{ root: classes.loginButton }} onClick={onLoginTap}>
          <span style={{ padding: '0px 16px 0px 16px' }}>Login</span>
        </Button>
      )}

      <HeaderUserIcon
        isLoggedIn={isLoggedIn}
        pictureUrl={avatarUrl}
        firstName={firstName}
        onLoginTap={onLoginTap}
      />
      <Hidden xsDown>
        <div className={classes.smallIconMainContainer}>
          <div className={classes.smallIconSubContainer1}>
            <RepeatUserIcon2 className={classes.smallIcon} />
            <span className={classes.smallIconText}>{repeats}</span>
          </div>

          <div className={classes.smallIconSubContainer2}>
            <FavoriteBorderIcon className={classes.smallIcon} />
            <span className={classes.smallIconText}>{hearts}</span>
          </div>
        </div>

        <Tooltip title="More">
          <IconButton
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{
              marginRight: 4,
              marginTop: -2,
            }}
          >
            <KeyboardArrowDownIcon style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <HeaderMenu
                open={open}
                handleClose={handleClose}
                isLoggedIn={isLoggedIn}
              />
            </Grow>
          )}
        </Popper>
      </Hidden>
    </div>
  );
};

export default container(HeaderRight);
