import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import _ from 'lodash';
import * as cookie from 'component-cookie';

import axios from 'instances/server';

import SectionTab from 'constants/SectionTab';
import Feature from 'constants/Feature';

import * as ArtistType from './artist.types';

import { setCurrentTab } from 'store/siteCoordinator/siteCoordinator.actions';
import {
  getArtistRecsSuccess,
  getArtistRecsDataSuccess,
  getSearchPreviewSuccess,
  setRadioFromSearch,
  getViewVideoSuggestionSuccess,
  getArtistInfoSuccess,
  setDataSuccess,
  setRadio,
  setRadioFromVideo,
  cueSongSuccess,
  removeArtistRadioRec,
  removeItemFromCue,
} from './artist.actions';
import { videoSearchEligiblity } from '../yourlor/yourlor.sagas';
import { playFeature, viewPlayerVideoStart } from '../player/player.actions';

import { hiddenSearch } from '../search/search.sagas';

import { normalizeArtistName } from './artist.utils';
import { parseDuration } from 'common/yt_utils';

import {
  selectVideosForPreview,
  selectVideoStatsForPreview,
} from '../video/video.selectors';
import { queueYtid } from 'store/playlist/playlist.actions';

const getArtistState = (state) => state.artist;
const getPlayer = (state) => state.player;
const getVideo = (state) => state.video;

export function* setDataAsync({ payload: { artistAPIData, title } }) {
  // array of similar artist + we insert the actual artist at the start of this array
  let artists = yield !_.isNull(artistAPIData) &&
  !_.isNull(artistAPIData.similar)
    ? [].concat(
        { image: artistAPIData.image, name: artistAPIData.name },
        artistAPIData.similar
      )
    : [];

  yield put(setDataSuccess({ artists, title }));
  return { artists, title };
}

/** Video Suggestion **/
export function* getViewVideoSuggestionAsync({ payload: { videoName, ytid } }) {
  const titleParts = videoName.split('-');
  const likelyAristName = titleParts[0].trim();

  if (titleParts.length !== 2) {
    return;
  }

  yield put(setRadioFromVideo(ytid, likelyAristName));

  try {
    const { data } = yield axios.get('/artist/radio', {
      params: {
        ytid,
        regen: false,
      },
    });
    // console.log({ sugestion: data });
    if (!data) {
      return;
    }
    yield put(getViewVideoSuggestionSuccess(data));
  } catch (err) {
    console.error('error::' + err);
  }
}

/** Called when a search is done to generate Artist Radio Suggestion **/
export function* getSearchPreviewAsync({ payload: { artistName, ytid } }) {
  // console.log('getSearchPreview::', { artistName, ytid });
  yield put(setRadioFromSearch(ytid, artistName));

  let radioChance = Math.floor(Math.random() * 10);
  // console.log('getSearchPreview:: chance', radioChance);
  if (radioChance === 0) {
    // analytics.actions.trackAction('Radio Regen', artistName);
    window._gaq.push(['_trackEvent', 'Action', 'Radio Regen', artistName]);

  }

  try {
    const { data } = yield axios.get('/artist/radio', {
      params: {
        ytid,
        regen: false,
      },
    });

    // console.log('getSearchPreview::', { data });
    yield put(getSearchPreviewSuccess(data));
    return data;
  } catch (err) {
    return [];
  }
}

/** Called when a starting artist radio to get the recommendations **/
export function* getArtistRecsAsync(name) {
  const data = yield call(getArtistRecsDataAsync, name);
  // console.log('getArtistRecsAsync::', { data });
  yield put(getArtistRecsSuccess(data.recs));
  return data.recs;
}

export function* getArtistRecsDataAsync(name, regen = false) {
  const artistState = yield select(getArtistState);
  // console.log('artist:: getArtistRecsData:: 0', { name });
  const normalizedName = normalizeArtistName(name);
  // console.log('artist:: getArtistRecsData:: 1', { normalizedName });
  const cachedData = artistState.artistsRecs[normalizedName];
  // console.log('artist:: getArtistRecsData:: 2', { cachedData });

  const response = { name, recs: [] };

  if (cachedData) {
    response.recs = cachedData;
    return response;
  }
  // console.log('artist:: getArtistRecsData:: 3');

  if (name.length === 0) {
    return response;
  }

  // console.log('artist:: getArtistRecsData:: 4');
  try {
    const { data } = yield axios.get('/artist/radio', {
      params: {
        name,
        regen,
      },
    });

    // console.log('artist:: getArtistRecsData:: 5', { data });

    if (!data || _.isNil(data)) {
      // yield put(getArticlesFail(err));
      yield put(getArtistRecsDataSuccess(response));
      return response;
    }

    response.recs = data;
    // console.log('artist:: getArtistRecsData:: 6', { response });
    yield put(getArtistRecsDataSuccess(response));
    return response;
  } catch (err) {
    // console.error('artist:: getArtistRecsData:: 7', { err });
    // yield put(getArticlesFail(err));
    yield put(getArtistRecsDataSuccess(response));
    return response;
  }
}

export function* getArtistInfoAsync({ payload: name }) {
  const artistState = yield select(getArtistState);

  const normalizedName = normalizeArtistName(name);
  const cachedInfoData = artistState.artistsInfo[normalizedName];
  const response = { name, info: null };
  if (cachedInfoData) {
    response.info = cachedInfoData;
    yield put(getArtistInfoSuccess(response));
    return response;
  }
  try {
    const { data } = yield axios.get('/artist/info', {
      params: {
        name,
      },
    });

    response.info = data;
    yield put(getArtistInfoSuccess(response));
    return response;
  } catch (err) {
    console.error('artist:: getArtistInfo', { err });
    // yield put(getArticlesFail(err));
  }
}

export function* getArtistInfoFromTitleAsync({ payload: title }) {
  const titleParts = title.split('-');
  let likelyAristName = titleParts[0].trim();

  if (titleParts.length !== 2) {
    return;
  }

  yield call(getArtistInfoAsync, {
    payload: likelyAristName,
  });
}

/* Set initial radio details */
export function* setRadioAsync(radioName, artistRadioRecs = []) {
  yield put(playFeature(Feature.Radio));
  yield put(setRadio({ radioName, artistRadioRecs }));
}
/** Play the next item in the artist radio queue (without removing an item from the queue) **/
export function* skipArtistRadioSaga() {
  const artistState = yield select(getArtistState);

  if (_.isNil(artistState.nextVideos[0])) {
    return;
  }

  const ytid = artistState.nextVideos[0].ytid;
  const artistName = artistState.nextVideos[0].artist;

  const dataPlayload = {
    payload: { ytid, artistName },
  };
  yield call(playArtistRadioSaga, dataPlayload);

  yield put(queueYtid(ytid));
  yield put(removeItemFromCue(ytid));
  yield call(cueAnotherSong);
}

function* trackFirstPlay() {
  const radioEvent = Number(cookie('analytics-radio-count') || 0) + 1;
  yield cookie('analytics-radio-count', String(radioEvent), {
    maxage: 1000 * 3600 * 24 * 365,
    path: '/',
  });
  if (radioEvent === 1) {
    // analytics.actions.trackAction('First Artist Radio', String(radioEvent));
    window._gaq.push(['_trackEvent', 'Action', 'First Artist Radio', String(radioEvent)]);

  }
}

/** Should call on first run of radio **/
export function* setupArtistRadioAsync({ payload: { artistName, ytid } }) {
  // analytics.actions.trackAction('Artist Radio Start', artistName);
  window._gaq.push(['_trackEvent', 'Action', 'Artist Radio Start', artistName]);

  // console.log('artist:: setupArtistRadio :: 1', { artistName, ytid });
  yield call(setRadioAsync, artistName);
  yield call(trackFirstPlay);

  // console.log('artist:: setupArtistRadio :: 2');
  // When given initial video play it
  const givenVideo = ytid !== null;
  if (givenVideo) {
    // console.log('artist:: setupArtistRadio :: 3');
    const dataPlayload = {
      payload: { ytid, artistName },
    };
    yield call(playArtistRadioSaga, dataPlayload);
  }

  // console.log('artist:: setupArtistRadio :: 4');
  yield call(getArtistRecsAsync, artistName);
  ytid = yield call(cueRandomSong, artistName, !givenVideo);
  // console.log('artist:: setupArtistRadio :: 5', { ytid });

  // When not given initial video, play first in que
  if (!givenVideo && ytid !== null) {
    // console.log('artist:: setupArtistRadio :: 6');
    const dataPlayload = {
      payload: { ytid, artistName },
    };
    yield call(playArtistRadioSaga, dataPlayload);
    yield put(removeItemFromCue(ytid));
  }
  // console.log('artist:: setupArtistRadio :: 7');
  yield put(setCurrentTab(SectionTab.Radio));
  return ytid;
}

/*
Play the artist radio given an artist to start from.
When ytid given then will play that video first.
*/
export function* playArtistRadioSaga({ payload: { ytid, artistName } }) {
  // console.log('artist:: playArtistRadio');
  // analytics.actions.trackAction('Artist Radio Song', artistName);
  window._gaq.push(['_trackEvent', 'Action', 'Artist Radio Song', artistName]);

  yield put(viewPlayerVideoStart(ytid, true));
}

export function* repeatCueSaga({ payload: amount }) {
  let searchList = amount > 0;
  while (searchList) {
    if (amount === 0) {
      searchList = false;
    }

    yield call(cueAnotherSong);
    amount--;
  }
}

// Add a new song to the cue based last in cue
export function* cueAnotherSong() {
  const artistState = yield select(getArtistState);
  const lastVideoInCue =
    artistState.nextVideos[artistState.nextVideos.length - 1];
  const ytid = lastVideoInCue.ytid;
  const artistName = lastVideoInCue.artist;

  return yield call(cueRandomSong, artistName);
}

/*
  Add a new song to the cue based on the given artist.
  Selects same artist or random new artist that should be played.
  Get song for the given artist.
  If getting song fails then remove artis from recs and retry with new artist.
*/
export function* cueRandomSong(
  artistName,
  keepExisting = false,
  ineligbleArtists = []
) {
  const artistState = yield select(getArtistState);
  // console.log('artist:: cueRandomSong :: 1');
  let artistSelector = Math.floor(Math.random() * 100) / 100;
  // console.log('artist:: cueRandomSong :: 2', { artistSelector });

  let nextArtist = null;

  // Choose next artist
  if (artistSelector <= 0.2 || keepExisting) {
    // console.log('artist:: cueRandomSong :: 3', { artistSelector });
    nextArtist = artistName;
  } else {
    // console.log('artist:: cueRandomSong :: 4');
    const stillEligibleRecs = artistState.artistRadioRecs.filter((rec) => {
      return !ineligbleArtists.some(
        (ineligbleArtist) => ineligbleArtist === rec.name
      );
    });
    // console.log('artist:: cueRandomSong :: 5');
    const newArtistInfo = selectNewArtist(stillEligibleRecs);
    // console.log('artist:: cueRandomSong :: 6', { newArtistInfo });
    if (!_.isNil(newArtistInfo)) {
      // console.log('artist:: cueRandomSong :: 7');
      nextArtist = newArtistInfo.name;
    }
  }

  // console.log('artist:: cueRandomSong :: 8');

  // When no eligible artists then do not search
  if (!nextArtist) {
    // console.log('artist:: cueRandomSong :: 9');
    // Only track on first iteration when no eligble artists identified
    if (ineligbleArtists.length > 0) {
      // console.log('artist:: cueRandomSong :: 10');
      // analytics.actions.trackAction(
      //   'Artist Radio Empty',
      //   artist.store.state.radioName
      // );
      // Could show message to users at this point if common
      console.log('No more eligible artists');
    }

    return null;
  }

  // console.log('artist:: cueRandomSong :: 11');

  try {
    const ytid = yield call(cueSongForArtist, nextArtist);
    // console.log('artist:: cueRandomSong :: 12');
    return ytid;
  } catch (err) {
    // console.log('artist:: cueRandomSong :: 13');
    yield put(removeArtistRadioRec(nextArtist));
    const data = yield* cueRandomSong(
      artistName,
      false,
      ineligbleArtists.concat([nextArtist])
    );

    return data;
  }
}

const selectNewArtist = (artistRadioRecs) => {
  if (_.isNull(artistRadioRecs)) {
    return null;
  }

  const artistSelection = Math.random() * artistRadioRecs.length;
  let cummulativeProbability = 0.0;
  for (let i = 0; i < artistRadioRecs.length; i++) {
    cummulativeProbability +=
      artistRadioRecs[i].weight * artistRadioRecs.length;
    if (cummulativeProbability >= artistSelection) {
      return artistRadioRecs[i];
    }
  }

  return artistRadioRecs[artistRadioRecs.length - 1];
};

// Cue song for a given artist if possible
export function* cueSongAsync(ytid, nextArtist) {
  // console.log('artist:: cueSong :: 1', { ytid, nextArtist });
  const artistState = yield select(getArtistState);
  // console.log('artist:: cueSong :: 2', { artistState });

  yield put(cueSongSuccess(ytid, nextArtist));

  // console.log('artist:: cueSong :: 3');

  // Ensure 5 items in the queue
  if (artistState.nextVideos.length < 5) {
    // console.log('artist:: cueSong :: 4');
    yield call(cueRandomSong, nextArtist);
  }
}

// Cue song for a given artist if possible
export function* cueSongForArtist(nextArtist) {
  // console.log('artist:: cueSongForArtist :: 1');

  const resp = yield call(hiddenSearch, nextArtist);
  // console.log('artist:: cueSongForArtist :: 2', { resp });

  try {
    const ytid = yield call(findRandomVideo, resp['res'], nextArtist, 'artist');
    // console.log('artist:: cueSongForArtist :: 3', { ytid });

    if (_.isNull(ytid)) {
      console.error('artist:: cueSongForArtist :: 4');
      throw new Error('No eligible videos for artist');
    }

    // console.log('artist:: cueSongForArtist :: 5');
    yield call(cueSongAsync, ytid, nextArtist);
    return ytid;
  } catch (err) {
    console.error('artist:: cueSongForArtist :: 6');
    throw new Error('No eligible videos for artist');
  }
}

// The criteria video must meet to be played
function* isEligibleVideo(title, formattedArtist, viableYtid, type) {
  const playerState = yield select(getPlayer);
  const lowerCaseTitle = title.toLowerCase();
  // console.log('artist:: isEligibleVideo', {
  //   title,
  //   formattedArtist,
  //   viableYtid,
  //   type,
  // });

  const valid1 = titleFirstPartContainsArtist(lowerCaseTitle, formattedArtist);
  // console.log('artist:: isEligibleVideo:: 1', valid1);
  if (!valid1) {
    return false;
  }
  const valid2 = !titleContainsExclude(lowerCaseTitle);
  // console.log('artist:: isEligibleVideo:: 2', valid2);
  if (!valid2) {
    return false;
  }
  const valid3 = titleValidRemix(lowerCaseTitle, formattedArtist);
  // console.log('artist:: isEligibleVideo:: 3', valid3);
  if (!valid3) {
    return false;
  }
  const valid4 = viableYtid !== playerState.ytid;
  // console.log('artist:: isEligibleVideo:: 4', valid4);
  if (!valid4) {
    return false;
  }
  let valid5;
  if (type === 'artist') {
    // console.log('artist:: isEligibleVideo:: 5.1');
    valid5 = yield call(extraEligiblity, viableYtid);
  } else if (type === 'discovery') {
    // console.log('discovery:: isEligibleVideo:: 5.2');
    valid5 = yield call(videoSearchEligiblity, viableYtid);
  }
  // console.log('artist:: isEligibleVideo:: 5', valid5);
  if (!valid5) {
    return false;
  }

  return true;
}

function* extraEligiblity(viableYtid) {
  const artistState = yield select(getArtistState);

  const selectedVideo = yield select(selectVideosForPreview(viableYtid));
  const selectedVideoStats = yield select(
    selectVideoStatsForPreview(viableYtid)
  );

  // console.log('artist:: extraEligiblity', { viableYtid, selectedVideoStats });

  const valid1 = selectedVideoStats?.totalRepeats > 0;
  // console.log('artist:: extraEligiblity :: 1', valid1);
  if (!valid1) {
    return false;
  }
  const valid2 = parseDuration(selectedVideo?.duration) < 600;
  // console.log('artist:: extraEligiblity :: 2', valid2);
  if (!valid2) {
    return false;
  }
  const valid3 =
    artistState.searchingQueue.filter((item) => item === viableYtid).length ===
    0;
  // console.log('artist:: extraEligiblity :: 3', valid3);
  if (!valid3) {
    return false;
  }
  const valid4 = artistState.radioHistory.indexOf(viableYtid) === -1;
  // console.log('artist:: extraEligiblity :: 4', valid4);
  if (!valid4) {
    return false;
  }
  const valid5 =
    artistState.nextVideos.filter((item) => item.ytid === viableYtid).length ===
    0;
  // console.log('artist:: extraEligiblity :: 5', valid5);
  if (!valid5) {
    return false;
  }
  return true;
}

const excludes = [
  'album',
  'documentary',
  'interview',
  'live @',
  'live at',
  '(live)',
  'trailer',
  'react',
];

function titleFirstPartContainsArtist(lowerCaseTitle, formattedArtist) {
  let splitTitle = lowerCaseTitle.split('-');
  return (
    splitTitle.length > 1 &&
    splitTitle[0].trim().indexOf(formattedArtist) !== -1
  );
}

function titleContainsExclude(lowerCaseTitle) {
  return excludes.some((exclude) => lowerCaseTitle.indexOf(exclude) !== -1);
}

// If it has "artist remix" then it is fine, but if it has just remix - then it will not be used
function titleValidRemix(lowerCaseTitle, formattedArtist) {
  return (
    (lowerCaseTitle.indexOf('remix') > -1 &&
      lowerCaseTitle.indexOf(formattedArtist + ' remix') > -1) ||
    lowerCaseTitle.indexOf('remix') === -1
  );
}

/** Find video from results randomly **/
export function* findRandomVideo(list, artistName, type) {
  let searchList = true;

  // console.log('artist:: findRandomVideo:: 0', {
  //   list,
  //   artistName,
  //   type,
  // });

  const formattedArtist = artistName
    .replace(new RegExp('_', 'g'), ' ')
    .toLowerCase();

  // console.log('artist:: findRandomVideo:: 1', { formattedArtist });

  const indexesToSearch = [...list];

  // console.log('artist:: findRandomVideo:: 2', {
  //   indexesToSearch: indexesToSearch.length,
  // });

  let viableYtid;

  let count = 0;
  // Search through results
  while (searchList) {
    // console.log(`artist:: findRandomVideo:: loop${count}:: 1`, {
    //   indexesToSearch: indexesToSearch.length,
    // });
    // If there are no results left then finish
    if (indexesToSearch.length === 0) {
      if (list.length > 0) {
        // console.log(`artist:: findRandomVideo:: loop${count}:: 2`);
        const randomPosition = Math.floor(Math.random() * list.length);
        // console.log(`artist:: findRandomVideo:: loop${count}:: 3`, {
        //   randomPosition,
        // });

        viableYtid = list[randomPosition].ytid;
        // console.log('artist:: findRandomVideo:: final:: 4', {
        //   ytid: viableYtid,
        // });
        searchList = false;
        return;
      }

      searchList = false;

      console.error(`artist:: findRandomVideo:: loop${count}:: 8`);
      throw new Error('Could not find ytid');
    }

    // Find a random number that has not been selected already, then remove
    const randomPosition = Math.floor(Math.random() * indexesToSearch.length);
    // console.log(`artist:: findRandomVideo:: loop${count}:: 3`, {
    //   randomPosition,
    // });

    // console.log(`artist:: findRandomVideo:: loop${count}:: 4`, {
    //   indexesToSearch: indexesToSearch.length,
    // });
    let title = indexesToSearch[randomPosition].title;
    viableYtid = indexesToSearch[randomPosition].ytid;
    indexesToSearch.splice(randomPosition, 1);
    // console.log(`artist:: findRandomVideo:: loop${count}:: 4.5`, {
    //   indexesToSearch: indexesToSearch.length,
    // });

    // console.log(`artist:: findRandomVideo:: loop${count}:: 5`, {
    //   title,
    //   viableYtid,
    // });

    const eligibleVideo = yield call(
      isEligibleVideo,
      title,
      formattedArtist,
      viableYtid,
      type
    );

    // console.log(`artist:: findRandomVideo:: loop${count}:: 6`, {
    //   eligibleVideo,
    // });

    count++;
    if (eligibleVideo) {
      // console.log('artist:: findRandomVideo:: final:: 7', {
      //   ytid: viableYtid,
      // });
      searchList = false;
    }
  }
  // console.log('artist:: findRandomVideo:: 3', {
  //   ytid: viableYtid,
  // });
  return viableYtid;
}

export function* watchGetSearchPreview() {
  yield takeLatest(ArtistType.GET_SEARCH_PREVIEW_START, getSearchPreviewAsync);
}

export function* watchGetViewVideoSuggestion() {
  yield takeLatest(
    ArtistType.GET_VIEW_VIDEO_SUGGESTION_START,
    getViewVideoSuggestionAsync
  );
}

export function* watchGetArtistInfoFromTitle() {
  yield takeLatest(
    ArtistType.GET_ARTIST_INFO_TITLE,
    getArtistInfoFromTitleAsync
  );
}

export function* watchSetData() {
  yield takeLatest(ArtistType.SET_DATA_START, setDataAsync);
}

export function* watchSetupArtistRadio() {
  yield takeLatest(ArtistType.SETUP_ARTIST_RADIO, setupArtistRadioAsync);
}

export function* watchSkipArtistRadio() {
  yield takeLatest(ArtistType.SKIP_ARTIST_RADIO, skipArtistRadioSaga);
}

export function* watchPlayArtistRadio() {
  yield takeLatest(ArtistType.PLAY_ARTIST_RADIO, playArtistRadioSaga);
}

export function* watchRepeatCue() {
  yield takeLatest(ArtistType.REPEAT_CUE, repeatCueSaga);
}

export function* artistSagas() {
  yield all([
    call(watchSetData),
    call(watchSetupArtistRadio),
    call(watchGetSearchPreview),
    call(watchGetViewVideoSuggestion),
    call(watchGetArtistInfoFromTitle),
    call(watchSkipArtistRadio),
    call(watchPlayArtistRadio),
    call(watchRepeatCue),
  ]);
}
