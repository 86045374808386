import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import { RepeatUserIcon2 } from 'common/ui/icons';
import { longNumberString, shortNumberString } from 'common/utils';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginRight: 10,
    fontSize: 12,
    lineHeight: '16px',
  },
  iconStyle: {
    width: 14,
    height: 14,
    marginRight: 5,
    marginTop: 1,
    marginBottom: -2,
    display: 'inline-block',
    color: 'rgb(37, 37, 37)',
    userSelect: 'none',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
}));

const UserRepeats = (props) => {
  const classes = useStyles();

  const n = Number(props.n);
  const userRepeatsInfo = 'Your repeats on LOR: ' + longNumberString(n);

  return (
    <Hidden smDown={!props.showMobile} className={classes.container}>
      <div title={userRepeatsInfo}>
        <RepeatUserIcon2
          className={classes.iconStyle}
          style={{
            fill: props.color,
          }}
        />
        <span style={{ color: props.color, marginRight: 10, marginTop: 2 }}>
          {shortNumberString(n)}
        </span>
      </div>
    </Hidden>
  );
};

export default UserRepeats;
