import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import DeleteIcon from '@material-ui/icons/Delete';
import { pluralizeUnits } from 'common/utils';

import styles from './PlaylistItemRow.style';

import container from './PlaylistItemRow.container';

const useStyles = makeStyles((theme) => ({
  item: {
    ...styles.item,
    borderBottomColor: theme.palette.borderColor,
  },
  title: {
    ...styles.item.title,
  },
  count: {
    ...styles.item.count,
  },
  iconButton: {
    ...styles.item.iconButton,
  },
}));

const PlaylistItemRow = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    pid,
    onOpenPlaylistStart,
    onPlayPlaylistStart,
    onDeletePlaylistStart,
    onShowSnackbarNotification,
  } = props;

  const accent2Color = theme.palette.accent2Color;

  const onClickPlaylist = () => {
    onOpenPlaylistStart(props.pid);
    // analytics.actions.trackUIClick('Opened Playlist', props.pid)
  };

  const onPlayPlaylist = (e) => {
    e.stopPropagation();

    onOpenPlaylistStart(props.pid);
    onPlayPlaylistStart(props.pid);
  };

  const onDeletePlaylistClick = (evt) => {
    evt.stopPropagation();

    let element = evt.currentTarget;
    if (props.isFollowing) {
      //  toggleDeletePlaylistToolTip(
      //   'Remove bookmark',
      //   'Are you sure you want to do this?',
      //   () => {
      //     playlist.actions.deleteFollowedPlaylist(props.pid);
      //     notification.actions.showSnackbarNotification('Removed bookmark 🗑');
      //   },
      //   element
      // );
    } else {
      //  toggleDeletePlaylistToolTip(
      //   'Delete Playlist',
      //   'Are you sure you want to do this?',
      //   () => {
      deletePlaylistConfirm(props.pid);
      //     notification.actions.showSnackbarNotification('Removed Playlist 🗑');
      //   },
      //   element
      // );
    }
  };

  const deleteFollowedPlaylistConfirm = (pid) => {};

  const deletePlaylistConfirm = (pid) => {
    onDeletePlaylistStart(pid);
    onShowSnackbarNotification('Removed Playlist 🗑');
  };

  return (
    <li
      key={pid}
      className={clsx('playlist-row-item', classes.item)}
      onClick={onClickPlaylist}
    >
      <span className={classes.title}>{props.name}</span>

      {/*/!*{props.isPlaying &&*!/*/}
      {/*/!*<PlayIndicatorIcon display={true} />*!/*/}
      {/*/!*}*!/*/}

      <span className={classes.count}>
        {pluralizeUnits(props.length, 'video', 'videos')}
      </span>

      <Tooltip title="Play playlist">
        <IconButton className={classes.iconButton} onClick={onPlayPlaylist}>
          <PlayCircleFilledIcon style={{ color: accent2Color }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Playlist">
        <IconButton
          className={classes.iconButton}
          onClick={onDeletePlaylistClick}
        >
          <DeleteIcon style={{ color: accent2Color }} />
        </IconButton>
      </Tooltip>
    </li>
  );
};

PlaylistItemRow.propTypes = {
  uid: PropTypes.string.isRequired,
  pid: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  firstYtid: PropTypes.string,
  isPlaying: PropTypes.bool,
  isFollowing: PropTypes.bool,
};

export default container(PlaylistItemRow);
