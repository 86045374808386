import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  GlobalRepeatStat,
  UserRepeatStat,
  PlaylistAddButton,
  ToggleHeartButton,
  Slider,
} from './components';

import ShareButtons from '../ShareButtons';

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    },
  },
  missionStatement: {
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: '1.5rem',
    maxWidth: '50em',
    lineHeight: 1.4,
  },
  avatar: {
    height: '25em',
    width: '25em',
    [theme.breakpoints.down('sm')]: {
      height: '20em',
      width: '20em',
      maxHeight: 300,
      maxWidth: 300,
    },
  },
  videoRepeatStatus: {
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: 5,
    paddingRight: 5,
    borderColor: 'rgb(238, 238, 238)',
  },
  socialIcon: {
    borderRadius: 2,
    width: 17,
    marginRight: 5,
    cursor: 'pointer',
  },
}));

const StatusBar = (props) => {
  const classes = useStyles();

  const { ytid, artistState, playerState, streamState, videoStore } = props;
  const iconColor = '#eee';
  const userPlay = props.userPlay || {};
  const videoMetadata = props.videoMetadata || {};
  const videoStats = props.videoStats || {};

  const url = window.location.href;
  const playingStream = streamState.streamPlaying;
  const playingArtist = playerState.radioMode;

  let titleContent = 'Check out - ';

  if (playingStream) {
    titleContent = `Check out the stream ${streamState.streams[playingStream]?.name}`;
  } else if (playingArtist) {
    titleContent = `Check out the artist radio for ${artistState.videoPreview?.radioName}`;
  } else if (ytid) {
    titleContent = `Check out ${videoStore.videos[ytid]?.title}`;
  }

  const image = `http://i.ytimg.com/vi/${ytid}/hqdefault.jpg`;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="space-between"
    >
      <Grid item container direction="row" justify="space-between">
        <Grid item className={classes.videoRepeatStatus}>
          <GlobalRepeatStat
            repeats={videoStats.totalRepeats}
            duration={videoMetadata.duration}
            color={iconColor}
          />
          <UserRepeatStat
            repeats={userPlay.repeats}
            duration={videoMetadata.duration}
            color={iconColor}
          />
        </Grid>

        <Grid
          item
          style={{
            flexGrow: 0,
            flexShrink: 0,
            borderColor: 'rgb(238, 238, 238)',
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Grid container>
            <Grid item style={{ display: 'flex' }}>
              <PlaylistAddButton ytid={props.ytid} color={iconColor} />
              <ToggleHeartButton
                ytid={props.ytid}
                heart={userPlay.star}
                color={iconColor}
              />
            </Grid>
            <ShareButtons title={titleContent} image={image} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Slider
          ytid={props.ytid}
          slider={userPlay.slider}
          durationStr={videoMetadata.duration}
        />
      </Grid>
    </Grid>
  );
};

export default StatusBar;
