import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const HeaderLeft = props => {
  const { className, sidebarOpen, onSidebarOpen, onSidebarClose, search, onToggleSearch } = props;

  const searchHandler = () => {
    onToggleSearch(!search);
  };

  return !sidebarOpen ? (
    search ? (
      <IconButton
        color="inherit"
        aria-label="close drawer"
        onClick={searchHandler}
        className={className}
      >
        <ArrowBackIcon />
      </IconButton>
    ) : (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={onSidebarOpen}
        className={className}
      >
        <MenuIcon />
      </IconButton>
    )
  ) : (
    <IconButton
      color="inherit"
      aria-label="close drawer"
      onClick={onSidebarClose}
      className={className}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default HeaderLeft;
